import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createClient } from "../../services/clientServices";
import { addClient } from "../../store/actions/clientActions";
import CloseIcon from "mdi-react/CloseIcon";
import CameraIcon from "mdi-react/CameraIcon";

import "../../assets/scss/global.scss";
import { Modal } from "react-bootstrap";
import { showToast } from "../task-update/utils";

class ClientModal extends Component {
  constructor(props) {
    super(props);
    this.clientImage = createRef();
    this.displayImageSrc = null;
    this.emptyClient = {
      companyName: "",
      companyEmail: "",
      companyPhone: "",
      contactPersonFirstName: "",
      contactPersonLastName: "",
      contactPersonEmail: "",
      contactPersonPhone: "",
      image: "",
    };
    this.state = {
      buttonText: "Create Client",
      isWaiting: false,
      displayImageSrc: this.displayImageSrc,
      client: this.emptyClient,
    };
  }

  componentDidMount() {}

  updateCompanyName = (event) => {
    const { client } = this.state;
    client.companyName = event.target.value;
    this.setState({
      client,
    });
  };

  updateCompanyEmail = (event) => {
    const { client } = this.state;
    client.companyEmail = event.target.value;
    this.setState({
      client,
    });
  };

  updateCompanyPhone = (event) => {
    const { client } = this.state;
    client.companyPhone = event.target.value;
    this.setState({
      client,
    });
  };

  updateContactPersonFirstName = (event) => {
    const { client } = this.state;
    client.contactPersonFirstName = event.target.value;
    this.setState({
      client,
    });
  };

  updateContactPersonLastName = (event) => {
    const { client } = this.state;
    client.contactPersonLastName = event.target.value;
    this.setState({
      client,
    });
  };

  updateContactPersonEmail = (event) => {
    const { client } = this.state;
    client.contactPersonEmail = event.target.value;
    this.setState({
      client,
    });
  };

  updateContactPersonPhone = (event) => {
    const { client } = this.state;
    client.contactPersonPhone = event.target.value;
    this.setState({
      client,
    });
  };

  updateClientImage = (event) => {
    const { client } = this.state;
    let currentDate = new Date();
    currentDate = currentDate.getTime();
    client.image = currentDate + ".png";
    this.setState({
      client,
    });

    let file = event.target.files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(file);
    reader.onloadend = function () {
      this.setState({
        displayImageSrc: [reader.result],
      });
    }.bind(this);
  };

  submit = (e) => {
    e.preventDefault();
    this.setState({ buttonText: "Creating ...", isWaiting: true });

    const { client, displayImageSrc } = this.state;
    const token = this.props.token;

    const fileInfo = this.clientImage.current.files[0];

    let formData = new FormData();
    const imageName = client.image;
    formData.append("client", JSON.stringify(client));

    if (displayImageSrc) {
      formData.append("file", fileInfo, imageName);
    }

    createClient(formData, token).then(async (response) => {
      if (response && response.client) {
        showToast({
          message: {
            title: `Client Created`,
          },
          type: "success",
        });
        await this.props.addClient(response.client);
        this.props.closeModal();
      }
    });
  };

  handleChangeImage = () => {
    this.clientImage.current.click();
  };

  handleCloseModal = (e, wrapper) => {
    this.props.closeModal();
  };

  render() {
    const { isAddModalOpen } = this.props;
    const { client, displayImageSrc, isWaiting } = this.state;
    return (
      <Modal
        show={isAddModalOpen}
        id="add_client"
        className="modal custom-modal fade mx-auto my-3"
        backdropClassName="global-backdrop max-z-index-2"
        role="dialog"
        onHide={(e) => this.handleCloseModal(e, "wrapper")}
        centered={true}
      >
        <Modal.Body bsPrefix="p-0">
          <div className="m-h-between p-4 pb-0">
            <h5 className="modal-title">Add Client</h5>

            <button
              className="modal-global-close-btn"
              onClick={(e) => this.handleCloseModal(e)}
            >
              <CloseIcon />
            </button>
          </div>
          <div>
            <form onSubmit={this.submit} ref="form">
              <div className="p-4 text-sm">
                <div className="d-flex justify-content-center">
                  {!displayImageSrc ? (
                    <div
                      className="bg-img"
                      onClick={() => this.handleChangeImage()}
                    >
                      <CameraIcon />
                    </div>
                  ) : (
                    <img
                      className="r-img"
                      src={displayImageSrc}
                      alt="Client Image"
                      onClick={() => this.handleChangeImage()}
                    />
                  )}
                </div>
                <input
                  className="invisible position-fixed"
                  type="file"
                  label="Upload File"
                  ref={this.clientImage}
                  onChange={(e) => this.updateClientImage(e)}
                />
                <div className="">
                  <div className="mb-4">
                    <label className="col-form-label pt-0">
                      Company Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control fm-input1"
                      type="text"
                      value={client.companyName}
                      onChange={this.updateCompanyName.bind(this)}
                      required
                      placeholder="Enter Company Name"
                    />
                  </div>
                  <div className="row flex justify-content-between">
                    <div className="col-md-6 mb-4">
                      <label className="col-form-label pt-0">
                        Company Email
                      </label>
                      <input
                        className="form-control fm-input1"
                        type="email"
                        value={client.companyEmail}
                        onChange={this.updateCompanyEmail.bind(this)}
                        placeholder="Enter Company Email"
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="col-form-label pt-0">
                        Company Phone
                      </label>
                      <input
                        className="form-control fm-input1"
                        type="tel"
                        value={client.companyPhone}
                        onChange={this.updateCompanyPhone.bind(this)}
                        placeholder="Enter CompanyPhone"
                      />
                    </div>
                  </div>
                  <div className="row flex justify-content-between">
                    <div className="col-md-6 mb-4">
                      <label className="col-form-label pt-0">
                        Contact Person First Name
                      </label>
                      <input
                        className="form-control fm-input1"
                        type="text"
                        value={client.contactPersonFirstName}
                        onChange={this.updateContactPersonFirstName.bind(this)}
                        placeholder="Enter First Name"
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="col-form-label pt-0">
                        Contact Person Last Name
                      </label>
                      <input
                        className="form-control fm-input1"
                        type="text"
                        value={client.contactPersonLastName}
                        onChange={this.updateContactPersonLastName.bind(this)}
                        placeholder="Enter Last Name"
                      />
                    </div>
                  </div>
                  <div className="row flex justify-content-between">
                    <div className="col-md-6 mb-4">
                      <label className="col-form-label pt-0">
                        Contact Person Email
                      </label>
                      <input
                        className="form-control fm-input1"
                        type="email"
                        value={client.contactPersonEmail}
                        onChange={this.updateContactPersonEmail.bind(this)}
                        placeholder="Enter email address"
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="col-form-label pt-0">
                        Contact Person Phone
                      </label>
                      <input
                        className="form-control fm-input1"
                        type="tel"
                        value={client.contactPersonPhone}
                        onChange={this.updateContactPersonPhone.bind(this)}
                        placeholder="Enter phone"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2">
                    <button
                      className={`btn btn-primary submit-btn ${
                        isWaiting ? "disabled" : ""
                      }`}
                    >
                      {this.state.buttonText}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClient: (clients) => {
      dispatch(addClient(clients));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClientModal));
