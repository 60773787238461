import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { URLS } from "../urls";
import Loader from "../global/Loader";
import { showToast } from "../task-update/utils";

const redirectUrl =
  process.env.REACT_APP_CLOUD_DRIVE_FRONTEND +
  "/login?redirect=" +
  process.env.REACT_APP_FRONTEND_URL;

export default class RedirectComponent extends Component {
  constructor(props) {
    super(props);

    const collaborationFrontEndUrl = process.env.REACT_APP_FRONTEND_URL;
    this.state = {
      hasValidated: false,
      collaborationFrontEndUrl,
    };
  }

  componentDidMount() {
    const fullUrl = window.location.pathname.split("/");
    let url = fullUrl[3],
      purpose = fullUrl[2];

    console.log(purpose, url);
    // return;

    if (purpose === "accept") {
      this.acceptInvitation(url);
    } else if (purpose === "decline") {
      this.declineInvitation(url);
    } else if (purpose === "accept-project-join") {
      this.acceptProjectJoin(url);
    } else if (purpose === "decline-project-join") {
      this.declineProjectJoin(url);
    }
  }

  acceptInvitation = (code) => {
    const { collaborationFrontEndUrl } = this.state;
    fetch(`${URLS.backendTeams}/acceptInvitation`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code }),
    })
      .then((res) => res.json())
      .then((result) => {
        const teamUrl = collaborationFrontEndUrl + "/teams";
        if (result.success) {
          window.location.replace(teamUrl);
        } else {
          window.location.replace(redirectUrl);
        }
      })
      .catch((error) => {
        console.log(error);
        window.location.replace(redirectUrl);
      });
  };

  declineInvitation = (code) => {
    fetch(`${URLS.backendTeams}/declineInvitation`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code }),
    })
      .then((res) => res.json())
      .then((result) => {
        window.location.replace(redirectUrl);
      })
      .catch((error) => {
        console.log(error);
        window.location.replace(redirectUrl);
      });
  };

  acceptProjectJoin = (code) => {
    fetch(`${URLS.backendProjects}/accept-project-join`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.success) {
          showToast({
            message: {
              title: "User accepted",
              description: "redirecting...",
            },

            type: "success",
            autoClose: false,
          });
          setTimeout(() => {
            window.location.replace(
              URLS.singleProjectTasks +
                `?projectId=${result.projectId}&taskId=${
                  result.taskId ? result.taskId : ""
                }`
            );
          }, 1000);
        } else {
          /*showToast({
            message: {
              title: "Unable",
              description: "...",
            },

            type: "error",
            autoClose: false,
          });*/

          window.location.replace(redirectUrl);
        }
      })
      .catch((error) => {
        showToast({
          message: {
            title: "Invalid link",
            description: "redirecting...",
          },
          type: "error",
          autoClose: false,
        });
        window.location.replace(redirectUrl);
      });
  };

  declineProjectJoin = (code) => {
    fetch(`${URLS.backendProjects}/decline-project-join`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code }),
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        if (result.success) {
          showToast({
            message: {
              title: "User declined",
              description: "redirecting...",
            },

            type: "success",
            autoClose: false,
          });
          setTimeout(() => {
            window.location.replace(
              URLS.singleProjectTasks +
                `?projectId=${result.projectId}&taskId=${
                  result.taskId ? result.taskId : ""
                }`
            );
          }, 1000);
        } else {
          window.location.replace(redirectUrl);
        }
      })
      .catch((error) => {
        showToast({
          message: {
            title: "Invalid link",
            description: "redirecting...",
          },
          type: "error",
          autoClose: false,
        });
        window.location.replace(redirectUrl);
      });
  };

  render() {
    const { hasValidated } = this.state;
    return <Loader />;
  }
}
