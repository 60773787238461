import React, {Component} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import $ from 'jquery';
import {URLS} from '../urls';
import '../../assets/css/jobgrade.css';

export default class AddEmployeeHMOModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeHMO: {
                name: '',
                description:''
            },
        };
    }

    updateEmployeeHMOName = (event) => {
        const {employeeHMO} = this.state;
        employeeHMO.name = event.target.value;
        this.setState({
            employeeHMO
        });
    };
    updateEmployeeHMODescription = (event) => {
        const {employeeHMO} = this.state;
        employeeHMO.description = event.target.value;
        this.setState({
            employeeHMO
        });
    };
    
    submitEmployeeHMO = (e) => {
        e.preventDefault();
        const {employeeHMO} = this.state;
        const token = 'dummy token'; // localStorage.getItem('token');
        fetch(`${URLS.backendEmployeeHMOs}/add`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({employeeHMO: employeeHMO}),
        }).then(res => res.json())
            .then((result) => {
                this.props.updateEmployeeHMOsList(result.employeeHMO);
                // $('#add_employeeHMO').hide();
                this.props.changeShowEmployeeHMOState();
            });

    };

    cancelEmployeeHMO = (e) => {
        e.preventDefault();
        this.props.changeShowEmployeeHMOState();
        // $('#add_employeeHMO').hide();
    };

    render() {
        const {employeeHMO} = this.state;
        return (
            <div id='add_employeeHMO' style={
                this.props.isShow ?
                    {
                        width: '50%',
                        position: 'fixed',
                        left: '50%',
                        marginLeft: '-25%',
                        top: '20px',
                        zIndex: '2000',
                        display: 'block',
                        backgroundColor: '#eaecef',
                        // height: '100%',
                        borderRadius: '10px'
                    }:{
                        width: '50%',
                        position: 'fixed',
                        left: '50%',
                        marginLeft: '-25%',
                        top: '20px',
                        zIndex: '2000',
                        display: 'none',
                        backgroundColor: '#eaecef',
                        // height: '100%',
                        borderRadius: '10px'
                    }
            }>
                <Form onSubmit={this.submitEmployeeHMO} style={{
                    // overflowY: 'scroll',
                    maxHeight: '100%',
                    // padding: '50px 30px',
                    borderRadius: '10px'
                    }}>
                    <Form.Group as={Row} style={{
                        backgroundColor: '#5378b2',
                        alignItems: 'left',
                        justifyContent: 'left',
                        fontSize: '16px',
                        color: '#ffffff',
                        fontWeight: 'bold',
                        height:'60px',
                        borderRadius: '10px 10px 0 0',
                        padding : '10px 15px',
                        marginLeft: '0',
                        marginRight: '0',
                        display : 'block'
                    }}>
                        <span style={{display:'inline-block', height:'40px',lineHeight:'40px',float:'left'}}>Create New EmployeeHMO</span>
                        <span style={{display:'inline-block', height:'40px',float:'right',padding: '2px 10px'}}>
                            <button className='btn btn-info' style={
                                {
                                    padding:'2px 10px',
                                    background: '#ffffff',
                                    color: '#000000',
                                    borderRadius: '20px',
                                    border: 'none'
                                }
                            } onClick={this.cancelEmployeeHMO}>x</button>
                        </span>
                    </Form.Group>
                    <Form.Group as={Row} style={{marginLeft: '0', marginRight: '0'}}>
                        <Form.Label style={{fontSize: '14px'}} column sm={2}>
                            EmployeeHMO
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control value={employeeHMO.name} onChange={this.updateEmployeeHMOName}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} style={{marginLeft: '0', marginRight: '0'}}>
                        <Form.Label style={{fontSize: '14px'}} column sm={2}>
                            Description
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control value={employeeHMO.description} onChange={this.updateEmployeeHMODescription}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} style={{marginLeft: '0', marginRight: '0',paddingRight: '15px', justifyContent: 'flex-end'}}>
                        <button style={{borderRadius : '7px',backgroundColor:'#5378b2', border:'none'}} className='btn btn-primary submit-btn'>Create Now</button>
                    </Form.Group>
                </Form>
            </div>
        );
    }
}