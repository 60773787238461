import { URLS } from "../components/urls";
import { socket } from "../sockets";

export function getTasksByProjectId(token, projectId) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendTasks}/getTasksByProjectId/${projectId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getTasksByUserId(token, userId) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendTasks}/getTasksByUserId/${userId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function addCustomer(data) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendCustomer}/add`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${data.token}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function fetchContactPerson(token, data) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendCustomer}/fetchContactPerson`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function updateContactPerson(token, data) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendCustomer}/updateContactPerson`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function deleteContactPerson(token, data) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendCustomer}/deleteContactPerson`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data)
        })
        .then((res) => res.json())
        .then((result) => {
            resolve(result);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export function createContactPerson(token, data) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendCustomer}/createContactPerson`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function updateCustomer(data) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendCustomer}/update`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${data.token}`,
            },
            body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((result) => {
            resolve(result);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export function sendQuotation(token, data) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendCustomer}/send-quotation`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: data,
        })
        .then((res) => res.json())
        .then((result) => {
            resolve(result);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export function deleteQuote(token, data) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendCustomer}/deleteQuote`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function fetchQuotes(token, data) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendCustomer}/fetchQuotes`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getProjectsAndTasksIBelongTo(token, usage = "") {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendTasks}/getProjectsAndTasksIBelongTo?usage=${usage}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getProjectsIBelongTo(token, usage = "") {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendTasks}/getProjectsIBelongTo?usage=${usage}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getTasksInProjectIBelongTo(token, projectId) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendTasks}/getTasksInProjectIBelongTo/${projectId}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function addNewMembers(data) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendTasks}/addNewMembers`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${data.token}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getTasksByClientId(token, id) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendTasks}/getByClientId/${id}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getCustomerById(token, customerId) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendCustomer}/getCustomerById/${customerId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function appendUploadedFileId(token, data) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendTasks}/appendUploadedFileId`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ data }),
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function appendUploadedFileIdForCommentReply(token, data) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendTasks}/appendUploadedFileIdForCommentReply`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ data }),
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getTaskFiles({
    token,
    taskId,
    page = "",
    limit = "",
    searchTerm = "",
    searchType = "",
}) {
    return new Promise((resolve, reject) => {
        fetch(
            `${URLS.backendTasks}/getTaskFiles/${taskId}?page=${page}&limit=${limit}&searchTerm=${searchTerm}&searchType=${searchType}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function deleteTask(token, taskId) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendTasks}/delete/${taskId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function createRootFolder({ token, taskId }) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendTasks}/create-root-folder`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ taskId }),
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function createFolder({ folder_name, parent_id, token }) {
    return new Promise((resolve, reject) => {
        fetch(`${URLS.backendTasks}/create-folder`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ folder_name, parent_id }),
        })
            .then((res) => res.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getComments({ page, taskId, limit }) {
    return new Promise(async (resolve, reject) => {
        try {
            socket.emit(
                "fx:get-task-comments",
                {
                    page,
                    taskId,
                    limit,
                },
                (response) => {
                    if (response.success) {
                        resolve(response);
                    } else {
                        reject();
                    }
                }
            );
        } catch (e) {
            reject(e);
        }
    });
}
