import { connected, socket, socketIO } from "../../sockets";
import { showToast, waitFor } from "../../components/task-update/utils";
import { showFile } from "./uploadFileActions";
import eventBus from "../../helper/EventBus";

export const commentTypes = {
  SET_GLOBAL_COMMENT: "SET_GLOBAL_COMMENT",
  SET_COMMENT_FILES_UPLOADED: "SET_COMMENT_FILES_UPLOADED",
  RETRY_SEND_COMMENT: "RETRY_SEND_COMMENT",
  SUCCESS_SEND_COMMENT: "SUCCESS_SEND_COMMENT",
  FAILURE_SEND_COMMENT: "FAILURE_SEND_COMMENT",
  REMOVE_COMMENT: "REMOVE_COMMENT",
  REDUCE_UPLOAD_COUNT: "REDUCE_UPLOAD_COUNT",
};

export const setGlobalComment = (data) => {
  // console.log("js", data);

  return {
    type: commentTypes.SET_GLOBAL_COMMENT,
    payload: data,
  };
};

export const setCommentFilesUploaded = (id, data) => ({
  type: commentTypes.SET_COMMENT_FILES_UPLOADED,
  payload: {
    id,
    data,
  },
});

export const retrySendComment = (id) => ({
  type: commentTypes.RETRY_SEND_COMMENT,
  payload: id,
});

export const failureSendComment = (id) => ({
  type: commentTypes.FAILURE_SEND_COMMENT,
  payload: id,
});

export const successSendComment = (id, data) => ({
  type: commentTypes.SUCCESS_SEND_COMMENT,
  payload: {
    id,
    data,
  },
});

export const removeComment = (id) => ({
  type: commentTypes.REMOVE_COMMENT,
  payload: id,
});

export const reduceUploadCount = (id) => ({
  type: commentTypes.REDUCE_UPLOAD_COUNT,
  payload: id,
});

const saveCommentToCollaboration = ({ newComment }) => {
  return new Promise(async (resolve, reject) => {
    //console.log("wait");
    //await waitFor(5000);
    // reject();
    //if (connected) {
    const { token, taskId, usage } = newComment;
    newComment.comment = newComment.ogComment;
    newComment.files = newComment.files.map((file) => {
      return {
        originalName: file.originalName,
        uniqueName: file.uniqueName,
        extension: file.extension,
        size: file.size,
        cloud_id: file.cloud_id,
        folder_id: file.folder_id,
      };
    });

    socket.emit(
      usage !== "comment-reply"
        ? "fx:task-add-comment"
        : "fx:task-add-reply-comment",
      { newComment, token, taskId, usage },
      async (response) => {
        if (response && response.success) {
          // setTimeout(() => {
          // console.log("done");
          if (usage !== "comment-reply") {
            resolve(response);
          } else {
            resolve({
              comment: {
                ...response.taskCommentReply,
                repliesCount: response.repliesCount,
              },
              success: true,
            });

            // publish replies count
          }
          // }, 5000);
        }
      }
    );
    /*} else {
      reject("you're offline");
    }*/
  });
};

const postComment = async (comment, dispatch) => {
  try {
    // check if empty
    console.log("post message", comment);
    // return;
    //if (!connected) throw new Error("Not connected");
    if (
      comment &&
      (comment.ogComment.trim() ||
        comment.files.length ||
        comment.dropboxFiles.length ||
        comment.googleFiles.length)
    ) {
      const saved = await saveCommentToCollaboration({
        newComment: comment,
      });
      if (saved.success) {
        console.log("success", comment);
        comment.files.forEach((file) => {
          dispatch(showFile(file.cloud_id));
        });
        dispatch(successSendComment(comment.id, saved.comment));

        // send to conversation
        eventBus.dispatch(`new-comment-from-store-${comment.taskId}`, {
          comment: { ...saved.comment, id: comment.id },
        });

        // delete from store
        setTimeout(() => {
          console.log("delete", comment);
          dispatch(removeComment(comment.id));
        }, 500);
      } else {
        throw new Error("Not saved", saved);
      }
    } else {
      // delete
      dispatch(removeComment(comment.id));
    }
  } catch (err) {
    console.log("sending comment error", err);
    // await waitFor(4500);
    // postComment(comment, dispatch);
  }
};

export const sendComment = (comments) => async (dispatch) => {
  if (comments.length) {
    for (const comment of comments) {
      postComment(comment, dispatch);
    }
  }
};
