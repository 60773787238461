import React, { Component } from "react";
import { URLS } from "../urls";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MoreIcon from "mdi-react/DotsVerticalIcon";
import "../../assets/css/project.css";

class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: props.client || {},
    };
  }

  showControlButtons = () => {
    const { isSuperAdmin, isAdmin, account_type } = this.props.user;

    let value = false;
    if (isSuperAdmin || isAdmin || account_type === 1) {
      value = true;
    }
    return value;
  };

  getClientInitials = (name) => {
    const value = name ? name.charAt(0) : "";
    return value;
  };

  render() {
    const {
      _id,
      company,
      firstName,
      lastName,
      displayImage,
      hasAccountExpired,
    } = this.props;
    const href = `${URLS.clientProfile}/${_id}`;
    const profileImage = displayImage
      ? URLS.backendPublic + "/clients/" + displayImage
      : this.getClientInitials(company);

    return (
      <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3 px-0">
        <div className="profile-widget mb-0">
          <div className="profile-img">
            <Link to={href} className="avatar">
              <img alt="" src={profileImage} style={{ objectFit: "cover" }} />
            </Link>
          </div>

          <div className="dropdown profile-action">
            {this.showControlButtons() && !hasAccountExpired ? (
              <a
                href=""
                className="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <MoreIcon />
              </a>
            ) : null}

            <div className="dropdown-menu dropdown-menu-right">
              <a
                className="dropdown-item"
                href=""
                data-toggle="modal"
                data-target="#edit_client"
                onClick={this.props.onEdit.bind(this, _id)}
              >
                <i className="fa fa-pencil m-r-5"></i> Edit
              </a>
              <button
                className="dropdown-item text-danger"
                onClick={() => this.props.onDelete()}
              >
                <i className="fa fa-trash-o m-r-5"></i> Delete
              </button>
            </div>
          </div>
          <h4 className="user-name m-t-10 mb-0 text-ellipsis">
            <Link to={href}>{company}</Link>
          </h4>
          <h5 className="user-name m-t-10 mb-0 text-ellipsis">
            <Link to={href}>{firstName + " " + lastName}</Link>
          </h5>
          <Link
            to={href}
            className="btn btn-white btn-sm m-t-10"
            style={{ margin: "0px 2px" }}
          >
            View Profile
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    user: state.userReducer.user,
    hasAccountExpired: state.userReducer.hasAccountExpired,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Client));
