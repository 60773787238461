import React, { Component } from "react";
import { connect } from "react-redux";
import { render } from "react-dom";
import introJs from "intro.js";
import "intro.js/introjs.css";
import "../../assets/scss/introjs-custom.scss";
import { socket } from "../../sockets";
import { setOnboarding } from "../../store/actions";
import eventBus from "../../helper/EventBus";

const svgs = {
  projects: `<svg width="218" height="204" viewBox="0 0 218 204" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M181.849 30.3436C194.82 46.3566 190.859 76.1735 188.728 103.872C186.598 131.571 186.421 157.156 173.45 174.541C160.436 191.938 134.714 201.07 111.472 198.602C88.2304 196.092 67.5129 181.94 47.0639 164.543C26.6149 147.145 6.40579 126.561 7.01336 106.553C7.62094 86.5446 29.0374 67.1714 49.4942 51.161C69.951 35.1506 89.4482 22.5109 114.163 17.3176C138.878 12.1242 168.889 14.3333 181.849 30.3436Z" fill="#92CFFB"/>
<path d="M179.395 47.4443H19V147H179.395V47.4443Z" fill="#F1F8FE"/>
<path d="M179.395 35H19V47.4444H179.395V35Z" fill="#EAF6FF"/>
<path d="M150.358 41.9115C150.358 42.4587 150.197 42.9937 149.893 43.4488C149.589 43.9039 149.157 44.2588 148.652 44.4684C148.147 44.6779 147.59 44.7329 147.054 44.6263C146.517 44.5196 146.024 44.2562 145.637 43.8693C145.25 43.4824 144.987 42.9895 144.88 42.4528C144.774 41.9161 144.829 41.3599 145.038 40.8545C145.248 40.3491 145.603 39.9172 146.058 39.6135C146.513 39.3098 147.048 39.148 147.595 39.1484C148.328 39.1491 149.03 39.4404 149.548 39.9584C150.066 40.4765 150.357 41.1789 150.358 41.9115Z" fill="#FB5D64"/>
<path d="M162.802 41.9115C162.803 42.4586 162.641 42.9935 162.337 43.4486C162.034 43.9036 161.602 44.2585 161.097 44.4681C160.592 44.6778 160.035 44.7329 159.499 44.6264C158.962 44.52 158.469 44.2568 158.082 43.8701C157.695 43.4835 157.432 42.9907 157.325 42.4542C157.218 41.9177 157.273 41.3616 157.482 40.8561C157.691 40.3507 158.045 39.9186 158.5 39.6147C158.955 39.3107 159.49 39.1484 160.037 39.1484C160.77 39.1484 161.473 39.4395 161.992 39.9576C162.51 40.4757 162.802 41.1785 162.802 41.9115Z" fill="#FB5D64"/>
<path d="M175.237 41.9116C175.238 42.459 175.076 42.9943 174.773 43.4495C174.469 43.9048 174.038 44.2596 173.533 44.469C173.028 44.6784 172.472 44.733 171.936 44.6258C171.4 44.5186 170.908 44.2544 170.522 43.8668C170.136 43.4793 169.874 42.9856 169.768 42.4485C169.662 41.9114 169.718 41.3549 169.928 40.8497C170.139 40.3444 170.494 39.913 170.949 39.6101C171.405 39.3073 171.939 39.1466 172.486 39.1485C173.218 39.1485 173.92 39.4395 174.437 39.9576C174.955 40.4757 175.246 41.1785 175.247 41.9116" fill="#FB5D64"/>
<path d="M159.953 55C158.224 55 157.767 58.2822 154.687 58.2822H136.531V75.7407H165.568V55H159.953Z" fill="#B8DBF4"/>
<path d="M155.293 87.6279H138V105.086H165.654V84.3457H160.305C158.66 84.3457 158.225 87.6279 155.29 87.6279" fill="#B8DBF4"/>
<circle cx="66.4268" cy="91.8909" r="27.5" transform="rotate(45.0014 66.4268 91.8909)" fill="#D9EBFF"/>
<path d="M86.9323 112.519C82.7607 116.69 77.4162 119.49 71.6118 120.545C65.8075 121.6 59.8195 120.859 54.447 118.422C49.0745 115.985 44.573 111.968 41.5433 106.906C38.5136 101.844 37.0997 95.978 37.4904 90.0916C37.8811 84.2052 40.0578 78.5779 43.73 73.9607C47.4021 69.3436 52.3951 65.9562 58.0426 64.2506C63.69 62.545 69.7233 62.6024 75.3373 64.4151C80.9514 66.2277 85.879 69.7095 89.4627 74.3956L66.4267 92.0122L86.9323 112.519Z" fill="#A1C7F1"/>
<path d="M87.6394 113.64C84.1508 117.129 79.8557 119.703 75.1346 121.135C70.4134 122.567 65.412 122.812 60.5733 121.85C55.7346 120.887 51.2079 118.746 47.3944 115.616C43.5808 112.486 40.5979 108.464 38.7101 103.906C36.8222 99.3482 36.0876 94.3949 36.5712 89.4852C37.0549 84.5754 38.742 79.8607 41.483 75.7587C44.224 71.6567 47.9344 68.294 52.2854 65.9684C56.6365 63.6429 61.4939 62.4263 66.4274 62.4264L66.4267 92.4264L87.6394 113.64Z" fill="#AEDBF9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M114.175 110H181.825C181.871 110 181.916 110.018 181.949 110.051C181.982 110.084 182 110.129 182 110.175V177.509C182 177.532 181.995 177.555 181.987 177.576C181.978 177.597 181.965 177.616 181.949 177.633C181.932 177.649 181.913 177.662 181.892 177.67C181.87 177.679 181.848 177.684 181.825 177.684H114.175C114.152 177.684 114.13 177.679 114.108 177.67C114.087 177.662 114.068 177.649 114.051 177.633C114.035 177.616 114.022 177.597 114.013 177.576C114.005 177.555 114 177.532 114 177.509V110.175C114 110.129 114.018 110.084 114.051 110.051C114.084 110.018 114.129 110 114.175 110V110Z" fill="#E1F0FD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M124.612 121.387H163.481C163.527 121.387 163.572 121.405 163.604 121.439C163.637 121.472 163.656 121.517 163.656 121.564V125.638C163.656 125.685 163.637 125.73 163.604 125.763C163.572 125.796 163.527 125.815 163.481 125.815H124.612C124.589 125.815 124.566 125.81 124.545 125.801C124.524 125.792 124.504 125.779 124.488 125.763C124.472 125.746 124.459 125.727 124.45 125.705C124.442 125.684 124.437 125.661 124.437 125.638V121.564C124.437 121.54 124.442 121.517 124.45 121.496C124.459 121.474 124.472 121.455 124.488 121.438C124.504 121.422 124.524 121.409 124.545 121.4C124.566 121.391 124.589 121.387 124.612 121.387Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M154.178 124H162.995C163.042 124 163.087 124.018 163.12 124.05C163.153 124.082 163.172 124.126 163.172 124.171V126.992C163.172 127.037 163.153 127.081 163.12 127.113C163.087 127.145 163.042 127.163 162.995 127.163H154.178C154.131 127.163 154.085 127.145 154.052 127.113C154.019 127.081 154 127.037 154 126.992V124.172C154 124.149 154.004 124.127 154.013 124.106C154.022 124.085 154.035 124.066 154.052 124.05C154.068 124.034 154.088 124.022 154.109 124.013C154.131 124.004 154.154 124 154.178 124Z" fill="#B2DCFB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M155.799 147.638H174.042C174.245 147.638 174.409 147.875 174.409 148.167V156.913C174.409 157.205 174.244 157.442 174.042 157.442H155.799C155.596 157.442 155.432 157.205 155.432 156.913V148.167C155.432 147.875 155.596 147.638 155.799 147.638Z" fill="#B2DCFB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M155.799 158.391H174.042C174.245 158.391 174.409 158.62 174.409 158.903V167.367C174.409 167.65 174.244 167.879 174.042 167.879H155.799C155.596 167.879 155.432 167.649 155.432 167.367V158.903C155.432 158.62 155.596 158.391 155.799 158.391Z" fill="#B2DCFB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M165.374 126.85L173.779 128.222C173.802 128.225 173.823 128.233 173.842 128.246C173.861 128.258 173.878 128.275 173.892 128.294C173.905 128.314 173.915 128.336 173.92 128.359C173.925 128.383 173.926 128.407 173.923 128.431L173.499 131.435C173.492 131.483 173.468 131.527 173.431 131.556C173.395 131.585 173.349 131.597 173.304 131.59L164.899 130.218C164.854 130.211 164.813 130.185 164.786 130.145C164.759 130.106 164.748 130.057 164.755 130.008L165.179 127.004C165.182 126.98 165.19 126.957 165.202 126.937C165.213 126.916 165.229 126.898 165.247 126.884C165.265 126.869 165.285 126.859 165.307 126.853C165.329 126.847 165.352 126.846 165.374 126.85Z" fill="#B2DCFB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M118.744 139.414C118.744 137.842 120.019 136.567 121.591 136.567C123.163 136.567 124.437 137.842 124.437 139.414C124.437 140.986 123.163 142.26 121.591 142.26C120.019 142.26 118.744 140.986 118.744 139.414Z" fill="white"/>
<path d="M126.968 138.149H151.005" stroke="#A9D4FB" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M126.968 140.679H138.67" stroke="#A9D4FB" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M118.744 148.269C118.744 146.697 120.019 145.423 121.591 145.423C123.163 145.423 124.437 146.697 124.437 148.269C124.437 149.841 123.163 151.116 121.591 151.116C120.019 151.116 118.744 149.841 118.744 148.269Z" fill="white"/>
<path d="M126.968 147.004H151.005" stroke="#A9D4FB" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M126.968 149.534H138.67" stroke="#A9D4FB" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M118.744 157.126C118.744 155.554 120.019 154.279 121.591 154.279C123.163 154.279 124.437 155.554 124.437 157.126C124.437 158.697 123.163 159.972 121.591 159.972C120.019 159.972 118.744 158.698 118.744 157.126Z" fill="white"/>
<path d="M126.968 155.86H151.005" stroke="#A9D4FB" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M126.968 158.391H138.67" stroke="#A9D4FB" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M118.744 165.982C118.744 164.41 120.019 163.136 121.591 163.136C123.163 163.136 124.437 164.41 124.437 165.982C124.437 167.555 123.163 168.829 121.591 168.829C120.019 168.829 118.744 167.554 118.744 165.982Z" fill="white"/>
<path d="M126.968 164.4H151.005" stroke="#A9D4FB" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M126.968 167.247H138.67" stroke="#A9D4FB" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M114.175 110H181.825C181.921 110 182 110.005 182 110.012V114.416C182 114.423 181.921 114.428 181.825 114.428H114.175C114.079 114.428 114 114.423 114 114.416V110.012C114 110.005 114.079 110 114.175 110Z" fill="#EAF6FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M169.214 111.949C169.214 111.397 169.662 110.949 170.214 110.949C170.766 110.949 171.214 111.397 171.214 111.949C171.214 112.501 170.766 112.949 170.213 112.949C169.661 112.949 169.214 112.501 169.214 111.949Z" fill="#FB5E64"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M173.958 111.949C173.958 111.397 174.406 110.949 174.958 110.949C175.51 110.949 175.958 111.397 175.958 111.949C175.958 112.501 175.51 112.949 174.958 112.949C174.406 112.949 173.958 112.501 173.958 111.949Z" fill="#FB5E64"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M178.386 111.949C178.386 111.397 178.833 110.949 179.386 110.949C179.938 110.949 180.386 111.397 180.386 111.949C180.386 112.501 179.938 112.949 179.386 112.949C178.833 112.949 178.386 112.501 178.386 111.949Z" fill="#FB5E64"/>
<path d="M120 138.5L121 140L124.5 137" stroke="#3F96D1"/>
<path d="M120 156.5L121 158L124.5 155" stroke="#3F96D1"/>
</svg>
`,
  drive: `<svg width="205" height="194" viewBox="0 0 205 194" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M174.849 25.3436C187.82 41.3566 183.859 71.1735 181.728 98.8721C179.598 126.571 179.421 152.156 166.45 169.541C153.436 186.938 127.714 196.07 104.472 193.602C81.2304 191.092 60.5129 176.94 40.0639 159.543C19.6149 142.145 -0.594212 121.561 0.0133647 101.553C0.620941 81.5446 22.0374 62.1714 42.4942 46.161C62.951 30.1506 82.4482 17.5109 107.163 12.3176C131.878 7.12424 161.889 9.33329 174.849 25.3436Z" fill="#92CFFB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.2767 124.379C29.3874 122.941 13.6982 106.349 13.6982 86.0931C13.6982 64.8924 30.8848 47.7059 52.0855 47.7059C57.5229 47.7059 62.6962 48.8364 67.3833 50.8751C74.9529 29.3947 95.4273 14 119.497 14C148.429 14 172.166 36.2418 174.542 64.5594C174.571 64.5593 174.6 64.5591 174.63 64.559C174.665 64.5589 174.701 64.5588 174.737 64.5588C191.284 64.5588 204.698 77.9727 204.698 94.5196C204.698 111.066 191.284 124.48 174.737 124.48H119.497H52.0855H49.2767V124.379Z" fill="#F3FAFE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.2767 132.379C29.3874 130.941 13.6982 114.349 13.6982 94.0931C13.6982 72.8924 30.8848 55.7059 52.0855 55.7059C57.5229 55.7059 62.6962 56.8364 67.3833 58.8751C74.9529 37.3947 95.4273 22 119.497 22C148.429 22 172.166 44.2418 174.542 72.5594C174.571 72.5593 174.6 72.5591 174.63 72.559C174.665 72.5589 174.701 72.5588 174.737 72.5588C191.284 72.5588 204.698 85.9727 204.698 102.52C204.698 119.066 191.284 132.48 174.737 132.48H119.497H52.0855H49.2767V132.379Z" fill="#F3FAFE" fill-opacity="0.5"/>
<path d="M178.698 105H62.6982V177H178.698V105Z" fill="#F1F8FE"/>
<path d="M178.698 96H62.6982V105H178.698V96Z" fill="#EAF6FF"/>
<path d="M157.698 100.998C157.699 101.394 157.582 101.781 157.362 102.11C157.142 102.439 156.83 102.696 156.464 102.847C156.099 102.999 155.697 103.039 155.308 102.962C154.92 102.885 154.564 102.694 154.284 102.414C154.004 102.134 153.814 101.778 153.737 101.39C153.659 101.002 153.699 100.599 153.851 100.234C154.002 99.8683 154.259 99.556 154.588 99.3364C154.917 99.1167 155.304 98.9997 155.7 99C156.23 99.0004 156.738 99.2111 157.112 99.5858C157.487 99.9604 157.698 100.468 157.698 100.998Z" fill="#FB5D64"/>
<path d="M166.698 100.998C166.699 101.394 166.582 101.781 166.362 102.11C166.142 102.439 165.83 102.696 165.465 102.847C165.099 102.999 164.697 103.039 164.309 102.962C163.921 102.885 163.565 102.694 163.285 102.415C163.005 102.135 162.814 101.779 162.737 101.391C162.659 101.003 162.699 100.601 162.85 100.235C163.002 99.8695 163.258 99.557 163.587 99.3372C163.916 99.1173 164.303 99 164.698 99C165.228 99 165.737 99.2105 166.112 99.5852C166.487 99.9599 166.698 100.468 166.698 100.998Z" fill="#FB5D64"/>
<path d="M175.692 100.998C175.692 101.394 175.575 101.781 175.355 102.111C175.136 102.44 174.824 102.696 174.459 102.848C174.093 102.999 173.692 103.039 173.304 102.961C172.916 102.884 172.56 102.693 172.281 102.412C172.002 102.132 171.812 101.775 171.736 101.387C171.659 100.998 171.7 100.596 171.852 100.23C172.004 99.8649 172.261 99.5529 172.59 99.3339C172.92 99.1149 173.306 98.9987 173.702 99C174.231 99 174.738 99.2105 175.113 99.5852C175.487 99.9599 175.698 100.468 175.698 100.998" fill="#FB5D64"/>
<path d="M164.637 116C163.387 116 163.056 118.374 160.829 118.374H147.698V131H168.698V116H164.637Z" fill="#B8DBF4"/>
<path d="M129.831 116C128.64 116 128.325 118.374 126.204 118.374H113.698V131H133.698V116H129.831Z" fill="#B8DBF4"/>
<path d="M92.2044 118.374H79.6982V131H99.6982V116H95.8292C94.64 116 94.3251 118.374 92.2025 118.374" fill="#B8DBF4"/>
<path d="M162.698 0H111.698V72H162.698V0Z" fill="#EAF6FF"/>
<path d="M152.698 22C152.698 24.9668 151.818 27.867 150.17 30.3338C148.522 32.8006 146.179 34.7232 143.438 35.8585C140.697 36.9937 137.681 37.2906 134.771 36.7116C131.861 36.1326 129.188 34.7037 127.091 32.6056C124.993 30.5075 123.565 27.8345 122.986 24.9246C122.408 22.0147 122.705 18.9986 123.841 16.2578C124.977 13.517 126.9 11.1745 129.367 9.52659C131.834 7.8787 134.734 6.99944 137.701 7C141.679 7.00075 145.493 8.58142 148.306 11.3944C151.118 14.2073 152.698 18.0222 152.698 22Z" fill="#CBE9FF"/>
<path d="M157.698 45H117.698V52H157.698V45Z" fill="#3F96D1"/>
<path d="M157.698 58H117.698V67H157.698V58Z" fill="#3F96D1"/>
</svg>
`,
  dashboard: `<svg width="219" height="204" viewBox="0 0 219 204" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1255_3301)">
  <path d="M188.651 35.3436C201.621 51.3566 197.66 81.1735 195.53 108.872C193.399 136.571 193.222 162.156 180.252 179.541C167.237 196.938 141.515 206.07 118.274 203.602C95.0319 201.092 74.3144 186.94 53.8654 169.543C33.4164 152.145 13.2073 131.561 13.8149 111.553C14.4225 91.5446 35.8389 72.1714 56.2957 56.161C76.7525 40.1506 96.2497 27.5109 120.965 22.3176C145.68 17.1242 175.691 19.3333 188.651 35.3436Z" fill="#92CFFB"/>
  <path d="M196.104 41.7734H23.0081V166.636H196.104V41.7734Z" fill="#F3FAFE"/>
  <path d="M196.104 41.7734H23.0081V55.4655H196.104V41.7734Z" fill="#EAF6FF"/>
  <path d="M165.003 48.6197C165.003 49.2936 164.803 49.9524 164.427 50.5129C164.05 51.0735 163.516 51.5104 162.89 51.7686C162.264 52.0267 161.575 52.0944 160.911 51.9631C160.246 51.8317 159.635 51.5073 159.156 51.0308C158.677 50.5544 158.351 49.9472 158.219 49.2863C158.087 48.6254 158.155 47.9404 158.415 47.3179C158.674 46.6955 159.114 46.1636 159.677 45.7896C160.241 45.4156 160.903 45.2162 161.581 45.2168C162.488 45.2176 163.358 45.5763 163.999 46.2143C164.641 46.8523 165.002 47.7174 165.003 48.6197Z" fill="#FB5D64"/>
  <path d="M178.158 48.6197C178.159 49.2934 177.959 49.9522 177.583 50.5126C177.207 51.0731 176.672 51.5101 176.046 51.7683C175.421 52.0265 174.732 52.0944 174.067 51.9633C173.403 51.8322 172.792 51.5081 172.313 51.0319C171.834 50.5557 171.507 49.9488 171.375 49.2881C171.243 48.6273 171.31 47.9424 171.569 47.3199C171.829 46.6974 172.268 46.1653 172.831 45.791C173.394 45.4166 174.056 45.2168 174.734 45.2168C175.641 45.2168 176.512 45.5752 177.154 46.2133C177.796 46.8514 178.158 47.7169 178.158 48.6197Z" fill="#FB5D64"/>
  <path d="M191.314 48.6197C191.314 49.2939 191.113 49.9532 190.737 50.5139C190.361 51.0746 189.825 51.5115 189.199 51.7694C188.572 52.0273 187.883 52.0945 187.218 51.9624C186.554 51.8304 185.943 51.5051 185.464 51.0278C184.986 50.5504 184.66 49.9425 184.529 49.281C184.398 48.6195 184.467 47.9342 184.728 47.312C184.989 46.6897 185.43 46.1584 185.994 45.7854C186.559 45.4124 187.223 45.2145 187.901 45.2168C188.808 45.2168 189.679 45.5752 190.321 46.2133C190.963 46.8514 191.324 47.717 191.325 48.6197" fill="#FB5D64"/>
  <path d="M174.572 71.9023C172.77 71.9023 172.294 75.4575 169.086 75.4575H150.169V94.3674H180.422V71.9023H174.572Z" fill="#B8DBF4"/>
  <path d="M135.977 71.9023C134.175 71.9023 133.699 75.4575 130.491 75.4575H111.574V94.3674H141.827V71.9023H135.977Z" fill="#B8DBF4"/>
  <path d="M91.8961 75.4575H72.9771V94.3674H103.233V71.9023H97.3796C95.5806 71.9023 95.1042 75.4575 91.8933 75.4575" fill="#B8DBF4"/>
  <path d="M218.5 106.214H169.086V179.094H218.5V106.214Z" fill="#EAF6FF"/>
  <path d="M209.199 128.65C209.199 131.681 208.295 134.644 206.602 137.164C204.909 139.684 202.502 141.648 199.687 142.807C196.871 143.967 193.773 144.27 190.784 143.679C187.795 143.087 185.05 141.628 182.895 139.484C180.74 137.341 179.273 134.611 178.679 131.638C178.085 128.665 178.39 125.584 179.557 122.784C180.723 119.985 182.699 117.592 185.233 115.908C187.767 114.225 190.746 113.327 193.794 113.327C197.88 113.328 201.798 114.943 204.687 117.816C207.576 120.69 209.199 124.587 209.199 128.65Z" fill="#CBE9FF"/>
  <path d="M212.993 150.969H174.595V159.363H212.993V150.969Z" fill="#3F96D1"/>
  <path d="M212.993 165.31H174.595V173.703H212.993V165.31Z" fill="#3F96D1"/>
  <path d="M62.0042 67.7715H3.96484V85.0015H62.0042V67.7715Z" fill="#EAF6FF"/>
  <path d="M57.0953 76.3836C57.0958 77.406 56.7913 78.4056 56.2205 79.2559C55.6496 80.1062 54.838 80.7691 53.8883 81.1607C52.9386 81.5522 51.8935 81.6549 50.8851 81.4557C49.8768 81.2565 48.9504 80.7644 48.2233 80.0416C47.4962 79.3188 47.001 78.3978 46.8002 77.395C46.5995 76.3923 46.7023 75.3529 47.0956 74.4082C47.4889 73.4636 48.1551 72.6562 49.0099 72.0881C49.8647 71.52 50.8697 71.2168 51.8978 71.2168C53.2758 71.2168 54.5975 71.7611 55.5722 72.73C56.5469 73.6989 57.0947 75.013 57.0953 76.3836Z" fill="#CBE9FF"/>
  <path d="M42.3692 73.5137H13.4939" stroke="#3F96D1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M42.3691 79.2568H27.0652" stroke="#3F96D1" stroke-linecap="round" stroke-linejoin="round"/>
  <path opacity="0.67" d="M3.09888 119.174H87.9923V180.34H3.09888V119.174Z" fill="#DEF1FC"/>
  <path d="M36.8075 119.174C36.2552 119.174 35.8075 119.622 35.8075 120.174V131.634C35.8075 131.656 35.7897 131.674 35.7678 131.674C35.7458 131.674 35.728 131.692 35.728 131.714V142.513C35.728 143.416 36.8313 143.857 37.4533 143.201L44.8951 135.362C45.0839 135.163 45.3461 135.05 45.6203 135.05H71.3997C71.952 135.05 72.3997 134.602 72.3997 134.05V120.174C72.3997 119.622 71.952 119.174 71.3997 119.174H36.8075Z" fill="white"/>
  <path d="M34.2916 138.619L34.2882 140.856C34.2868 141.75 35.3708 142.197 35.9995 141.56L43.7524 133.713C43.9403 133.523 44.1965 133.416 44.4638 133.416H69.9561C70.5084 133.416 70.9561 132.968 70.9561 132.416V118.451C70.9561 117.899 70.5084 117.451 69.9561 117.451H67.0975" stroke="#3F96D1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M58.5397 117.451H35.2844C34.7321 117.451 34.2844 117.899 34.2844 118.451V130.948" stroke="#3F96D1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M38.6152 122.333H65.1805" stroke="#3F96D1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M38.6152 128.65H47.8553" stroke="#3F96D1" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25488 148.286H50.4762V188.723H8.25488V148.286Z" fill="#F3FAFE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5002 154.93H44.0288V156.545H20.5002V154.93Z" fill="#3F96D1"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5002 162.132H44.0288V163.747H20.5002V162.132Z" fill="#3F96D1"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5002 169.334H44.0288V170.95H20.5002V169.334Z" fill="#3F96D1"/>
  <path d="M16.7901 154.189L14.0614 156.754L12.7556 155.528" stroke="#3F96D1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.7901 161.732L14.0614 164.297L12.7556 163.071" stroke="#3F96D1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.7901 169.275L14.0614 171.84L12.7556 170.614" stroke="#3F96D1" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5002 176.536H44.0288V178.152H20.5002V176.536Z" fill="#E5F4FD"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5002 183.738H44.0288V185.354H20.5002V183.738Z" fill="#E5F4FD"/>
  </g>
  <defs>
  <clipPath id="clip0_1255_3301">
  <rect width="218" height="204" fill="white" transform="translate(0.5)"/>
  </clipPath>
  </defs>
  </svg>
  `,
  tasks: `<svg width="218" height="204" viewBox="0 0 218 204" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M192.849 182.656C205.82 166.643 201.859 136.827 199.728 109.128C197.598 81.4294 197.421 55.8439 184.45 38.4594C171.436 21.0619 145.714 11.9301 122.472 14.3985C99.2304 16.9083 78.5129 31.0596 58.0639 48.4571C37.6149 65.8546 17.4058 86.4386 18.0134 106.447C18.6209 126.455 40.0374 145.829 60.4942 161.839C80.951 177.849 100.448 190.489 125.163 195.682C149.878 200.876 179.889 198.667 192.849 182.656Z" fill="#92CFFB"/>
<path d="M190.395 46.6318H30V146.187H190.395V46.6318Z" fill="#F1F8FE"/>
<path d="M190.395 34.1875H30V46.6319H190.395V34.1875Z" fill="#EAF6FF"/>
<path d="M161.358 41.099C161.358 41.6462 161.197 42.1812 160.893 42.6363C160.589 43.0914 160.157 43.4463 159.652 43.6559C159.147 43.8654 158.59 43.9204 158.054 43.8138C157.517 43.7071 157.024 43.4437 156.637 43.0568C156.25 42.6699 155.987 42.177 155.88 41.6403C155.774 41.1036 155.829 40.5474 156.038 40.042C156.248 39.5366 156.603 39.1047 157.058 38.801C157.513 38.4973 158.048 38.3355 158.595 38.3359C159.328 38.3366 160.03 38.6279 160.548 39.1459C161.066 39.664 161.357 40.3664 161.358 41.099Z" fill="#FB5D64"/>
<path d="M173.802 41.099C173.803 41.6461 173.641 42.181 173.337 42.6361C173.034 43.0911 172.602 43.446 172.097 43.6556C171.592 43.8653 171.035 43.9204 170.499 43.8139C169.962 43.7075 169.469 43.4443 169.082 43.0576C168.695 42.671 168.432 42.1782 168.325 41.6417C168.218 41.1052 168.273 40.5491 168.482 40.0436C168.691 39.5382 169.045 39.1061 169.5 38.8022C169.955 38.4982 170.49 38.3359 171.037 38.3359C171.77 38.3359 172.473 38.627 172.992 39.1451C173.51 39.6632 173.802 40.366 173.802 41.099Z" fill="#FB5D64"/>
<path d="M186.237 41.0991C186.238 41.6465 186.076 42.1818 185.773 42.637C185.469 43.0923 185.038 43.4471 184.533 43.6565C184.028 43.8659 183.472 43.9205 182.936 43.8133C182.4 43.7061 181.908 43.4419 181.522 43.0543C181.136 42.6668 180.874 42.1731 180.768 41.636C180.662 41.0989 180.718 40.5424 180.928 40.0372C181.139 39.5319 181.494 39.1005 181.949 38.7976C182.405 38.4948 182.939 38.3341 183.486 38.336C184.218 38.336 184.92 38.627 185.437 39.1451C185.955 39.6632 186.246 40.366 186.247 41.0991" fill="#FB5D64"/>
<path d="M120 138.5L121 140L124.5 137" stroke="#3F96D1"/>
<path d="M120 156.5L121 158L124.5 155" stroke="#3F96D1"/>
<path d="M77.4573 180L66.2289 149.5L55 119L87.4998 124.697L120 130.392L98.7287 155.196L77.4573 180Z" stroke="#67C5F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M67 63H172V164H67V63Z" fill="#E5F2FD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M97 80H156V84H97V80Z" fill="#B2DCFB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M97 98H156V101H97V98Z" fill="#B2DCFB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M97 115H156V119H97V115Z" fill="#B2DCFB"/>
<path d="M88 77L81.2365 84L78 80.6526" stroke="#67C5F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M88 96L81.2365 103L78 99.6526" stroke="#67C5F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M88 115L81.2365 122L78 118.653" stroke="#67C5F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M97 133H156V138H97V133Z" fill="#F4F7FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M97 152H156V156H97V152Z" fill="#F4F7FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M139.231 133L165 137.102L158.769 176L133 171.898L139.231 133Z" fill="#B2E5FB"/>
<path d="M0 74H49V122H0V74Z" fill="white"/>
<path d="M17.6186 82H41.3814C43.3799 82 45 83.5969 45 85.5667V86.4333C45 88.4031 43.3799 90 41.3814 90H17.6186C15.6201 90 14 88.4031 14 86.4333V85.5667C14 83.5969 15.6201 82 17.6186 82Z" fill="#80C3F0"/>
<path d="M8 90C5.79086 90 4 88.2091 4 86C4 83.7909 5.79086 82 8 82C10.2091 82 12 83.7909 12 86C12 88.2091 10.2091 90 8 90Z" fill="#80C3F0"/>
<path d="M4 95H45" stroke="#80C3F0" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4 100H45" stroke="#80C3F0" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4 106H25" stroke="#80C3F0" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.7584 30H11.2416C8.89905 30 7 31.899 7 34.2416V47.7584C7 50.101 8.89905 52 11.2416 52H24.7584C27.101 52 29 50.101 29 47.7584V34.2416C29 31.899 27.101 30 24.7584 30Z" fill="#CBE7FA"/>
<path d="M27.5 37C30.5376 37 33 34.5376 33 31.5C33 28.4624 30.5376 26 27.5 26C24.4624 26 22 28.4624 22 31.5C22 34.5376 24.4624 37 27.5 37Z" fill="#FFB147"/>
</svg>
`,
  schedule: `<svg width="218" height="204" viewBox="0 0 218 204" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.1575 29.5302C14.1872 45.5431 18.1481 75.36 20.2786 103.059C22.409 130.757 22.5863 156.343 35.5566 173.727C48.5713 191.125 74.2929 200.256 97.5347 197.788C120.776 195.278 141.494 181.127 161.943 163.729C182.392 146.332 202.601 125.748 201.993 105.74C201.386 85.7311 179.969 66.3579 159.513 50.3475C139.056 34.3372 119.559 21.6974 94.8436 16.5041C70.1285 11.3108 40.1174 13.5198 27.1575 29.5302Z" fill="#92CFFB"/>
<path d="M190.395 58.6318H30V158.187H190.395V58.6318Z" fill="#F1F8FE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M58 28.999H78V38.999H145V28.999H165V38.999H190V58.999H30V38.999H58V28.999Z" fill="#EAF6FF"/>
<rect x="44" y="66.999" width="17" height="17" fill="#CEE6F7"/>
<rect x="44" y="124.999" width="17" height="17" fill="#E2E8F1"/>
<rect x="44" y="95.999" width="17" height="17" fill="#61DB99"/>
<rect x="73" y="66.999" width="17" height="17" fill="#CEE6F7"/>
<rect x="73" y="95.999" width="17" height="17" fill="#E2E8F1"/>
<rect x="73" y="124.999" width="17" height="17" fill="#CEE6F7"/>
<rect x="102" y="66.999" width="17" height="17" fill="#E2E8F1"/>
<rect x="102" y="95.999" width="17" height="17" fill="#CEE6F7"/>
<rect x="102" y="124.999" width="17" height="17" fill="#CEE6F7"/>
<rect x="131" y="66.999" width="17" height="17" fill="#CEE6F7"/>
<rect x="131" y="95.999" width="17" height="17" fill="#E2E8F1"/>
<rect x="131" y="124.999" width="17" height="17" fill="#CEE6F7"/>
<rect x="160" y="66.999" width="17" height="17" fill="#E2E8F1"/>
<rect x="160" y="95.999" width="17" height="17" fill="#FFB147"/>
<rect x="160" y="124.999" width="17" height="17" fill="#E2E8F1"/>
<path d="M166 56.999H215V104.999H166V56.999Z" fill="white"/>
<path d="M174.786 64.999H206.214C208.857 64.999 211 66.5959 211 68.5657V69.4323C211 71.4022 208.857 72.999 206.214 72.999H174.786C172.143 72.999 170 71.4022 170 69.4323V68.5657C170 66.5959 172.143 64.999 174.786 64.999Z" fill="#80C3F0"/>
<path d="M170 77.999H211" stroke="#80C3F0" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M170 82.999H211" stroke="#80C3F0" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M170 88.999H191" stroke="#80C3F0" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M211 90.999L204.237 97.999L201 94.6516" stroke="#67C5F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M54.385 104H8V165H54.385V104Z" fill="#F3F7FC"/>
<path d="M54.385 104H8V112.448H54.385V104Z" fill="#D8F1FF"/>
<path d="M13.1469 110.254C14.3013 110.254 15.2372 109.318 15.2372 108.164C15.2372 107.009 14.3013 106.073 13.1469 106.073C11.9925 106.073 11.0566 107.009 11.0566 108.164C11.0566 109.318 11.9925 110.254 13.1469 110.254Z" fill="#B2D8FB"/>
<path d="M19.5121 110.254C20.6665 110.254 21.6024 109.318 21.6024 108.164C21.6024 107.009 20.6665 106.073 19.5121 106.073C18.3577 106.073 17.4219 107.009 17.4219 108.164C17.4219 109.318 18.3577 110.254 19.5121 110.254Z" fill="#B2D8FB"/>
<path d="M25.8803 110.254C27.0347 110.254 27.9706 109.318 27.9706 108.164C27.9706 107.009 27.0347 106.073 25.8803 106.073C24.7259 106.073 23.79 107.009 23.79 108.164C23.79 109.318 24.7259 110.254 25.8803 110.254Z" fill="#B2D8FB"/>
<path d="M26.4397 119.344H13.623V137.126H26.4397V119.344Z" fill="#D8F1FF"/>
<path d="M30.8857 119.344H48.2654" stroke="#B2D8FB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.623 146.882H48.265" stroke="#B2D8FB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.623 156.062H48.265" stroke="#B2D8FB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.8857 128.522H48.2654" stroke="#B2D8FB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.8857 137.702H48.2654" stroke="#B2D8FB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  meeting: `<svg width="218" height="204" viewBox="0 0 218 204" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M191.849 25.5311C204.819 41.5441 200.858 71.361 198.728 99.0596C196.598 126.758 196.42 152.344 183.45 169.728C170.435 187.126 144.714 196.257 121.472 193.789C98.2302 191.279 77.5126 177.128 57.0636 159.73C36.6146 142.333 16.4055 121.749 17.0131 101.74C17.6207 81.7321 39.0371 62.3589 59.4939 46.3485C79.9507 30.3381 99.4479 17.6984 124.163 12.5051C148.878 7.31174 178.889 9.52079 191.849 25.5311Z" fill="#92CFFB"/>
<path d="M189.395 42.6318H28.9998V142.187H189.395V42.6318Z" fill="#F1F8FE"/>
<path d="M189.395 30.1875H28.9998V42.6319H189.395V30.1875Z" fill="#EAF6FF"/>
<path d="M160.358 37.099C160.358 37.6462 160.196 38.1812 159.893 38.6363C159.589 39.0914 159.157 39.4463 158.652 39.6559C158.146 39.8654 157.59 39.9204 157.053 39.8138C156.517 39.7071 156.024 39.4437 155.637 39.0568C155.25 38.6699 154.987 38.177 154.88 37.6403C154.773 37.1036 154.828 36.5474 155.038 36.042C155.247 35.5366 155.602 35.1047 156.057 34.801C156.513 34.4973 157.048 34.3355 157.595 34.3359C158.327 34.3366 159.03 34.6279 159.548 35.1459C160.066 35.664 160.357 36.3664 160.358 37.099Z" fill="#FB5D64"/>
<path d="M172.802 37.099C172.803 37.6461 172.641 38.181 172.337 38.6361C172.034 39.0911 171.602 39.446 171.097 39.6556C170.592 39.8653 170.035 39.9204 169.499 39.8139C168.962 39.7075 168.469 39.4443 168.082 39.0576C167.695 38.671 167.432 38.1782 167.325 37.6417C167.218 37.1052 167.273 36.5491 167.482 36.0436C167.691 35.5382 168.045 35.1061 168.5 34.8022C168.955 34.4982 169.49 34.3359 170.037 34.3359C170.77 34.3359 171.473 34.627 171.992 35.1451C172.51 35.6632 172.802 36.366 172.802 37.099Z" fill="#FB5D64"/>
<path d="M185.237 37.0991C185.238 37.6465 185.076 38.1818 184.773 38.637C184.469 39.0923 184.038 39.4471 183.533 39.6565C183.028 39.8659 182.472 39.9205 181.936 39.8133C181.4 39.7061 180.908 39.4419 180.522 39.0543C180.136 38.6668 179.874 38.1731 179.768 37.636C179.662 37.0989 179.718 36.5424 179.928 36.0372C180.139 35.5319 180.494 35.1005 180.949 34.7976C181.405 34.4948 181.939 34.3341 182.486 34.336C183.218 34.336 183.92 34.627 184.437 35.1451C184.955 35.6632 185.246 36.366 185.247 37.0991" fill="#FB5D64"/>
<path d="M152 13.1875H67.9998V42.1875H152V13.1875Z" fill="white"/>
<path d="M80.9998 21.1875H137" stroke="#3F96D1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M80.9998 27.1875H137" stroke="#3F96D1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M80.9998 33.1875H108" stroke="#3F96D1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="154.5" cy="143.5" r="36.5" fill="#E0EFFE"/>
<circle cx="50.5" cy="125.5" r="36.5" fill="#E0EFFE"/>
<path d="M144.2 131.7C145.2 136.8 149.7 140.5 154.9 140.5C160.1 140.5 164.6 136.8 165.6 131.7L167.3 123.1C168.2 116.2 162.9 110 155.9 110H154.9H153.9C146.9 110 141.6 116.2 142.5 123.1L144.2 131.7ZM177.8 148L166.2 143.9L155 155.1L143.8 143.9L132.1 148C131 148.4 130.2 149.3 130 150.4L126 169.1H183.8L179.8 150.4C179.6 149.3 178.9 148.4 177.8 148Z" fill="#577FAB"/>
<path d="M39.2 113.7C40.2 118.8 44.7 122.5 49.9 122.5C55.1 122.5 59.6 118.8 60.6 113.7L62.3 105.1C63.2 98.2 57.9 92 50.9 92H49.9H48.9C41.9 92 36.6 98.2 37.5 105.1L39.2 113.7ZM72.8 130L61.2 125.9L50 137.1L38.8 125.9L27.1 130C26 130.4 25.2 131.3 25 132.4L21 151.1H78.8L74.8 132.4C74.6 131.3 73.9 130.4 72.8 130Z" fill="#577FAB"/>
<path d="M60.67 54H10.33C9.44682 54 8.59982 54.3528 7.97533 54.9807C7.35084 55.6086 7 56.4602 7 57.3482V76.8624C7 77.7504 7.35084 78.602 7.97533 79.2299C8.59982 79.8578 9.44682 80.2106 10.33 80.2106H24.3345L35.5017 95L46.6722 80.2106H60.67C61.5532 80.2106 62.4002 79.8578 63.0247 79.2299C63.6492 78.602 64 77.7504 64 76.8624V57.3482C64 56.4602 63.6492 55.6086 63.0247 54.9807C62.4002 54.3528 61.5532 54 60.67 54Z" fill="#D8EFFF"/>
<path d="M176.67 62H126.33C125.447 62 124.6 62.3528 123.975 62.9807C123.351 63.6086 123 64.4602 123 65.3482V84.8624C123 85.7504 123.351 86.602 123.975 87.2299C124.6 87.8578 125.447 88.2106 126.33 88.2106H140.334L151.502 103L162.672 88.2106H176.67C177.553 88.2106 178.4 87.8578 179.025 87.2299C179.649 86.602 180 85.7504 180 84.8624V65.3482C180 64.4602 179.649 63.6086 179.025 62.9807C178.4 62.3528 177.553 62 176.67 62Z" fill="#D8EFFF"/>
<path d="M136 81C139.314 81 142 78.3137 142 75C142 71.6863 139.314 69 136 69C132.686 69 130 71.6863 130 75C130 78.3137 132.686 81 136 81Z" fill="white"/>
<path d="M147 72H173" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M147 78H161" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<rect x="21" y="60" width="20" height="14" rx="2" fill="white"/>
<path d="M39 65.4832C39 64.7459 39.4056 64.0684 40.0556 63.7202L44.0556 61.5774C45.3879 60.8636 47 61.8289 47 63.3403V71.2537C47 72.853 45.216 73.8054 43.8872 72.9155L39.8872 70.2369C39.3328 69.8657 39 69.2424 39 68.5751V65.4832Z" fill="white"/>
<path d="M29.1922 62.2396C29.4548 62.1604 29.7365 62.1736 29.9905 62.2768C30.2446 62.3801 30.4556 62.5673 30.5884 62.8072L30.6334 62.8966L31.03 63.7792C31.1506 64.0471 31.1897 64.3445 31.1425 64.6344C31.0954 64.9244 30.964 65.1941 30.7648 65.41L30.6856 65.4892L30.0592 66.073C29.9464 66.1798 30.031 66.5932 30.4396 67.3C30.8062 67.936 31.1056 68.233 31.252 68.2492H31.2778L31.3096 68.2432L32.5396 67.867C32.7049 67.8163 32.8813 67.8143 33.0477 67.8612C33.2142 67.9081 33.3635 68.002 33.478 68.1316L33.5332 68.2006L34.3468 69.3286C34.5065 69.5496 34.5861 69.8184 34.5726 70.0907C34.559 70.363 34.4532 70.6226 34.2724 70.8268L34.1992 70.9024L33.8734 71.2108C33.5814 71.4873 33.2161 71.6742 32.821 71.7492C32.4258 71.8242 32.0174 71.7841 31.6444 71.6338C30.4834 71.1658 29.4286 70.0966 28.4704 68.437C27.5104 66.7732 27.1114 65.323 27.2896 64.081C27.3436 63.7055 27.4983 63.3516 27.7372 63.0569C27.9762 62.7623 28.2905 62.5379 28.6468 62.4076L28.7626 62.3692L29.1922 62.2396Z" fill="#D8EFFF"/>
</svg>
`,
  conversations: `<svg width="218" height="204" viewBox="0 0 218 204" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M192.849 29.5302C205.82 45.5431 201.859 75.36 199.728 103.059C197.598 130.757 197.421 156.343 184.45 173.727C171.436 191.125 145.714 200.256 122.472 197.788C99.2304 195.278 78.5129 181.127 58.0639 163.729C37.6149 146.332 17.4058 125.748 18.0134 105.74C18.6209 85.7311 40.0374 66.3579 60.4942 50.3475C80.951 34.3372 100.448 21.6974 125.163 16.5041C149.878 11.3108 179.889 13.5198 192.849 29.5302Z" fill="#92CFFB"/>
<path d="M190 56H58V163H190V56Z" fill="#F1F8FE"/>
<path d="M190 46H58V64H190V46Z" fill="#EAF6FF"/>
<path d="M73.5 60C75.9853 60 78 57.9853 78 55.5C78 53.0147 75.9853 51 73.5 51C71.0147 51 69 53.0147 69 55.5C69 57.9853 71.0147 60 73.5 60Z" fill="#3F96D1"/>
<path d="M82 53H102" stroke="#3F96D1" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M82 58H93" stroke="#3F96D1" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<rect x="169" y="53" width="6.15385" height="4.30769" fill="#3F96D1"/>
<path d="M174.539 54.3187L177 53V57.6154L174.539 55.967V54.3187Z" fill="#3F96D1"/>
<path d="M159.795 51.826C159.97 51.7733 160.158 51.782 160.327 51.8509C160.497 51.9197 160.637 52.0445 160.726 52.2044L160.756 52.264L161.02 52.8524C161.101 53.031 161.127 53.2293 161.095 53.4226C161.064 53.6159 160.976 53.7957 160.843 53.9396L160.791 53.9924L160.373 54.3816C160.298 54.4528 160.354 54.7284 160.627 55.1996C160.871 55.6236 161.071 55.8216 161.168 55.8324H161.185L161.207 55.8284L162.027 55.5776C162.137 55.5439 162.254 55.5425 162.365 55.5738C162.476 55.6051 162.576 55.6677 162.652 55.754L162.689 55.8L163.231 56.552C163.338 56.6994 163.391 56.8786 163.382 57.0602C163.373 57.2417 163.302 57.4148 163.182 57.5508L163.133 57.6012L162.916 57.8068C162.721 57.9912 162.478 58.1158 162.214 58.1658C161.951 58.2158 161.678 58.1891 161.43 58.0888C160.656 57.7768 159.953 57.064 159.314 55.9576C158.674 54.8484 158.408 53.8816 158.527 53.0536C158.563 52.8033 158.666 52.5674 158.825 52.371C158.984 52.1745 159.194 52.0249 159.431 51.938L159.509 51.9124L159.795 51.826Z" fill="#3F96D1"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M70 74C69.4477 74 69 74.4477 69 75V88C69 88.5523 69.4477 89 70 89H75.6132L78.5 94L81.3868 89H116C116.552 89 117 88.5523 117 88V75C117 74.4477 116.552 74 116 74H70Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M183 98C183.552 98 184 98.4477 184 99V120.125C184 120.677 183.552 121.125 183 121.125H173.805L169.354 128.833L164.904 121.125H111C110.448 121.125 110 120.677 110 120.125V99C110 98.4477 110.448 98 111 98H183Z" fill="white"/>
<path d="M78 79H104" stroke="#3F96D1" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M176 104H136" stroke="#3F96D1" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M78 84H92" stroke="#3F96D1" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M176 111H155" stroke="#3F96D1" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M111.843 109L21 109.176L21.157 190.229L112 190.054L111.843 109Z" fill="#F1F8FE"/>
<path d="M44.7045 109.129L21.001 109.175L21.158 190.229L44.8615 190.183L44.7045 109.129Z" fill="#B3D7F8"/>
<path d="M31.0587 128.012C34.9883 129.014 38.9863 126.641 39.9886 122.712C40.9909 118.782 38.6179 114.784 34.6883 113.782C30.7588 112.779 26.7607 115.152 25.7584 119.082C24.7561 123.011 27.1292 127.01 31.0587 128.012Z" fill="#F1F8FE"/>
<path d="M41.8933 132.326L24.0786 132.36L24.0819 134.072L41.8966 134.038L41.8933 132.326Z" fill="#F1F8FE"/>
<path d="M41.9016 136.448L24.0869 136.482L24.0902 138.194L41.9049 138.16L41.9016 136.448Z" fill="#F1F8FE"/>
<path d="M59.5187 148.46C60.6105 148.739 61.7214 148.079 61.9999 146.987C62.2784 145.896 61.619 144.785 60.5272 144.506C59.4354 144.228 58.3245 144.887 58.0461 145.979C57.7676 147.071 58.4269 148.182 59.5187 148.46Z" fill="#B2E5FB"/>
<path d="M98.7272 145.549L64.96 145.614L64.9633 147.326L98.7306 147.261L98.7272 145.549Z" fill="#B3D7F8"/>
<path d="M98.6721 115.751L57.9014 115.83L57.9533 142.618L98.724 142.539L98.6721 115.751Z" fill="white"/>
<path d="M59.59 186.353C60.6818 186.631 61.7927 185.972 62.0712 184.88C62.3497 183.788 61.6903 182.677 60.5985 182.399C59.5067 182.12 58.3958 182.78 58.1173 183.871C57.8389 184.963 58.4982 186.074 59.59 186.353Z" fill="#B2E5FB"/>
<path d="M98.8019 183.443L65.0347 183.509L65.038 185.221L98.8053 185.155L98.8019 183.443Z" fill="#B3D7F8"/>
<path d="M98.7449 153.646L57.9741 153.725L58.026 180.513L98.7968 180.434L98.7449 153.646Z" fill="white"/>
<path d="M169 151V175.702H180.074L185.5 185L190.926 175.699H202V151H169Z" fill="#F4F7FF"/>
<path d="M181 162.997C181.001 163.392 180.884 163.779 180.665 164.109C180.445 164.438 180.133 164.695 179.768 164.847C179.402 164.999 179 165.039 178.612 164.962C178.224 164.885 177.867 164.695 177.587 164.415C177.307 164.136 177.116 163.779 177.039 163.391C176.961 163.003 177.001 162.601 177.152 162.235C177.303 161.87 177.56 161.557 177.889 161.337C178.217 161.117 178.604 161 179 161C179.262 161 179.522 161.051 179.765 161.151C180.008 161.251 180.228 161.398 180.414 161.584C180.6 161.769 180.747 161.989 180.848 162.232C180.948 162.474 181 162.734 181 162.997Z" fill="#FB5D64"/>
<path d="M186.997 162.997C186.998 163.392 186.91 163.78 186.746 164.109C186.581 164.438 186.347 164.695 186.073 164.847C185.799 164.999 185.498 165.039 185.207 164.962C184.916 164.885 184.649 164.694 184.439 164.414C184.229 164.134 184.086 163.778 184.029 163.389C183.971 163.001 184.001 162.599 184.115 162.233C184.228 161.867 184.421 161.555 184.668 161.336C184.915 161.116 185.205 160.999 185.501 161C185.698 161 185.893 161.051 186.075 161.151C186.256 161.251 186.422 161.398 186.561 161.584C186.7 161.769 186.811 161.989 186.886 162.232C186.961 162.474 187 162.734 187 162.997" fill="#FB5D64"/>
<path d="M18 22V59.7788H35.115L43.5 74L51.885 59.7744H69V22H18Z" fill="#F4F7FF"/>
<path d="M36 39.995C36.0008 40.5885 35.8549 41.169 35.5807 41.663C35.3065 42.157 34.9164 42.5424 34.4596 42.7702C34.0029 42.998 33.5001 43.0582 33.0149 42.943C32.5296 42.8278 32.0838 42.5425 31.7337 42.1231C31.3837 41.7037 31.1451 41.1692 31.0483 40.5871C30.9515 40.005 31.0008 39.4016 31.1899 38.8531C31.379 38.3046 31.6994 37.8358 32.1106 37.5059C32.5219 37.1761 33.0054 37 33.5 37C33.8281 36.9993 34.1531 37.0763 34.4564 37.2265C34.7597 37.3767 35.0353 37.5972 35.2675 37.8754C35.4997 38.1536 35.6839 38.484 35.8096 38.8477C35.9353 39.2114 36 39.6012 36 39.995Z" fill="#FB5D64"/>
<path d="M45.9958 39.995C45.9966 40.5887 45.8507 41.1694 45.5766 41.6636C45.3024 42.1577 44.9124 42.543 44.4557 42.7707C43.9991 42.9984 43.4965 43.0582 43.0116 42.9426C42.5267 42.827 42.0812 42.5412 41.7316 42.1213C41.382 41.7015 41.144 41.1665 41.0478 40.584C40.9515 40.0016 41.0014 39.398 41.1909 38.8496C41.3805 38.3012 41.7013 37.8328 42.1128 37.5035C42.5242 37.1742 43.0077 36.999 43.5021 37C43.8299 36.9993 44.1547 37.0763 44.4577 37.2265C44.7607 37.3767 45.0361 37.5972 45.2681 37.8754C45.5001 38.1536 45.6842 38.484 45.8098 38.8477C45.9354 39.2114 46 39.6012 46 39.995" fill="#FB5D64"/>
<path d="M56 40C56 40.5933 55.8534 41.1734 55.5787 41.6667C55.304 42.1601 54.9135 42.5446 54.4567 42.7716C53.9999 42.9987 53.4972 43.0581 53.0123 42.9424C52.5273 42.8266 52.0819 42.5409 51.7322 42.1213C51.3826 41.7018 51.1445 41.1672 51.048 40.5853C50.9516 40.0033 51.0011 39.4001 51.1903 38.8519C51.3795 38.3038 51.7 37.8352 52.1111 37.5056C52.5222 37.1759 53.0055 37 53.5 37C54.163 37 54.7989 37.3161 55.2678 37.8787C55.7366 38.4413 56 39.2044 56 40Z" fill="#FB5D64"/>
<path d="M194 163C194 163.396 193.883 163.782 193.663 164.111C193.443 164.44 193.131 164.696 192.765 164.848C192.4 164.999 191.998 165.039 191.61 164.962C191.222 164.884 190.865 164.694 190.586 164.414C190.306 164.135 190.116 163.778 190.038 163.39C189.961 163.002 190.001 162.6 190.152 162.235C190.304 161.869 190.56 161.557 190.889 161.337C191.218 161.117 191.604 161 192 161C192.53 161 193.039 161.211 193.414 161.586C193.789 161.961 194 162.47 194 163Z" fill="#FB5D64"/>
</svg>
`,
  team: `<svg width="218" height="204" viewBox="0 0 218 204" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1255_7300)">
  <path d="M181.849 30.3436C194.82 46.3566 190.859 76.1735 188.728 103.872C186.598 131.571 186.421 157.156 173.45 174.541C160.436 191.938 134.714 201.07 111.472 198.602C88.2304 196.092 67.5129 181.94 47.0639 164.543C26.6149 147.145 6.40579 126.561 7.01336 106.553C7.62094 86.5446 29.0374 67.1714 49.4942 51.161C69.951 35.1506 89.4482 22.5109 114.163 17.3176C138.878 12.1242 168.889 14.3333 181.849 30.3436Z" fill="#92CFFB"/>
  <path d="M34.8632 172.677C23.726 158.941 27.1272 133.363 28.9565 109.603C30.7858 85.8421 30.9381 63.8943 42.0752 48.9815C53.2504 34.0575 75.3366 26.2241 95.2935 28.3414C115.25 30.4944 133.04 42.6338 150.599 57.5577C168.157 72.4817 185.51 90.1392 184.989 107.303C184.467 124.467 166.077 141.085 148.512 154.819C130.946 168.553 114.205 179.396 92.9828 183.851C71.7608 188.306 45.9913 186.411 34.8632 172.677Z" fill="#F1F8FE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M146 126H208.648V186H146V126Z" fill="#DBEFFB"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M164.169 135.856H199.081V138.253H164.169V135.856Z" fill="#3F96D1"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M164.169 146.544H199.081V148.941H164.169V146.544Z" fill="#3F96D1"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M164.169 157.229H199.081V159.627H164.169V157.229Z" fill="#3F96D1"/>
  <path d="M158.665 134.761L154.616 138.567L152.679 136.747" stroke="#3F96D1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M158.665 145.95L154.616 149.756L152.679 147.936" stroke="#3F96D1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M158.665 157.143L154.616 160.949L152.679 159.129" stroke="#3F96D1" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M164.169 167.917H199.081V170.314H164.169V167.917Z" fill="#E5F4FD"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M164.169 178.605H199.081V181.003H164.169V178.605Z" fill="#E5F4FD"/>
  <path d="M83.2894 98.0622H96.5523H109.815C111.256 98.0622 112.422 96.8908 112.422 95.4443V92.5912C112.422 85.1089 106.48 78.6469 98.3843 78.6469C102.66 77.7921 105.883 74.0047 105.883 69.4588C105.883 64.2847 101.705 60.0908 96.5523 60.0908C91.3995 60.0908 87.221 64.2847 87.221 69.4588C87.221 74.0047 90.4446 77.7921 94.7202 78.6469C86.6347 78.6469 80.6821 85.0989 80.6821 92.5912V95.4443C80.6823 96.8907 81.8488 98.0622 83.2894 98.0622Z" fill="#3F96D1"/>
  <path d="M61.7187 105.367C61.8836 105.397 62.0473 105.412 62.2091 105.412C63.4988 105.412 64.6444 104.487 64.8836 103.168C65.5537 99.4709 66.8223 95.8785 68.6539 92.4911C70.4217 89.2221 72.6384 86.2865 75.2426 83.7658C76.3248 82.7185 76.3564 80.9884 75.3132 79.9016C74.2701 78.815 72.5472 78.7833 71.465 79.8306C68.4594 82.7399 65.9041 86.1221 63.8704 89.8831C61.7628 93.7808 60.3019 97.9211 59.5283 102.189C59.2593 103.674 60.24 105.097 61.7187 105.367Z" fill="#ABBAC5"/>
  <path d="M124.451 92.4912C126.283 95.8788 127.551 99.4709 128.221 103.168C128.46 104.488 129.606 105.412 130.896 105.412C131.057 105.412 131.221 105.397 131.386 105.367C132.865 105.097 133.845 103.674 133.576 102.189C132.803 97.9212 131.342 93.7807 129.234 89.8832C127.201 86.1222 124.645 82.7398 121.64 79.8307C120.557 78.7831 118.835 78.8151 117.791 79.9017C116.748 80.9884 116.78 82.7184 117.862 83.7659C120.466 86.2865 122.683 89.2222 124.451 92.4912Z" fill="#ABBAC5"/>
  <path d="M107.547 126.241C104.037 127.4 100.339 127.994 96.5524 128.006C96.5034 128.008 96.4527 128.008 96.4037 128.008C92.7006 128.008 89.0719 127.453 85.6228 126.358C84.1894 125.902 82.6616 126.7 82.2081 128.139C81.7545 129.579 82.5492 131.113 83.9825 131.568C87.9632 132.832 92.1417 133.474 96.4037 133.474H96.5524C100.918 133.457 105.187 132.772 109.246 131.433C110.674 130.963 111.452 129.418 110.982 127.984C110.514 126.551 108.975 125.769 107.547 126.241Z" fill="#ABBAC5"/>
  <path d="M67.7018 133.493C71.9776 132.638 75.2014 128.85 75.2014 124.305C75.2014 119.131 71.0229 114.936 65.8701 114.936C60.7173 114.936 56.5389 119.131 56.5389 124.305C56.5389 128.85 59.7626 132.638 64.0385 133.493C55.9598 133.493 50 139.939 50 147.438V150.291C50 151.736 51.1667 152.909 52.6073 152.909H65.8701H79.133C80.5736 152.909 81.7402 151.736 81.7402 150.291V147.438C81.7402 139.971 75.8144 133.493 67.7018 133.493Z" fill="#3F96D1"/>
  <path d="M128.962 133.493C133.238 132.638 136.461 128.85 136.461 124.305C136.461 119.131 132.283 114.936 127.13 114.936C121.975 114.936 117.799 119.131 117.799 124.305C117.799 128.85 121.021 132.638 125.298 133.493C117.201 133.493 111.26 139.954 111.26 147.438V150.291C111.26 151.736 112.426 152.909 113.867 152.909H127.13H140.393C141.832 152.909 143 151.736 143 150.291V147.438C143 139.955 137.058 133.493 128.962 133.493Z" fill="#3F96D1"/>
  <circle cx="25" cy="126" r="21" fill="#ECF5FC"/>
  <path d="M15.813 112.406L14.405 113.812L16.5 115.906L17.906 114.5L15.812 112.406H15.813ZM34.188 112.406L32.093 114.5L33.5 115.906L35.594 113.813L34.187 112.406H34.188ZM25 113.03C24.67 113.034 24.336 113.053 24 113.094C23.99 113.094 23.98 113.092 23.97 113.094C19.914 113.559 16.686 116.836 16.125 120.874C15.677 124.124 17.017 127.071 19.25 128.969C20.1634 129.749 20.7812 130.819 21 131.999V137.999H23.28C23.628 138.596 24.263 138.999 25 138.999C25.737 138.999 26.372 138.596 26.72 137.999H29V133.999H29.094V132.811C29.094 131.345 29.856 129.867 31.094 128.718C32.75 127.06 34 124.705 34 122C34 117.06 29.934 112.984 25 113.03ZM25 115.03C28.865 114.976 32 118.14 32 122C32 124.094 31.03 125.938 29.687 127.28L29.719 127.312C28.3844 128.543 27.5214 130.201 27.279 132H22.905C22.685 130.286 21.95 128.627 20.561 127.437C18.794 125.937 17.741 123.677 18.093 121.125C18.53 117.975 21.086 115.442 24.218 115.095C24.4766 115.059 24.737 115.038 24.998 115.031L25 115.03ZM11 122V124H14V122H11ZM36 122V124H39V122H36ZM16.5 130.094L14.406 132.187L15.813 133.594L17.905 131.5L16.5 130.094ZM33.5 130.094L32.094 131.5L34.187 133.594L35.594 132.187L33.5 130.094ZM23 134H27V136H23V134Z" fill="#FFB147"/>
  <path d="M193 24H112V74H193V24Z" fill="#F6FAFD"/>
  <path d="M193 18H112V24H193V18Z" fill="#EAF6FF"/>
  <path d="M179 21.4987C179 21.7955 178.912 22.0857 178.748 22.3326C178.583 22.5794 178.349 22.7719 178.075 22.8856C177.8 22.9993 177.499 23.0291 177.208 22.9712C176.917 22.9134 176.649 22.7705 176.439 22.5607C176.229 22.3508 176.087 22.0834 176.029 21.7923C175.971 21.5012 176.001 21.1995 176.114 20.9254C176.228 20.6512 176.421 20.417 176.667 20.2523C176.914 20.0875 177.204 19.9998 177.501 20C177.899 20.0003 178.28 20.1583 178.561 20.4393C178.842 20.7203 179 21.1013 179 21.4987Z" fill="#FB5D64"/>
  <path d="M185 21.4987C185 21.7955 184.912 22.0856 184.748 22.3324C184.583 22.5793 184.349 22.7717 184.075 22.8855C183.801 22.9992 183.499 23.0291 183.208 22.9713C182.917 22.9136 182.65 22.7708 182.44 22.5611C182.23 22.3514 182.087 22.0841 182.029 21.7931C181.971 21.5021 182.001 21.2004 182.114 20.9263C182.228 20.6521 182.42 20.4178 182.667 20.2529C182.913 20.088 183.203 20 183.5 20C183.898 20 184.279 20.1579 184.56 20.4389C184.841 20.7199 185 21.1011 185 21.4987Z" fill="#FB5D64"/>
  <path d="M190.995 21.4987C190.995 21.7957 190.907 22.086 190.743 22.333C190.578 22.5799 190.344 22.7724 190.07 22.8859C189.796 22.9995 189.495 23.0291 189.204 22.971C188.914 22.9128 188.647 22.7695 188.437 22.5593C188.228 22.3491 188.086 22.0813 188.028 21.79C187.971 21.4987 188.001 21.1968 188.115 20.9228C188.229 20.6487 188.422 20.4147 188.669 20.2504C188.916 20.0862 189.206 19.999 189.503 20C189.899 20 190.28 20.1579 190.561 20.4389C190.842 20.7199 191 21.1011 191 21.4987" fill="#FB5D64"/>
  <circle cx="135.951" cy="46.4506" r="13.8876" transform="rotate(45.0014 135.951 46.4506)" fill="#D9EBFF"/>
  <path d="M146.306 56.868C144.199 58.9745 141.5 60.3885 138.569 60.9212C135.638 61.4538 132.614 61.0799 129.901 59.8492C127.188 58.6185 124.914 56.5896 123.384 54.0333C121.854 51.477 121.14 48.5148 121.338 45.5422C121.535 42.5695 122.634 39.7277 124.489 37.396C126.343 35.0643 128.865 33.3537 131.717 32.4924C134.569 31.6311 137.615 31.66 140.451 32.5754C143.286 33.4908 145.774 35.2491 147.584 37.6156L135.951 46.5121L146.306 56.868Z" fill="#A1C7F1"/>
  <path d="M146.663 57.4334C144.901 59.1951 142.732 60.4951 140.348 61.2182C137.964 61.9414 135.438 62.0654 132.995 61.5793C130.551 61.0932 128.265 60.0119 126.339 58.4313C124.413 56.8507 122.907 54.8196 121.954 52.5177C121 50.2159 120.629 47.7145 120.874 45.2351C121.118 42.7556 121.97 40.3747 123.354 38.3031C124.738 36.2316 126.612 34.5334 128.809 33.359C131.007 32.1846 133.46 31.5702 135.951 31.5703L135.951 46.7204L146.663 57.4334Z" fill="#AEDBF9"/>
  <path d="M164 34H222.039V51.23H164V34Z" fill="#EAF6FF"/>
  <path d="M168.909 42.6121C168.908 43.6345 169.213 44.6341 169.783 45.4844C170.354 46.3347 171.166 46.9976 172.116 47.3892C173.065 47.7808 174.11 47.8834 175.119 47.6843C176.127 47.4851 177.053 46.9929 177.781 46.2701C178.508 45.5473 179.003 44.6263 179.204 43.6235C179.404 42.6208 179.302 41.5814 178.908 40.6367C178.515 39.6921 177.849 38.8847 176.994 38.3166C176.139 37.7485 175.134 37.4453 174.106 37.4453C172.728 37.4453 171.406 37.9896 170.432 38.9585C169.457 39.9274 168.909 41.2416 168.909 42.6121Z" fill="#CBE9FF"/>
  <path d="M183.634 39.7422H212.51" stroke="#3F96D1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M183.635 45.4863H198.938" stroke="#3F96D1" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
  <clipPath id="clip0_1255_7300">
  <rect width="218" height="204" fill="white"/>
  </clipPath>
  </defs>
  </svg>`,
  feed: `<svg width="218" height="209" viewBox="0 0 218 209" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1255_7874)">
  <path d="M181.849 31.0876C194.819 47.493 190.858 78.0407 188.728 106.418C186.597 134.796 186.42 161.008 173.45 178.819C160.435 196.643 134.713 205.998 111.472 203.469C88.2299 200.898 67.5124 186.4 47.0634 168.576C26.6144 150.752 6.4053 129.664 7.01288 109.165C7.62045 88.666 29.0369 68.818 49.4937 52.4152C69.9505 36.0124 89.4477 23.0629 114.163 17.7422C138.878 12.4216 168.889 14.6848 181.849 31.0876Z" fill="#92CFFB"/>
  <path d="M191.543 186.973C176.283 202.594 144.104 202.591 114.418 204.192C84.7323 205.793 57.5511 209.124 37.3615 198.092C17.1522 187.015 4.01478 161.655 3.52228 137.267C3.07379 112.874 15.3202 89.4931 31.0481 65.9441C46.776 42.3952 65.9184 18.6567 87.2375 16.5367C108.557 14.4167 131.988 33.9153 151.722 52.8828C171.455 71.8504 187.483 90.2882 196.305 115.148C205.127 140.008 206.801 171.364 191.543 186.973Z" fill="#F1F8FE"/>
  <path d="M79.1748 159.736C82.7873 172.661 88.692 183.334 95.1676 186V159.736H79.1748Z" fill="#3C9DDF"/>
  <path d="M75.1855 133.252C75.397 139.972 76.2359 146.741 77.58 153.115H95.1673V133.252H75.1855Z" fill="#3C9DDF"/>
  <path d="M101.811 159.736V186C108.286 183.334 114.213 172.661 117.825 159.736H101.811Z" fill="#3C9DDF"/>
  <path d="M68.5696 133.252H42.168C42.5809 140.299 44.271 146.995 47.0512 153.115H70.7978C69.5146 146.763 68.7647 140.036 68.5696 133.252Z" fill="#3C9DDF"/>
  <path d="M72.3774 159.736H50.6216C58.0767 171.618 69.8212 181.04 83.6911 184.773C78.9466 178.82 75.0452 169.659 72.3774 159.736Z" fill="#3C9DDF"/>
  <path d="M124.623 159.736C121.955 169.659 118.054 178.82 113.31 184.773C127.179 181.04 138.924 171.618 146.379 159.736H124.623Z" fill="#3C9DDF"/>
  <path d="M128.431 133.252C128.236 140.036 127.486 146.763 126.203 153.115H149.949C152.729 146.995 154.42 140.299 154.832 133.252H128.431Z" fill="#3C9DDF"/>
  <path d="M101.811 133.252V153.115H119.42C120.764 146.741 121.603 139.972 121.814 133.252H101.811Z" fill="#3C9DDF"/>
  <path d="M98.5 73C91.1967 73 85.0371 79.601 85.0371 86.9043C85.0371 94.2076 91.1967 100.146 98.5 100.146C105.803 100.146 111.963 94.2076 111.963 86.9043C111.963 79.601 105.803 73 98.5 73Z" fill="#3C9DDF"/>
  <path d="M138.447 86.9043C132.971 86.9043 128.516 91.3594 128.516 96.8359C128.516 102.312 132.971 106.768 138.447 106.768C143.924 106.768 148.379 102.312 148.379 96.8359C148.379 91.3594 143.924 86.9043 138.447 86.9043Z" fill="#3C9DDF"/>
  <path d="M58.5527 86.9043C53.0762 86.9043 48.6211 91.3594 48.6211 96.8359C48.6211 102.312 53.0762 106.768 58.5527 106.768C64.0293 106.768 68.4844 102.312 68.4844 96.8359C68.4844 91.3594 64.0293 86.9043 58.5527 86.9043Z" fill="#3C9DDF"/>
  <path d="M148.183 110.155C145.443 112.164 142.097 113.388 138.447 113.388C134.79 113.388 131.438 112.158 128.695 110.142C127.103 111.33 125.65 112.69 124.567 114.362C124.966 116.186 125.205 118.068 125.205 120.009V126.631H152.661C153.953 126.631 155 125.584 155 124.292C155 118.682 152.365 113.425 148.183 110.155Z" fill="#3C9DDF"/>
  <path d="M109.63 103.474C106.495 105.545 102.531 106.768 98.5 106.768C94.4746 106.768 90.5154 105.547 87.3825 103.48C81.883 107.245 78.416 113.814 78.416 120.804V123.32C78.416 125.174 79.8724 126.631 81.7266 126.631H115.273C117.128 126.631 118.584 125.174 118.584 123.32V120.01C118.584 112.911 115.006 107.015 109.63 103.474Z" fill="#3C9DDF"/>
  <path d="M68.3045 110.142C65.5618 112.158 62.2096 113.388 58.5527 113.388C54.9032 113.388 51.5567 112.164 48.8171 110.155C44.6348 113.425 42 118.682 42 124.292V126.631H71.7949V120.009C71.7949 118.068 72.0342 116.186 72.4325 114.362C71.3495 112.69 69.8962 111.33 68.3045 110.142Z" fill="#3C9DDF"/>
  <path d="M133 28V59.9667H150.115L158.5 72L166.885 59.963H184V28H133Z" fill="#F4F7FF"/>
  <path d="M71 -1V38.2319H91.8064L102 53L112.194 38.2273H133V-1H71Z" fill="#EBEEF5"/>
  <path d="M151 43.4958C151.001 43.9904 150.855 44.4742 150.581 44.8859C150.307 45.2975 149.916 45.6186 149.46 45.8085C149.003 45.9984 148.5 46.0485 148.015 45.9525C147.53 45.8565 147.084 45.6187 146.734 45.2693C146.384 44.9198 146.145 44.4743 146.048 43.9893C145.952 43.5042 146.001 43.0013 146.19 42.5443C146.379 42.0872 146.699 41.6965 147.111 41.4216C147.522 41.1467 148.005 41 148.5 41C148.828 40.9995 149.153 41.0636 149.456 41.1888C149.76 41.314 150.035 41.4977 150.268 41.7295C150.5 41.9613 150.684 42.2367 150.81 42.5397C150.935 42.8428 151 43.1677 151 43.4958Z" fill="#FB5D64"/>
  <path d="M160.996 43.4958C160.997 43.9906 160.851 44.4745 160.577 44.8863C160.302 45.2981 159.912 45.6192 159.456 45.8089C158.999 45.9986 158.497 46.0485 158.012 45.9522C157.527 45.8558 157.081 45.6176 156.732 45.2678C156.382 44.9179 156.144 44.472 156.048 43.9867C155.952 43.5014 156.001 42.9983 156.191 42.5413C156.381 42.0844 156.701 41.694 157.113 41.4196C157.524 41.1452 158.008 40.9992 158.502 41C158.83 40.9995 159.155 41.0636 159.458 41.1888C159.761 41.314 160.036 41.4977 160.268 41.7295C160.5 41.9613 160.684 42.2367 160.81 42.5397C160.935 42.8428 161 43.1677 161 43.4958" fill="#FB5D64"/>
  <path d="M171 43.5C171 43.9945 170.853 44.4778 170.579 44.8889C170.304 45.3 169.914 45.6205 169.457 45.8097C169 45.9989 168.497 46.0484 168.012 45.952C167.527 45.8555 167.082 45.6174 166.732 45.2678C166.383 44.9181 166.145 44.4727 166.048 43.9877C165.952 43.5028 166.001 43.0001 166.19 42.5433C166.38 42.0865 166.7 41.696 167.111 41.4213C167.522 41.1466 168.006 41 168.5 41C169.163 41 169.799 41.2634 170.268 41.7322C170.737 42.2011 171 42.837 171 43.5Z" fill="#FB5D64"/>
  <path d="M81.3848 34H11.6152C10.3912 34 9.2173 34.4904 8.35177 35.3634C7.48625 36.2363 7 37.4203 7 38.6549V65.7843C7 67.0188 7.48625 68.2028 8.35177 69.0758C9.2173 69.9487 10.3912 70.4391 11.6152 70.4391H31.0249L46.5023 91L61.9842 70.4391H81.3848C82.6088 70.4391 83.7827 69.9487 84.6482 69.0758C85.5138 68.2028 86 67.0188 86 65.7843V38.6549C86 37.4203 85.5138 36.2363 84.6482 35.3634C83.7827 34.4904 82.6088 34 81.3848 34Z" fill="#CFE4FC"/>
  <path d="M24.5 60C28.6421 60 32 56.6421 32 52.5C32 48.3579 28.6421 45 24.5 45C20.3579 45 17 48.3579 17 52.5C17 56.6421 20.3579 60 24.5 60Z" fill="#FFB147"/>
  <path d="M40 47H77" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M40 56H59" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M100.839 21.7244H103.204V21.5398C103.218 20.0341 103.708 19.3452 104.866 18.6349C106.173 17.8537 106.99 16.7884 106.99 15.1619C106.99 12.7614 105.122 11.2557 102.409 11.2557C99.9228 11.2557 97.9057 12.6335 97.8347 15.3324H100.363C100.427 13.9972 101.393 13.358 102.394 13.358C103.481 13.358 104.355 14.0824 104.355 15.1974C104.355 16.1918 103.694 16.8736 102.835 17.4134C101.578 18.1946 100.853 18.983 100.839 21.5398V21.7244ZM102.082 26.1562C102.92 26.1562 103.637 25.4602 103.644 24.5938C103.637 23.7415 102.92 23.0455 102.082 23.0455C101.215 23.0455 100.512 23.7415 100.519 24.5938C100.512 25.4602 101.215 26.1562 102.082 26.1562Z" fill="#3F96D1"/>
  </g>
  <defs>
  <clipPath id="clip0_1255_7874">
  <rect width="218" height="209" fill="white"/>
  </clipPath>
  </defs>
  </svg>
  `,
};

const markUp = ({ step, title, description, hasNext, hasPrevious, image }) => {
  return `
        <div class="onboard-header">
          <span class="page">Step ${step}/9</span>
          <span class="skip">Skip Tour</span>
        </div>

        <div class="content">
          <div class="body">
            <div class="image">
                ${image}
            </div>

            <h4 class="title">${title}</h4>
            <p class="desc">
            ${description}
            </p>
          </div>

         
        </div>
    
`;
};

class OnBoard extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const user = this.props.user;
    this.triggerOnboard(user, false);

    eventBus.on("start-tour", () => {
      this.triggerOnboard(user, true);
    });
  }

  componentWillUnmount() {
    eventBus.remove("start-tour");
  }

  triggerOnboard = (user, ignoreCheck) => {
    // console.log(user);
    if (user && (!user?.hasOnboarded || ignoreCheck)) {
      const intro = introJs();

      let isMobile = false;

      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        isMobile = true;
        this.handleNavMenu();
      }
      const rememberElements = (intro) => {
        // console.log('remeber')
        intro._introItems[0].element = document.querySelector("#dashboard");
        intro._introItems[1].element = document.querySelector("#drive");
        intro._introItems[2].element = document.querySelector("#projects");
        intro._introItems[3].element = document.querySelector("#task");
        intro._introItems[4].element = document.querySelector("#schedules");
        intro._introItems[5].element = document.querySelector("#meeting");
        intro._introItems[6].element = document.querySelector("#conversations");
        intro._introItems[7].element = document.querySelector("#team");
        intro._introItems[8].element = document.querySelector("#feed");
      };
      setTimeout(() => {
        intro

          .setOptions({
            showBullets: false,
            hidePrev: true,
            scrollToElement: false,
            // showStepNumbers: true,
            skipLabel: "Skip Tour",
            doneLabel: "Got it",
            // nextLabel: 'Start a Tour',
            steps: [
              {
                element: document.getElementById("dashboard"),
                position: "bottom",
                intro: markUp({
                  step: 1,
                  image: svgs.dashboard,
                  title: "Welcome to Fexspace Dashboard!",
                  description:
                    "Your new workspace, where your important collaboration tools are organized in one place. Let's explore our amazing features.",
                }),
              },
              {
                element: document.getElementById("drive"),
                position: "bottom",
                intro: markUp({
                  image: svgs.drive,
                  step: 2,
                  title: "Fexspace Cloud Drive",
                  description:
                    "Save and organize your personal and project files on Fexspace Cloud Drive.",
                }),
              },
              {
                element: document.getElementById("projects"),
                position: "bottom",
                intro: markUp({
                  image: svgs.projects,
                  step: 3,
                  title: "Project Management",
                  description:
                    "Create and organize your personal or team project. You can add your team to this project.",
                }),
              },

              {
                element: document.getElementById("task"),
                position: "bottom",
                intro: markUp({
                  image: svgs.tasks,
                  step: 4,
                  title: "Task Management",
                  description:
                    "Your taskboard is where you organize all your tasks, knowing if they're done and what needs to be done. You can also drag and drop tasks at the appropriate stage.",
                }),
              },
              {
                element: document.getElementById("schedules"),
                position: "bottom",
                intro: markUp({
                  image: svgs.schedule,
                  step: 5,
                  title: "Organize Your Schedules",
                  description:
                    "Schedule your meetings, events, task reminder in your schedule board. So, it can make sure you didn’t miss important appointments",
                }),
              },
              {
                element: document.getElementById("meeting"),
                position: "bottom",
                intro: markUp({
                  image: svgs.meeting,
                  step: 6,
                  title: "Meeting with Your Team",
                  description:
                    "All your meetings will appear here. You can also create a new meeting with your team.",
                }),
              },
              {
                element: document.getElementById("conversations"),
                position: "bottom",
                intro: markUp({
                  image: svgs.conversations,
                  step: 7,
                  title: "Personal & Group Conversation",
                  description:
                    "All personal and group chats are here. Catch up with conversations around your team.",
                }),
              },
              {
                element: document.getElementById("team"),
                position: "bottom",
                intro: markUp({
                  image: svgs.team,
                  step: 8,
                  title: "Manage Your Team",
                  description:
                    "See people you work with and what they are working on.",
                }),
              },
              {
                element: document.getElementById("feed"),
                position: "bottom",
                intro: markUp({
                  image: svgs.feed,
                  step: 9,
                  title:
                    user.account_type && user.account_type != 1
                      ? "Updates around your organisation"
                      : "Meet & Discuss with Community",
                  description:
                    user.account_type && user.account_type != 1
                      ? "Share something you want anyone around your organization to see. You can catch up with what is going on around your organization in your Feed"
                      : "Share something you want anyone around your Fexspace to see. You can catch up with what is going on around the Fexspace Community.",
                }),
              },
            ],
          })

          .onexit(() => {
            this.setHasOnboarded(user._id);
            if (isMobile) this.handleNavMenu();
          })
          .onbeforechange((targetElement) => {
            setTimeout(() => {
              if (document.querySelector(".introjs-helperLayer")) {
                document.querySelector(".introjs-helperLayer").style.boxShadow =
                  "rgba(14, 29, 47, 0.54) 0px 0px 1px 2px, rgba(14, 29, 47, 0.54) 0px 0px 0px 5000px";
              }
            }, 100);
            // console.log(document.querySelector(".introjs-progress"));
            rememberElements(intro);
            if (intro._currentStep == 0) {
              /*document.querySelector('.introjs-overlay').style.display = 'none'
              if (document.querySelector('.introjs-helperLayer')) {
                document.querySelector('.introjs-helperLayer').style.boxShadow = ''
              }
              */
              if (document.querySelector(".introjs-helperLayer")) {
                document.querySelector(".introjs-helperLayer").style.boxShadow =
                  "rgba(14, 29, 47, 0.54) 0px 0px 1px 2px, rgba(14, 29, 47, 0.54) 0px 0px 0px 5000px";
              }
            } else {
              if (intro._currentStep > 1 && isMobile) {
                intro.setOption("tooltipClass", "intro-ga");
              } else {
                intro.setOption("tooltipClass", "");
              }
              document.querySelector(".introjs-overlay").style.display =
                "block";
              if (document.querySelector(".introjs-helperLayer")) {
                document.querySelector(".introjs-helperLayer").style.display =
                  "block";
                document.querySelector(".introjs-helperLayer").style.boxShadow =
                  "rgba(14, 29, 47, 0.54) 0px 0px 1px 2px, rgba(14, 29, 47, 0.54) 0px 0px 0px 5000px";
              }
              document.querySelector(".introjs-skipbutton").textContent =
                "Skip Tour";
              document.querySelector(".introjs-skipbutton").style.fontSize =
                "14px";
            }

            if (targetElement.id !== "dashboard") {
              intro.setOption("nextLabel", "Next");
            } else {
              intro.setOption("nextLabel", "Start a Tour");
            }
          })
          .start();
      }, 500);
    }
  };

  handleNavMenu = () => {
    eventBus.dispatch("toggleNav", {});
  };

  setHasOnboarded = (userId) => {
    return new Promise((resolve, reject) => {
      socket.emit("eb:has-onboarded", userId, (err, data) => {
        if (err) {
          reject(err);
        } else {
          this.props.setOnboarding();
          resolve({});
        }
      });
    });
  };

  render() {
    return <div id="onboard-anchor"></div>;
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setOnboarding: () => dispatch(setOnboarding()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnBoard);
