import React from 'react';
import CheckIcon from 'mdi-react/CheckIcon';

const ColorPicker = ({
  changeColor,
  checkedColor,
}) => {
  
  const colors = [
    '#fff',
    '#9b9b99',
    'beige',
    '#343a40',
    '#007bff',
    '#17a2b8',
    '#28a745',
    '#ffc107',
    '#dc3545',
    '#0d538d',
    'rgb(11, 133, 125)',
    'rgb(16, 124, 16)',
    'rgb(0, 120, 212)',
    'rgb(136, 23, 152)',
    'rgb(230, 124, 115)',
    'rgb(227, 0, 140)',
    'rgb(179, 157, 219)',
    'rgb(213, 0, 0)',
    'rgb(219, 58, 0)',
    'rgb(121, 85, 72)',
    'rgb(102, 102, 102)',
    'rgb(0, 0, 0)'
  ];
  const isBlack = [
    '#ffc107',
    '#fff',
    'beige'
  ];
  return (
    <div className="d-flex flex-wrap color-box bg-white">
      {colors.map((color, i) => {
          return (
            <div key={i} className={`color-circle ${['#fff', '#ffffff'].includes(color) && 'border'}`}
              style={{ backgroundColor: color }}
             onClick={() => {
              changeColor(color)
            }}>{
              (checkedColor === color) && <CheckIcon style={
                (isBlack.includes(color)) ? { color: '#000000' } : { color: '#fff' }
              }/>
            }</div>
          )
      })}
    </div>
  )
}

export {
  ColorPicker
};