import React, { Component } from 'react';
import styled from 'styled-components';

class Subscription extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message: props.message
		};
	}
	render() {
		const { message, show } = this.state;

		return (
			<Wrapper>
				<span id="snackbar" className='show'>
					{message}
				</span>
			</Wrapper>
		);
	}
}

export default Subscription;

const Wrapper = styled.div`
	#snackbar {
		display: none;
		background-color: red;
		color: #fff;
		text-align: center;
		border-radius: 3px;
		padding: 5px 15px;
		position: fixed;
		z-index: 1000;
		right: 20px;
        bottom: 20px;
		min-width: 150px;
		font-size: 13px;
	}

	#snackbar.show {
		display: block;
		-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
		animation: fadein 0.5s, fadeout 0.5s 2.5s;
	}
`;
