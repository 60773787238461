import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Form, Col, Row, Image, textarea, Popover } from "react-bootstrap";
import Cookies from "universal-cookie";
import Spinner from "../global/Spinner";
import { URLS } from "../urls";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import AddClientModal from "./AddClientModal";
import EditClientModal from "./EditClientModal";
import Client from "./Client";
import { getClients, deleteClient } from "../../services/clientServices";
import {
  populateClients,
  removeClient,
  searchClient,
} from "../../store/actions/clientActions";
import { removeModalElements } from "../../utils";
import { InputReload, SearchIcon } from "../task-update/components/Icons";
import PlusIcon from "mdi-react/PlusIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import DialogModal from "../task-update/modals/DialogModal";
import { openPageSideBar, showToast } from "../task-update/utils";
import TextIcon from "mdi-react/TextIcon";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      searchClientList: [],
      selectedClientData: "",
      companies: [],
      searchFilter: {
        company: "",
        clientName: "",
        clientId: "",
      },
      isLoadingClients: true,
      userData: [],
      isAddModalOpen: false,
      isEditModalOpen: false,
    };
  }

  selectClient = async (client, modal) => {
    await this.setState({ selectedClientData: client });
    if (modal === "edit") {
      this.handleModal("edit");
    } else if (modal === "delete") {
      this.handleDelete();
    }
  };

  handleDelete = async (id) => {
    if (
      await DialogModal({
        title: "Delete Client",
        description: `Are you sure, you want to delete?`,
        type: "warning",
      })
    ) {
      const { token } = this.props;
      deleteClient(id, token).then((response) => {
        if (response.success) {
          this.props.removeClient(id);
          showToast({
            message: {
              title: "Client Deleted",
            },
            type: "success",
          });
        }
      });
    }
  };

  getClientFromId = (_id) => {
    const { clients } = this.state;
    let client = {};
    clients.forEach((e) => {
      if (e._id === _id) client = e;
    });
    return client;
  };

  updateClientsList = (client) => {
    let { clients } = this.state;
    const id = client && client._id;
    let isExists = false;
    for (let index = clients.length - 1; index >= 0; --index) {
      if (clients[index]._id === id) {
        clients.splice(index, 1);
        clients.splice(index, 0, client);
        isExists = true;
        break;
      }
    }
    if (isExists) {
      this.setState({ clients: clients });
    } else {
      this.setState({ clients: clients.concat(client) });
    }
  };

  componentDidMount() {
    const token = this.props.token;
    getClients(token).then((response) => {
      if (response.success) {
        const userData = response.user;
        const clients = response.clients;

        this.props.populateClients(clients);
        this.setState({ isLoadingClients: false, userData });
      }
    });
  }

  updateSearchFilterName = (evt) => {
    const { searchFilter } = this.state;
    searchFilter.name = evt.target.value;
    this.setState({
      searchFilter,
    });
  };

  updateSearchFilterCompany = (evt) => {
    const { searchFilter } = this.state;
    searchFilter.company = evt.target.value;
    this.setState({
      searchFilter,
    });
  };

  searchClients = () => {
    const { searchFilter, clients } = this.state;
    if (searchFilter.id && searchFilter.id !== "") {
      let tempClientList = clients.filter((client) => {
        return client.contactPersonEmail === searchFilter.email;
      });
      this.setState({
        searchClientList: tempClientList,
      });
    } else {
      if (searchFilter.name && searchFilter.name !== "") {
        let name = searchFilter.name.toLowerCase();
        let tempClientList = clients.filter(function (client) {
          let clientName =
            client.contactPersonFirstName + client.contactPersonLastName;
          return clientName.toLowerCase().indexOf(name) > -1;
        });
        if (searchFilter.company && searchFilter.company !== "") {
          tempClientList = tempClientList.filter(function (client) {
            return client.companyName
              ? client.companyName === searchFilter.company
              : null;
          });
        }
        this.setState({
          searchClientList: tempClientList,
        });
      } else if (searchFilter.company && searchFilter.company !== "") {
        let tempClientList = clients.filter(function (client) {
          return client.companyName
            ? client.companyName === searchFilter.company
            : null;
        });

        this.setState({
          searchClientList: tempClientList,
        });
      } else {
        this.setState({
          searchClientList: clients,
        });
      }
    }
  };

  handleModal = (modal) => {
    if (modal === "add") {
      const isAddModalOpen = !this.state.isAddModalOpen;
      this.setState({ isAddModalOpen });
    } else if (modal === "edit") {
      const isEditModalOpen = !this.state.isEditModalOpen;
      this.setState({ isEditModalOpen });
    }
  };

  showControlButtons = (isSuperAdmin, isAdmin, account_type) => {
    let value;
    if (isSuperAdmin || isAdmin || account_type === 1) {
      value = true;
    }
    return value;
  };

  handleSearch = (e) => {
    if (e) e.preventDefault();
    const value = e ? e.target.value.trim() : "";
    this.props.searchClient(value);
  };

  render() {
    const {
      selectedClientData,
      companies,
      searchFilter,
      searchClientList,
      isLoadingClients,
      userData,
      isAddModalOpen,
      isEditModalOpen,
    } = this.state;

    const { clients, user, hasAccountExpired } = this.props;
    const { isSuperAdmin, isAdmin, account_type } = user;

    return (
      <section>
        <div className="main-wrapper">
          <Topbar />
          <div className="d-flex new-wrapper sleek-scrollbar">
            <Sidebar activeSideBar="clients" />

            <div className="page-wrapper p-0">
              <div className="page-top-bar">
                <Link to="/" className="d-none d-md-block">
                  Home
                </Link>

                <button
                  className="btn d-md-none p-0 nav"
                  onClick={() => openPageSideBar()}
                >
                  <TextIcon />
                </button>

                <form
                  onSubmit={(e) => e.preventDefault()}
                  className="d-flex align-items-center"
                >
                  <label className="search">
                    <SearchIcon />
                    <input
                      onChange={(e) => this.handleSearch(e)}
                      placeholder="Search Clients"
                    />
                  </label>
                  <button
                    type="reset"
                    onChange={(e) => this.handleSearch()}
                    className="btn p-0 ml-2 md-ml-3"
                  >
                    <InputReload />
                  </button>
                </form>

                {this.showControlButtons(isSuperAdmin, isAdmin, account_type) &&
                !hasAccountExpired ? (
                  <button
                    className="btn btn-primary"
                    onClick={() => this.handleModal("add")}
                  >
                    <PlusIcon />{" "}
                    <span className="d-none d-md-flex">Add Client</span>
                  </button>
                ) : (
                  <span></span>
                )}
              </div>
              <div className="content container-fluid">
                <div className="g-crumb">
                  <a href="/">Home</a> <ChevronRightIcon class="active" />{" "}
                  <a href="#" class="active">
                    Clients
                  </a>
                </div>

                {isLoadingClients ? (
                  <Spinner />
                ) : (
                  <div>
                    {clients && clients.length ? (
                      <div className="row staff-grid-row m-0">
                        {clients.map((client) => {
                          return (
                            <Client
                              key={client._id}
                              _id={client._id}
                              firstName={client.contactPersonFirstName}
                              lastName={client.contactPersonLastName}
                              company={client.companyName}
                              displayImage={client.image}
                              onEdit={() => this.selectClient(client, "edit")}
                              onDelete={() => this.handleDelete(client._id)}
                              client={client}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <div className="d-flex flex-column align-items-center m-t-50">
                        <img
                          src="../../assets/svg/undraw_interview_rmcf.svg"
                          className="empty-svg"
                        />
                        <p className="mt-4 text-lg">
                          <b>None found</b>
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {isAddModalOpen && (
            <AddClientModal
              isAddModalOpen={isAddModalOpen}
              updateClientsList={() => this.updateClientsList()}
              closeModal={() => this.handleModal("add")}
            />
          )}

          {isEditModalOpen && (
            <EditClientModal
              isEditModalOpen={isEditModalOpen}
              client={selectedClientData}
              updateClientsList={this.updateClientsList}
              closeModal={() => this.handleModal("edit")}
            />
          )}

          {/* <!-- Delete Client Modal --> */}
          {/*<div
            className="modal custom-modal fade"
            id="delete_client"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered modal-sm">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="form-header">
                    <h3>Delete Client</h3>
                    <p>Are you sure want to delete?</p>
                  </div>
                  <div className="modal-btn delete-action">
                    <div className="row">
                      <div className="col-6">
                        <span
                          className="btn btn-primary continue-btn"
                          onClick={() => {
                            this.handleDelete();
                          }}
                        >
                          Delete
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          data-dismiss="modal"
                          className="btn btn-primary cancel-btn"
                        >
                          Cancel
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                        </div>*/}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    user: state.userReducer.user,
    clients: state.clientReducer.clients,
    hasAccountExpired: state.userReducer.hasAccountExpired,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    populateClients: (clients) => {
      dispatch(populateClients(clients));
    },
    removeClient: (client) => {
      dispatch(removeClient(client));
    },
    searchClient: (value) => {
      dispatch(searchClient(value));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Clients));
