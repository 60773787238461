/* eslint-disable react/jsx-no-undef */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Col, Row, textarea, Modal } from "react-bootstrap";
import { pdfjs, Document, Page } from "react-pdf";
import CloseIcon from "mdi-react/CloseIcon";
import BackIcon from "mdi-react/ArrowBackIcon";
import CheckIcon from "mdi-react/CheckIcon";
import { debounce, validateEmail } from "../../utils";
import Spinner from "../global/Spinner";

import { URLS } from "../urls";

import "../../assets/css/project-files.css";

import "../../assets/scss/global.scss";
import "../../assets/scss/projects.scss";

import "../../../src/assets/css/date-picker.css";
import "../../../src/assets/css/image-list.css";

import moment from "moment";
import { addTeam } from "../../services/teamServices";
import { searchUserByEmail } from "../../services/userServices";
import {
  CheckboxIcon,
  CheckmarkGreyIcon,
  CheckmarkIcon,
  SearchIcon,
} from "../task-update/components/Icons";
import { Checkbox, showToast } from "../task-update/utils";
import { uniq, uniqBy } from "lodash";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const FormProgress = ({ creationStage, setCreationStage }) => {
  return (
    <div className="form-progress h-auto">
      <span>{creationStage + 1} of 2</span>
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="divider"></div>
        <CheckmarkIcon onClick={() => setCreationStage(1)} />
        {creationStage >= 2 ? (
          <CheckmarkIcon onClick={() => setCreationStage(2)} />
        ) : (
          <CheckmarkGreyIcon />
        )}
      </div>
    </div>
  );
};

class EmployeeModal extends Component {
  constructor(props) {
    super(props);

    const { account_type } = props.user;

    this.state = {
      buttonText: "Create Team",
      isWaiting: false,
      employee: null,
      team: {
        name: "",
        description: "",
        totalSize: 0,
        members: [],
        leaders: [],
        teamEmails: [],
      },
      allMembers: props.companyUsers || [],
      allMembersClone: props.companyUsers || [],
      nameFieldError: false,
      hasFilledDetails: false,
      hasAddedParticipants: false,
      isAdding: false,
      searchParams: "",
      activeParticipants: "leaders",
      account_type,
      allLeadersChecked: false,
      allMembersChecked: false,
      membersToJoin: [],
    };
  }

  componentDidMount() {
    // let targetNode = document.getElementById("create_team");
    // var observer = new MutationObserver(function () {
    //   if (targetNode.style.display != "none") {
    // console.log("opps");
    // var e = new Event("keydown");
    // e.key = "tab";
    // e.keyCode = 9;
    // e.which = 9;
    // e.altKey = false;
    // e.ctrlKey = true;
    // document.dispatchEvent(e);
    let element = document.getElementById("team-name");
    element.dispatchEvent(new Event("focus"));
    //   }
    // });
    // observer.observe(targetNode, { attributes: true, childList: true });
  }

  updateTeamField = (event) => {
    const { team } = this.state;
    const { name, value } = event.target;
    team[name] = value;
    this.setState({
      team,
      nameFieldError: false,
    });
  };

  handleCreateTeam = () => {
    this.setState({ isAdding: true, hasAddedParticipants: true });

    const { team, allMembersClone, membersToJoin } = this.state;

    const leaders = [];
    const members = [];

    if (allMembersClone && allMembersClone.length) {
      for (let member of allMembersClone) {
        if (member.isTeam) {
          delete member.isTeam;
          delete member.isLeader;
          members.push(member._id);
        } else if (member.isLeader) {
          delete member.isTeam;
          delete member.isLeader;
          leaders.push(member._id);
        }
      }
    }

    team.members = members;
    team.leaders = leaders;
    team.totalSize = team.members.length + team.leaders.length;
    if (team.totalSize === 0) {
      this.setState({ isAdding: false, hasAddedParticipants: false });
      showToast({
        message: {
          title: `A Team should have atleast one member`,
        },
        type: "error",
      });
      return;
    }
    team.createdAt = moment(new Date()).format("YYYY-MM-DD HH:mm Z");
    team.membersToJoin = membersToJoin;

    const token = this.props.token;

    addTeam(token, team).then((response) => {
      if (response.team) {
        showToast({
          message: {
            title: `Team Created`,
          },
          type: "success",
        });

        this.props.updateTeamsList(response.team);
      }
    });
  };

  handleCloseModal = (e) => {
    this.props.closeModal();
  };

  setClassNameStyle = (name) => {
    let value;
    if (!name || !name.trim()) {
      value = "in-active-project";
    }
    return value;
  };

  isAvailableStyle = () => {
    const { allMembersClone } = this.state;
    let value;

    const totalSelected = allMembersClone.filter(
      (member) => member.isLeader || member.isTeam
    );

    if (totalSelected.length < 1) {
      value = "in-active-project";
    }
    return value;
  };

  handleNextLayout = (value) => {
    const { team } = this.state;
    if (value === 1 && team.name.trim().length) {
      this.setState({
        hasFilledDetails: true,
        hasAddedParticipants: false,
      });
    } else if (value === 0) {
      this.setState({
        hasFilledDetails: false,
        hasAddedParticipants: false,
      });
    }
  };

  handleActiveParticipants = (activeParticipants) => {
    this.setState({ activeParticipants });
  };

  setEmployeeName = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name;
  };

  setEmployeeInitials = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name.charAt(0);
  };

  handleSelectedMember = (member, type) => {
    let {
      allMembers,
      allMembersClone,
      searchParams,
      allMembersChecked,
      allLeadersChecked,
      account_type,
      membersToJoin,
    } = this.state;

    const memberId = member._id;

    // If personal user hasn't been invited
    if (account_type === 1 && member.showInvitation) {
      if (!(member.isTeam || member.isLeader)) {
        allMembersClone.push(member);
      }

      // Add or Remove user from membersToJoin
      let indexFound = membersToJoin.findIndex(
        (memberToJoin) => memberToJoin.userId === memberId
      );
      if (indexFound === -1) {
        membersToJoin.push({ userId: memberId });
      } else {
        membersToJoin.splice(indexFound, 1);
      }
    }

    allMembersClone = allMembersClone.map((member) => {
      if (member._id === memberId) {
        if (type === "team") {
          if (member.isTeam) {
            member.isTeam = false;
            allMembersChecked = false;
          } else {
            member.isTeam = true;
            member.isLeader = false;
          }
        } else if (type === "leader") {
          if (member.isLeader) {
            member.isLeader = false;
            allLeadersChecked = false;
          } else {
            member.isLeader = true;
            member.isTeam = false;
          }
        }
      }
      return member;
    });

    // For company users
    if (account_type === 2) {
      if (searchParams) {
        allMembers = this.handleFilterByName(allMembersClone, searchParams);
      } else {
        allMembers = allMembersClone;
      }
    }

    this.setState({
      allMembers,
      allMembersClone,
      allMembersChecked,
      allLeadersChecked,
      membersToJoin,
    });
  };

  searchPersonalAccount = (searchParams) => {
    const token = this.props.token;
    let { allMembersClone } = this.state;

    if (searchParams) {
      searchUserByEmail(searchParams, token).then((response) => {
        if (response.users) {
          let usersToInvite = response.users;

          usersToInvite = usersToInvite.filter(
            (userToInvite) =>
              allMembersClone.findIndex(
                (currentMember) => currentMember.email === userToInvite.email
              ) < 0
          );

          this.setState({ allMembers: usersToInvite });
        }
      });
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    const searchParams = e.target.value.trim();
    this.setState({ searchParams });
    let { allMembers, allMembersClone, account_type } = this.state;

    if (account_type === 1) {
      if (searchParams) {
        debounce(this.searchPersonalAccount(searchParams), 500);
      } else {
        allMembers = allMembersClone;
        this.setState({ allMembers, allMembersClone });
      }
      this.setState({ allMembers, allMembersClone });
    } else if (account_type === 2) {
      if (searchParams) {
        allMembers = this.handleFilterByName(allMembersClone, searchParams);
      } else {
        allMembers = allMembersClone;
      }
      this.setState({ allMembers, allMembersClone });
    }
  };

  handleFilterByName = (arr, value) => {
    let result = [];
    result = arr.filter((member) => {
      let name;
      if (member.name.first) {
        name = member.name.first + " " + member.name.last;
      } else {
        name = member.name;
      }

      if (name.toLowerCase().includes(value)) {
        return member;
      }
    });
    return result;
  };

  handleSwitch = (e) => {
    const showOnlySelected = e.target.checked;
    let { allMembers, allMembersClone } = this.state;

    if (showOnlySelected) {
      allMembers = allMembersClone.filter(
        (member) => member.isTeam || member.isLeader
      );
    } else {
      allMembers = allMembersClone;
    }
    this.setState({ allMembers });
  };

  handleCheckAll = (e) => {
    const showChecked = e.target.checked;
    let {
      allMembers,
      allMembersClone,
      activeParticipants,
      allLeadersChecked,
      allMembersChecked,
    } = this.state;

    // if (activeParticipants === "leaders") {
    if (showChecked) {
      allMembers = allMembersClone.map((member) => {
        if (!member.isLeader && !member.isTeam) {
          member.isLeader = true;
        }
        return member;
      });
      allLeadersChecked = true;
    } else {
      allMembers = allMembersClone.map((member) => {
        if (member.isLeader) {
          member.isLeader = false;
        }
        return member;
      });
      allLeadersChecked = false;
    }
    // } else if (activeParticipants === "members") {
    if (showChecked) {
      allMembers = allMembersClone.map((member) => {
        if (!member.isLeader && !member.isTeam) {
          member.isTeam = true;
        }
        return member;
      });
      allMembersChecked = true;
    } else {
      allMembers = allMembersClone.map((member) => {
        if (member.isTeam) {
          member.isTeam = false;
        }
        return member;
      });
      allMembersChecked = false;
    }
    // }

    this.setState({
      allMembers,
      allMembersClone: allMembers,
      allLeadersChecked,
      allMembersChecked,
    });
  };

  addEmail = (email) => {
    if (email === this.props.user.email) {
      showToast({
        message: {
          title: "You cannot invite yourself",
        },
        type: "error",
      });
      return;
    }

    const { team } = this.state;
    this.setState({
      team: {
        ...team,
        teamEmails: uniqBy(
          [...team.teamEmails, { email, isTeam: true }],
          "email"
        ),
      },
    });

    // -----------------------------------------------
    let searchParams = "";
    this.setState({ searchParams });
    let { allMembers, allMembersClone, account_type } = this.state;

    if (account_type === 1) {
      if (searchParams) {
        debounce(this.searchPersonalAccount(searchParams), 500);
      } else {
        allMembers = allMembersClone;
        this.setState({ allMembers, allMembersClone });
      }
      this.setState({ allMembers, allMembersClone });
    } else if (account_type === 2) {
      if (searchParams) {
        allMembers = this.handleFilterByName(allMembersClone, searchParams);
      } else {
        allMembers = allMembersClone;
      }
      this.setState({ allMembers, allMembersClone });
    }
  };

  searchEmails = (emails, searchParams) => {
    let u = emails;
    if (searchParams) {
      u = u.filter((user) => user.email.includes(searchParams.trim()));
    }

    return u;
  };

  handleSelectedMemberEmail = (member, position) => {
    const { team } = this.state;
    const index = team.teamEmails.findIndex((el) => el.email === member.email);
    team.teamEmails[index] = {
      ...member,
      isTeam: !team.teamEmails[index].isTeam && position === "team",
      isLeader: position === "leader",
    };

    this.setState({
      team: {
        ...team,
      },
    });
  };

  render() {
    const {
      team,
      hasFilledDetails,
      hasAddedParticipants,
      activeParticipants,
      isAdding,
      allMembers,
      account_type,
      allLeadersChecked,
      allMembersChecked,
      searchParams,
    } = this.state;

    const { mode, isAddModalOpen } = this.props;
    return (
      <Modal
        show={isAddModalOpen}
        className={`modal custom-modal fade mx-auto my-3 ${
          hasFilledDetails && "team-members"
        }`}
        backdropClassName="global-backdrop max-z-index-2"
        role="dialog"
        onHide={(e) => this.handleCloseModal(e)}
        centered={true}
      >
        <Modal.Body bsPrefix="p-0">
          <div className="m-h-between p-4 pb-0">
            <h5 className="modal-title">Create Team</h5>

            <button
              className="modal-global-close-btn"
              onClick={(e) => this.handleCloseModal(e)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="modal-body px-4 pt-0 pb-4 text-sm">
            {!hasFilledDetails && (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.handleNextLayout(1);
                }}
              >
                <Form.Group as={Row}>
                  <Col sm={12}>
                    <Form.Label>Team Name</Form.Label>
                    <Form.Label className="text-danger"></Form.Label>
                    <Form.Control
                      id="team-name"
                      name="name"
                      value={team.name}
                      onChange={this.updateTeamField}
                      placeholder="Enter new team name"
                      className="w-p-100"
                      required
                      onFocus={(event) => {
                        // event.persist();
                        // console.log(event.target);
                        // // References
                        // var e = event.target;
                        // // Action
                        // e.setSelectionRange(0, 0); // Doesn’t work for focus event
                        // window.setTimeout(function () {
                        //   e.value = "hey";
                        //   e.setSelectionRange(0, 0); // Works
                        //   e.setSelectionRange( 1, 1 );      // Move caret to second position
                        //   e.setSelectionRange( 1, 2 );      // Select second character
                        // }, 0);
                      }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm={12}>
                    <Form.Label>Description</Form.Label>
                    <textarea
                      rows="3"
                      className="form-control summernote team-description"
                      name="description"
                      onChange={this.updateTeamField}
                      value={team.description}
                      placeholder="Write description here"
                    />
                  </Col>
                </Form.Group>

                <div className=" d-flex justify-content-between align-items-end">
                  <FormProgress
                    creationStage={0}
                    setCreationStage={() => this.handleNextLayout(1)}
                  />
                  <button
                    type="submit"
                    className={`btn btn-primary p-3 px-4 ${this.setClassNameStyle(
                      team.name
                    )}`}
                  >
                    Next
                  </button>
                </div>
              </Form>
            )}
            {hasFilledDetails && !hasAddedParticipants && (
              <div className="dv-participant">
                <div className="participants-tabs d-none">
                  <div
                    className={`${
                      activeParticipants === "leaders"
                        ? "active-participants"
                        : "inactive-participants"
                    }`}
                    onClick={() => this.handleActiveParticipants("leaders")}
                  >
                    Add Team Leaders
                  </div>
                  <div
                    className={`${
                      activeParticipants === "members"
                        ? "active-participants"
                        : "inactive-participants"
                    }`}
                    onClick={() => this.handleActiveParticipants("members")}
                  >
                    Add Team Members
                  </div>
                </div>
                <div className="w-100 position-relative">
                  <input
                    placeholder="Search to add"
                    className="form-control add-project-input p-3 mt-0"
                    type="text"
                    value={searchParams}
                    onChange={(e) => this.handleSearch(e)}
                  />
                  <SearchIcon />
                </div>

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <label htmlFor="check-all">
                    {/*activeParticipants === "members" ? (
                      <>
                        <Checkbox
                          checked={allMembersChecked}
                          type="checkbox"
                          style={{ marginRight: "1rem" }}
                          onChange={(e) => this.handleCheckAll(e)}
                        />
                        <span>Select All</span>
                      </>
                    ) : (
                      <>
                        <Checkbox
                          checked={allLeadersChecked}
                          type="checkbox"
                          style={{ marginRight: "1rem" }}
                          onChange={(e) => this.handleCheckAll(e)}
                        />
                        <span>Select All</span>
                      </>
                    )*/}
                    <Checkbox
                      id="check-all"
                      checked={allLeadersChecked && allMembersChecked}
                      type="checkbox"
                      style={{ marginRight: "1rem" }}
                      onChange={(e) => this.handleCheckAll(e)}
                    />
                    <span>Select All</span>
                  </label>

                  <div className="team-dv-switch">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={(e) => this.handleSwitch(e)}
                      />
                      <span className="slider round"></span>
                    </label>
                    <span>Show only selected</span>
                  </div>
                </div>

                {activeParticipants === "leaders" && (
                  <div className="participants-list sleek-scrollbar">
                    {(allMembers && allMembers.length) ||
                    this.searchEmails(team.teamEmails, searchParams).length ? (
                      <>
                        {this.searchEmails(team.teamEmails, searchParams)
                          ? this.searchEmails(
                              team.teamEmails,
                              searchParams
                            ).map((member, index) => (
                              <div className="user-info" key={index}>
                                <div className="d-flex align-items-center">
                                  <Checkbox
                                    style={{ marginRight: "1rem" }}
                                    checked={member.isLeader || member.isTeam}
                                    value={member.isLeader || member.isTeam}
                                    onClick={() =>
                                      this.handleSelectedMemberEmail(
                                        member,
                                        "team"
                                      )
                                    }
                                  />

                                  {member.photo ? (
                                    <img
                                      className="user-desc"
                                      src={
                                        URLS.backendStatic + "/" + member.photo
                                      }
                                    />
                                  ) : (
                                    <div className="user-desc user-init">
                                      {this.setEmployeeInitials({
                                        name: member.email.toUpperCase(),
                                      })}
                                    </div>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <span className="employee-name">
                                    {member.email}
                                  </span>
                                </div>

                                <div className="actions d-flex align-items-center justify-content-end pr-3">
                                  {(member.isTeam || !member.isTeam) &&
                                  !member.isLeader ? (
                                    <button
                                      className="btn text-primary p-1 btn-sm"
                                      onClick={() =>
                                        this.handleSelectedMemberEmail(
                                          member,
                                          "leader"
                                        )
                                      }
                                    >
                                      Set as Leader
                                    </button>
                                  ) : (
                                    <button
                                      className="btn bg-light text-dark p-1 btn-sm px-3"
                                      onClick={() =>
                                        this.handleSelectedMemberEmail(
                                          member,
                                          "team"
                                        )
                                      }
                                    >
                                      Leader <CloseIcon />
                                    </button>
                                  )}
                                </div>
                              </div>
                            ))
                          : null}

                        {allMembers.map((member, index) => (
                          <div className="user-info" key={index}>
                            <div className="d-flex align-items-center">
                              <Checkbox
                                style={{ marginRight: "1rem" }}
                                checked={member.isLeader || member.isTeam}
                                value={member.isLeader || member.isTeam}
                                onClick={() =>
                                  this.handleSelectedMember(member, "team")
                                }
                              />

                              {member.photo ? (
                                <img
                                  className="user-desc"
                                  src={URLS.backendStatic + "/" + member.photo}
                                />
                              ) : (
                                <div className="user-desc user-init">
                                  {this.setEmployeeInitials(member)}
                                </div>
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "5px",
                              }}
                            >
                              <span className="employee-name">
                                {this.setEmployeeName(member)}
                              </span>
                              <span style={{ color: "#888" }}>
                                @{member.username}
                              </span>
                            </div>

                            <div className="actions d-flex align-items-center justify-content-end pr-3">
                              {(member.isTeam || !member.isTeam) &&
                              !member.isLeader ? (
                                <button
                                  className="btn text-primary p-1 btn-sm"
                                  onClick={() =>
                                    this.handleSelectedMember(member, "leader")
                                  }
                                >
                                  Set as Leader
                                </button>
                              ) : (
                                <button
                                  className="btn bg-light text-dark p-1 btn-sm px-3"
                                  onClick={() =>
                                    this.handleSelectedMember(member, "team")
                                  }
                                >
                                  Leader <CloseIcon />
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : validateEmail(searchParams) ? (
                      <div className="add-new-email-container">
                        <div>
                          <span className="dy-text">
                            Do you want to add this email address?
                          </span>
                          "
                          <span className="em-text text-primary">
                            {searchParams}
                          </span>
                          "
                        </div>
                        <span
                          className="yes-text bg-primary p-cursor"
                          onClick={() => this.addEmail(searchParams)}
                        >
                          Yes please
                        </span>
                      </div>
                    ) : (
                      <div className="none-found">None found</div>
                    )}
                  </div>
                )}
                {/*activeParticipants === "members" && (
                  <div className="participants-list sleek-scrollbar">
                    {allMembers && allMembers.length ? (
                      <div>
                        {allMembers.map((member, index) => (
                          <div
                            className="user-info"
                            key={index}
                            onClick={() =>
                              this.handleSelectedMember(member, "team")
                            }
                          >
                            {member.photo ? (
                              <img
                                className="user-desc"
                                src={URLS.backendStatic + "/" + member.photo}
                              />
                            ) : (
                              <div className="user-desc user-init">
                                {this.setEmployeeInitials(member)}
                              </div>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "5px",
                              }}
                            >
                              <span>{this.setEmployeeName(member)}</span>
                              <span style={{ fontSize: "13px", color: "#888" }}>
                                {member.email}
                              </span>
                            </div>
                            {member.isLeader ? (
                              <span className="unchecked-box">
                                <CheckIcon className="check-mark-icon" />
                              </span>
                            ) : (
                              <input
                                type="checkbox"
                                checked={member.isTeam ? member.isTeam : false}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    ) : validateEmail(searchParams) ? (
                      <div className="add-new-email-container">
                        <div>
                          <span className="dy-text">
                            Do you want to add this email address?
                          </span>
                          "<span className="em-text">{searchParams}</span>"
                        </div>
                        <span className="yes-text">Yes please</span>
                      </div>
                    ) : (
                      <div className="none-found">None found</div>
                    )}
                  </div>
                )*/}
                <div className="team-controls participants-team-controls">
                  <div>
                    <FormProgress
                      creationStage={1}
                      setCreationStage={() => this.handleNextLayout(0)}
                    />
                  </div>
                  <div className="w-100 d-flex py-3 mt-4">
                    <button
                      className="btn btn-outline-primary p-3 px-4"
                      onClick={() => this.handleNextLayout(0)}
                    >
                      Back
                    </button>

                    <button
                      className={`btn btn-primary p-3 px-4`}
                      onClick={() => this.handleCreateTeam()}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            )}
            {hasAddedParticipants && isAdding && (
              <div className="f-height">
                <Spinner />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    user: state.userReducer.user,
  };
};

export default connect(mapStateToProps, null)(withRouter(EmployeeModal));
