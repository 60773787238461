import * as Actions from "../actions";

const initialState = {
  clients: [],
  clientsClone: [],
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.POPULATE_CLIENTS: {
      const clients = action.payload
      return {
        ...state,
        clients,
        clientsClone: clients
      };
    }
    case Actions.ADD_CLIENT: {
      const clients = [...state.clientsClone, action.payload];
      return {
        ...state,
        clients,
        clientsClone: clients
      };
    }
    case Actions.REMOVE_CLIENT: {
      const _id = action.payload;
      let clients = state.clientsClone;
      clients = clients.filter((client) => client._id !== _id);
      return {
        ...state,
        clients,
        clientsClone: clients
      };
    }
    case Actions.SEARCH_CLIENT: {
      const value = action.payload.trim();
      let clients = state.clientsClone;
      if(value){      
        clients = clients.filter((client) => client.companyName.toLowerCase().includes(value));
      }
      return {
        ...state,
        clients,
      };
    }
    default: {
      return state;
    }
  }
};

export default clientReducer;
