import CloseIcon from "mdi-react/CloseIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { addList } from "../../../services/projectServices";
import { showToast } from "../utils";
import ColorPicker from "./ColorPicker";

export function AddNewList({
  token,
  selectedProject,
  updateProjectList,
  list,
  showAddButton,
  setShowAddButton,
  user,
}) {
  const initialData = {
    title: "",
    status: "",
    color: "#dbe1e8",
    projectId: "",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    ...initialData,
  });
  const handleSelectedColor = (color) => {
    setData({ ...data, color });
  };

  useEffect(() => {
    setData({
      ...data,
      projectId: selectedProject?._id,
    });
    return () => {};
  }, [selectedProject]);

  const saveList = async (e) => {
    try {
      setIsLoading(true);
      if (list.find((list) => list.status === data.status)) {
        showToast({
          message: {
            title: "There's already a list with that name",
          },
          type: "error",
        });
        return;
      }
      const response = await addList({
        token,
        list: { ...data, createdBy: user._id },
      });
      // console.log(response);
      if (response.success) {
        showToast({
          message: {
            title: "List Created",
            description: "You have successfully created a new list!",
          },
          type: "success",
        });
        setData({
          ...initialData,
        });
        setShowAddButton(!showAddButton);
        updateProjectList(response);
      } else {
        throw new Error();
      }
    } catch (err) {
      console.log(err);
      showToast({
        message: {
          title: "Unable to add list",
        },
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Wrapper>
      {showAddButton ? (
        <button
          className="btn open-btn"
          onClick={() => setShowAddButton(!showAddButton)}
        >
          <PlusIcon /> Add New List
        </button>
      ) : (
        <div id="list-form" style={{ borderTop: `4px solid ${data.color}` }}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              saveList(e);
            }}
          >
            <Form.Group
              className=""
              bsPrefix="form-group-custom"
              controlId="listName"
            >
              <Form.Label className="d-none"></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter list title..."
                name="listName"
                required
                value={data.title}
                onChange={(e) => {
                  setData({
                    ...data,
                    title: e.target.value,
                    status: e.target.value,
                  });
                }}
                autoComplete="off"
                autoFocus
              />
            </Form.Group>

            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <button
                  disabled={isLoading}
                  className="btn btn-primary mr-3"
                  type="submit"
                >
                  {!isLoading ? "Add List" : "Please wait.."}
                </button>
                <ColorPicker
                  type="dark"
                  handleSelectedColor={handleSelectedColor}
                  selectedColor={data.color}
                />
              </div>

              <CloseIcon
                className="close-icon p-cursor border"
                title="close"
                onClick={() => {
                  setData({
                    ...initialData,
                  });
                  setShowAddButton(!showAddButton);
                }}
              />
            </div>
          </Form>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  #list-form {
    background: #f6fafd !important;
    padding: 0.5rem;
    border-radius: 0.375rem;
    box-shadow: 0px 0px 8px 0px #5c758c2e;

    .form-group-custom {
      margin: 0;
      margin-bottom: 0.5rem;
      ::placeholder {
        color: #bdc9d7;
        font-size: 1rem;
      }
      .form-control {
      }
    }

    .close-icon {
      color: #788898;
      background: #f2f7fc;
      border: none;
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;
