import { format } from "date-fns";
import { debounce, isEqual, result, toArray, isEmpty } from "lodash-es";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Dropdown } from "react-bootstrap";
import { socket } from "../../../sockets";
import {
  AppRegExp,
  cloudDriveBackend,
  downloadFile,
  downloadURI,
  formatFileSize,
  GetFileIcon,
  getUniqueListBy,
  microsoftOfficeFileExtensions,
  setFileUrl,
  showToast,
  styleHelper,
  truncateFileName,
} from "../utils";
import {
  ChevronDownIcon,
  EmptyAttachments,
  FolderIcon,
  TextIcon,
} from "./Icons";
import FileModal from "../modals/FileModal";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import ViewGridOutline from "mdi-react/ViewGridOutlineIcon";
import FormatListBulleted from "mdi-react/FormatListBulletedIcon";
import eventBus from "../../../helper/EventBus";
import { getTaskFiles } from "../../../services/taskServices";
import { GridIcon, LockIcon } from "../../topbar/Icons";
import { connect } from "react-redux";
import { deleteUploadFile } from "../../../store/actions/uploadFileActions";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import { truncate } from "lodash";

const Attachments = React.memo(
  ({
    selectedTask,
    selectedProject,
    setAsCover,
    canSeeChat,
    isShared,
    fileProgress,
    deleteUploadFile,
    rootFolderId,
  }) => {
    const watcherServerUrl = process.env.REACT_APP_FILE_WATCHER_SERVER;
    const cloudDriveFrontEnd = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;
    const initialPagination = {
      next: { page: 1, limit: 18 },
    };

    let firstUpdate = useRef(true);
    let searchRef = useRef({});
    const [task, setTask] = useState([]);
    const [files, setFiles] = useState([]);
    const [pagination, setPagination] = useState(initialPagination);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchType, setSearchType] = useState("");
    const [showFileModal, setShowFileModal] = useState(false);

    const [crumbs, setCrumbs] = useState([]);
    const [isFileGrid, setIsFileGrid] = useState(
      JSON.parse(localStorage.getItem("isFileGrid"))
    );
    const [data, setData] = useState({
      indexInView: 0,
      imageFiles: [],
    });
    const [isLoading, setIsLoading] = useState(true);

    const setIsFileGridInLocalStorage = (value) => {
      localStorage.setItem("isFileGrid", value);
      setIsFileGrid(value);
    };

    const _files = useRef(files);
    const _setFiles = (data) => {
      _files.current = data;
      setFiles(data);
    };

    /*useEffect(() => {
    _setFiles([
      ...[
        ...toArray(fileProgress).filter(
          (file) =>
            file.taskId === selectedTask._id &&
            file.status &&
            file.show &&
            !_files.current.find((fileEl) => fileEl.cloud_id === file.cloud_id)
        ),
        ..._files.current,
      ],
    ]);

    return () => {};
  }, [fileProgress]);*/

    const delayedQuery = useCallback(
      debounce(
        () =>
          getFiles({
            selectedTask,
            next: initialPagination.next,
            searchTerm,
            searchType,
            caller: "bounce",
            parentFolderId: crumbs[crumbs.length - 1]._id,
          }),
        500
      ),
      [searchTerm, searchType]
    );
    useEffect(() => {
      if (!firstUpdate.current) {
        delayedQuery();
      }
      return delayedQuery.cancel;
    }, [searchTerm, searchType, delayedQuery]);

    useEffect(() => {
      const imageFiles = files.filter((file) =>
        AppRegExp.isImageFullPath(`.${file.extension}`)
      );
      setData({ ...data, imageFiles: [...imageFiles] });
    }, [files]);

    useEffect(() => {
      if (selectedTask._id && firstUpdate.current) {
        getFiles({
          selectedTask,
          next: pagination.next,
          searchTerm,
          caller: "first",
          parentFolderId: rootFolderId,
        });
      }
      firstUpdate.current = false;
      setTask({ ...selectedTask });
      return;
    }, [selectedTask]);

    const getFoldersSocket = ({
      page,
      limit,
      parentFolderId,
      searchTerm,
      searchType,
    }) => {
      return new Promise((resolve, reject) => {
        socket.emit(
          "fx:get-task-folders",
          {
            page,
            limit,
            parentFolderId,
            searchTerm,
            searchType,
          },
          async (response) => {
            resolve(response);
          }
        );
      });
    };

    const getFiles = async ({
      selectedTask,
      next,
      searchTerm = "",
      searchType = "",
      caller,
      parentFolderId,
    }) => {
      try {
        console.log(caller);
        setIsLoading(true);
        const { page, limit } = next;
        const response = await getFoldersSocket({
          page,
          limit,
          parentFolderId,
          searchTerm,
          searchType,
        });
        console.log("get files", response, crumbs);

        if (response.success) {
          if (!response.data.parentFolder) {
            showToast({
              message: {
                title: "Folder was not found",
              },
              type: "error",
            });
            throw new Error();
          }
          setPagination({
            ...response.data.pagination,
          });
          const crumbIndex = crumbs.findIndex(
            (el) => el._id === response.data.parentFolder._id
          );

          if (crumbIndex === -1) {
            setCrumbs([...crumbs, response.data.parentFolder]);
          } else {
            setCrumbs([...crumbs.slice(0, crumbIndex + 1)]);
          }

          _setFiles([
            ...(page === 1 ? [] : [...files]),
            ...response.data.childFolders,
          ]);
        } else {
          showToast({
            message: {
              title: "Unable to get Files",
            },
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
      /*if (isShared) {
      return getFilesHTTP({
        taskId: selectedTask._id,
        searchTerm,
        searchType: searchType.toLowerCase(),
      });
    }
    */
      /*
      setIsLoading(true);
      const { page, limit } = next;
      socket.emit(
        "fx:get-task-files",
        {
          page,
          taskId: selectedTask._id,
          limit,
          searchTerm,
          searchType: searchType.toLowerCase(),
          caller
          
        },
        async (response) => {
          setIsLoading(false);
          if (response.success) {
            console.log("get files", caller, response);
            setPagination({
              ...response.data.pagination,
            });
            _setFiles([
              ...(page === 1 ? [] : [...files]),
              ...response.data.files,
            ]);
          } else {
            showToast({
              message: {
                title: "Unable to get Comments",
              },
              type: "error",
            });
          }
        }
      );*/
    };

    const getFilesHTTP = async ({
      selectedTask,
      next,
      searchTerm = "",
      searchType = "",
    }) => {
      try {
        setIsLoading(true);
        const { page, limit } = next;
        const response = await getTaskFiles({
          page,
          taskId: selectedTask._id,
          limit,
          searchTerm,
          searchType: searchType.toLowerCase(),
        });
        if (response.success) {
          console.log(response);
          setPagination({
            ...response.data.pagination,
          });
          _setFiles([
            ...(page === 1 ? [] : [...files]),
            ...response.data.files,
          ]);
        } else {
          showToast({
            message: {
              title: "Unable to get Comments",
            },
            type: "error",
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    const handleSearch = (text) => {
      setSearchTerm(text);
    };

    const handleShowFile = (file) => {
      file.extension = file.extension ? file.extension : file.ext;
      if (file.is_dir) {
        getFiles({
          next: initialPagination.next,
          searchTerm,
          parentFolderId: file._id,
        });
      } else if (AppRegExp.isImageFullPath(`.${file.extension}`)) {
        const foundFileIndex = data.imageFiles.findIndex(
          (fileEl) => fileEl._id === file._id
        );
        if (foundFileIndex !== -1) {
          setData({ ...data, indexInView: foundFileIndex });
          setShowFileModal(true);
        }
      } else if (file.extension === "pdf") {
        let fileUrl = `${watcherServerUrl}/files/${file.cloud_id}/${file.originalName}.${file.extension}`;

        eventBus.dispatch("onViewPdf", { fileUrl, ...file });
      } else if (microsoftOfficeFileExtensions.includes(file.extension)) {
        const fileUrl = cloudDriveFrontEnd + "/group-edit-doc/" + file.cloud_id;
        window.open(fileUrl, "_blank");
      } else {
        const fileUrl = `${watcherServerUrl}/files/${file.cloud_id}/${file.originalName}.${file.extension}?view`;
        window.open(fileUrl, "_blank");
      }
    };

    /*const handleFileIonClick = (e) => {
      console.log(e.target.closest(".grid-item"));
    };*/

    const addFileToComment = (file) => {
      if (styleHelper.isMobile) {
        eventBus.dispatch("setCurrentTab", "conversations");
      }
      eventBus.dispatch("add-file-to-comment", { file });
    };

    const openComment = (file) => {
      socket.emit(
        "fx:get-file-comment",
        { ...file, taskId: selectedTask._id },
        async (response) => {
          console.log("openComment", response);

          if (response.comment) {
            if (styleHelper.isMobile) {
              eventBus.dispatch("setCurrentTab", "conversations");
            }
            // emitCustomEvent("handleSelectedComment", response.comment);
            eventBus.dispatch("handleSelectedComment", response.comment);

            // TODO - toggle to conversation on mobile
          }
        }
      );
    };

    eventBus.useCustomEventListener("add-file-to-comment", (file) => {
      setShowFileModal(false);
    });

    const removeAttachments = ({ filesToRemove }) => {
      const filesIds = filesToRemove.map((file) => {
        deleteUploadFile(file.cloud_id);
        return file.cloud_id;
      });
      const filteredFiles = _files.current.filter((file) => {
        return ![...filesIds].includes(file.cloud_id);
      });
      _setFiles([...filteredFiles]);
    };

    const addNewFolder = (folder) => {
      console.log("add new folder", folder);
      if (
        !isEmpty(crumbs) &&
        folder.parent_id === crumbs[crumbs.length - 1]._id
      ) {
        _setFiles([folder, ...files]);
      }
    };

    eventBus.useCustomEventListener("addNewFolder", addNewFolder);

    eventBus.useCustomEventListener(
      "remove-file-from-attachments",
      removeAttachments
    );

    return (
      <>
        <div className="attachment">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3>Attachments</h3>

            <div className="actions">
              <form
                className="search-wrap"
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearch(searchTerm);
                }}
              >
                <label htmlFor="searchBox" className="btn search-btn px-2">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.8402 15.462L13.1648 11.7866C14.0497 10.6086 14.5273 9.17478 14.5257 7.70146C14.5257 3.93818 11.464 0.876465 7.70073 0.876465C3.93745 0.876465 0.875732 3.93818 0.875732 7.70146C0.875732 11.4647 3.93745 14.5265 7.70073 14.5265C9.17404 14.5281 10.6079 14.0504 11.7859 13.1655L15.4613 16.8409C15.6473 17.0072 15.89 17.096 16.1394 17.089C16.3889 17.082 16.6262 16.9798 16.8026 16.8034C16.9791 16.6269 17.0813 16.3896 17.0883 16.1402C17.0953 15.8907 17.0065 15.6481 16.8402 15.462ZM2.82573 7.70146C2.82573 6.73728 3.11165 5.79475 3.64732 4.99306C4.18299 4.19137 4.94436 3.56653 5.83515 3.19755C6.72594 2.82857 7.70614 2.73203 8.6518 2.92014C9.59745 3.10824 10.4661 3.57254 11.1479 4.25432C11.8297 4.9361 12.294 5.80474 12.4821 6.7504C12.6702 7.69606 12.5736 8.67625 12.2046 9.56704C11.8357 10.4578 11.2108 11.2192 10.4091 11.7549C9.60745 12.2905 8.66491 12.5765 7.70073 12.5765C6.40828 12.5749 5.1692 12.0608 4.2553 11.1469C3.3414 10.233 2.82728 8.99392 2.82573 7.70146Z"
                      fill="#97A3B0"
                    />
                  </svg>
                </label>
                <input
                  className={`search`}
                  placeholder="Search in Fexspace Drive"
                  id="searchBox"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                />{" "}
              </form>

              <select
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
              >
                <option value="">All</option>
                <option>Media</option>
                <option>Files</option>
              </select>

              <button
                className={`btn ml-4 mr-2 ${!isFileGrid ? "active" : ""}`}
                onClick={() => setIsFileGridInLocalStorage(false)}
              >
                <ViewGridOutline />
              </button>
              <button
                className={`btn ${isFileGrid ? "active" : ""}`}
                onClick={() => setIsFileGridInLocalStorage(true)}
              >
                <FormatListBulleted />
              </button>
            </div>
          </div>

          {isShared ? (
            <div className="w-100 d-flex align-items-center justify-content-center p-5">
              <div className="text-center" style={{ color: "#A7B9CB" }}>
                <LockIcon width="100" height="100" />
                <p className="h4 mt-2">Files are private</p>
              </div>
            </div>
          ) : (
            <>
              <div className="crumbs px-2 mb-2">
                {!isEmpty(crumbs) && (
                  <span
                    onClick={() =>
                      getFiles({
                        selectedTask,
                        next: initialPagination.next,
                        searchTerm,
                        parentFolderId: rootFolderId,
                      })
                    }
                  >
                    <FolderIcon style={{ width: "1.5rem" }} />
                  </span>
                )}
                {crumbs.map((crumb, i) => (
                  <span
                    key={i}
                    onClick={() =>
                      getFiles({
                        selectedTask,
                        next: initialPagination.next,
                        searchTerm,
                        parentFolderId: crumb._id,
                      })
                    }
                    className={`p-cursor  ${
                      crumb._id === rootFolderId && "d-none"
                    }`}
                  >
                    <ChevronRightIcon />{" "}
                    {truncate(crumb?.name, {
                      length: 15,
                    })}
                  </span>
                ))}
              </div>
              {!isLoading &&
              !files.length &&
              isEmpty(
                toArray(fileProgress).filter(
                  (file) =>
                    file.taskId === selectedTask._id &&
                    file.folder_id === crumbs[crumbs.length - 1]?._id &&
                    file.status &&
                    file.show &&
                    !_files.current.find(
                      (fileEl) => fileEl.cloud_id === file.cloud_id
                    )
                )
              ) ? (
                <div className="no-attachments mx-auto">
                  <EmptyAttachments />
                  <span className="mt-3 d-block">
                    {searchTerm || searchType
                      ? "No Search Results..."
                      : "No attached files yet"}
                  </span>
                </div>
              ) : (
                <>
                  <div
                    className={
                      !isFileGrid
                        ? "files-update  justify-content-start"
                        : "files-update-full"
                    }
                  >
                    {
                      /*!isLoading &&*/
                      [
                        ...toArray(fileProgress)
                          .filter(
                            (file) =>
                              file.taskId === selectedTask._id &&
                              file.folder_id ===
                                crumbs[crumbs.length - 1]?._id &&
                              file.status &&
                              file.show &&
                              !_files.current.find(
                                (fileEl) => fileEl.cloud_id === file.cloud_id
                              )
                          )
                          .reverse(),
                        ...getUniqueListBy(files, "_id"),
                      ].map((file, index) => (
                        <React.Fragment key={index}>
                          {isFileGrid ? (
                            <div key={index} className="item-full d-flex">
                              {AppRegExp.isImageFullPath(
                                `${file.originalName}.${file.extension}`
                              ) ? (
                                <div className="image-holder">
                                  <img
                                    onClick={() => handleShowFile(file)}
                                    src={setFileUrl(file)}
                                    dsrc="https://images.unsplash.com/photo-1629581477374-9237dc216942?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzMjIyMzQ2Ng&ixlib=rb-1.2.1&q=80&w=1080"
                                    alt={`${file.originalName}.${file.extension}`}
                                  />
                                </div>
                              ) : (
                                <GetFileIcon
                                  file={file}
                                  className={`icon-ga bg-white p-cursor`}
                                  style={{
                                    transform: "scale(1.5) !important",
                                  }}
                                  onClick={() => handleShowFile(file)}
                                />
                              )}

                              <div
                                className="filename-holder p-cursor"
                                onClick={() => handleShowFile(file)}
                              >
                                <span className="filename" title={file?.name}>
                                  {file.is_dir
                                    ? truncate(file.name, {
                                        length: 20,
                                      })
                                    : truncateFileName({
                                        ...file,
                                        maxLength: 20,
                                        startLimit: 6,
                                        endLimit: -5,
                                      })}
                                </span>
                                <span
                                  className={`filesize ${
                                    file.is_dir && "d-none"
                                  }`}
                                >
                                  {formatFileSize(file.size)}
                                </span>
                              </div>

                              {!isShared && !file.is_dir ? (
                                <Dropdown alignRight drop="up">
                                  <Dropdown.Toggle
                                    bsPrefix={`btn btn-light p-0 border-none file-more`}
                                  >
                                    <DotsVerticalIcon />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu bsPrefix={`menu`} alignRight>
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() => handleShowFile(file)}
                                    >
                                      View
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() => downloadFile(file)}
                                    >
                                      Download
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      as="button"
                                      onClick={() => addFileToComment(file)}
                                    >
                                      Comment
                                    </Dropdown.Item>

                                    {AppRegExp.isImageFullPath(
                                      `${file.originalName}.${file.extension}`
                                    ) && (
                                      <Dropdown.Item
                                        as="button"
                                        onClick={() => setAsCover(file)}
                                      >
                                        Set as cover
                                      </Dropdown.Item>
                                    )}

                                    <Dropdown.Item
                                      as="button"
                                      onClick={() => openComment(file)}
                                    >
                                      View comment
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ) : null}
                            </div>
                          ) : (
                            <div key={index} className="item">
                              <div className={`icon-holder`}>
                                <>
                                  {AppRegExp.isImageFullPath(
                                    `${file.originalName}.${file.extension}`
                                  ) ? (
                                    <img
                                      onClick={() => handleShowFile(file)}
                                      src={setFileUrl(file)}
                                      dsrc="https://images.unsplash.com/photo-1629581477374-9237dc216942?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzMjIyMzQ2Ng&ixlib=rb-1.2.1&q=80&w=1080"
                                      alt={`${file.originalName}.${file.extension}`}
                                      className="w-100"
                                    />
                                  ) : (
                                    <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                                      <GetFileIcon
                                        file={file}
                                        className={`icon-ga`}
                                        style={{
                                          transform: "scale(1.5) !important",
                                        }}
                                        onClick={() => handleShowFile(file)}
                                      />
                                    </div>
                                  )}
                                </>
                              </div>
                              <div className="file-details d-flex align-items-center">
                                <span>
                                  <span
                                    className="filename"
                                    onClick={() => handleShowFile(file)}
                                    title={file?.name}
                                  >
                                    {file.is_dir
                                      ? truncate(file.name, {
                                          length: 14,
                                        })
                                      : truncateFileName({
                                          ...file,
                                          maxLength: 14,
                                          startLimit: 6,
                                          endLimit: -5,
                                        })}
                                  </span>

                                  <span
                                    className={`filesize ${
                                      file.is_dir && "d-none"
                                    }`}
                                  >
                                    {formatFileSize(file.size)}
                                  </span>
                                </span>
                                {!isShared && !file.is_dir ? (
                                  <Dropdown alignRight drop="up">
                                    <Dropdown.Toggle
                                      bsPrefix={`btn btn-light p-0 border-none file-more`}
                                    >
                                      <DotsVerticalIcon />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu bsPrefix={`menu`} alignRight>
                                      <Dropdown.Item
                                        as="button"
                                        onClick={() => handleShowFile(file)}
                                      >
                                        View
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as="button"
                                        onClick={() => downloadFile(file)}
                                      >
                                        Download
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        as="button"
                                        onClick={() => addFileToComment(file)}
                                      >
                                        Comment
                                      </Dropdown.Item>

                                      {AppRegExp.isImageFullPath(
                                        `${file.originalName}.${file.extension}`
                                      ) && (
                                        <Dropdown.Item
                                          as="button"
                                          onClick={() => setAsCover(file)}
                                        >
                                          Set as cover
                                        </Dropdown.Item>
                                      )}

                                      <Dropdown.Item
                                        as="button"
                                        onClick={() => openComment(file)}
                                      >
                                        View comment
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ))

                      /*files.map((file, index) => (
                  <div key={index} className="grid-item">
                    {isShared ? (
                      <></>
                    ) : AppRegExp.isImageFullPath(
                        `${file.originalName}.${file.extension}`
                      ) ? (
                      <div className="img-holder is-image p-cursor">
                        {!isShared && (
                          <Dropdown alignRight>
                            <Dropdown.Toggle
                              bsPrefix={`btn btn-light p-0 border-none file-more`}
                            >
                              <DotsVerticalIcon />
                            </Dropdown.Toggle>

                            <Dropdown.Menu bsPrefix={`menu`} alignRight>
                              <Dropdown.Item
                                as="button"
                                onClick={() => handleShowFile(file)}
                              >
                                View
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => downloadFile(file)}
                              >
                                Download
                              </Dropdown.Item>

                              <Dropdown.Item
                                as="button"
                                onClick={() => addFileToComment(file)}
                              >
                                Comment
                              </Dropdown.Item>

                              {AppRegExp.isImageFullPath(
                                `${file.originalName}.${file.extension}`
                              ) && (
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => setAsCover(file)}
                                >
                                  Set as cover
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                        <img
                          onClick={() => handleShowFile(file)}
                          src={`${watcherServerUrl}/files/${file.cloud_id}/${file.originalName}.${file.extension}?view`}
                          alt={`${file.originalName}.${file.extension}`}
                          className="w-100"
                        />
                      </div>
                    ) : (
                      <div
                        className="img-holder d-flex justify-content-center align-items-center p-cursor"
                        onClick={(e) => handleFileIonClick(e)}
                      >
                        {!isShared && (
                          <Dropdown alignRight>
                            <Dropdown.Toggle
                              bsPrefix={`btn btn-light p-0 border-none file-more`}
                            >
                              <DotsVerticalIcon />
                            </Dropdown.Toggle>

                            <Dropdown.Menu bsPrefix={`menu`} alignRight>
                              <Dropdown.Item
                                as="button"
                                onClick={() => handleShowFile(file)}
                              >
                                View
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => downloadFile(file)}
                              >
                                Download
                              </Dropdown.Item>

                              <Dropdown.Item
                                as="button"
                                onClick={() => addFileToComment(file)}
                              >
                                Comment
                              </Dropdown.Item>

                              {AppRegExp.isImageFullPath(
                                `${file.originalName}.${file.extension}`
                              ) && (
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => setAsCover(file)}
                                >
                                  Set as cover
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                        <GetFileIcon
                          file={file}
                          
                          onClick={() => handleShowFile(file)}
                        />
                      </div>
                    )}

                    <span onClick={() => handleShowFile(file)}>
                      {truncateFileName({
                        ...file,
                        maxLength: 20,
                        startLimit: 10,
                        endLimit: -5,
                      })}
                    </span>
                  </div>
                    ))*/
                    }
                  </div>
                </>
              )}
              {pagination.hasNext && (
                <button
                  className={`btn btn-light text-primary d-block mx-auto mt-3 w-100 p-3`}
                  onClick={() =>
                    getFiles({
                      selectedTask,
                      next: pagination.next,
                      searchTerm,
                      parentFolderId: crumbs[crumbs.length - 1]._id,
                    })
                  }
                >
                  <b>Load more...</b>
                </button>
              )}
            </>
          )}
        </div>

        {showFileModal && (
          <FileModal
            indexInView={data.indexInView}
            showFileModal={showFileModal}
            setShowFileModal={(isOpen) => setShowFileModal(isOpen)}
            files={data.imageFiles}
            setAsCover={setAsCover}
            canSeeChat={canSeeChat()}
            isShared={isShared}
          />
        )}
      </>
    );
  }
  /*(oldVal, newVal) => {
    // console.log("kd", newVal);
    // let { fileProgress: oldFileProgress, ...restOldVal } = oldVal;
    let { fileProgress: newFileProgress, ...restNewVal } = newVal;

    let newFiles = toArray(newFileProgress).filter(
      (file) =>
        file.taskId === newVal.selectedTask._id && file.status && file.show
    );

    console.log(newFiles.length);
    return !newFiles.length;
  }*/
);

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  token: state.userReducer.token,
  hasAccountExpired: state.userReducer.hasAccountExpired,
  fileProgress: state.uploadFileReducer.fileProgress,
});

const mapDispatchToProps = (dispatch) => {
  return {
    deleteUploadFile: (fileId) => dispatch(deleteUploadFile(fileId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Attachments);
