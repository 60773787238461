import React, { Component } from "react";
import { URLS } from "../urls";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "../../assets/scss/sidebar.scss";
import { toggleLeftSideBar } from "../../store/actions/topBarActions";
import {
  Clients,
  FexMobileLogo,
  TeamsIcon,
  ProjectsIcon,
  TasksIcon,
  PerformanceIcon,
  ReportsIcon,
} from "../topbar/Icons";
import eventBus from "../../helper/EventBus";
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submenuOpen: {
        employee: false,
        payroll: false,
        jobs: false,
        reports: false,
      },
      activeSideBar: props.activeSideBar || "",
      isPageSidebarOpen: false,
    };
  }
  changeEmployeeSubMenu = (e) => {
    const { submenuOpen } = this.state;
    submenuOpen.employee = !submenuOpen.employee;
    this.setState({
      submenuOpen,
    });
  };
  changePayrollSubMenu = (e) => {
    const { submenuOpen } = this.state;
    submenuOpen.payroll = !submenuOpen.payroll;
    this.setState({
      submenuOpen,
    });
  };
  changeJobsSubMenu = (e) => {
    const { submenuOpen } = this.state;
    submenuOpen.jobs = !submenuOpen.jobs;
    this.setState({
      submenuOpen,
    });
  };
  changeReportsSubMenu = (e) => {
    const { submenuOpen } = this.state;
    submenuOpen.reports = !submenuOpen.reports;
    this.setState({
      submenuOpen,
    });
  };

  showPerformance = (isSuperAdmin, isAdmin, account_type) => {
    let value;
    if (isSuperAdmin || isAdmin || (account_type && account_type === 1)) {
      value = true;
    }
    return value;
  };

  openPageSideBar = () => {
    this.setState({
      isPageSidebarOpen: !this.state.isPageSidebarOpen,
    });
  };

  componentDidMount() {
    eventBus.on("openPageSideBar", this.openPageSideBar);
  }

  componentWillUnmount() {
    eventBus.remove("openPageSideBar", this.openPageSideBar);
  }

  render() {
    const { submenuOpen, activeSideBar, isPageSidebarOpen } = this.state;
    const { isSuperAdmin, isAdmin, account_type } = this.props.user;

    const scrollParent = {
      height: "100%",
      overflow: "hidden",
      paddingBottom: "70px",
      width: "240px",
    };
    const scrollChild = {
      width: "100%",
      height: "100%",
      boxSizing: "content-box",
      paddingRight: "15px",
    };
    return (
      <div
        className={`d-flex overlay ${isPageSidebarOpen ? "opened" : "closed"}`}
      >
        <div className="sd-cs">
          <div className="d-flex page-title">
            <FexMobileLogo blue={true} className="mr-2" />{" "}
            <p> {activeSideBar}</p>
          </div>
          <Link
            className={`sd-link ${
              activeSideBar === "clients" ? "sd-active" : ""
            }`}
            to="/clients"
          >
            <Clients />
            <span>Clients</span>
          </Link>
          <Link
            className={`sd-link ${
              activeSideBar === "teams" ? "sd-active" : ""
            }`}
            to="/teams"
          >
            <TeamsIcon />
            <span>Teams</span>
          </Link>
          <Link
            className={`sd-link ${
              activeSideBar === "projects" ? "sd-active" : ""
            }`}
            to="/projects"
          >
            <ProjectsIcon />
            <span>Projects</span>
          </Link>
          <Link
            className={`sd-link ${
              activeSideBar === "tasks" ? "sd-active" : ""
            }`}
            to="/tasks-board"
          >
            <TasksIcon />
            <span>Tasks</span>
          </Link>
          {/* {this.showPerformance(isSuperAdmin, isAdmin, account_type) && ( */}
          <Link
            className={`sd-link ${
              activeSideBar === "performance" ? "sd-active" : ""
            }`}
            to="/performance"
          >
            <PerformanceIcon />
            <span>Performance</span>
          </Link>
          {/* )} */}
          <Link
            className={`sd-link ${
              activeSideBar === "reports" ? "sd-active" : ""
            }`}
            to={`${URLS.reports}`}
          >
            <ReportsIcon />
            <span>Reports</span>
          </Link>
        </div>
        <div
          className="flex-col  w-100 h-100 d-md-none"
          onClick={() => this.openPageSideBar()}
        ></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
  };
};

export default connect(mapStateToProps, null)(withRouter(Sidebar));
