import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import Spinner from "../global/Spinner";
import { URLS } from "../urls";
import Team from "./Team";
import AddTeamModal from "./AddTeamModal";
import EditTeamModal from "./EditTeamModal";
import { socket } from "../../sockets";
import { deleteTeam, getTeamsIBelongTo } from "../../services/teamServices";
import { getCompanyUsers } from "../../services/userServices";
import { removeModalElements } from "../../utils";
import "../../assets/scss/new-ui.scss";
import { InputReload, SearchIcon } from "../task-update/components/Icons";
import PlusIcon from "mdi-react/PlusIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import { openPageSideBar, showToast } from "../task-update/utils";
import TextIcon from "mdi-react/TextIcon";
import DialogModal from "../task-update/modals/DialogModal";
class Teams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [],
      teamsClone: [],
      editTeam: "",
      employeesList: [],
      projects: {},
      tasks: {},
      selectedTeamId: null,
      isLoadingTeams: true,
      companyUsers: [],
      isAddModalOpen: false,
      isEditModalOpen: false,
      editTeamData: "",
    };
  }

  componentDidMount() {
    const token = this.props.token;
    getTeamsIBelongTo(token).then((result) => {
      if (result.teams) {
        const { projects, tasks } = this.state;
        const teams = result.teams;
        teams.forEach((team) => {
          projects[team._id] = { open: 0, completed: 0 };
          tasks[team._id] = { open: 0, completed: 0 };
        });
        teams.forEach((team) => {
          this.fetchProjectsByTeamId(team._id);
          this.fetchTasksByTeamId(team._id);
        });

        this.setState({
          teams,
          teamsClone: teams,
          tasks,
          projects,
          isLoadingTeams: false,
        });
      }
    });

    getCompanyUsers(token).then((result) => {
      this.setState({
        companyUsers: result.users,
      });
    });

    this.handleTeamEventsReceived();
  }

  handleTeamEventsReceived = () => {
    socket.on("fx-added-to-team", ({ team }) => {
      if (team) {
        this.updateTeamsList(team);
      }
    });

    socket.on("fx-removed-from-team", ({ teamId }) => {
      if (teamId) {
        let teamsClone = this.state.teamsClone;
        teamsClone = teamsClone.filter((team) => team._id !== teamId);
        this.setState({ teamsClone, teams: teamsClone });
      }
    });

    socket.on("fx-team-updated", ({ team }) => {
      if (team) {
        this.updateTeamsList(team);
      }
    });
  };

  selectClient = (_id) => {
    this.setState({ selectedTeamId: _id });
  };

  editTeam = async (editTeamData) => {
    console.log(editTeamData);
    await this.setState({ editTeamData });
    this.handleModal("edit");
  };

  getEditTeamDataFromId = (id) => {
    const { teams } = this.state;
    return teams.find((item) => id === item._id);
  };

  handleDelete = async (selectedTeamId) => {
    if (
      await DialogModal({
        title: "Delete Team",
        description: `Are you sure, you want to delete?`,
        type: "warning",
      })
    ) {
      let teams = this.state.teams;
      let teamsClone = this.state.teamsClone;
      const token = this.props.token;

      deleteTeam(selectedTeamId, token).then((response) => {
        if (response.success) {
          teams = teams.filter((team) => team._id !== selectedTeamId);
          teamsClone = teamsClone.filter((team) => team._id !== selectedTeamId);
          this.setState({ teams, teamsClone });
          showToast({
            message: {
              title: `Team Deleted`,
            },
            type: "success",
          });
        }
      });
    }
  };

  fetchProjectsByTeamId = (id) => {
    const token = this.props.token;
    fetch(`${URLS.backendProjects}/getProjectsByTeamId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const { projects } = this.state;
        projects[id]["open"] = result.projects.length;
        this.setState({
          projects,
        });
      });
  };

  fetchTasksByTeamId = (id) => {
    const token = this.props.token;
    fetch(`${URLS.backendTasks}/getTasksByTeamId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const { tasks } = this.state;
        const allTasks = result.tasks;
        var open = 0,
          completed = 0;
        if (allTasks) {
          allTasks.forEach((task) => {
            if (task.completeDate && task.status === "COMPLETE") completed += 1;
            else open += 1;
          });
        }
        tasks[id]["open"] = open;
        tasks[id]["completed"] = completed;
        this.setState({ tasks });
      });
  };

  updateTeamsList = (team) => {
    if (team) {
      let {
        teams,
        teamsClone,
        tasks,
        projects,
        isAddModalOpen,
        isEditModalOpen,
      } = this.state;
      const id = team && team._id;
      if (id) {
        let isExists = false;
        for (let index = teamsClone.length - 1; index >= 0; --index) {
          if (teamsClone[index]._id == id) {
            teamsClone.splice(index, 1);
            teamsClone.splice(index, 0, team);
            isExists = true;
            break;
          }
        }

        if (isExists) {
          this.setState({ teamsClone, teams: teamsClone });
        } else {
          tasks[team._id] = { open: 0, completed: 0 };
          projects[team._id] = { open: 0, completed: 0 };
          this.setState({
            teamsClone: [...teamsClone, team],
            teams: [...teamsClone, team],
            tasks,
            projects,
          });
        }
      }
      if (isAddModalOpen) {
        this.handleModal("add");
      } else if (isEditModalOpen) {
        this.handleModal("edit");
      }
    }
  };

  handleModal = (modal) => {
    if (modal === "add") {
      const isAddModalOpen = !this.state.isAddModalOpen;
      this.setState({ isAddModalOpen });
    } else if (modal === "edit") {
      const isEditModalOpen = !this.state.isEditModalOpen;
      this.setState({
        isEditModalOpen,
        ...(isEditModalOpen ? {} : { editTeamData: null }),
      });
    }
  };

  handleSearchTeams = (e) => {
    if (e) e.preventDefault();
    const value = e ? e.target.value.trim() : "";
    let { teams, teamsClone } = this.state;

    if (value) {
      teams = teamsClone.filter((team) => {
        if (team.name.toLowerCase().includes(value)) {
          return team;
        }
      });
    } else {
      teams = teamsClone;
    }
    this.setState({
      teams,
    });
  };

  render() {
    const {
      teams,
      tasks,
      projects,
      isLoadingTeams,
      companyUsers,
      isAddModalOpen,
      isEditModalOpen,
      editTeamData,
    } = this.state;
    const { hasAccountExpired } = this.props;

    return (
      <div className="main-wrapper">
        <Topbar />
        <div className="d-flex new-wrapper sleek-scrollbar">
          <Sidebar activeSideBar="teams" />

          <div className="page-wrapper p-0">
            <div className="page-top-bar">
              <Link to="/" className="d-none d-md-block">
                Home
              </Link>
              <button
                className="btn d-md-none p-0 nav"
                onClick={() => openPageSideBar()}
              >
                <TextIcon />
              </button>

              <form
                onSubmit={(e) => e.preventDefault()}
                className="d-flex align-items-center"
              >
                <label className="search">
                  <SearchIcon />
                  <input
                    onChange={(e) => this.handleSearchTeams(e)}
                    placeholder="Search Teams"
                  />
                </label>
                <button
                  type="reset"
                  onClick={(e) => this.handleSearchTeams()}
                  className="btn p-0 ml-3"
                >
                  <InputReload />
                </button>
              </form>

              <button
                onClick={() => this.handleModal("add")}
                disabled={hasAccountExpired}
                className="btn btn-primary"
                title="Create Team"
              >
                <PlusIcon />{" "}
                <span className="d-none d-md-flex"> Create Team</span>
              </button>
            </div>
            <div className="content container-fluid">
              <div className="g-crumb">
                <a href="/">Home</a> <ChevronRightIcon class="active" />{" "}
                <a href="#" class="active">
                  Teams
                </a>
              </div>

              {isLoadingTeams ? (
                <Spinner />
              ) : (
                <div>
                  {teams && teams.length ? (
                    <div className="row staff-grid-row bg-none m-0">
                      {teams.map((team) => {
                        return (
                          <Team
                            key={team._id}
                            team={team}
                            onEdit={() => this.editTeam(team)}
                            onDelete={() => this.handleDelete(team._id)}
                            tasks={tasks}
                            projects={projects}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div className="d-flex flex-column align-items-center m-t-50">
                      <img
                        src="../../assets/svg/undraw_live_collaboration_2r4y.svg"
                        className="empty-svg"
                      />
                      <p className="mt-4 text-lg">
                        <b>None found</b>
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>

            {isAddModalOpen && (
              <AddTeamModal
                mode="add"
                key={isAddModalOpen}
                isAddModalOpen={isAddModalOpen}
                updateTeamsList={this.updateTeamsList}
                companyUsers={companyUsers}
                closeModal={() => this.handleModal("add")}
                autoFocus={false}
                enforceFocus={false}
              />
            )}
            {isEditModalOpen && editTeamData?._id ? (
              <EditTeamModal
                key={editTeamData?._id}
                isEditModalOpen={isEditModalOpen}
                mode="edit"
                team={editTeamData}
                employeesList={companyUsers}
                updateTeamsList={this.updateTeamsList}
                closeModal={() => this.handleModal("edit")}
              />
            ) : null}
            {/*
            <div
              className="modal custom-modal fade"
              id="delete_team"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Team</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action mt-4">
                      <div className="row">
                        <div className="col-6">
                          <span
                            className="btn btn-primary delete-btn"
                            onClick={this.handleDelete}
                          >
                            Delete
                          </span>
                        </div>
                        <div className="col-6">
                          <span
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                          >
                            Cancel
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    hasAccountExpired: state.userReducer.hasAccountExpired,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Teams));
