import React from 'react';
import { connect } from "react-redux";
import { useState } from 'react';
import { formatSlashedDate, showToast, styleHelper } from "../utils";
import { updateContactPerson } from '../../../services/customerServices'

function EditContactPerson({
    token,
    index,
    setShowEditForm,
    contactPerson,
    reloadPerson
}) {
    const [person, setPerson] = useState({
        name: { first: contactPerson.name.first, last: contactPerson.name.last },
        email: contactPerson.email,
        phone: contactPerson.phone,
        position: contactPerson.position,
        taskId: contactPerson.taskId,
        projectId: contactPerson.projectId,
        _id: contactPerson._id
    })

    const updatePerson = async (e) => {
        e.preventDefault()
        showToast({
            message: {
                title: "Contact person updated successfully...",
            },
            type: "success",
        });

        let data = {
            name: { first: person.name.first, last: person.name.last },
            phone: person.phone,
            email: person.email,
            position: person.position,
            taskId: person.taskId,
            projectId: person.projectId,
            id: person._id
        }

        const res = await updateContactPerson(token, data)

        reloadPerson()
        setShowEditForm(false)
    }
    return (
        <form
            key={index}
            className="mt-3"
            onSubmit={(e) => updatePerson(e)}
        >
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <input
                        placeholder="First Name"
                        value={person.name.first}
                        autoComplete="off"
                        autoFocus
                        required
                        onChange={(e) => {
                            setPerson({
                                ...person,
                                name: {
                                    ...person.name,
                                    first: e.target.value
                                }
                            })
                        }
                        }
                        spellCheck={false}
                        className="form-control mb-2"
                    />
                </div>
                <div className="col-md-6 col-sm-12">
                    <input
                        placeholder="Last Name"
                        value={person.name.last}
                        autoComplete="off"
                        autoFocus
                        required
                        onChange={(e) => {
                            setPerson({
                                ...person,
                                name: {
                                    ...person.name,
                                    last: e.target.value
                                }
                            })
                        }}
                        spellCheck={false}
                        className="form-control mb-2"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <input
                        placeholder="Phone"
                        value={person.phone}
                        autoComplete="off"
                        autoFocus
                        required
                        onChange={(e) => {
                            setPerson({
                                ...person,
                                phone: e.target.value
                            })
                        }}
                        spellCheck={false}
                        className="form-control mb-2"
                    />
                </div>
                <div className="col-md-6 col-sm-12">
                    <input
                        placeholder="Email"
                        value={person.email}
                        type="email"
                        autoComplete="off"
                        autoFocus
                        required
                        onChange={(e) => {
                            setPerson({
                                ...person,
                                email: e.target.value
                            })
                        }}
                        spellCheck={false}
                        className="form-control mb-2"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <input
                        placeholder="Position"
                        value={person.position}
                        autoComplete="off"
                        autoFocus
                        required
                        onChange={(e) => {
                            setPerson({
                                ...person,
                                position: e.target.value
                            })
                        }}
                        spellCheck={false}
                        className="form-control mb-2"
                    />
                </div>
            </div>
            <div className="d-flex justify-content-between mt-1 mb-3">
                <div>
                    <button className="btn btn-primary mr-2 py-2">Update</button>
                    <button
                        onClick={() => setShowEditForm(false)}
                        className="btn btn-light text-dark py-2"
                        type="button"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </form>
    );
}

const mapStateToProps = (state) => {
    return {
        token: state.userReducer.token,
        user: state.userReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditContactPerson);