import React, { useEffect, useState } from "react";

import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import EmailIcon from "mdi-react/EmailIcon";
import PhoneIcon from 'mdi-react/PhoneIcon';
import BellOutlineIcon from "mdi-react/BellOutlineIcon";
import BellOffOutlineIcon from "mdi-react/BellOffOutlineIcon";
import CheckCircleOutline from "mdi-react/CheckCircleOutlineIcon";
import CommentTextMultipleIcon from "mdi-react/CommentTextMultipleIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PaletteIcon from "mdi-react/PaletteIcon";
import TrashCanIcon from "mdi-react/TrashCanOutlineIcon";
import { Dropdown } from "react-bootstrap";
import { AddUserIcon, CompleteIcon, ImageRemoveOutlineIcon } from "./Icons";
import {
    allTaskMembers,
    cloudDriveBackend,
    CopyToClipboard,
    formatSlashedDate,
    dateFormat,
    checkExpiration,
    isBaseGrey,
    setFileUrl,
} from "../utils";
import Avatar from "./Avatar";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import { socket } from "../../../sockets";
import {
    compareAsc,
    differenceInDays,
    differenceInHours,
    subDays,
} from "date-fns";
import PageLayoutHeaderIcon from "mdi-react/PageLayoutHeaderIcon";
import { truncate } from "lodash";
import EditContactPerson from "./EditContactPerson";

export default function ContactPersonCard({
    index,
    contactPerson,
    handleDeleteContact,
    reloadPerson,
    setSelectedTask = () => { },
    setSelectedList = () => { },
    setShowTaskDetailsModal = () => { },
    showColorPicker,
    user,
    isProjectLeaderOrCreatedBy,
    showUsersList,
    setTaskEditStage,
    toggleTaskNotifications,
    moveTaskToList,
    list = [],
    setAsCover,
}) {
    const [showEditForm, setShowEditForm] = useState(false)
    const showTaskDetails = () => {
        setSelectedTask();
        setSelectedList();
        setShowTaskDetailsModal();
    };

    const resolveDueColor = (schedule) => {

        if (checkExpiration(schedule.dueDate)) {
            return {
                background: "#FC6D6C",
                color: "#fff",
                border: "none",
                "padding-left": "10px",
                "padding-right": "10px",
                width: "100px"
            };
        } else {
            return {
                background: "#40D886",
                color: "#fff",
                border: "none",
                "padding-left": "10px",
                "padding-right": "10px",
                width: "100px"
            };
        }
    };

    return (
        <>
            <div
                key={contactPerson._id}
                className="stage-card"
            >

                <div className="stage-content">
                    <div className="d-flex justify-content-between align-items-center">
                        <span
                            className="title p-cursor"
                            title={
                                contactPerson.name.first + ' ' + contactPerson.name.last
                            }
                        >
                            {contactPerson.name.first + ' ' + contactPerson.name.last}
                        </span>

                        <Dropdown
                            alignRight
                        /*style={
                        isProjectLeaderOrCreatedBy() || task.createdBy === user._id
                            ? {}
                            : { visibility: "hidden" }
                        } */
                        >
                            <Dropdown.Toggle bsPrefix={`btn bg-white p-0 toggle-btn`}>
                                <DotsHorizontalIcon
                                    className="text-lighter"
                                />
                            </Dropdown.Toggle>

                            <Dropdown.Menu bsPrefix={`task-menu`} alignRight>
                                <Dropdown.Item
                                    as="button"
                                    onClick={() => { setShowEditForm(true) }}
                                >
                                    <PencilIcon /> Edit Contact
                                </Dropdown.Item>
                                <Dropdown.Item
                                    as="button"
                                    className="text-danger"
                                    onClick={() => handleDeleteContact(contactPerson, index)}
                                >
                                    <TrashCanIcon className="text-danger" /> Delete Contact
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <p
                        className="m-0 task-detail w-100 p-cursor"
                    >
                        Position: {contactPerson.position}
                    </p>
                    <p
                        className="m-0 task-detail w-100 p-cursor"
                    >
                        <PhoneIcon /> {contactPerson.phone}
                    </p>
                    <p
                        className="m-0 task-detail w-100 p-cursor"
                    >
                        <EmailIcon /> {contactPerson.email}
                    </p>
                </div>

            </div>
            {showEditForm && <EditContactPerson index={index} setShowEditForm={setShowEditForm} contactPerson={contactPerson} reloadPerson={reloadPerson} />}
        </>
    );
}
