import CheckCircleOutlineIcon from "mdi-react/CheckCircleOutlineIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import TrashCanOutlineIcon from "mdi-react/TrashCanOutlineIcon";
import CloseIcon from "mdi-react/CloseIcon";
import React, { useCallback, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { format } from "date-fns";
import "../../../assets/scss/conversationDetail.scss";
import Conversations from "../components/Conversations";
import Avatar from "../components/Avatar";
import { useEffect } from "react";
import { socket } from "../../../sockets";
import { connect } from "react-redux";
import Attachments from "../components/Attachments";
import { formatSlashedDate, showToast, styleHelper } from "../utils";
import styled from "styled-components";
import { setShowLoginModal } from "../../../store/actions";
import eventBus from "../../../helper/EventBus";
import { v4 as uuidv4 } from "uuid";
import { isUserInProjectApi } from "../../../services/projectServices";
import { createRootFolder } from "../../../services/taskServices";
import { fetchContactPerson, sendQuotation } from '../../../services/customerServices';
// ... other imports requires
import watch from "redux-watch";
import store from "../../../store";
import { ShareBg } from "../../topbar/Icons";
// import UploadToModal from "./CRMUploadToModal";
import Spinner from "./../../global/Spinner";
import { TodoList } from "../components/TodoList";
import { TaskTodoList } from "../components/TaskTodoList";
import CustomerScheduleList from "../components/CustomerScheduleList";
import { ContactPersonList } from "../components/ContactPersonList";
import UploadCommentArea from '../components/UploadCommentArea';

function UploadQuotation({
    showUploadQuotation,
    setShowTaskDetailsModal = () => { },
    selectedTask,
    selectedList = {},
    selectedProject = [],
    projectUsersList,
    user,
    handleClearUnread,
    showUsersList,
    isShared,
    token,
    setShowLoginModal,
    list,
    moveTaskToList,
    isProjectLeaderOrCreatedBy,
    setShowUploadQuotation,
    updateQuotes
}) {
    // const [show, setShow] = useState(false);
    const fileUpload = React.useRef(null);
    const [currentTab, setCurrentTab] = useState("conversations");
    const [currentSubTab, setCurrentSubTab] = useState("todo");
    const [usersList, setUsersList] = useState({});
    // const [componentKey, setComponentKey] = useState(uuidv4());
    const [hasClickedJoin, setHasClickedJoin] = useState(false);
    const [hasBeenAccepted, setHasBeenAccepted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [rootFolderId, setRootFolderId] = useState(null);
    const [scheduleType, setScheduleType] = useState('')
    const [scheduleText, setScheduleText] = useState('')
    const [scheduleAddress, setScheduleAddress] = useState('')
    const [scheduleDate, setScheduleDate] = useState('')
    const [showAddForm, setShowAddForm] = useState(false)
    const [personCommenter, setPersonCommenter] = useState('')
    const [person, setPerson] = useState('')
    const [recipient, setRecipient] = useState('')
    const [contactPersons, setContactPersons] = useState([])
    const [employeeComment, setEmployeeComment] = useState('')
    const [contactPersonError, setContactPersonError] = useState(false)
    const [selectFileError, setSelectFileError] = useState(false)
    const [files, setFiles] = useState([])

    eventBus.useCustomEventListener("setCurrentTab", setCurrentTab);

    const clearUnreadTaskComment = (selectedTask) => {
        // if (selectedTask.unreadCount) {
        socket.emit(
            "fx:clear-unread-task-comment",
            {
                taskId: selectedTask._id,
                userId: user._id,
            },
            (response) => {
                if (response.success) {
                    handleClearUnread(response);
                }
            }
        );
        // }
    };

    useEffect(() => {
        fetchData();
    }, [])

    const captureFile = function(e) {
        setFiles([...files, e.target.files[0]])
    }

    const removeFile = function(index) {
        setFiles((oldValue) => {
            oldValue = oldValue.filter((el, i) => i !== index);
            return [...oldValue];
        });
    }

    async function fetchData() {
        let data = await fetchContactPerson(token, { taskId: selectedTask._id })
        setContactPersons([...data.data])
    }

    const joinTask = () => {
        socket.emit(
            "request-to-join-task",
            {
                projectId: selectedTask.project,
                taskId: selectedTask._id,
                token,
            },
            (response) => {
                if (response.success) {
                    showToast({
                        message: {
                            title: "You request to join this task has been sent",
                        },
                        type: "info",
                    });
                    setHasClickedJoin(true);
                } else {
                    showToast({
                        message: {
                            title: "Unable to join Project",
                        },
                        type: "error",
                    });
                }
            }
        );
    };

    const handleTaskJoin = () => {
        let ready = false;
        if (Object.keys(user).length) {
            // request to join project
            setInterval(async () => {
                if (ready) return;
                const res = await isUserInProjectApi({
                    projectId: selectedTask.project,
                    userId: user._id,
                });
                if (res.project) {
                    ready = true;
                    setHasBeenAccepted(true);
                    showToast({
                        message: {
                            title: "Your request to join this task has been accept",
                        },
                        type: "success",
                    });
                }
                // console.log("timeout", res);
            }, 5000);

            if (
                selectedTask.assignedTo?.find((assigned) => assigned._id === user._id)
            ) {
                showToast({
                    message: {
                        title: "Your are already part of this task",
                    },
                    type: "info",
                });
                return;
            }

            joinTask();
        } else {
            // login
            setShowLoginModal(true);
        }
    };

    const handleAccepted = useCallback(
        (res) => {
            console.log(res);
            if (res?.success && res.taskId === selectedTask._id) {
                setHasBeenAccepted(true);
                showToast({
                    message: {
                        title: "Your request to join this task has been accept",
                    },
                    type: "success",
                });
            }
        },
        [selectedTask]
    );

    const submitQuotation = async function() {
        let post = true
        if(person == '') {
            post = false
            setContactPersonError(true)
        } else {
            setContactPersonError(false)
        }

        if(!files.length) {
            post = false
            setSelectFileError(true)
        } else {
            setSelectFileError(false)
        }

        if(post) {
            setIsLoading(true)
            const formData = new FormData();
            files.forEach((file, index) => {
                formData.append(`file${index + 1}`, file);
            })

            formData.append('taskId', selectedTask._id)
            formData.append('projectId', selectedTask.project)
            formData.append('contact_person', person)
            formData.append('comment', employeeComment)
    
            const res = await sendQuotation(token, formData)
            console.log(res)
            if(res.success) {
                showToast({
                    message: {
                        title: "Quotation sent to recipient successfully...",
                    },
                    type: "success",
                });
                setIsLoading(false)
                eventBus.dispatch("updateQuotes", res.data);
                setShowUploadQuotation(false)
                // updateQuotes(res.data)
            }
        }
    }

    const captureData = (e) => {
        setEmployeeComment(e.target.value)
    }

    useEffect(() => {
        socket.on(`fx:request-to-join-task-accepted`, handleAccepted);

        return () => {
            socket.off(`fx:request-to-join-task-accepted`, handleAccepted);
        };
    }, [handleAccepted]);

    useEffect(() => {
        setUp();

        return () => { };
    }, []);

    const setUp = async () => {
        //console.log("mounted", selectedTask);
        const isDev = process.env.REACT_APP_ENV !== "production";
        try {
            if (isDev) {
                setRootFolderId(
                    selectedProject.project_folder_id
                        ? selectedProject.project_folder_id
                        : "5f351216c56f413004f6d9fe"
                );
                setIsLoading(false);
            } /* else if (selectedTask.folder_id) {
        setRootFolderId(selectedTask.folder_id);
        setIsLoading(false);
      }*/ else {
                // create root folder - check if previous rootfolder was deleted
                const response = await createRootFolder({
                    token,
                    taskId: selectedTask._id,
                });

                // console.log(response);
                if (response.success && response.folder_id) {
                    setRootFolderId(response.folder_id);
                    setIsLoading(false);
                } else {
                    throw new Error("");
                }
            }
        } catch (err) {
            if (!isShared) {
                setShowTaskDetailsModal(false);
            } else {
                // to login page
            }
            showToast({
                message: {
                    title: "Unable to setup Task folder",
                },
                type: "error",
            });
        }
    };

    return (
        <>
            <Modal
                show={showUploadQuotation}
                onHide={() => {
                    if (!isShared) setShowTaskDetailsModal(false);
                }}
                dialogClassName="conversation-detail-content task-detail-content"
                backdropClassName={`global-backdrop max-z-index-2`}
                //     }`}
                // aria-labelledby="example-custom-modal-styling-title"
                centered={false}
                animation={false}
                enforceFocus={false}
            >
                {isLoading ? (
                    <div
                        className="disabled-share d-flex justify-content-center align-items-center w-100 h-100 position-relative"
                        style={{ background: "#ffffff", minHeight: "inherit" }}
                    >
                        <ShareBg className="bg" />
                        <div className="content text-center">
                            <Spinner spinnerHeight={`3.5rem`} />
                            <p className="h3 mb-3">Please wait...</p>
                        </div>
                    </div>
                ) : isShared &&
                    !selectedProject?.sharable?.enabled &&
                    !hasBeenAccepted ? (
                    <div
                        className="disabled-share d-flex justify-content-center align-items-center w-100 h-100 position-absolute rounded"
                        style={{ background: "#ffffff" }}
                    >
                        <ShareBg className="bg" />
                        <div className="content text-center">
                            <img
                                alt=""
                                src="../../assets/svg/undraw_Organizing_projects_0p9a.svg"
                                className="empty-svg my-3"
                            />
                            <p className="h3 mb-3">This project is private</p>
                            <button
                                onClick={() => handleTaskJoin()}
                                disabled={hasClickedJoin}
                                className="btn btn-primary btn-lg"
                            >
                                {hasClickedJoin ? "Awaiting Response..." : "Request to join"}
                            </button>
                        </div>
                    </div>
                ) : (
                    rootFolderId && (
                        <>
                            <Modal.Header bsPrefix="modal-header">
                                <div>
                                    <h3
                                        className="task-status d-flex align-items-center"
                                    >
                                        Upload Quotation
                                    </h3>
                                </div>

                                <button
                                    id="close-btn"
                                    onClick={() => {
                                        setShowUploadQuotation(false);
                                    }}
                                >
                                    <CloseIcon />
                                </button>
                            </Modal.Header>
                            <Modal.Body bsPrefix="modal-body">
                                <div className="holder d-flex">
                                    <div>
                                        <div className="info">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <label>Recipient</label>
                                                </div>
                                                        <div className="col-md-12 col-sm-12 mb-3">
                                                    <select
                                                        required
                                                        className="form-control"
                                                        value={person}
                                                        onChange={(e) => {
                                                            setContactPersonError(false)
                                                            setPerson(e.target.value)
                                                            // setRecipient(JSON.parse(e.target.value).id)
                                                            console.log(person)
                                                        }}
                                                    >
                                                        <option value={''}>Select Contact Person</option>
                                                        {contactPersons.map((person, index) => (
                                                            <option value={person._id} key={index}>{person.name.first + ' ' + person.name.last}</option>
                                                        ))}
                                                    </select>
                                                    {contactPersonError?(<span style={{ color: '#c33' }}>*Required</span>) : ''}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <button
                                                        onClick={() => {
                                                            setSelectFileError(false)
                                                            fileUpload.current.click();
                                                        }}
                                                        className="border-0"
                                                        style={{borderRadius: '4px', fontSize: '12px', padding: '8px'}}
                                                    >
                                                        + Select a file
                                                    </button>
                                                    <input type="file" ref={fileUpload} style={{display: 'none'}} onChange={captureFile} />
                                                    {selectFileError? (<p style={{color: '#c33'}}>Please select a file</p>) : ''}
                                                </div>
                                            </div>
                                            {files.length? (<div className="row mt-3">
                                                {files.map((file, index) => (
                                                    <div className="col-md-12 col-sm-12 mb-2" key={index}>
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                {file.name}
                                                            </div>
                                                            <div className="col-md-2">
                                                                <TrashCanOutlineIcon onClick={() => { removeFile(index) }} style={{color: '#c33', cursor: 'pointer'}} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>) : ''}
                                            <div className="row my-3">
                                                <label className="col-md-12 col-sm-12">Comment</label>
                                                <div className="comment-area col-md-12 col-sm-12">
                                                    <div className="comment-section">
                                                        <UploadCommentArea
                                                            selectedProject={selectedProject}
                                                            selectedTask={selectedTask}
                                                            projectUsersList={projectUsersList}
                                                            scrollToBottom={false}
                                                            thread={[]}
                                                            rootFolderId={rootFolderId}
                                                            commentName={'employeeComment'}
                                                            hideUploadFolder={true}
                                                            hideFilePicker={true}
                                                            setShowUploadQuotation={setShowUploadQuotation}
                                                            employeeCommentId={'employee_comment'}
                                                            captureData={captureData}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="">
                                    <div className="d-flex justify-content-between mt-3">
                                        <div>
                                            <button className="btn btn-primary mr-2 py-3" onClick={submitQuotation}>{isLoading? 'Loading...' : 'Submit'}</button>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Footer>
                        </>
                    )
                )}
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user,
    token: state.userReducer.token,
    hasAccountExpired: state.userReducer.hasAccountExpired,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setShowLoginModal: (isShown) => dispatch(setShowLoginModal(isShown)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadQuotation);
