import { format } from "date-fns";
import CloseIcon from "mdi-react/CloseIcon";
import PinIcon from "mdi-react/PinIcon";
import PinOffIcon from "mdi-react/PinOffIcon";
import TextLongIcon from "mdi-react/TextIcon";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import React, { useEffect, useState, useRef } from "react";
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import Avatar from "./Avatar";
import { AddUserIcon } from "./Icons";
import { CopyLinkIcon } from "../../topbar/Icons";
import CogOutlineIcon from "mdi-react/CogOutlineIcon";

export default function MobileToolbar({
  selectedProject,
  pinnedProject,
  handlePinProject,
  setShowProjectDetailsModal,
  showProjectDetailsModal,
  data,
  handleSelectProject,
  list,
  countCompleted,
  countTasks,
  isProjectLeaderOrCreatedBy,
  handleAddProjectMembers,
  setShowToolbarDetails,
  calculateProjectProgress,
  getSharableLink,
  toggleProjectShare,
  setShowBoardSettings,
  allProjectMembers,
}) {
  return (
    <div className="content w-100">
      <div className="holder">
        <div className="p-3 border-bottom">
          <div className="m-buttons">
            <div className="d-flex">
              <button
                className="btn button-light-custom "
                title={
                  pinnedProject === selectedProject._id
                    ? "Unpin project"
                    : "Pin project"
                }
                onClick={() =>
                  handlePinProject(
                    pinnedProject === selectedProject._id ? "" : "pin"
                  )
                }
              >
                {pinnedProject === selectedProject._id ? (
                  <PinOffIcon />
                ) : (
                  <PinIcon />
                )}
              </button>
              <button
                className="btn button-light-custom "
                title="Project Details"
                onClick={() => setShowBoardSettings(false)}
              >
                <CogOutlineIcon />
              </button>
            </div>

            <CloseIcon
              className="close-icon"
              onClick={() => setShowToolbarDetails(false)}
            />
          </div>

          <select
            className="project-select"
            onChange={(e) => handleSelectProject(e.target.value)}
            value={selectedProject?._id}
            readOnly={true}
          >
            {data.projects.map((project) => (
              <option key={project._id} value={project._id}>
                {project.name}
              </option>
            ))}
          </select>
          <p className="date-created m-0">
            Created at{" "}
            {selectedProject?.createdAt
              ? format(new Date(selectedProject?.createdAt), "MMM dd yy")
              : "..."}
          </p>

          <button
            onClick={() => setShowProjectDetailsModal(!showProjectDetailsModal)}
            className="btn project-details-btn p-0"
          >
            View Project Details
          </button>
        </div>

        <div className="m-progress p-3">
          <p className="title">Progress</p>

          <div className="d-flex">
            <div>
              <Progress
                type="circle"
                percent={`${calculateProjectProgress()}`}
                strokeWidth={10}
                width={61}
                theme={{
                  success: {
                    trailColor: "#CED8E1",
                    color: "#40D886",
                  },
                  active: {
                    trailColor: "#CED8E1",
                    color: "#40D886",
                  },
                  default: {
                    trailColor: "#CED8E1",
                    color: "#40D886",
                  },
                }}
              />
            </div>
            <div className="content d-flex flex-column justify-content-between py-2 pb-1">
              <span>
                <CheckCircleIcon />
                <span className="ml-1">
                  {countCompleted(list)} Tasks Completed
                </span>
              </span>
              <span>
                <FileDocumentIcon />
                <span className="ml-1">{countTasks(list)} Tasks Opened</span>
              </span>
            </div>
          </div>
        </div>

        <div className="m-lead pb-2 px-3">
          <p className="title">Team</p>

          <div className="d-flex flex-wrap">
            {allProjectMembers(selectedProject, {
              includeInvited: true,
            }) &&
              allProjectMembers(selectedProject, {
                includeInvited: true,
              }).map((user, index) => (
                <Avatar
                  key={index}
                  width={40}
                  height={40}
                  user={user}
                  usage={"mobile"}
                />
              ))}
            {isProjectLeaderOrCreatedBy(selectedProject, {
              includeInvited: true,
            }) ? (
              <AddUserIcon
                className="p-cursor"
                onClick={() => handleAddProjectMembers()}
              />
            ) : null}
          </div>
        </div>

        {/* <div className="m-team border-bottom">
          <p className="title">Team</p>
          <div className="d-flex flex-wrap">
            {selectedProject?.team &&
              selectedProject?.team.map((user, index) => (
                <Avatar key={index} width={40} height={40} user={user} />
              ))}
            {isProjectLeaderOrCreatedBy(selectedProject) ? (
              <AddUserIcon
                className="p-cursor"
                onClick={() => handleAddProjectMembers()}
              />
            ) : null}
          </div>
        </div>*/}

        <div className="actions p-4">
          <div>
            <div className="d-flex justify-content-between">
              <span className="share-title">Get Sharable Link</span>
              <label
                className="switch"
                title={
                  selectedProject?.sharable?.enabled
                    ? "Disable link"
                    : "Enable link"
                }
              >
                <input
                  type="checkbox"
                  onChange={() => toggleProjectShare()}
                  checked={selectedProject?.sharable?.enabled}
                />
                <span className="slider round"></span>
              </label>
            </div>

            <button
              className="btn btn-outline-primary d-flex mt-3 justify-content-center align-items-center"
              style={{
                width: "13.1875rem",
                height: "2.25rem",
                fontWeight: 600,
                lineHeight: "1rem",
              }}
              onClick={() => {
                if (selectedProject?.sharable?.enabled) getSharableLink();
              }}
              disabled={!selectedProject?.sharable?.enabled}
            >
              <CopyLinkIcon />
              <span className="ml-2">Copy Project Link</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
