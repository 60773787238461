export const POPULATE_TASKS = "POPULATE_TASKS";
export const ADD_TASK = "ADD_TASK";
export const REMOVE_TASK = "REMOVE_TASK";

export function populateTasks(tasks) {
  return {
    type: POPULATE_TASKS,
    payload: tasks,
  };
}

export function addTask(task) {
  return {
    type: ADD_TASK,
    payload: task,
  };
}

export function removeTask(task) {
  return {
    type: REMOVE_TASK,
    payload: task,
  };
}
