import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import moment from "moment";
import MoreIcon from "mdi-react/DotsHorizontalIcon";
import "../../assets/css/project.css";
import "../../assets/scss/tasks.scss";
import { URLS } from "../urls";
import Avatar from "../../components/task-update/components/Avatar";
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import {
  allProjectMembers,
  formatSlashedDate,
  isProjectLeaderOrCreatedBy,
} from "../task-update/utils";
import { CompleteIcon } from "../task-update/components/Icons";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import { compareAsc, differenceInHours } from "date-fns";
import { isEmpty } from "lodash";

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      completedTaskCount: 0,
      inCompletedTaskCount: 0,
      underReviewedTaskCount: 0,
      projectProgress: 0,
    };
  }
  componentDidMount() {
    const token = this.props.token;

    fetch(
      `${URLS.backendTasks}/getTasksByProjectId/${this.props.project._id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result && result.tasks) {
          let projectProgress = result.tasks.reduce((acc, cur) => {
            if (cur.status === "AWAITING") {
              acc += 0;
            } else if (cur.status === "INCOMPLETE") {
              acc += 25;
            } else if (cur.status === "UNDER_REVIEW") {
              acc += 50;
            } else if (cur.status === "COMPLETE") {
              acc += 100;
            }
            return acc;
          }, 0);

          const numberOfTasks = result.tasks.length || 0;
          projectProgress = (projectProgress / (numberOfTasks * 100)) * 100;
          projectProgress = projectProgress || 0;

          const completedTaskCount = result.tasks.filter((task) => {
            return task.status === "COMPLETE";
          }).length;
          const inCompletedTaskCount = result.tasks.filter((task) => {
            return task.status === "INCOMPLETE";
          }).length;
          const underReviewedTaskCount = result.tasks.filter((task) => {
            return task.status === "UNDER_REVIEW";
          }).length;
          this.setState({
            completedTaskCount,
            inCompletedTaskCount,
            underReviewedTaskCount,
            projectProgress,
          });
        }
      });
  }

  setEmployeeName = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name;
  };

  setEmployeeInitials = (employee) => {
    let name;
    if (employee.name && employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name.charAt(0);
  };

  getCreatedBy = (createdBy) => {
    let value;
    if (createdBy && createdBy.constructor.name === "Object") {
      value = createdBy._id;
    } else if (createdBy) {
      value = createdBy;
    }
    return value;
  };

  calculateProgress = () => {
    const { completedTaskCount, inCompletedTaskCount, underReviewedTaskCount } =
      this.state;
    const progress =
      completedTaskCount + inCompletedTaskCount + underReviewedTaskCount > 0
        ? (
            (completedTaskCount * 100) /
            (completedTaskCount + inCompletedTaskCount + underReviewedTaskCount)
          ).toFixed(2)
        : 0;
    return progress;
  };

  hasPersonalAccountMemberAccepted = (employee) => {
    let value;
    const { project, user } = this.props;
    const membersToJoin = project.membersToJoin;
    const account_type = user.account_type;

    if (account_type === 2) {
      value = true;
    } else {
      let isFound = membersToJoin.find(
        (memberToJoin) => memberToJoin.userId == employee._id
      );
      if (isFound) {
        if (isFound.hasAccepted) {
          value = true;
        }
      } else {
        value = true;
      }
    }

    return value;
  };

  resolveDueColor = (task) => {
    let dueDate = task.endDate;
    let startDate = task.startDate;
    let hoursBetween = 0;
    let percentageToDue = 0;
    let hoursToDue = 0;

    if (!dueDate || !startDate) return {};

    dueDate = dueDate.split("/");
    dueDate = new Date(dueDate[2], dueDate[1] - 1, dueDate[0]);
    // const twoDaysToDue = subDays(new Date(dueDate), 2);

    if (startDate) {
      startDate = startDate.split("/");
      startDate = new Date(startDate[2], startDate[1] - 1, startDate[0]);

      hoursBetween = differenceInHours(dueDate, startDate);
      hoursToDue = differenceInHours(dueDate, new Date());
      percentageToDue = (hoursToDue / hoursBetween) * 100;
      // console.log(percentageToDue);
    }

    // const daysBetween = (task.startDate && task.dueDate) ? differenceInDays()

    if (task.status === "Completed") {
      return {
        background: "#40D886",
        color: "#fff",
        border: "none",
      };
    } else if ([0, -1].includes(compareAsc(new Date(dueDate), new Date()))) {
      // Task Due
      return {
        background: "#FC6D6C",
        color: "#fff",
        border: "none",
      };
    } else if (
      /*[0, -1].includes(compareAsc(new Date(twoDaysToDue), new Date())) */
      percentageToDue <= 30
    ) {
      // Task Close to due
      return {
        background: "#FACF61",
        color: "#fff",
        border: "none",
      };
    }

    return {};
  };

  render() {
    const {
      project,
      projectTeamList,
      projectLeaderList,
      user,
      isMoreHidden,
      hasAccountExpired,
    } = this.props;
    const userId = user._id;
    const {
      completedTaskCount,
      inCompletedTaskCount,
      underReviewedTaskCount,
      projectProgress,
    } = this.state;

    let endDate = (project.endDate || "").split("/");
    if (endDate.length !== 3) {
      endDate = ["a", "b", "c"];
    }
    return (
      <div to={`${URLS.projectView}/${project._id}`} className="pj-card">
        <div className="widget">
          <div>
            <div className="widget-title">
              <Link to={`${URLS.projectView}/${project._id}`}>
                {project.name}
              </Link>
            </div>

            <div className="widget-subbody m-0">
              <div className="m-progress py-3">
                <div className="d-flex">
                  <div>
                    <Progress
                      type="circle"
                      percent={Math.trunc(projectProgress)}
                      strokeWidth={10}
                      width={61}
                      theme={{
                        success: {
                          symbol: `${Math.trunc(projectProgress)}%`,
                          trailColor: "#CED8E1",
                          color: "#40D886",
                        },
                        active: {
                          trailColor: "#CED8E1",
                          color: "#40D886",
                        },
                        default: {
                          trailColor: "#CED8E1",
                          color: "#40D886",
                        },
                      }}
                    />
                  </div>
                  <div className="content d-flex flex-column justify-content-between py-2 pb-1">
                    <span>
                      <CheckCircleIcon />
                      <span className="ml-1">
                        {completedTaskCount} Tasks Completed
                      </span>
                    </span>
                    <span>
                      <FileDocumentIcon />
                      <span className="ml-1">
                        {inCompletedTaskCount + underReviewedTaskCount} Tasks
                        Opened
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="widget-body text-sm">
              <p className="m-0">
                {project.description && project.description.length > 100
                  ? `${project.description.slice(0, 150)}...`
                  : `${project.description || ""}`}
              </p>
            </div>

            <div>
              {project.endDate && (
                <button
                  className={`btn border-secondary btn-date mt-3`}
                  style={this.resolveDueColor(project)}
                  title={`Due date: ${formatSlashedDate(
                    project.endDate,
                    true
                  )}`}
                >
                  {projectProgress === 100 ||
                  project?.status === "Completed" ? (
                    <CompleteIcon />
                  ) : (
                    <ClockOutlineIcon />
                  )}{" "}
                  <span>{formatSlashedDate(project.endDate)}</span>
                </button>
              )}
            </div>
          </div>
          {isProjectLeaderOrCreatedBy(project, user) &&
            !isMoreHidden &&
            !hasAccountExpired && (
              <div className="dropdown profile-action">
                <a
                  href=""
                  className="action-icon dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                >
                  <MoreIcon />
                </a>

                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    className="dropdown-item"
                    href=""
                    data-toggle="modal"
                    data-target="#edit_project"
                    onClick={this.props.onEdit.bind(this, project)}
                  >
                    <i className="fa fa-pencil m-r-5"></i> Edit
                  </a>
                  <a
                    className="dropdown-item text-danger"
                    href=""
                    data-toggle="modal"
                    data-target="#delete_project"
                    onClick={this.props.onDelete.bind(this, project)}
                  >
                    <i className="fa fa-trash-o m-r-5"></i> Delete
                  </a>
                </div>
              </div>
            )}

          <div className="widget-subbody">
            <div className="m-lead pb-2">
              {(!isEmpty(projectLeaderList) || !isEmpty(projectTeamList)) && (
                <p className="title">Team</p>
              )}

              <div className="d-flex flex-wrap gap">
                {projectLeaderList &&
                  projectLeaderList.map((user, index) => (
                    <Avatar
                      key={index}
                      width={40}
                      height={40}
                      user={user}
                      usage={"mobile"}
                    />
                  ))}

                {projectTeamList &&
                  projectTeamList.map((user, index) => (
                    <Avatar
                      key={index}
                      width={40}
                      height={40}
                      user={user}
                      usage={"mobile"}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    token: state.userReducer.token,
    hasAccountExpired: state.userReducer.hasAccountExpired,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
