import React, { useState, useEffect, useRef } from "react";
import eventBus from "../../../helper/EventBus";
import { ChevronDownIcon } from "./Icons";
export function ShowDownComponent({
  scrollToBottom,
  getTaskComments,
  unreadMessagesCount,
  selectedTask,
  thread,
}) {
  /*const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);*/
  const [showDownIcon, setShowDownIcon] = useState(false);
  const _showDownIcon = useRef(showDownIcon);

  const _setShowDownIcon = (show) => {
    // if (!mounted) return;
    _showDownIcon.current = show;
    setShowDownIcon(show);
  };

  const setShowDownIconEvt = ({ show }) => {
    // if (!mounted) return;
    if (_showDownIcon.current === show) return;
    _setShowDownIcon(show);
  };

  eventBus.useCustomEventListener("setShowDownIcon", setShowDownIconEvt);

  return showDownIcon ? (
    <button
      className="btn new-message shadow border"
      onClick={() => {
        getTaskComments({
          selectedTask,
          next: { page: 1, limit: 15 },
          usage: "default",
          isLoadingMessagesNewer: true,
        });
        scrollToBottom(thread);
      }}
    >
      <ChevronDownIcon />
      {unreadMessagesCount ? (
        <span class="badge badge-primary position-absolute border-none">
          {/*unreadMessagesCount */ ""}
        </span>
      ) : null}
    </button>
  ) : null;
}
