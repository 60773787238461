import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { pdfjs, Document, Page } from "react-pdf";
import CloseIcon from "mdi-react/CloseIcon";
import Spinner from "../global/Spinner";
import FileIcon from "mdi-react/FileOutlineIcon";
import FilePdfIcon from "mdi-react/FilePdfBoxIcon";
import FileWordIcon from "mdi-react/FileWordBoxIcon";
import FileExcelIcon from "mdi-react/FileExcelBoxIcon";
import { getTaskFiles } from "../../services/taskServices";
import { URLS } from "../urls";
import "../../assets/scss/fileGallery.scss";
import { microsoftOfficeFileExtensions } from "../task-update/utils";

class FileGallery extends Component {
  constructor(props) {
    super(props);

    const watcherServerUrl = process.env.REACT_APP_FILE_WATCHER_SERVER;
    const cloudDriveFrontEnd = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;

    this.state = {
      task: props.task || null,
      isLoading: true,
      files: [],
      filesClone: [],
      fileToOpen: "",
      isFileViewerOpen: false,
      watcherServerUrl,
      cloudDriveFrontEnd,
      mediaExtensions: ["png", "jpg", "jpeg", "gif", "mp3", "mp4", "ogg"],
      fileExtensions: ["pdf", "xls", "doc"],
    };
  }

  componentDidMount() {
    const { task } = this.state;
    const taskId = task._id;
    const token = this.props.token;

    getTaskFiles(token, taskId).then((response) => {
      if (response && response.success) {
        const files = response.files;
        this.setState({ isLoading: false, files, filesClone: files });
      }
    });
  }

  handleCloseModal = (e, wrapper) => {
    if (wrapper) {
      if (e.target !== e.currentTarget) return;
    }
    this.props.closeModal();
  };

  handleFileType = (e) => {
    let { files, filesClone, fileExtensions, mediaExtensions } = this.state;
    const value = e.target.value;
    if (value == 0) {
      files = filesClone;
    } else if (value == 1) {
      files = filesClone.filter((fileClone) =>
        mediaExtensions.includes(fileClone.extension)
      );
    } else if (value == 2) {
      files = filesClone.filter((fileClone) => fileClone.extension === "link");
    } else if (value == 3) {
      files = filesClone.filter((fileClone) =>
        fileExtensions.includes(fileClone.extension)
      );
    }

    this.setState({ files });
  };

  handleOpenFileViewer = (file) => {
    const { watcherServerUrl, cloudDriveFrontEnd } = this.state;

    if (microsoftOfficeFileExtensions.includes(file.extension)) {
      const fileUrl = cloudDriveFrontEnd + "/group-edit-doc/" + file.cloud_id;
      window.open(fileUrl, "_blank");
    } else {
      const fileToOpen = watcherServerUrl + "/files/" + file.cloud_id;
      this.setState({ fileToOpen, isFileViewerOpen: true });
    }
  };

  handleCloseFileModal = (e) => {
    // if (e.target !== e.currentTarget) return;

    this.setState({
      fileToOpen: "",
      isFileViewerOpen: false,
    });
  };

  handleSearchFiles = (e) => {
    e.preventDefault();
    const value = e.target.value.trim();
    let { files, filesClone } = this.state;

    if (value) {
      files = filesClone.filter((file) => {
        if (
          file.uniqueName.toLowerCase().includes(value) ||
          file.originalName.toLowerCase().includes(value)
        ) {
          return file;
        }
      });
    } else {
      files = filesClone;
    }
    this.setState({
      files,
    });
  };

  render() {
    const { isLoading, files, fileToOpen, isFileViewerOpen, watcherServerUrl } =
      this.state;
    const imagesExtensions = ["png", "jpg", "jpeg"];
    return (
      <div
        id="file_gallery"
        className="modal custom-modal fade"
        role="dialog"
        onClick={(e) => this.handleCloseModal(e, "wrapper")}
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="m-h-between">
              <h5 className="modal-title">Gallery</h5>
              <CloseIcon onClick={(e) => this.handleCloseModal(e)} />
            </div>
            <div className="modal-body" style={{ padding: "15px 10px 10px" }}>
              <div style={{ height: "400px" }}>
                {isLoading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100%" }}
                  >
                    <Spinner spinnerHeight="50px" />
                  </div>
                ) : (
                  <div>
                    <div className="d-flex justify-content-between px-2">
                      <input
                        onChange={(e) => this.handleSearchFiles(e)}
                        placeholder="Search"
                        className="search-gallery-files"
                      />
                      <div className="select">
                        <select
                          name="slct"
                          id="slct"
                          className="select-tag"
                          onChange={(e) => this.handleFileType(e)}
                        >
                          <option value="0">All</option>
                          <option value="1">Media</option>
                          <option value="2">Links</option>
                          <option value="3">Files</option>
                        </select>
                      </div>
                    </div>
                    <div className="file-gallery-container sleek-scrollbar">
                      {files && files.length ? (
                        <div className="file-gallery-content">
                          {files.map((file) => {
                            // if (imagesExtensions.includes(file.extension)) {
                            // 	const image =
                            // 		watcherServerUrl +
                            // 		'/images/' +
                            // 		file.cloud_id +
                            // 		'?w=400';
                            // 	return (
                            // 		<img
                            // 			className="file-gallery-image"
                            // 			src={image}
                            // 			onClick={() => this.handleOpenFileViewer(file)}
                            // 			data-toggle="modal"
                            // 			data-target="#view_file"
                            // 		/>
                            // 	);
                            // }
                            // else if (file.extension === 'pdf') {
                            // 	const fileSource =
                            // 		watcherServerUrl + '/files/' + file.cloud_id;
                            // 	return (
                            // 		<Document
                            // 			file={fileSource}
                            // 			noData={
                            // 				<div
                            // 					style={{
                            // 						width: '110px',
                            // 						height: '120px',
                            // 						border: '1px solid #bebebe',
                            // 					}}
                            // 				></div>
                            // 			}
                            // 			className="file-gallery-single"
                            // 			onClick={() => this.handleOpenFileViewer(file)}
                            // 			data-toggle="modal"
                            // 			data-target="#view_file"
                            // 		>
                            // 			<Page
                            // 				pageNumber="1"
                            // 				height="120"
                            // 				width="120"
                            // 				className="change-pdf-size"
                            // 			/>
                            // 		</Document>
                            // 	);
                            // }
                            if (file.extension === "link") {
                              return (
                                <div className="file-gallery-single">
                                  <a
                                    className="file-gallery-link"
                                    href={file.uniqueName}
                                    target="blank"
                                  >
                                    {file.uniqueName}
                                  </a>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  className="file-gallery-single"
                                  onClick={() =>
                                    this.handleOpenFileViewer(file)
                                  }
                                  data-toggle="modal"
                                  data-target="#view_file"
                                >
                                  {file.extension.includes("pdf") ? (
                                    <FilePdfIcon className="file-pdf-icon" />
                                  ) : file.extension.includes("doc") ? (
                                    <FileWordIcon className="file-word-icon" />
                                  ) : file.extension.includes("xls") ? (
                                    <FileExcelIcon className="file-excel-icon" />
                                  ) : (
                                    <FileIcon className="file-other-icon" />
                                  )}
                                  <span className="file-gallery-name">
                                    {file.originalName}
                                  </span>
                                </div>
                              );
                            }
                          })}
                        </div>
                      ) : (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "100%" }}
                        >
                          <span>None found</span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {isFileViewerOpen ? (
          <div
            id="view_file"
            className="modal custom-modal fade"
            role="dialog"
            onClick={(e) => this.handleCloseFileModal(e)}
          >
            <div className="modal-dialog modal-dialog-centered gallery-preview-modal">
              <div className="modal-content gallery-preview-content">
                <iframe
                  src={fileToOpen}
                  description=""
                  style={{ height: "100%", width: "100%", border: "none" }}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FileGallery));
