import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { showToast } from "./components/task-update/utils";

export default function OfflineCheck() {
  const isDev = process.env.REACT_APP_ENV !== "production";
  let [online, isOnline] = useState(isDev ? true : navigator.onLine);
  

  const toastId = React.useRef(null);

  const notify = () => {
    toastId.current = showToast({
      message: {
        title: "You're offline",
      },
      type: "error",
      autoClose: false,
    });
  };

  const dismiss = () => toast.dismiss(toastId.current);

  // const dismissAll = () => toast.dismiss();

  const setOnline = () => {
    isOnline(true);
  };
  const setOffline = () => {
    isOnline(false);
  };

  useEffect(() => {
    if (!online) {
      notify();
    } else {
      dismiss();
    }
  }, [online]);

  // Register the event listeners
  useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    // cleanup if we unmount
    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);

  return null;
}
