import React, { PureComponent } from 'react';
import {
  PieChart, Pie, Sector, Cell,
} from 'recharts';

// const data = [
//   { name: 'Group A', value: 400 }
// ];

export default class Example extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/pb1jwdt1/';
  state = {
    data : {}
  };
  componentWillReceiveProps(props){
    this.setState({
      data : props.data
    })
  }
  render() {
    const {data} = this.state;
    return (
      <PieChart width={200} height={200}
        margin={{
          top: 10, right: 0, left: 0, bottom: 10,
        }}>
        <Pie 
          dataKey="tasks" 
          startAngle={240} 
          endAngle={data.Assigned && data.Assigned[0]['tasks'] ? 240 - (data.Assigned[0]['tasks'] * 3.6) : 240} 
          data={data.Assigned&&data.Assigned} 
          cx={100} 
          cy={100} 
          outerRadius={80} 
          innerRadius={73} 
          fill="#8884d8" 
        />
        <Pie 
          dataKey="tasks" 
          startAngle={240} 
          endAngle={data.Active&&data.Active[0]['tasks'] ? 240 - data.Active[0]['tasks'] * 3.6 : 240}  
          data={data.Active&&data.Active} 
          cx={100} 
          cy={100} 
          outerRadius={60} 
          innerRadius={53} 
          fill="#00b386" 
        />

        <Pie 
          dataKey="tasks" 
          startAngle={240} 
          endAngle={data.Completed && data.Completed[0]['tasks'] ? 240 - data.Completed[0]['tasks'] * 3.6 : 240}  
          data={data.Completed&&data.Completed} 
          cx={100} 
          cy={100} 
          outerRadius={40} 
          innerRadius={33} 
          fill="#9c27b0" 
        />
        
      </PieChart>
    );
  }
}
