import CloseIcon from "mdi-react/CloseIcon";
import React, { useState, useRef, useEffect } from "react";
import {
  cloudDriveBackend,
  stopEventPropagationTry,
  styleHelper,
} from "./components/task-update/utils";
import "../src/assets/scss/viewPdf.scss";
import { Document, Page } from "react-pdf";
import eventBus from "./helper/EventBus";
import { connect } from "react-redux";

function ViewPdf({ token }) {
  const observer = useRef();
  const loaderEl = useRef();
  const holder = useRef();

  const [pdfUrl, setPdfUrl] = useState("");
  //"https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf"
  const [pageCount, setPageCount] = useState(0);
  const [navPage, setNavPage] = useState(1);
  const [hideLoader, setHideLoader] = useState(false);
  const [buffer, setBuffer] = useState(null);

  function onDocumentLoadSuccess(payload) {
    setPageCount(payload.numPages);
    setHideLoader(true);

    setTimeout(() => {
      // const el = document.querySelector(".react-pdf__Document");
      // observerIntersection(el.children);
    }, 500);
  }

  /*function onPageLoadSuccess(page) {
    setTimeout(() => {
      const el = document.querySelector(
        `[data-page-number="${page.pageNumber}"]`
      );
      if (el)
        observerIntersection(
          document.querySelector(`[data-page-number="${page.pageNumber}"]`)
        );
    }, 500);
  }*/

  function onLoadProgress({ loaded, total }) {
    let result = (loaded / total) * 100;
    if (loaderEl.current) loaderEl.current.style.width = `${result}%`;
  }

  function observerIntersection(pages) {
    const options = {
      root: holder.current,
      rootMargin: "0px",
      threshold: 0.75,
    };
    observer.current = new IntersectionObserver(handleIntersect, options);
    [...pages].forEach((page) => observer.current.observe(page));
    // observer.current.observe(page);
  }
  function handleIntersect(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setNavPage(entry.target.getAttribute("data-page-number"));
      }
    });
  }

  /*
  useEffect(() => {
    if (!pdfUrl && observer.current) {
      observer.current.disconnect();
    }
  }, [pdfUrl]);

  useEffect(() => {
    console.log("render view pdf");
    return () => {};
  }, []);*/

  const scrollTo = (navPage) => {
    const el = document.querySelector(`[data-page-number="${navPage}"]`);
    if (el) el.scrollIntoView({ block: "start", inline: "nearest" });
  };

  const onViewPdf = async (file) => {
    // console.log("onViewPdf", file);
    if (styleHelper.isMobile) {
      setPdfUrl(`${cloudDriveBackend}/pdf/${file.cloud_id}/${token}`);
      /*setPdfUrl(
        "https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf"
      );*/

      return;
    }
    setPdfUrl(file.fileUrl);
  };

  const closePdf = (e) => {
    stopEventPropagationTry(e);
    setPdfUrl("");
  };

  eventBus.useCustomEventListener("onViewPdf", onViewPdf);

  return pdfUrl ? (
    <div className="view-file pdf" onClick={(e) => closePdf(e)}>
      <button
        className="close-btn shadow"
        onClick={() => setPdfUrl("")}
        title="Close PDF"
      >
        <CloseIcon />
      </button>
      <div className="img bg-white rounded-md">
        {!styleHelper.isMobile ? (
          <iframe
            src={pdfUrl + "?view"}
            width="100%"
            height="100%"
            title={pdfUrl}
          ></iframe>
        ) : (
          <>
            <div className="pdf-holder">
              <div className="info">
                <div className="flex justify-between items-center p-2">
                  <span>
                    {/* <input
                      value={navPage}
                      className="nav-input"
                      onChange={(e) => {
                        setNavPage(e.target.value);
                        // scrollTo(e.target.value);
                      }}
                    /> */}
                    {/*" of "*/}
                    {pageCount ? `${pageCount} Pages` : "..."}
                  </span>
                </div>
                <div
                  className="pdf-loader p-1 transition-all"
                  style={
                    hideLoader
                      ? { visibility: "hidden" }
                      : { visibility: "visible" }
                  }
                  ref={loaderEl}
                ></div>
              </div>
              <div className="holder" ref={holder}>
                <Document
                  file={{ url: pdfUrl }}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadProgress={onLoadProgress}
                  loading=""
                >
                  {[...Array(pageCount)].map((el, index) => (
                    <Page
                      key={index + 1}
                      pageNumber={index + 1}
                      width={styleHelper.innerWidth - 16 * 3}
                    />
                  ))}
                </Document>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  ) : null;
}

const mapStateToProps = (state) => ({
  // user: state.userReducer.user,
  token: state.userReducer.token,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPdf);
