import React, { useEffect, useState } from "react";

import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import DownloadIcon from "mdi-react/DownloadIcon";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import BellOutlineIcon from "mdi-react/BellOutlineIcon";
import BellOffOutlineIcon from "mdi-react/BellOffOutlineIcon";
import CheckCircleOutline from "mdi-react/CheckCircleOutlineIcon";
import CommentTextMultipleIcon from "mdi-react/CommentTextMultipleIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PaletteIcon from "mdi-react/PaletteIcon";
import TrashCanIcon from "mdi-react/TrashCanOutlineIcon";
import { Dropdown } from "react-bootstrap";
import { AddUserIcon, CompleteIcon, ImageRemoveOutlineIcon } from "./Icons";
import {
    allTaskMembers,
    cloudDriveBackend,
    CopyToClipboard,
    formatSlashedDate,
    dateFormat,
    checkExpiration,
    isBaseGrey,
    setFileUrl,
} from "../utils";
import Avatar from "./Avatar";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import { socket } from "../../../sockets";
import {
    compareAsc,
    differenceInDays,
    differenceInHours,
    subDays,
} from "date-fns";
import PageLayoutHeaderIcon from "mdi-react/PageLayoutHeaderIcon";
import { truncate } from "lodash";
import Comment from './../../task-update/components/Comments';

export default function ScheduleCard({
    index,
    quote,
    handleDeleteQuote,
    handleEditTask = () => { },
    setSelectedTask = () => { },
    setSelectedList = () => { },
    setShowTaskDetailsModal = () => { },
    showColorPicker,
    user,
    isProjectLeaderOrCreatedBy,
    showUsersList,
    setTaskEditStage,
    toggleTaskNotifications,
    moveTaskToList,
    list = [],
    setAsCover,
}) {
    const showTaskDetails = () => {
        setSelectedTask();
        setSelectedList();
        setShowTaskDetailsModal();
    };

    const resolveDueColor = (schedule) => {
        console.log(checkExpiration(schedule.dueDate))

        if (checkExpiration(schedule.dueDate)) {
            return {
                background: "#FC6D6C",
                color: "#fff",
                border: "none",
                "padding-left": "10px",
                "padding-right": "10px",
                width: "100px"
            };
        } else {
            return {
                background: "#40D886",
                color: "#fff",
                border: "none",
                "padding-left": "10px",
                "padding-right": "10px",
                width: "100px"
            };
        }
    };

    return (
        <div
            key={quote._id}
            className="stage-card"
        >

            <div className="stage-content">
                <div className="d-flex justify-content-between align-items-center">
                    <span
                        className="title py-2"
                    >
                        <strong>Recipient Name:</strong> {quote.recipient_name}
                    </span>

                    <Dropdown
                        alignRight
                    /*style={
                      isProjectLeaderOrCreatedBy() || task.createdBy === user._id
                        ? {}
                        : { visibility: "hidden" }
                    } */
                    >
                        <Dropdown.Toggle bsPrefix={`btn bg-white p-0 toggle-btn`}>
                            <DotsHorizontalIcon
                                className="text-lighter"
                            />
                        </Dropdown.Toggle>

                        <Dropdown.Menu bsPrefix={`task-menu`} alignRight>
                            <Dropdown.Item
                                as="button"
                                onClick={() => handleEditTask({ schedule: { ...quote } })}
                            >
                                <PencilIcon /> Resend Quote
                            </Dropdown.Item>
                            <Dropdown.Item
                                as="button"
                                className="text-danger"
                                onClick={() => handleDeleteQuote(quote, index)}
                            >
                                <TrashCanIcon className="text-danger" /> Delete Quote
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <div
                    className="m-0 w-100 p-cursor my-2"
                >
                    <span
                        className="title p-cursor py-3"
                    >
                        <strong>Comment:</strong>
                    </span>
                    <p style={{lineHeight: '18px', color: '#333', fontSize: '16px'}}>
                        {quote.comment}
                    </p>
                </div>
            </div>

            <div className="px-3">
                {quote.files.map((file, index) => (
                    <div className="row" key={index}>
                        <span className="col-md-10">
                            {file.original_filename}
                        </span>
                        <span className="col-md-2 d-flex justify-content-end">
                            <DownloadIcon style={{'cursor': 'pointer'}} />
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}
