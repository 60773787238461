import React, { Component } from "react";
import { URLS } from "../urls";
import { Link } from "react-router-dom";
export default class Error404 extends Component {
  render() {
    return (
      <div
        class="d-flex flex-column align-items-center justify-content-center"
        style={{ backgroundColor: "#fff", height: "100vh" }}
      >
        <a href="/">
          <img
            src="assets/img/logo-blue.png"
            height="80"
            width="150"
            alt="FexSpace logo"
            style={{ objectFit: "contain" }}
          />
        </a>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="fd59ce54-f850-4dfc-bc34-dd7d379d600e"
          data-name="Layer 1"
          width="600"
          height="300"
          viewBox="0 0 1074.392 584.231"
        >
          <title>page not found</title>
          <ellipse
            cx="540.64346"
            cy="549.3094"
            rx="527.5"
            ry="34.9216"
            fill="#f2f2f2"
          ></ellipse>
          <path
            d="M583.47969,324.89424c-85.94407,0-147.651,55.13938-147.651,183.79791,0,145.813,61.70691,184.41057,147.651,184.41057s151.327-42.27352,151.327-184.41057C734.80664,356.75255,669.42376,324.89424,583.47969,324.89424Zm.56495,319.80837c-59.52686,0-90.62592-34.92288-90.62592-135.9163,0-89.11185,32.37209-136.10461,91.899-136.10461s91.899,30.86774,91.899,136.10461C677.21663,607.23367,643.5715,644.70261,584.04464,644.70261Z"
            transform="translate(-63.054 -157.8845)"
            fill="#2f2e41"
          ></path>
          <path
            d="M384.36531,591.40121H348.831V486.76183A20.95585,20.95585,0,0,0,327.87517,465.806h-8.32638a20.95585,20.95585,0,0,0-20.95586,20.95585V591.40121H198.36285a11.96327,11.96327,0,0,1-10.57763-17.552l106.0824-200.78034A20.95585,20.95585,0,0,0,284.28724,344.33l-6.26231-2.9572a20.95585,20.95585,0,0,0-27.4293,9.07005L121.21416,592.4754a28.41578,28.41578,0,0,0-3.35584,13.39612v0a28.41583,28.41583,0,0,0,28.41584,28.41583H298.59293v66.16727a25.119,25.119,0,0,0,25.119,25.119h.00005a25.119,25.119,0,0,0,25.119-25.119V634.28739h35.53428a21.44307,21.44307,0,0,0,21.44307-21.44307v0A21.44307,21.44307,0,0,0,384.36531,591.40121Z"
            transform="translate(-63.054 -157.8845)"
            fill="#6c63ff"
          ></path>
          <path
            d="M1042.36183,591.40121h-35.53428V486.76183A20.95585,20.95585,0,0,0,985.87169,465.806h-8.32638a20.95585,20.95585,0,0,0-20.95586,20.95585V591.40121H856.35937a11.96326,11.96326,0,0,1-10.57763-17.552L951.86413,373.06891A20.95586,20.95586,0,0,0,942.28376,344.33l-6.26231-2.9572a20.95586,20.95586,0,0,0-27.42931,9.07005L779.21068,592.4754a28.41578,28.41578,0,0,0-3.35584,13.39612v0a28.41583,28.41583,0,0,0,28.41583,28.41583H956.58945v66.16727a25.119,25.119,0,0,0,25.119,25.119h0a25.119,25.119,0,0,0,25.119-25.119V634.28739h35.53428a21.44307,21.44307,0,0,0,21.44307-21.44307v0A21.44307,21.44307,0,0,0,1042.36183,591.40121Z"
            transform="translate(-63.054 -157.8845)"
            fill="#6c63ff"
          ></path>
          <path
            d="M394.16787,579.148H358.63358V474.50864a20.95585,20.95585,0,0,0-20.95585-20.95586h-8.32638a20.95586,20.95586,0,0,0-20.95586,20.95586V579.148H208.16541a11.96327,11.96327,0,0,1-10.57763-17.552L303.67017,360.81572a20.95586,20.95586,0,0,0-9.58037-28.73893l-6.26231-2.9572a20.95586,20.95586,0,0,0-27.42931,9.07L131.01672,580.2222a28.41582,28.41582,0,0,0-3.35584,13.39613v0a28.41583,28.41583,0,0,0,28.41583,28.41583H308.39549v66.16727a25.119,25.119,0,0,0,25.119,25.119h.00005a25.119,25.119,0,0,0,25.119-25.119V622.0342h35.53429a21.44307,21.44307,0,0,0,21.44307-21.44307v0A21.44307,21.44307,0,0,0,394.16787,579.148Z"
            transform="translate(-63.054 -157.8845)"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M1060.74162,579.148h-35.53428V474.50864a20.95586,20.95586,0,0,0-20.95586-20.95586H995.9251a20.95586,20.95586,0,0,0-20.95586,20.95586V579.148H874.73916a11.96327,11.96327,0,0,1-10.57763-17.552L970.24392,360.81572a20.95586,20.95586,0,0,0-9.58037-28.73893l-6.26231-2.9572a20.95586,20.95586,0,0,0-27.42931,9.07L797.59047,580.2222a28.41582,28.41582,0,0,0-3.35584,13.39613v0a28.41583,28.41583,0,0,0,28.41583,28.41583H974.96924v66.16727a25.119,25.119,0,0,0,25.119,25.119h0a25.119,25.119,0,0,0,25.119-25.119V622.0342h35.53428a21.44307,21.44307,0,0,0,21.44307-21.44307v0A21.44307,21.44307,0,0,0,1060.74162,579.148Z"
            transform="translate(-63.054 -157.8845)"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M603.0848,313.86637c-85.94407,0-147.651,55.13937-147.651,183.79791,0,145.813,61.70691,184.41057,147.651,184.41057s151.327-42.27352,151.327-184.41057C754.41175,345.72467,689.02887,313.86637,603.0848,313.86637Zm.565,319.80836c-59.52686,0-90.62592-34.92287-90.62592-135.91629,0-89.11185,32.37209-136.10461,91.899-136.10461s91.899,30.86774,91.899,136.10461C696.82174,596.20579,663.17661,633.67473,603.64975,633.67473Z"
            transform="translate(-63.054 -157.8845)"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M1069.91781,577.43414a20.81252,20.81252,0,1,0,2.7716-39.91524l.52093,10.7122-5.06814-9.18045a20.734,20.734,0,0,0-10.68367,11.72261,20.40847,20.40847,0,0,0-1.19713,5.62986A20.80856,20.80856,0,0,0,1069.91781,577.43414Z"
            transform="translate(-63.054 -157.8845)"
            fill="#57b894"
          ></path>
          <path
            d="M1094.99516,701.67756c-1.78906-9.11027,5.9633-17.1868,13.62086-22.43651s16.605-10.40779,19.21775-19.31684c3.755-12.80387-7.43-24.52981-16.13564-34.64176a125.30044,125.30044,0,0,1-16.52359-24.55738c-1.81107-3.5325-3.47558-7.22528-3.95221-11.16626-.68641-5.67546,1.13693-11.32309,2.9739-16.73673q9.17925-27.05169,19.62843-53.65005"
            transform="translate(-63.054 -157.8845)"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M1070.77493,574.6762a20.81252,20.81252,0,1,0,2.7716-39.91524l.52093,10.7122-5.06815-9.18045a20.734,20.734,0,0,0-10.68366,11.72261,20.40847,20.40847,0,0,0-1.19713,5.62986A20.80855,20.80855,0,0,0,1070.77493,574.6762Z"
            transform="translate(-63.054 -157.8845)"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M1092.45136,515.47266a20.78819,20.78819,0,0,1,14.97993-13.19764l1.71361,10.18378,3.177-10.69566a20.81,20.81,0,1,1-19.87057,13.70952Z"
            transform="translate(-63.054 -157.8845)"
            fill="#57b894"
          ></path>
          <path
            d="M1093.59418,511.7954a20.7882,20.7882,0,0,1,14.97993-13.19763l1.71361,10.18378,3.177-10.69567a20.81,20.81,0,1,1-19.87057,13.70952Z"
            transform="translate(-63.054 -157.8845)"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M1108.04474,625.48885a20.81,20.81,0,0,0,18.419-37.02267l-2.44121,8.21926-1.73105-10.30382a.36183.36183,0,0,0-.053-.0201,20.81113,20.81113,0,1,0-14.1938,39.12733Z"
            transform="translate(-63.054 -157.8845)"
            fill="#57b894"
          ></path>
          <path
            d="M1109.035,621.76417a20.81,20.81,0,0,0,18.419-37.02267l-2.44121,8.21926-1.73105-10.30382a.3621.3621,0,0,0-.053-.0201,20.81113,20.81113,0,1,0-14.1938,39.12733Z"
            transform="translate(-63.054 -157.8845)"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M1086.37782,660.05148a20.80131,20.80131,0,1,0,4.01058-16.29737l9.27267,13.95654-12.66994-7.40768A20.61638,20.61638,0,0,0,1086.37782,660.05148Z"
            transform="translate(-63.054 -157.8845)"
            fill="#57b894"
          ></path>
          <path
            d="M1087.23494,657.29354a20.80131,20.80131,0,1,0,4.01058-16.29737l9.27267,13.95655-12.66994-7.40769A20.61626,20.61626,0,0,0,1087.23494,657.29354Z"
            transform="translate(-63.054 -157.8845)"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M72.06146,628.13325a13.67421,13.67421,0,1,0,1.821-26.225l.34227,7.03811-3.32987-6.03172a13.62263,13.62263,0,0,0-7.01936,7.702,13.40883,13.40883,0,0,0-.78654,3.69893A13.6716,13.6716,0,0,0,72.06146,628.13325Z"
            transform="translate(-63.054 -157.8845)"
            fill="#57b894"
          ></path>
          <path
            d="M88.53774,709.76344c-1.17545-5.98561,3.918-11.292,8.94915-14.7412s10.90978-6.8381,12.62642-12.69151c2.46711-8.41238-4.88167-16.11653-10.60142-22.76027A82.32442,82.32442,0,0,1,88.6556,643.43581a22.20962,22.20962,0,0,1-2.59668-7.33643c-.451-3.72888.747-7.43947,1.95391-10.99634q6.03093-17.77346,12.89623-35.24906"
            transform="translate(-63.054 -157.8845)"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M72.62461,626.32123a13.6742,13.6742,0,1,0,1.821-26.225l.34227,7.03812L71.458,601.10258a13.62262,13.62262,0,0,0-7.01936,7.702,13.40912,13.40912,0,0,0-.78654,3.69892A13.67158,13.67158,0,0,0,72.62461,626.32123Z"
            transform="translate(-63.054 -157.8845)"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M86.86641,587.42343a13.65822,13.65822,0,0,1,9.84209-8.67109l1.12587,6.69093,2.08737-7.02725a13.67252,13.67252,0,1,1-13.05533,9.00741Z"
            transform="translate(-63.054 -157.8845)"
            fill="#57b894"
          ></path>
          <path
            d="M87.61727,585.0074a13.65822,13.65822,0,0,1,9.84209-8.67108l1.12587,6.69093L100.6726,576a13.67252,13.67252,0,1,1-13.05533,9.0074Z"
            transform="translate(-63.054 -157.8845)"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M97.11155,659.70607a13.67255,13.67255,0,0,0,12.10164-24.32457l-1.60392,5.4002-1.13733-6.76979a.238.238,0,0,0-.0348-.0132,13.67329,13.67329,0,1,0-9.32559,25.70736Z"
            transform="translate(-63.054 -157.8845)"
            fill="#57b894"
          ></path>
          <path
            d="M97.76214,657.25889a13.67255,13.67255,0,0,0,12.10164-24.32457l-1.60392,5.4002-1.13733-6.7698a.238.238,0,0,0-.0348-.0132,13.67329,13.67329,0,1,0-9.32559,25.70737Z"
            transform="translate(-63.054 -157.8845)"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M82.876,682.41435a13.66684,13.66684,0,1,0,2.635-10.70767l6.09231,9.16971-8.32438-4.867A13.54535,13.54535,0,0,0,82.876,682.41435Z"
            transform="translate(-63.054 -157.8845)"
            fill="#57b894"
          ></path>
          <path
            d="M83.43913,680.60233a13.66684,13.66684,0,1,0,2.635-10.70767l6.09231,9.16971-8.32439-4.867A13.54535,13.54535,0,0,0,83.43913,680.60233Z"
            transform="translate(-63.054 -157.8845)"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></path>
          <ellipse
            cx="480.946"
            cy="319.1155"
            rx="17"
            ry="22"
            fill="#2f2e41"
          ></ellipse>
          <ellipse
            cx="573.446"
            cy="319.6155"
            rx="17"
            ry="22"
            fill="#2f2e41"
          ></ellipse>
          <path
            d="M623.5,542.5c0,9.94-13.88,18-31,18s-31-8.06-31-18c0-8.61,10.41-15.81,24.32-17.57a50.10353,50.10353,0,0,1,6.68-.43,50.69869,50.69869,0,0,1,11.13,1.2C615.25,528.29,623.5,534.84,623.5,542.5Z"
            transform="translate(-63.054 -157.8845)"
            fill="#2f2e41"
          ></path>
          <ellipse
            cx="484.946"
            cy="314.1155"
            rx="17"
            ry="22"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></ellipse>
          <ellipse
            cx="577.446"
            cy="314.6155"
            rx="17"
            ry="22"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></ellipse>
          <ellipse
            cx="533.446"
            cy="379.6155"
            rx="31"
            ry="18"
            fill="none"
            stroke="#3f3d56"
            stroke-miterlimit="10"
          ></ellipse>
          <path
            d="M604,527.2a4.93658,4.93658,0,0,1-1.32,3.392A4.33873,4.33873,0,0,1,599.5,532h-10a4.66433,4.66433,0,0,1-4.5-4.8,4.90458,4.90458,0,0,1,.82-2.74134A47.02,47.02,0,0,1,592.5,524a47.66454,47.66454,0,0,1,11.13,1.28A5.06656,5.06656,0,0,1,604,527.2Z"
            transform="translate(-63.054 -157.8845)"
            fill="#fff"
          ></path>
          <circle cx="484.946" cy="308.1155" r="5" fill="#fff"></circle>
          <circle cx="577.946" cy="308.1155" r="5" fill="#fff"></circle>
          <circle
            cx="582.946"
            cy="355.1155"
            r="5"
            fill="#6c63ff"
            opacity="0.3"
          ></circle>
          <circle
            cx="460.946"
            cy="355.1155"
            r="5"
            fill="#6c63ff"
            opacity="0.3"
          ></circle>
        </svg>
        <h1 style={{ fontSize: "1.125rem", opacity: "0.5", marginTop: "25px" }}>Page not found</h1>
        <a href="/" style={{ fontSize: "0.8rem", textDecoration: "underline" }}>
          Go back Home
        </a>
      </div>
    );
  }
}
