import React, { useRef } from "react";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import styled from "styled-components";
import { useOnClickOutside } from "../custom-hooks";

const EmojiPicker = React.memo(({ selectEmoji, style, setShowEmoji }) => {
  let emojiRef = useRef("");

  useOnClickOutside(emojiRef, () => {
    if (setShowEmoji) setShowEmoji(false);
  });

  return (
    <Wrapper>
      <div
        ref={emojiRef}
        className="emoji-holder"
        style={{ zIndex: 3, maxHeight: "auto", ...style }}
      >
        <Picker
          native
          showPreview={false}
          title=""
          emojiSize={16}
          onSelect={(emoji) => selectEmoji(emoji)}
        />
      </div>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  @import "./../global.scss";

  .emoji-holder {
    margin-bottom: 0.5rem;
    border-radius: 10px;
    box-shadow: 0px 4px 1rem 0px #abb3bc36;

    .emoji-mart {
      width: 100% !important;
      font-size: 14px;
      font-family: Inter;
      border: none;

      .emoji-mart-bar {
        border: none;
      }

      .emoji-mart-search input {
        background: #f2f7fc;
        height: 2.375rem;
        border-radius: 0.5rem;
        border: none;
        font-size: 0.875rem !important;
        padding: 0.5rem 0.75rem 0.5rem 0.75rem;
        margin-right: 0.5rem;
      }

      .emoji-mart-anchor:hover,
      .emoji-mart-anchor:focus,
      .emoji-mart-anchor-selected {
        color: #3f96d1 !important;

        .emoji-mart-anchor-bar {
          background-color: #3f96d1 !important;
        }
      }
    }

    .emoji-mart-scroll {
      height: 10rem;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 4px #ddd;
        box-shadow: inset 0 0 4px #ddd;
        border-radius: 50px;
        background-color: #d8e6f5;
      }

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #bdc9d7;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        -webkit-box-shadow: inset 0 0 4px #ddd;
        box-shadow: inset 0 0 4px #ddd;
        background-color: #bdc9d7;
      }

      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar {
        background-color: transparent !important;
        box-shadow: none !important;
        border: none;
        width: 0.375rem !important;
        height: 0.375rem !important;
      }

      &::-webkit-scrollbar-thumb {
        box-shadow: none;
      }
    }

    .emoji-mart-bar:last-child {
      display: none;
    }
  }
`;
export default EmojiPicker;
