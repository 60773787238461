import { URLS } from "../components/urls";

export function getClientById(token, id) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendClients}/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.json())
    .then((result) => {
      resolve(result);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

export function getClients(token) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendClients}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createClient(formData, token) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendClients}/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateClient(formData, token) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendClients}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteClient(clientId, token) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendClients}/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ clientId }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
