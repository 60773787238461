import io from "socket.io-client";
import Cookies from "universal-cookie";
import eventBus from "../helper/EventBus";

const SERVER_BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const socket = io(SERVER_BASE_URL, {
  transports: ["websocket"],
  rejectUnauthorized: false,
  secure: true,
});

export let connected = socket.connected;

export const socketIO = () => {
  return new Promise((resolve, reject) => {
    const cookies = new Cookies();

    if (!socket.connected) {
      console.log("collaboration: connect socket");
      socket.connect();
      const token = cookies.get("token");
      socket.on("connect", () => {
        connected = true;
        if (token) {
          socket.emit("fx:authenticate", token);
          resolve({
            token,
          });

          // if token can trigger stuffs
          console.log("collaboration: socket connected", connected);
          eventBus.dispatch("socket-connected", {});
        } else {
          reject({
            error: "token not found",
          });
        }
      });

      socket.on("disconnect", () => {
        connected = false;
        console.log("collaboration: socket-disconnected", connected);
        eventBus.dispatch("socket-disconnected", {});
      });

      socket.on("error", () => {
        console.log("socket-error");
        eventBus.dispatch("socket-error", {});
      });
    } else {
      const token = cookies.get("token");
      resolve({
        token,
      });
    }
  });
};

/*
class SocketHandler {
  _socket;
  connecting = false;
  get socket() {
    return this._socket;
  }

  connect() {
    let socket = this.socket;
    if (socket) {
      console.log("Socket already started");
      if (!socket.connected) {
        console.log("Socket not connected, reconnecting");
        return;
      }

      return;
    } else {
      console.log("Socket Not started");
      this.connecting = true;
      socket = io(SERVER_BASE_URL, {
        transports: ["websocket"],
        rejectUnauthorized: false,
        secure: true,
      });
    }

    this._socket = socket;

    socket.on("connect", () => {
      console.log("collaboration: connected...");
      const cookies = new Cookies();
      const token = cookies.get("token");
      socket.emit("fx:authenticate", token);
    });

    socket.on("disconnect", () => {
      console.log("collaboration: socket-disconnected");
      eventBus.dispatch("socket-disconnected", {});
    });

    socket.on("connect_error", (err) => {
      console.log(err);
      console.log("collaboration: connect_error");
    });
  }
}
const IO = new SocketHandler();

console.log(IO);

export default IO;

export const socket = IO.socket;
export const connected = IO.socket?.connected;
*/
