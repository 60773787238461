/* eslint-disable react/jsx-no-undef */
import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Col, Row } from "react-bootstrap";
import { pdfjs } from "react-pdf";
import Datepicker from "react-datetime";
import CloseIcon from "mdi-react/CloseIcon";
import AttachFileIcon from "mdi-react/AttachFileIcon";
import CheckIcon from "mdi-react/CheckIcon";
import FileIcon from "mdi-react/FileIcon";
import { Progress } from "react-sweet-progress";
import Spinner from "../global/Spinner";
import { URLS } from "../urls";
import { debounce, validateEmail } from "../../utils";

import "../../assets/css/project-files.css";
import "../../../src/assets/css/date-picker.css";
import "../../../src/assets/css/image-list.css";

import "../../assets/scss/global.scss";
import "../../assets/scss/projects.scss";

import moment from "moment";
import {
  addProject,
  appendUploadedFileId,
} from "../../services/projectServices";
import { updateFileName, searchUserByEmail } from "../../services/userServices";
import uploadClient from "../../helper/uploadClient";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class EmployeeModal extends React.Component {
  constructor(props) {
    super(props);

    this.selectTeamRef = createRef();
    this.projectFiles = createRef();
    this.searchBar = createRef();
    const { account_type } = props.user;
    this.state = {
      project: {
        name: "",
        client: null,
        startDate: moment(new Date()).format("DD/MM/YYYY"),
        endDate: "",
        cost: 0,
        duration: 0,
        durationType: "",
        priority: "High",
        projectLeader: [],
        team: [],
        description: "",
        files: [],
        teamId: null,
        status: "Created",
        isPublic: false,
      },
      projectFilesDataList: [],
      clientList: [],
      teamTemplates: [],
      nameFieldError: false,
      hasFilledDetails: false,
      hasAddedParticipants: false,
      activeParticipants: "leaders",
      selectedTeam: "Select Team",
      newTeam: [],
      newLeaders: [],
      isAdding: false,
      searchParams: "",
      employeesList: props.employeesList || [],
      allMembers: props.employeesList || [],
      allMembersClone: props.employeesList || [],
      account_type,
      canCreateProjectFolder: true,
      ongoingUploads: [],
      allLeadersChecked: false,
      allMembersChecked: false,
      membersToJoin: [],
      hasCreatedProject: false,
    };
  }

  componentDidMount() {
    const clientList = this.props.clientList;
    const teamTemplates = this.props.teams;

    this.setState({
      clientList,
      teamTemplates,
    });
  }

  updateProjectName = (event) => {
    const { project } = this.state;
    project.name = event.target.value;
    this.setState({
      project,
    });
  };

  updateClient = (event) => {
    const { project } = this.state;
    project.client = event.target.value;
    this.setState({
      project,
    });
  };

  updateStartDate = (event) => {
    const { project } = this.state;
    project.startDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      project,
    });
  };

  updateEndDate = (event) => {
    const { project } = this.state;
    project.endDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      project,
    });
  };

  updateCost = (event) => {
    const { project } = this.state;
    project.cost = event.target.value;
    this.setState({
      project,
    });
  };

  updateDuration = (event) => {
    const { project } = this.state;
    project.duration = event.target.value;
    this.setState({
      project,
    });
  };

  updateDurationType = (event) => {
    const { project } = this.state;
    project.durationType = event.target.value;
    this.setState({
      project,
    });
  };

  updateStatus = (event) => {
    const { project } = this.state;
    project.status = event.target.value;
    this.setState({
      project,
    });
  };

  updatePriority = (event) => {
    const { project } = this.state;
    project.priority = event.target.value;
    this.setState({
      project,
    });
  };

  updateIsPublic = () => {
    const { project } = this.state;
    let isPublic = project.isPublic;
    if (isPublic !== true) {
      isPublic = true;
    } else {
      isPublic = false;
    }
    project.isPublic = isPublic;
    this.setState({
      project,
    });
  };

  updateTeamTemplateId = (event) => {
    const value = event.target.value;
    if (value !== "Select Team") {
      const { project, teamTemplates, employeesList } = this.state;
      project.teamId = value;

      const teamTemplate = teamTemplates.find(
        (tT) => tT._id === project.teamId
      );

      let teamMembers = teamTemplate.members ? teamTemplate.members : [];
      let teamLeaders = teamTemplate.leaders ? teamTemplate.leaders : [];

      let allMembers = [...teamLeaders, ...teamMembers, ...employeesList];
      let unique = {};
      allMembers = allMembers.filter((member) => {
        if (!unique[member._id]) {
          unique[member._id] = true;

          if (teamMembers.find((t) => t._id === member._id)) {
            member.isTeam = true;
          } else if (teamLeaders.find((t) => t._id === member._id)) {
            member.isLeader = true;
          }
          return true;
        } else {
          return false;
        }
      });

      this.setState({
        selectedTeam: value,
        project,
        allMembers,
        allMembersClone: allMembers,
      });
    } else {
      this.setState({
        selectedTeam: value,
      });
    }
  };

  updateDescription = (event) => {
    const { project } = this.state;
    project.description = event.target.value;
    this.setState({
      project,
    });
  };

  updateProjectFile = (event) => {
    let { project, projectFilesDataList } = this.state;

    let fileInput = event.target;
    let files = fileInput.files;
    let fl = files.length;
    let i = 0;
    while (i < fl) {
      const projectFile = {
        originalName: "",
        uniqueName: "",
        extension: "",
        size: "",
      };
      const file = files[i];
      const name = file.name;
      const lastDot = name.lastIndexOf(".");
      const fileName = name.substring(0, lastDot);
      const ext = name.substring(lastDot + 1);
      const size = file.size;
      let currentDate = new Date();
      currentDate = currentDate.getTime();
      projectFile.originalName = fileName;
      projectFile.uniqueName = currentDate;
      projectFile.extension = ext;
      if (size < 1024) {
        projectFile.size = size + " B";
      } else if (size >= 1024 && size < 1024 * 1024) {
        projectFile.size = (size / 1024).toFixed(2) + " KB";
      } else {
        projectFile.size = (size / 1024 / 1024).toFixed(2) + " MB";
      }

      projectFile.file = file;
      project.files = project.files.concat(projectFile);
      projectFilesDataList = projectFilesDataList.concat(file);
      this.setState({
        project,
        projectFilesDataList,
      });
      i++;
    }
  };

  removeProjectFile = (idx) => {
    const { project, projectFilesDataList } = this.state;
    project.files.splice(idx, 1);
    projectFilesDataList.splice(idx, 1);

    this.setState({
      project,
      projectFilesDataList,
    });
  };

  validationStartDate = (currentDate) => {
    return currentDate.isAfter(moment(new Date()).add(-1, "days"));
  };

  validationEndDate = (currentDate) => {
    const { project } = this.state;
    return currentDate.isAfter(
      moment(project.startDate, "DD/MM/YYYY").add(-1, "days")
    );
  };

  handleCloseModal = (e, wrapper) => {
    if (wrapper) {
      if (e.target !== e.currentTarget) return;
    }
    let { allMembers, allMembersClone } = this.state;

    if (allMembersClone && allMembersClone.length) {
      allMembersClone.map((member) => {
        if (member.isChecked) {
          member.isChecked = false;
        }
        return member;
      });
      allMembers = allMembersClone;
    }

    this.setState({
      allMembers,
      allMembersClone,
    });

    this.props.closeModal();
  };

  setClassNameStyle = (name) => {
    let value;
    if (!name || !name.trim()) {
      value = "in-active-project";
    }
    return value;
  };

  isAvailableStyle = () => {
    const { allMembersClone } = this.state;
    const totalSelected = allMembersClone.filter(
      (member) => member.isLeader || member.isTeam
    );
    let value;

    if (totalSelected.length < 1) {
      value = "in-active-project";
    }
    return value;
  };

  handleNextLayout = (value) => {
    const { project, employeesList } = this.state;

    if (value === 1 && project.name.trim().length) {
      const selectedValue =
        this.selectTeamRef.current.options[
          this.selectTeamRef.current.selectedIndex
        ].value;
      if (selectedValue === "Select Team") {
        this.setState({
          allMembers: employeesList,
          allMembersClone: employeesList,
        });
      }
      this.setState({
        hasFilledDetails: true,
        hasAddedParticipants: false,
      });
    } else if (value === 0) {
      this.setState({
        hasFilledDetails: false,
        hasAddedParticipants: false,
      });
    }
  };

  handleActiveParticipants = (activeParticipants) => {
    const { allMembersClone } = this.state;
    this.searchBar.current.value = "";
    this.setState({ activeParticipants, allMembers: allMembersClone });
  };

  setEmployeeName = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name;
  };

  setEmployeeInitials = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name.charAt(0);
  };

  handleSelectedMember = (member, type) => {
    let {
      allMembers,
      allMembersClone,
      searchParams,
      allMembersChecked,
      allLeadersChecked,
      account_type,
      membersToJoin,
    } = this.state;

    const memberId = member._id;

    // If personal user hasn't been invited
    if (account_type === 1 && member.showInvitation) {
      if (!(member.isTeam || member.isLeader)) {
        allMembersClone.push(member);
      }

      // Add or Remove user from membersToJoin
      let indexFound = membersToJoin.findIndex(
        (memberToJoin) => memberToJoin.userId === memberId
      );
      if (indexFound === -1) {
        membersToJoin.push({ userId: memberId });
      } else {
        membersToJoin.splice(indexFound, 1);
      }
    }

    allMembersClone = allMembersClone.map((member) => {
      if (member._id === memberId) {
        if (type === "team") {
          if (member.isTeam) {
            member.isTeam = false;
            allMembersChecked = false;
          } else {
            member.isTeam = true;
            member.isLeader = false;
          }
        } else if (type === "leader") {
          if (member.isLeader) {
            member.isLeader = false;
            allLeadersChecked = false;
          } else {
            member.isLeader = true;
            member.isTeam = false;
          }
        }
      }
      return member;
    });

    // For company users
    if (account_type === 2) {
      if (searchParams) {
        allMembers = this.handleFilterByName(allMembersClone, searchParams);
      } else {
        allMembers = allMembersClone;
      }
    }

    this.setState({
      allMembers,
      allMembersClone,
      allMembersChecked,
      allLeadersChecked,
      membersToJoin,
    });
  };

  searchPersonalAccount = (searchParams) => {
    const token = this.props.token;
    let { allMembersClone } = this.state;

    if (searchParams) {
      searchUserByEmail(searchParams, token).then((response) => {
        if (response.users) {
          let usersToInvite = response.users;

          usersToInvite = usersToInvite.filter(
            (userToInvite) =>
              allMembersClone.findIndex(
                (currentMember) => currentMember.email === userToInvite.email
              ) < 0
          );

          this.setState({ allMembers: usersToInvite });
        }
      });
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    const searchParams = e.target.value.trim();
    this.setState({ searchParams });
    let { allMembers, allMembersClone, account_type } = this.state;

    if (account_type === 1) {
      if (searchParams) {
        debounce(this.searchPersonalAccount(searchParams), 500);
      } else {
        allMembers = allMembersClone;
        this.setState({ allMembers, allMembersClone });
      }
      this.setState({ allMembers, allMembersClone });
    } else if (account_type === 2) {
      if (searchParams) {
        allMembers = this.handleFilterByName(allMembersClone, searchParams);
      } else {
        allMembers = allMembersClone;
      }
      this.setState({ allMembers, allMembersClone });
    }
  };

  handleFilterByName = (arr, value) => {
    let result = [];
    result = arr.filter((member) => {
      let name;
      if (member.name.first) {
        name = member.name.first + " " + member.name.last;
      } else {
        name = member.name;
      }

      if (name.toLowerCase().includes(value)) {
        return member;
      }
    });
    return result;
  };

  calculateFileSize = (size) => {
    let newSize;
    if (size < 1024) {
      newSize = size + " B";
    } else if (size >= 1024 && size < 1024 * 1024) {
      newSize = (size / 1024).toFixed(2) + " KB";
    } else {
      newSize = (size / 1024 / 1024).toFixed(2) + " MB";
    }
    return newSize;
  };

  handleModalClose = () => {
    const { project } = this.state;
    this.props.updateProjectsList(project);
  };

  handleCreateTask = () => {
    const { project } = this.state;
    this.props.closeModal();
    this.props.history.push(`create-task/${project._id}`);
  };

  handleCreateProject = () => {
    try {
      this.setState({ hasAddedParticipants: true, isAdding: true });
      const { project, allMembersClone, projectFilesDataList, membersToJoin } =
        this.state;
      const { _id, company_id } = this.props.user;
      const leaders = [];
      const team = [];

      if (allMembersClone && allMembersClone.length) {
        for (let member of allMembersClone) {
          if (member.isTeam) {
            team.push(member._id);
          } else if (member.isLeader) {
            leaders.push(member._id);
          }
        }
      }
      project.projectLeader = leaders;
      project.team = team;
      project.createdBy = _id;
      project.company_id = company_id;
      project.membersToJoin = membersToJoin;

      const token = this.props.token;
      const { canCreateProjectFolder, ongoingUploads } = this.state;

      addProject(token, project, canCreateProjectFolder).then(
        async (response) => {
          if (response.project) {
            const folder_id = response.project_folder_id;
            if (projectFilesDataList && projectFilesDataList.length) {
              let id;

              uploadClient.addEventListener("progress", (pro) => {
                let percentage = (pro.current / pro.size) * 100;
                let { ongoingUploads } = this.state;
                ongoingUploads = ongoingUploads.map((ongoingUpload) => {
                  if (ongoingUpload.id === pro.id) {
                    ongoingUpload.percentage = percentage.toFixed(2);
                  }
                  return ongoingUpload;
                });
                this.setState({ ongoingUploads });
              });

              for (let [index, file] of projectFilesDataList.entries()) {
                id = Date.now();
                ongoingUploads.push({ id: id, name: file.name, percentage: 0 });
                this.setState({ ongoingUploads });

                const res = await uploadClient.upload(file, {
                  folder_id,
                  id,
                });

                if (res) {
                  //Rename file using it's id
                  const { cloud_id } = res;
                  const body = {
                    name: file.name,
                    size: file.size,
                    current_directory_id: folder_id,
                    id: cloud_id,
                    type: file.type,
                    token,
                  };
                  await updateFileName(body).then((response) => {
                    // console.log('File renamed:', response);
                  });

                  const uniqueName =
                    response.project.files && response.project.files.length
                      ? project.files[index].uniqueName
                      : "";
                  const project_id =
                    response && response.project ? response.project._id : "";
                  const data = {
                    cloud_id,
                    uniqueName,
                    project_id,
                  };

                  if (cloud_id && uniqueName && project_id) {
                    await appendUploadedFileId(token, data).then((response) => {
                      // console.log('Appended file id', response);
                    });
                  }
                }
              }
            }

            // Display question to add Task
            this.setState({
              canCreateProjectFolder: false,
              hasCreatedProject: true,
              project: response.project,
            });

            // this.props.updateProjectsList(response.project);
          }
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  handleSelectFiles = () => {
    this.projectFiles.current.click();
  };

  handleSwitch = (e) => {
    const showOnlySelected = e.target.checked;
    let { allMembers, allMembersClone } = this.state;

    if (showOnlySelected) {
      allMembers = allMembersClone.filter(
        (member) => member.isTeam || member.isLeader
      );
    } else {
      allMembers = allMembersClone;
    }
    this.setState({ allMembers });
  };

  handleDateAndDuration = (event, field) => {
    if (event) {
      const { project } = this.state;
      let { durationType, duration, startDate, endDate } = project;

      if (field === "durationType" || field === "durationValue") {
        if (field === "durationType") {
          durationType = event.target ? event.target.value : event;
        } else if (field === "durationValue") {
          duration = event.target ? event.target.value : event;
        }

        if (
          Number(duration) &&
          durationType !== "" &&
          durationType !== "Select Unit"
        ) {
          if (durationType === "Hours") {
            endDate = moment(startDate, "DD/MM/YYYY")
              .add(duration, "hours")
              .format("DD/MM/YYYY");
          } else if (durationType === "Days") {
            endDate = moment(startDate, "DD/MM/YYYY")
              .add(duration, "days")
              .format("DD/MM/YYYY");
          } else if (durationType === "Months") {
            endDate = moment(startDate, "DD/MM/YYYY")
              .add(duration, "months")
              .format("DD/MM/YYYY");
          } else if (durationType === "Years") {
            endDate = moment(startDate, "DD/MM/YYYY")
              .add(duration, "years")
              .format("DD/MM/YYYY");
          }
        }
      } else if (field === "startDate" || field === "endDate") {
        if (field === "startDate") {
          startDate = event.target
            ? event.target.value
            : moment(event).format("DD/MM/YYYY");
        }

        if (field === "endDate") {
          endDate = event.target
            ? event.target.value
            : moment(event).format("DD/MM/YYYY");
        }

        if (endDate !== "") {
          const diff = moment(endDate, "DD/MM/YYYY").diff(
            moment(startDate, "DD/MM/YYYY"),
            "hours"
          );

          if (diff <= 24) {
            durationType = "Hours";
            duration = Math.round(diff);
          } else if (diff > 24 && diff <= 720) {
            durationType = "Days";
            duration = Math.round(diff / 24);
          } else if (diff > 720 && diff <= 8760) {
            durationType = "Months";
            duration = Math.round(diff / 720);
          } else {
            durationType = "Years";
            duration = Math.round(diff / 8760);
          }
        }
      }

      this.setDateAndDuration(durationType, duration, startDate, endDate);
    }
  };

  setDateAndDuration = (durationType, duration, startDate, endDate) => {
    const { project } = this.state;

    project.durationType = durationType;
    project.duration = duration;
    project.startDate = startDate;
    project.endDate = endDate;

    this.setState({ project });
  };

  handleCheckAll = (e) => {
    const showChecked = e.target.checked;
    let {
      allMembers,
      allMembersClone,
      activeParticipants,
      allLeadersChecked,
      allMembersChecked,
    } = this.state;

    if (activeParticipants === "leaders") {
      if (showChecked) {
        allMembers = allMembersClone.map((member) => {
          if (!member.isLeader && !member.isTeam) {
            member.isLeader = true;
          }
          return member;
        });
        allLeadersChecked = true;
      } else {
        allMembers = allMembersClone.map((member) => {
          if (member.isLeader) {
            member.isLeader = false;
          }
          return member;
        });
        allLeadersChecked = false;
      }
    } else if (activeParticipants === "members") {
      if (showChecked) {
        allMembers = allMembersClone.map((member) => {
          if (!member.isLeader && !member.isTeam) {
            member.isTeam = true;
          }
          return member;
        });
        allMembersChecked = true;
      } else {
        allMembers = allMembersClone.map((member) => {
          if (member.isTeam) {
            member.isTeam = false;
          }
          return member;
        });
        allMembersChecked = false;
      }
    }

    this.setState({
      allMembers,
      allMembersClone: allMembers,
      allLeadersChecked,
      allMembersChecked,
    });
  };

  render() {
    const {
      project,
      displayFileSrc,
      clientList,
      teamTemplates,
      nameFieldError,
      hasFilledDetails,
      hasAddedParticipants,
      activeParticipants,
      selectedTeam,
      isAdding,
      allMembers,
      account_type,
      canCreateProjectFolder,
      ongoingUploads,
      projectFilesDataList,
      allLeadersChecked,
      allMembersChecked,
      hasCreatedProject,
      searchParams,
    } = this.state;

    return (
      <div
        id="create_project"
        className="modal custom-modal fade"
        role="dialog"
        onClick={(e) => this.handleCloseModal(e, "wrapper")}
      >
        <div className="modal-dialog modal-dialog-centered project-modal">
          <div className="modal-content">
            <div className="m-h-between">
              <h5 className="modal-title">
                {hasCreatedProject ? "Create Task" : "Create Project"}
              </h5>
              <CloseIcon
                // data-toggle="modal"
                // data-target="#create_project"
                onClick={(e) => this.handleCloseModal(e)}
                className="p-cursor"
              />
            </div>
            <div className="modal-body" style={{ paddingTop: "10px" }}>
              {!hasFilledDetails && (
                <Form className="s-height">
                  <Form.Group as={Row}>
                    <Col sm={12}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Project Name
                      </Form.Label>
                      <Form.Label
                        className="text-danger"
                        style={{ fontSize: "14px" }}
                      ></Form.Label>
                      <Form.Control
                        placeholder="Project name"
                        value={project.name}
                        onChange={this.updateProjectName}
                        required
                        className="w-p-100"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Client
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={project.client || ""}
                        onChange={this.updateClient}
                        required
                        className="w-p-100"
                      >
                        <option>Select</option>
                        <option disabled>__________________</option>
                        {clientList.map((client, index) => {
                          return (
                            <option value={client._id} key={index}>
                              {client.companyName}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Start Date
                      </Form.Label>
                      <div className="datePicker">
                        <i
                          className="fa fa-calendar fa-xs calendar-icon-3"
                          aria-hidden="true"
                        ></i>
                        <Datepicker
                          value={project.startDate}
                          closeOnSelect={true}
                          onChange={(e) =>
                            this.handleDateAndDuration(e, "startDate")
                          }
                          // isValidDate={this.validationStartDate}
                          className="w-dt-100"
                        />
                      </div>
                    </Col>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        End Date
                      </Form.Label>
                      <div className="datePicker">
                        <i
                          className="fa fa-calendar fa-xs calendar-icon-3"
                          aria-hidden="true"
                        ></i>
                        <Datepicker
                          value={project.endDate}
                          closeOnSelect={true}
                          onChange={(e) =>
                            this.handleDateAndDuration(e, "endDate")
                          }
                          isValidDate={this.validationEndDate}
                          className="w-dt-100"
                        />
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>Cost</Form.Label>
                      <Form.Control
                        value={project.cost}
                        placeholder="$"
                        onChange={this.updateCost}
                        className="w-p-100"
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Duration
                      </Form.Label>
                      <Form.Control
                        value={project.duration}
                        onChange={(e) =>
                          this.handleDateAndDuration(e, "durationValue")
                        }
                        className="w-p-100"
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Duration Unit
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={project.durationType}
                        onChange={(e) =>
                          this.handleDateAndDuration(e, "durationType")
                        }
                        className="w-p-100"
                      >
                        <option value="">Select Unit</option>
                        <option value="Hours">Hours</option>
                        <option value="Days">Days</option>
                        <option value="Months">Months</option>
                        <option value="Years">Years</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Assign a Team to Project
                      </Form.Label>
                      <Form.Control
                        as="select"
                        onChange={this.updateTeamTemplateId}
                        className="w-p-100"
                        ref={this.selectTeamRef}
                        value={selectedTeam}
                      >
                        <option value="Select Team"> Select Team </option>
                        {teamTemplates.length > 0 &&
                          teamTemplates.map((teamTemplate, index) => {
                            return (
                              <option key={index} value={teamTemplate._id}>
                                {teamTemplate.name}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Col>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Project Status
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={project.status}
                        onChange={this.updateStatus}
                        className="w-p-100"
                      >
                        <option value="Created">Created</option>
                        <option value="Working">Working</option>
                        <option value="Completed">Completed</option>
                      </Form.Control>
                    </Col>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Priority
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={project.priority}
                        onChange={this.updatePriority}
                        className="w-p-100"
                      >
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={12}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Description
                      </Form.Label>
                      <textarea
                        className="form-control summernote fm-area sleek-scrollbar"
                        onChange={this.updateDescription}
                        value={project.description}
                        style={{ height: "50px" }}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={8}>
                      <div className="upload-project-layout">
                        <div className="d-flex align-items-center">
                          <div
                            className="dv-up-cn"
                            style={{ fontSize: "13px", color: "#808080" }}
                          >
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked={canCreateProjectFolder}
                              onChange={() =>
                                this.setState({
                                  canCreateProjectFolder:
                                    !canCreateProjectFolder,
                                })
                              }
                            />
                            <span
                              onClick={() =>
                                this.setState({
                                  canCreateProjectFolder:
                                    !canCreateProjectFolder,
                                })
                              }
                              style={{ cursor: "default" }}
                            >
                              Create Project Folder
                            </span>
                          </div>
                          <div
                            className="ml-4 dv-up-cn"
                            style={{ fontSize: "13px", color: "#808080" }}
                          >
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked={project.isPublic}
                              onChange={() => this.updateIsPublic()}
                            />
                            <span
                              onClick={() => this.updateIsPublic()}
                              style={{ cursor: "default" }}
                            >
                              Make Project public
                            </span>
                          </div>
                        </div>
                        {canCreateProjectFolder ? (
                          <div className="dv-up-lt">
                            <span onClick={() => this.handleSelectFiles()}>
                              <AttachFileIcon />
                            </span>
                            <div className="sleek-scrollbar">
                              <div className="list-of-files">
                                {project &&
                                  project.files &&
                                  project.files.map((file, index) => (
                                    <div key={index}>
                                      <CloseIcon
                                        onClick={() =>
                                          this.removeProjectFile(index)
                                        }
                                        className="p-cursor fl-close"
                                      />
                                      <FileIcon className="fl-file-icon" />
                                      <span
                                        className="fl-info"
                                        title={
                                          file.originalName +
                                          "." +
                                          file.extension
                                        }
                                      >
                                        {file.originalName}
                                      </span>
                                      <span className="fl-file-size">
                                        {file.size} {"|"} {file.extension}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <input
                          ref={this.projectFiles}
                          id="projectfiles"
                          type="file"
                          multiple
                          label="Upload File"
                          onChange={this.updateProjectFile}
                          style={{ display: "none" }}
                        />
                      </div>
                    </Col>
                    <Col sm={4}>
                      <span
                        className={`next-add-project p-cursor ${this.setClassNameStyle(
                          project.name
                        )}`}
                        onClick={() => this.handleNextLayout(1)}
                      >
                        Next
                      </span>
                    </Col>
                  </Form.Group>
                </Form>
              )}
              {hasFilledDetails && !hasAddedParticipants && (
                <div className="dv-participant">
                  <div
                    className="participants-tabs"
                    style={{ marginBottom: "10px" }}
                  >
                    <div
                      className={`${
                        activeParticipants === "leaders"
                          ? "active-participants"
                          : "inactive-participants"
                      }`}
                      onClick={() => this.handleActiveParticipants("leaders")}
                    >
                      Add Project Leaders
                    </div>
                    <div
                      className={`${
                        activeParticipants === "members"
                          ? "active-participants"
                          : "inactive-participants"
                      }`}
                      onClick={() => this.handleActiveParticipants("members")}
                    >
                      Add Project Members
                    </div>
                  </div>
                  <input
                    placeholder="Search to add"
                    className="form-control add-project-input"
                    type="text"
                    ref={this.searchBar}
                    onChange={(e) => this.handleSearch(e)}
                  />
                  {activeParticipants === "leaders" && (
                    <div className="participants-list sleek-scrollbar">
                      {allMembers && allMembers.length ? (
                        <div>
                          {allMembers.map((member, index) => (
                            <div
                              className="user-info"
                              key={index}
                              onClick={() =>
                                this.handleSelectedMember(member, "leader")
                              }
                            >
                              {member.photo ? (
                                <img
                                  className="user-desc"
                                  src={URLS.backendStatic + "/" + member.photo}
                                />
                              ) : (
                                <div className="user-desc user-init">
                                  {this.setEmployeeInitials(member)}
                                </div>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: "5px",
                                }}
                              >
                                <span>{this.setEmployeeName(member)}</span>
                                <span
                                  style={{ fontSize: "13px", color: "#888" }}
                                >
                                  {member.email}
                                </span>
                              </div>
                              {member.isTeam ? (
                                <span className="unchecked-box">
                                  <CheckIcon className="check-mark-icon" />
                                </span>
                              ) : (
                                <input
                                  type="checkbox"
                                  checked={
                                    member.isLeader ? member.isLeader : false
                                  }
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      ) : validateEmail(searchParams) ? (
                        <div className="add-new-email-container">
                          <div>
                            <span className="dy-text">
                              Do you want to add this email address?
                            </span>
                            "<span className="em-text">{searchParams}</span>"
                          </div>
                          <span className="yes-text">Yes please</span>
                        </div>
                      ) : (
                        <div className="none-found">None found</div>
                      )}
                    </div>
                  )}
                  {activeParticipants === "members" && (
                    <div className="participants-list sleek-scrollbar">
                      {allMembers && allMembers.length ? (
                        <div>
                          {allMembers.map((member, index) => (
                            <div
                              className="user-info"
                              key={index}
                              onClick={() =>
                                this.handleSelectedMember(member, "team")
                              }
                            >
                              {member.photo ? (
                                <img
                                  className="user-desc"
                                  src={URLS.backendStatic + "/" + member.photo}
                                />
                              ) : (
                                <div className="user-desc user-init">
                                  {this.setEmployeeInitials(member)}
                                </div>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: "5px",
                                }}
                              >
                                <span>{this.setEmployeeName(member)}</span>
                                <span
                                  style={{ fontSize: "13px", color: "#888" }}
                                >
                                  {member.email}
                                </span>
                              </div>
                              {member.isLeader ? (
                                <span className="unchecked-box">
                                  <CheckIcon className="check-mark-icon" />
                                </span>
                              ) : (
                                <input
                                  type="checkbox"
                                  checked={
                                    member.isTeam ? member.isTeam : false
                                  }
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      ) : validateEmail(searchParams) ? (
                        <div className="add-new-email-container">
                          <div>
                            <span className="dy-text">
                              Do you want to add this email address?
                            </span>
                            "<span className="em-text">{searchParams}</span>"
                          </div>
                          <span className="yes-text">Yes please</span>
                        </div>
                      ) : (
                        <div className="none-found">None found</div>
                      )}
                    </div>
                  )}
                  <div className="project-controls participants-project-controls">
                    <div>
                      <div className="d-flex">
                        <div className="check-all" style={{ height: "50px" }}>
                          {activeParticipants === "members" ? (
                            <>
                              <input
                                checked={allMembersChecked}
                                type="checkbox"
                                style={{ marginTop: "auto" }}
                                onChange={(e) => this.handleCheckAll(e)}
                              />
                              <span>Select All</span>
                            </>
                          ) : (
                            <>
                              <input
                                checked={allLeadersChecked}
                                type="checkbox"
                                style={{ marginTop: "auto" }}
                                onChange={(e) => this.handleCheckAll(e)}
                              />
                              <span>Select All</span>
                            </>
                          )}
                        </div>

                        <div className="project-dv-switch">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={(e) => this.handleSwitch(e)}
                            />
                            <span className="slider round"></span>
                          </label>
                          <span className="sh-only">Show only selected</span>
                        </div>
                      </div>

                      {activeParticipants === "members" ? (
                        <span
                          className="back"
                          onClick={() =>
                            this.handleActiveParticipants("leaders")
                          }
                        >
                          Back
                        </span>
                      ) : (
                        <span
                          className="back"
                          onClick={() => this.handleNextLayout(0)}
                        >
                          Back
                        </span>
                      )}
                      <span
                        className="cancel"
                        onClick={(e) => this.handleCloseModal(e)}
                      >
                        Cancel
                      </span>
                      {activeParticipants === "leaders" ? (
                        <span
                          className="next"
                          onClick={() =>
                            this.handleActiveParticipants("members")
                          }
                        >
                          Next
                        </span>
                      ) : (
                        <span
                          className={`next ${this.isAvailableStyle()}`}
                          onClick={() => this.handleCreateProject()}
                        >
                          Create
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {hasAddedParticipants && isAdding && (
                <div
                  className="d-flex flex-column"
                  style={{ height: "400px", paddingTop: "30px" }}
                >
                  {canCreateProjectFolder ? (
                    <>
                      <Spinner spinnerHeight="50px" />
                      <div
                        className="d-flex flex-column align-items-center"
                        style={{
                          marginTop: "20px",
                          height: "700px",
                          overflowY: "auto",
                        }}
                      >
                        {projectFilesDataList && projectFilesDataList.length ? (
                          <div className="d-flex flex-column align-items-center w-100">
                            <span
                              style={{
                                marginTop: "40px",
                                marginBottom: "40px",
                              }}
                            >
                              Uploading Project Files....
                            </span>
                            {ongoingUploads && ongoingUploads.length
                              ? ongoingUploads.map((ongoingUpload) => (
                                  <div
                                    className="d-flex col-md-12 justify-content-between"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <span
                                      className="col-md-7"
                                      style={{
                                        color: "#888",
                                        fontSize: "15px",
                                      }}
                                    >
                                      {ongoingUpload.name}
                                    </span>
                                    <span className="col-md-3">
                                      <Progress
                                        percent={Math.trunc(
                                          ongoingUpload.percentage
                                        )}
                                      />
                                    </span>
                                  </div>
                                ))
                              : null}
                          </div>
                        ) : (
                          <span>Creating Project....</span>
                        )}
                      </div>
                    </>
                  ) : hasCreatedProject ? (
                    <div className="d-flex flex-column create-task-layout align-items-center">
                      <h4 className="center m-b-50">
                        Do you want to create a Task ?
                      </h4>
                      <div className="d-flex justify-content-center w-100 m-t-50">
                        <span
                          onClick={() => this.handleModalClose()}
                          className="maybe-btn"
                        >
                          Maybe Later
                        </span>
                        <span
                          className="yes-btn"
                          onClick={() => this.handleCreateTask()}
                        >
                          Yes
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmployeeModal));
