import * as Actions from "../actions";

const initialState = {
  mode: "colors",
  color: "#E8F0F6",
  currentPhoto: "",
  customPhoto: "",
};

const boardSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SAVE_SETTINGS: {
      const settings = action.payload;
      if (!settings) return state;
      return {
        ...state,
        ...settings,
      };
    }
    case Actions.GET_SETTINGS: {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};

export default boardSettingsReducer;
