import React, { useState } from "react";
import { NotiCheck, NotiError } from "./Icons";
import InformationIcon from "mdi-react/InformationIcon";

export default function ToastUI({ type, message: { title, description } }) {
  return (
    <>
      <div className="d-flex align-items-center content">
        <div className="icon">
          {type === "success" && <NotiCheck />}
          {type === "error" && <NotiError className="text-danger" />}
          {type === "info" && <InformationIcon className="text-primary" />}
        </div>
        <div className="d-flex flex-column">
          <span className="title">{title}</span>
          <span className="message">{description}</span>
        </div>
      </div>
    </>
  );
}
