import { URLS } from "../components/urls";

export function getTeamsIBelongTo(token) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendTeams}/getTeamsIBelongTo`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => resolve(result))
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTeamsICreated(token) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendTeams}/getTeamsICreated`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => resolve(result))
      .catch((error) => {
        reject(error);
      });
  });
}

export function addTeam(token, team) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendTeams}/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ team }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateTeam(token, team, oldTeam) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendTeams}/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ team, oldTeam }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTeamById(token, teamId) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendTeams}/get/${teamId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDateWiseCompletedTasksByTeamId(token, teamId) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendTasks}/getDateWiseCompletedTasksByTeamId/${teamId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProjectsByTeamId(token, teamId) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendProjects}/getProjectsByTeamId/${teamId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTasksByTeamId(token, teamId) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendTasks}/getTasksByTeamId/${teamId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteTeam(teamId, token) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendTeams}/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ teamId }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
