import React from "react";
import { connect } from "react-redux";
import { URLS } from "../urls";

import "../../assets/scss/projects.scss";
import "../../assets/scss/global.scss";
import "../../assets/scss/tasks.scss";
import moment from "moment";
import { isProjectLeaderOrCreatedBy, showToast } from "../task-update/utils";

class AllTypeTasksClient extends React.Component {
  constructor(props) {
    super(props);

    let completedTask = [];
    let completedTaskCount = 0;
    let inCompletedTask = [];
    let inCompletedTaskCount = 0;
    let underReviewedTask = [];
    let underReviewedTaskCount = 0;
    let projectTasks = [];

    if (props.projectTasks.length) {
      completedTask = props.projectTasks.filter((task) => {
        return task.status === "COMPLETE";
      });
      completedTaskCount = completedTask.length;

      inCompletedTask = props.projectTasks.filter((task) => {
        return task.status === "INCOMPLETE";
      });

      inCompletedTaskCount = inCompletedTask.length;

      underReviewedTask = props.projectTasks.filter((task) => {
        return task.status === "UNDER_REVIEW";
      });
      underReviewedTaskCount = underReviewedTask.length;

      projectTasks = props.projectTasks;
    }

    this.state = {
      projectTasks: projectTasks,
      inCompletedTask: inCompletedTask,
      underReviewedTask: underReviewedTask,
      completedTask: completedTask,
      completedTaskCount: completedTaskCount,
      inCompletedTaskCount: inCompletedTaskCount,
      underReviewedTaskCount: underReviewedTaskCount,
      selectedTask: null,
    };
  }
  changeDateFormat = (date) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (date) {
      date = date.split("/");
      date = date[0] + " " + months[parseInt(date[1]) - 1] + " " + date[2];
    }
    return date;
  };
  // componentWillReceiveProps(props) {
  //   if (props.projectTasks.length) {
  //     const completedTask = props.projectTasks.filter((task) => {
  //       return task.status === "COMPLETE";
  //     });
  //     const completedTaskCount = completedTask.length;

  //     const inCompletedTask = props.projectTasks.filter((task) => {
  //       return task.status === "INCOMPLETE";
  //     });
  //     const inCompletedTaskCount = inCompletedTask.length;

  //     const underReviewedTask = props.projectTasks.filter((task) => {
  //       return task.status === "UNDER_REVIEW";
  //     });
  //     const underReviewedTaskCount = underReviewedTask.length;

  //     this.setState({
  //       completedTask,
  //       inCompletedTask,
  //       underReviewedTask,
  //       completedTaskCount,
  //       inCompletedTaskCount,
  //       underReviewedTaskCount,
  //       projectTasks: props.projectTasks,
  //     });
  //     this.setState({
  //       projectTasks: props.projectTasks,
  //     });
  //   }
  // }
  changeSelectedTask = (task) => (evt) => {
    this.setState({
      selectedTask: task,
    });
  };
  approveTask = (task) => (evt) => {
    const { token, user } = this.props;
    const userId = user;

    if (!task.assignedTo || task.assignedTo === "") {
      showToast({
        message: {
          title: "Task is not assigned to anyone. Can not change status.",
        },
        type: "error",
      });
      return;
    }

    if (
      !isProjectLeaderOrCreatedBy(this.props.project, user) &&
      !task.assignedTo.find((el) => el._id === user._id)
    ) {
      showToast({
        message: {
          title: "Unauthorized",
          description:
            "Only project leaders and assigned members can approve a task",
        },
        type: "error",
      });
      return;
    }

    task.status = "COMPLETE";
    task.approvedBy = user._id;
    task.approvedDate = new Date();
    task.completeDate = moment(new Date()).format("DD/MM/YYYY");

    fetch(`${URLS.backendTasks}/approveTask`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ task: task }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          if (result.task) {
            this.updateTaskLists(task);
            showToast({
              message: {
                title: `Task Approved`,
              },
              type: "success",
            });
          }
        } else {
          showToast({
            message: {
              title: `Unable to Approve`,
            },
            type: "error",
          });
        }
      });
  };
  updateTaskLists(task) {
    var {
      completedTask,
      underReviewedTask,
      completedTaskCount,
      underReviewedTaskCount,
    } = this.state;

    for (let i = 0; i < underReviewedTaskCount; i++) {
      let uTask = underReviewedTask[i];
      if (uTask._id === task._id) {
        underReviewedTask.splice(i, 1);
        break;
      }
    }
    underReviewedTaskCount -= 1;
    completedTaskCount += 1;
    completedTask.push(task);
    this.setState({
      completedTask,
      underReviewedTask,
      completedTaskCount,
      underReviewedTaskCount,
    });
  }

  changeStatusOfTask = (task) => (event) => {
    const token = this.props.token;
    const taskId = task._id;
    // const { activeProjectId, tasks } = this.state;
    task.status = task.status === "COMPLETE" ? "INCOMPLETE" : "COMPLETE";
    if (task.status === "COMPLETE")
      task.completeDate = moment(new Date()).format("DD/MM/YYYY");
    fetch(`${URLS.backendTasks}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        task: task,
        projectId: task.project,
        newProjectMembers: [],
        newTaskMembers: [],
        membersToRemove: [],
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        let { completedTask, inCompletedTask } = this.state;
        if (result.task.status === "COMPLETE") {
          completedTask.push(result.task);
          console.log("inCompletedTask", inCompletedTask);
          inCompletedTask = inCompletedTask.filter((item) => {
            console.log(item._id, result.task._id);
            return item._id != result.task._id;
          });
          console.log("inCompletedTask", inCompletedTask);
        } else {
          console.log("completedTask", completedTask);
          completedTask = completedTask.filter((item) => {
            console.log(item._id, result.task._id);
            return item._id != result.task._id;
          });
          console.log("completedTask", completedTask);
          inCompletedTask.push(result.task);
        }

        this.state.completedTask.forEach((projectTask) => {
          if (projectTask._id === taskId) {
            projectTask.status =
              projectTask.status === "COMPLETE" ? "COMPLETE" : "INCOMPLETE";
            showToast({
              message: {
                title: `Task marked as ${projectTask.status.toLowerCase()}`,
              },
              type: "success",
            });
          }
        });
        this.setState({
          // tasks: tasks,
          selectedTask: result.task,
          completedTask,
          inCompletedTask,
        });
        console.log("....result......", result);
      });
  };

  render() {
    const { projectTasks, inCompletedTask, completedTask, underReviewedTask } =
      this.state;
    return (
      <div className="tab-content">
        <div className="tab-pane show active" id="all_tasks">
          <div className="task-wrapper">
            <div className="task-list-container">
              <div className="task-list-body sleek-scrollbar">
                <ul id="task-list">
                  {projectTasks.length
                    ? projectTasks.map((projectTask, index) => {
                        return (
                          <TaskEntry
                            projectTask={projectTask}
                            changeSelectedTask={this.changeSelectedTask}
                            approveTask={this.approveTask}
                            changeStatusOfTask={this.changeStatusOfTask}
                            key={index}
                          />
                        );
                      })
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane" id="pending_tasks">
          <div className="task-wrapper">
            <div className="task-list-container">
              <div className="task-list-body sleek-scrollbar">
                <ul id="task-list">
                  {inCompletedTask.length
                    ? inCompletedTask.map((projectTask, index) => {
                        return (
                          <TaskEntry
                            key={index}
                            projectTask={projectTask}
                            changeSelectedTask={this.changeSelectedTask}
                            approveTask={this.approveTask}
                            changeStatusOfTask={this.changeStatusOfTask}
                          />
                        );
                      })
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane" id="under_reviewed_tasks">
          <div className="task-wrapper">
            <div className="task-list-container">
              <div className="task-list-body sleek-scrollbar">
                <ul id="task-list">
                  {underReviewedTask
                    ? underReviewedTask.map((projectTask, index) => {
                        return (
                          <TaskEntry
                            key={index}
                            projectTask={projectTask}
                            changeSelectedTask={this.changeSelectedTask}
                            approveTask={this.approveTask}
                            changeStatusOfTask={this.changeStatusOfTask}
                          />
                        );
                      })
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane" id="completed_tasks">
          <div className="task-wrapper">
            <div className="task-list-container">
              <div className="task-list-body sleek-scrollbar">
                <ul id="task-list">
                  {completedTask
                    ? completedTask.map((projectTask) => {
                        return (
                          <TaskEntry
                            projectTask={projectTask}
                            changeSelectedTask={this.changeSelectedTask}
                            approveTask={this.approveTask}
                            changeStatusOfTask={this.changeStatusOfTask}
                          />
                        );
                      })
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
class TaskEntry extends React.Component {
  changeDateFormat = (date) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (date) {
      date = date.split("/");
      date = date[0] + " " + months[parseInt(date[1]) - 1] + " " + date[2];
    }
    return date;
  };

  setEmployeeName = (employee) => {
    let name;
    if (employee.name && employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else if (employee.name) {
      name = employee.name;
    }
    return name;
  };

  setEmployeeInitials = (employee) => {
    let name;
    if (employee.name && employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else if (employee.name) {
      name = employee.name;
    } else {
      name = employee;
    }
    return name.charAt(0);
  };

  render() {
    const { projectTask } = this.props;
    return (
      <li
        className={`
          single-all-type-task mb-3
          ${
            projectTask.status === "COMPLETE"
              ? "completed task"
              : projectTask.status === "UNDER_REVIEW"
              ? "under-reviewed task "
              : "task"
          }`}
      >
        <div
          className="task-container s-a-t border-none"
          style={{ background: "none" }}
        >
          <span className="task-action-btn task-check">
            <span
              onClick={this.props.changeStatusOfTask(projectTask)}
              className={`action-circle large complete-btn ${
                projectTask.status === "COMPLETE"
                  ? "cmtask"
                  : projectTask.status === "UNDER_REVIEW"
                  ? "untask"
                  : "intask"
              }`}
            >
              <i className="material-icons">check</i>
            </span>
          </span>
          <span
            className="task-label"
            onClick={this.props.changeSelectedTask(projectTask)}
          >
            {projectTask.taskName}
          </span>
          <span
            className="task-action-btn"
            style={{ display: "block", fontSize: "0.875rem" }}
          >
            <span style={{ display: "inline-block", padding: "0 15px" }}>
              <span style={{ color: "#677888", fontSize: "0.75rem" }}>
                Start Date
              </span>{" "}
              <span
                style={{
                  display: "block",
                  fontWeight: 600,
                  fontSize: "0.875rem",
                  color: "#677888",
                }}
              >
                {this.changeDateFormat(projectTask.startDate) || "N/A"}
              </span>
            </span>
            <span style={{ display: "inline-block", padding: "0 15px" }}>
              <span style={{ color: "#677888", fontSize: "0.75rem" }}>
                Due Date
              </span>
              <span
                style={{
                  display: "block",
                  fontWeight: 600,
                  fontSize: "0.875rem",
                  color: "#677888",
                }}
              >
                {this.changeDateFormat(projectTask.dueDate) || "N/A"}
              </span>
            </span>
            {projectTask.status === "COMPLETE" ? (
              <span style={{ display: "inline-block", padding: "0 15px" }}>
                <span style={{ color: "#677888", fontSize: "0.75rem" }}>
                  Complete Date
                </span>{" "}
                <span
                  style={{
                    display: "block",
                    fontWeight: 600,
                    fontSize: "0.875rem",
                    color: "#677888",
                  }}
                >
                  {this.changeDateFormat(projectTask.completeDate)}
                </span>
              </span>
            ) : projectTask.status === "UNDER_REVIEW" ? (
              <span
                style={{
                  display: "inline-block",
                  padding: "0 15px",
                  verticalAlign: "super",
                }}
              >
                <button
                  style={{ minHeight: "30px", padding: "2px 12px" }}
                  className={`btn btn-primary ${
                    projectTask.status === "COMPLETE"
                      ? "cmtask"
                      : projectTask.status === "UNDER_REVIEW"
                      ? "untask"
                      : "intask"
                  } btn-sm`}
                  onClick={this.props.approveTask(projectTask)}
                >
                  Approve as Completed
                </button>
              </span>
            ) : (
              <span style={{ display: "inline-block", padding: "0 15px" }}>
                <span style={{ color: "#677888", fontSize: "0.75rem" }}>
                  Complete Date
                </span>{" "}
                <span
                  style={{
                    display: "block",
                    fontWeight: 600,
                    fontSize: "0.875rem",
                    color: "#677888",
                  }}
                >
                  N/A
                </span>
              </span>
            )}
          </span>
        </div>
        <div style={{ paddingLeft: "5rem", paddingBottom: "1rem" }}>
          <div className="task-img-collection">
            {projectTask.assignedTo &&
              projectTask.assignedTo.map((employee, index) => {
                if (index < 3) {
                  return (
                    <div className="avatars" key={index}>
                      <span
                        className="avatar"
                        title={this.setEmployeeName(employee)}
                      >
                        {employee.photo ? (
                          <img
                            src={URLS.backendStatic + "/" + employee.photo}
                          />
                        ) : (
                          <span className="av-alternative">
                            {this.setEmployeeInitials(employee)}
                          </span>
                        )}
                      </span>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </li>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    user: state.userReducer.user,
  };
};

export default connect(mapStateToProps, null)(AllTypeTasksClient);
