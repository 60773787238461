import React, { useRef, useEffect, useMemo } from "react";
import styled from "styled-components";
import CloseIcon from "mdi-react/CloseIcon";
import eventBus from "../../helper/EventBus";
import { getQueryParams } from "../task-update/utils";
import { connect } from "react-redux";
import { toggleRightSideBar } from "../../store/actions";

function Addons({
  type, // notification, chat, note
  notificationIncrement,
  onClose,
  toggleRightSideBar,
  ...props
}) {
  const url = process.env.REACT_APP_MESSENGER_FRONTEND + "/m/chats";
  /**@type { React.MutableRefObject<HTMLIFrameElement>} */
  const iframeRef = useRef(null);
  const COLLABORATION_FRONTEND = process.env.REACT_APP_MESSENGER_FRONTEND;

  const openChat = (userId) => {
    eventBus.dispatch("handleRightSideBarDisplay", "chat");

    setTimeout(() => {
      const iframe = iframeRef.current;
      // console.log("iframe", iframe);
      if (iframe && iframe.contentWindow) {
        // console.log("ready");
        iframe.contentWindow.postMessage(
          {
            type: "open-chat-external",
            data: {
              serverOrigin: process.env.REACT_APP_FRONTEND_URL,
              chatId: userId,
            },
          },
          "*"
        );
      }
    }, 500);
  };

  const openGroup = (groupId) => {
    eventBus.dispatch("handleRightSideBarDisplay", "chat");

    setTimeout(() => {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            type: "open-group",
            data: {
              serverOrigin: process.env.REACT_APP_FRONTEND_URL,
              chatId: groupId,
            },
          },
          "*"
        );
      }
    }, 500);
  };

  const groupCreated = (data) => {
    console.log(data);
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "group-created",
          data: {
            serverOrigin: process.env.REACT_APP_FRONTEND_URL,
            ...data,
          },
        },
        "*"
      );
    }
  };

  eventBus.useCustomEventListener("open-chat", openChat);
  eventBus.useCustomEventListener("open-group", openGroup);
  eventBus.useCustomEventListener("group-created", groupCreated);

  function switchView() {
    const iframe = iframeRef.current;
    // console.log("iframe", iframe, type);
    /*if (iframe) {
      iframe.onload = () => {
        alert("type");
        iframe.style.opacity = "1";
        if (iframe.contentWindow) {
          iframe.contentWindow.postMessage(
            {
              type: "ready",
              view: type,
              data: {
                serverOrigin: process.env.REACT_APP_FRONTEND_URL,
              },
            },
            "*"
          );
        }
      };
    }*/

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "ready",
          view: type,
          data: {
            serverOrigin: process.env.REACT_APP_FRONTEND_URL,
          },
        },
        "*"
      );
    }
  }

  useEffect(() => {
    switchView();
  }, [type]);
  useEffect(() => {
    window.addEventListener(
      "message",
      (ev) => {
        if (ev.data) {
          if (ev.data.kind === "LINK") {
            const pathString = `/${window.location.pathname.split("/")[1]}`;
            // console.log(pathString);
            // console.log("new link", ev.data.data);
            const params = getQueryParams(ev.data.data);

            if (
              (params?.projectId || params?.taskId) &&
              pathString === "/tasks-board"
            ) {
              // console.log("link-to-nav", params);
              eventBus.dispatch("loadTask", params);
              onClose();
            } else {
              window.location.href = ev.data.data;
            }
          } else if (ev.data.isReady) {
            switchView();
          } else if (ev.data.kind === "CHAT") {
            notificationIncrement(ev.data.data, "chat");
          } else if (ev.data.kind === "NOTIFICATION") {
            console.log(ev.data.data);
            notificationIncrement(ev.data.data, "notification");
          } else if (ev.data.kind === "INCOMING_CALL") {
            toggleRightSideBar("chat");
          }
        }
      },
      false
    );
  }, []);
  return (
    <Wrapper {...props}>
      <header className="embed-header p-3">
        <p className="text-capitalize">{type}</p>
        <div>
          {type === "note" ? (
            <a
              href={`${COLLABORATION_FRONTEND}/notes`}
              className="all-notes text-primary"
            >
              All Notes
            </a>
          ) : null}
          <CloseIcon onClick={() => onClose()} className="close-icon" />
        </div>
      </header>
      <div className="w-full" style={{ height: "calc(100% - 4.5rem)" }}>
        <iframe
          title="..."
          ref={iframeRef}
          className="w-full h-full"
          src={url}
          frameBorder="0"
          onLoad={() => switchView()}
        ></iframe>
      </div>
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleRightSideBar: (value) => dispatch(toggleRightSideBar(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Addons);

const Wrapper = styled.div`
  z-index: 10080;
  width: 24.4375rem;
  max-width: 100vw;
  height: 100%;
  top: 0;
  right: 0;
  position: fixed;
  box-shadow: -10px 4px 61px 0px #757f8838;
  background-color: #fff;
  transition: transform 300ms ease-in;

  .embed-header {
    height: 4.5rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    color: #888;
    gap: 1rem;
    width: 100%;

    p {
      flex-grow: 1;
      font-size: 1.125rem;
      font-weight: 600;
      margin: 0;
      color: #111111;
    }

    .close-icon {
      height: 25px;
      width: 25px;
      margin-left: 15px;
      background-color: #f2f7fc;
      border-radius: 50%;
      padding: 0.25rem;
      cursor: pointer;
    }
  }
  .w-full {
    width: 100%;
  }
  .h-full {
    height: 100%;
    overflow-y: auto;
  }
  .all-notes {
    color: #000;
    margin: 0 10px 0 auto;
    font-size: 13px;
    font-weight: 600;
  }
`;
