import "./wdyr";
import React, { Component } from "react";
import { Provider } from "react-redux";
import store from "./store";
import Main from "./Main.js";
import { socketIO } from "./sockets";
import UploadProgress from "./UploadProgress";
import { watchStore } from "./store/watch";
import ViewPdf from "./ViewPdf";
import { Helmet } from "react-helmet";

class App extends Component {
  async componentDidMount() {
    try {
      this.setDocHeight();
      window.addEventListener("resize", () => {
        this.setDocHeight();
      });
      window.addEventListener("onrientationchange", () => {
        this.setDocHeight();
      });
      await socketIO();
      // watchStore(store);
    } catch (err) {
      console.log("app error", err);
    }
  }

  setDocHeight() {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    );
  }

  render() {
    // if (process.env.REACT_APP_ENV === 'production') {
    // 	console.log = console.warn = console.error = function no_console() {};
    // }
    return (
      <Provider store={store}>
        <Main />
        <UploadProgress />
        <ViewPdf />
      </Provider>
    );
  }
}

export default App;
