import { TodoList } from "./TodoList";

import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import DialogModal from "../modals/DialogModal";
import { socket } from "../../../sockets";
import { allTaskMembers } from "../utils";
import { formatSlashedDate, showToast, styleHelper } from "../utils";
import { createSchedule, fetchSchedules, deleteSchedule } from '../../../services/crmServices'
import ScheduleCard from './ScheduleCard'
import "../../../assets/scss/schedule.scss";

function CustomerScheduleList({ token, selectedTask, usersList }) {
    const [showAddForm, setShowAddForm] = useState(false)
    const [scheduleText, setScheduleText] = useState('')
    const [scheduleType, setScheduleType] = useState('')
    const [scheduleAddress, setScheduleAddress] = useState('')
    const [scheduleDate, setScheduleDate] = useState('')
    const [schedules, setSchedules] = useState([])

    useEffect(() => {
        fetchData();
    }, [])

    async function fetchData() {
        let data = await fetchSchedules(token, { taskId: selectedTask._id })
        setSchedules([...data.data])
    }

    const onSubmit = async (e) => {
        const payload = {
            scheduleName: scheduleText,
            scheduleType: scheduleType,
            dueDate: scheduleDate,
            meetingAddress: scheduleAddress,
            taskId: selectedTask._id,
            isDue: false
        };

        setShowAddForm(false)
        setSchedules((oldValue) => {
            setScheduleText('')
            setScheduleType('')
            setScheduleDate('')
            setScheduleAddress('')
            showToast({
                message: {
                    title: "Schedule created successfully...",
                },
                type: "success",
            });
            return [...oldValue, payload]
        });

        let res = await createSchedule(token, payload)
        console.log(res)
        e.preventDefault()
    }

    const handleOnChange = (e) => {
        setScheduleText(e.target.value)
    }

    const handleSelectChange = (e) => {
        setScheduleType(e.target.value)
    }

    const handleDateChange = (e) => {
        setScheduleDate(e.target.value)
    }

    const handleAddressChange = (e) => {
        setScheduleAddress(e.target.value)
    }

    const handleDeleteSchedule = async (schedule, index) => {
        // let res = await deleteSchedule(token, schedule._id)
        if (
            await DialogModal({
                title: "Delete",
                description: `Are you sure you want to delete ?`,
                type: "warning",
            })
        ) {
            setSchedules((oldValue) => {
                oldValue = oldValue.filter((el, i) => i !== index);
                return [...oldValue];
            });

            const res = await deleteSchedule(token, { id: schedule._id })
            if (res.success) {
                showToast({
                    message: {
                        title: "Schedule deleted successfully...",
                    },
                    type: "success",
                });
            }
        }
    }

    return (
        <>
            <div className="todo">
                {!schedules.length ?
                    (<p className="title mb-2">Create schedule for your follow-up</p>) : (
                        <p className="title mb-2">Schedules</p>)}
                {schedules.map((el, index) => (
                    <div key={index}>
                        <ScheduleCard
                            index={index}
                            schedule={el}
                            reloadSchedule={fetchData}
                            handleDeleteSchedule={handleDeleteSchedule}
                        />
                    </div>
                ))}
                {!showAddForm ? (<div className="mt-2 add-todo">
                    <button
                        onClick={() => {
                            setShowAddForm(true)
                        }}
                        className="btn btn-outline-primary border-0"
                    >
                        + Create a Schedule
                    </button>
                </div>) : (<form
                    key={schedules?.length}
                    className="mt-3"
                    onSubmit={(e) => onSubmit(e)}
                >
                    <input
                        placeholder="Schedule title"
                        value={scheduleText}
                        autoComplete="off"
                        autoFocus
                        required
                        onChange={(e) => handleOnChange(e)}
                        spellCheck={false}
                        className="form-control mb-3"
                    />
                    <select
                        required
                        onChange={(e) => handleSelectChange(e)}
                        className="form-control mb-3"
                        value={scheduleType}
                    >
                        <option value={''}>Select Schedule Type</option>
                        <option value={'Call'}>Call</option>
                        <option value={'Physical Meeting'}>Physical Meeting</option>
                        <option value={'Online Meeting'}>Online Meeting</option>
                        <option value={'Email'}>Email</option>
                    </select>
                    {(scheduleType === 'Physical Meeting' || scheduleType === 'Online Meeting') && <input
                        placeholder="Enter meeting address"
                        value={scheduleAddress}
                        autoComplete="off"
                        autoFocus
                        required
                        onChange={(e) => handleAddressChange(e)}
                        spellCheck={false}
                        type="=text"
                        className="form-control mb-3"
                    />}
                    <input
                        placeholder="Select Date"
                        value={scheduleDate}
                        autoComplete="off"
                        autoFocus
                        required
                        onChange={(e) => handleDateChange(e)}
                        spellCheck={false}
                        type="datetime-local"
                        className="form-control"
                    />
                    <div className="d-flex justify-content-between mt-3">
                        <div>
                            <button className="btn btn-primary mr-2 py-2">Save</button>
                            <button
                                onClick={() => setShowAddForm(false)}
                                className="btn btn-light text-dark py-2"
                                type="button"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>)}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.userReducer.token,
        user: state.userReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerScheduleList);
