import Client from "@akumzy/dedupe-upload";

const fileWatcherServer = process.env.REACT_APP_FILE_WATCHER_SOCKET;

const client = new Client(fileWatcherServer);

client.addEventListener("socket-error", (err) => {
  // console.log("upload socket error -", err);
});

export default client;
