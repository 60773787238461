export const SAVE_SETTINGS = "SAVE_SETTINGS";
export const GET_SETTINGS = "GET_SETTINGS";

export function saveSettings(settings) {
  return {
    type: SAVE_SETTINGS,
    payload: settings,
  }
}

export function getSettings(settings) {
  return {
    type: GET_SETTINGS,
    payload: settings,
  }
}

