import { TodoList } from "./TodoList";

import React, { useEffect, useState } from "react";
import DialogModal from "../modals/DialogModal";
import { socket } from "../../../sockets";
import { allTaskMembers } from "../utils";

export function TaskTodoList({
  selectedTask,
  user,
  canMarkTodo,
  moveTaskToList,
  list,
  usersList,
}) {
  const [todos, setTodos] = useState([]);

  useEffect(() => {
    getTodos();
  }, []);

  const getTodos = async () => {
    socket.emit("fx:todo-all", { taskId: selectedTask._id }, (data) => {
      if (data?.todos) setTodos([...data.todos]);
    });
  };

  const selectAll = async (checked) => {
    setTodos((oldValue) => {
      oldValue = oldValue.map((el, i) => ({ ...el, checked }));
      return [...oldValue];
    });
    socket.emit(
      "fx:todo-select-all",
      { taskId: selectedTask._id, checked },
      (data) => {}
    );

    if (
      isFirstCheck() &&
      selectedTask.status !== "INCOMPLETE" &&
      Boolean(list.find((el) => el.status === "INCOMPLETE"))
    ) {
      if (
        await DialogModal({
          title: "Move Task to In Progress ?",
          description: ``,
          type: "info",
          confirmMessage: "Yes",
        })
      ) {
        moveTaskToList({
          taskToMove: {
            ...selectedTask,
            status: "INCOMPLETE",
          },
          oldStatus: selectedTask.status,
          newStatus: "INCOMPLETE",
          saveToServer: true,
        });
      }
    }
  };

  const removeTodo = async (index) => {
    if (
      await DialogModal({
        title: "Delete",
        description: `Are you sure you want to delete ?`,
        type: "warning",
      })
    ) {
      setTodos((oldValue) => {
        oldValue = oldValue.filter((el, i) => i !== index);
        return [...oldValue];
      });
      socket.emit(
        "fx:todo-delete",
        { taskId: selectedTask._id, todo: todos[index] },
        (data) => {}
      );
    }
  };

  const isFirstCheck = () => {
    return todos.every((el) => !el.checked);
  };

  const checkTodo = async (index, checked) => {
    // if is the first check ask to move to in progress

    const payload = {
      ...todos[index],
      checked,
      modifiedBy: user._id,
    };

    setTodos((oldValue) => {
      oldValue[index].checked = checked;
      return [...oldValue];
    });

    socket.emit(
      "fx:todo-edit",
      { taskId: selectedTask._id, todo: payload },
      (data) => {}
    );

    if (
      isFirstCheck() &&
      selectedTask.status !== "INCOMPLETE" &&
      Boolean(list.find((el) => el.status === "INCOMPLETE"))
    ) {
      if (
        await DialogModal({
          title: "Move Task to In Progress ?",
          description: ``,
          type: "info",
          confirmMessage: "Yes",
        })
      ) {
        moveTaskToList({
          taskToMove: {
            ...selectedTask,
            status: "INCOMPLETE",
          },
          oldStatus: selectedTask.status,
          newStatus: "INCOMPLETE",
          saveToServer: true,
        });
      }
    }
  };

  const addTodo = (value, mentionedUsers = []) => {
    const payload = {
      text: value,
      modifiedBy: user._id,
      checked: false,
    };

    setTodos((oldValue) => [...oldValue, payload]);

    socket.emit(
      "fx:todo-add",
      { taskId: selectedTask._id, todo: payload, mentionedUsers },
      (data) => {}
    );
  };

  const editTodo = (index, text) => {
    const payload = {
      ...todos[index],
      text,
    };

    setTodos((oldValue) => {
      oldValue[index].text = text;
      return [...oldValue];
    });

    socket.emit(
      "fx:todo-edit",
      { taskId: selectedTask._id, todo: payload },
      (data) => {}
    );
  };
  return (
    <TodoList
      todos={todos}
      addTodo={addTodo}
      checkTodo={checkTodo}
      removeTodo={removeTodo}
      showProgress={true}
      selectAll={selectAll}
      editTodo={editTodo}
      disabled={!canMarkTodo()}
      usersList={usersList}
      user={user}
    />
  );
}
