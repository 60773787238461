import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as FreeDrag from "react-draggable";
import arrayMove from "array-move";
import cloneDeep from "lodash/cloneDeep";
import shortid from "shortid";

import image1 from "../../assets/board-default-images/img1.svg";
import image2 from "../../assets/board-default-images/img2.svg";
import image3 from "../../assets/board-default-images/img3.svg";
import image4 from "../../assets/board-default-images/img4.svg";
import image5 from "../../assets/board-default-images/img5.svg";
import image6 from "../../assets/board-default-images/img6.svg";
import image7 from "../../assets/board-default-images/img7.svg";
import image8 from "../../assets/board-default-images/img8.svg";
import image9 from "../../assets/board-default-images/img9.svg";
import image10 from "../../assets/board-default-images/img10.svg";
import image11 from "../../assets/board-default-images/img11.svg";

import "react-sweet-progress/lib/style.css";

import MoreIcon from "mdi-react/DotsHorizontalIcon";
import BackIcon from "mdi-react/HomeAccountIcon";
import MessageIcon from "mdi-react/MessageIcon";
import AttachmentIcon from "mdi-react/PaperclipIcon";
import PlusIcon from "mdi-react/PlusIcon";
import CheckIcon from "mdi-react/CheckIcon";
import AddPersonIcon from "mdi-react/AccountPlusOutlineIcon";
import DeleteIcon from "mdi-react/DeleteOutlineIcon";
import DeleteFullIcon from "mdi-react/DeleteIcon";
import SendIcon from "mdi-react/SendIcon";
import CloseIcon from "mdi-react/CloseIcon";
import FileIcon from "mdi-react/FileIcon";
import FilePdfIcon from "mdi-react/FilePdfBoxIcon";
import FileWordIcon from "mdi-react/FileWordBoxIcon";
import FileExcelIcon from "mdi-react/FileExcelBoxIcon";
import FolderIcon from "mdi-react/FolderIcon";
import LoadIcon from "mdi-react/ClockTimeThreeIcon";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import AdjustIcon from "mdi-react/AdjustIcon";
import EmptyIcon from "mdi-react/TimerSandEmptyIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import EditIcon from "mdi-react/PencilIcon";
import PaletteIcon from "mdi-react/PaletteIcon";
import ClipboardTextOutline from "mdi-react/ClipboardTextOutlineIcon";
import SwapHorizontalBold from "mdi-react/SwapHorizontalBoldIcon";
import CogIcon from "mdi-react/CogIcon";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "../../assets/scss/tasks.scss";
import "../../assets/scss/sidebar.scss";

import Topbar from "../topbar/Topbar";
import AssignTaskModal from "./AssignTaskModal";
import EditTaskModal from "./EditTaskModal";
import AddProjectModal from "../projects/AddProjectModal";
import EditProjectModal from "../projects/EditProjectModal";
import DeleteProjectModal from "../projects/DeleteProjectModal";
import AddTaskModal from "./AddTaskModal";
import FileGallery from "./FileGallery";
import Spinner from "../global/Spinner";
import Toast from "../global/Toast";
import BoardSettingsContainer from "./board-settings/BoardSettingsContainer";
import { getBoardSettings } from "../../services/boardSettingsService";

import { ColorPicker } from "./ColorPicker";

import {
  getProjectsIBelongTo,
  deleteProject,
} from "../../services/projectServices";
import {
  getTaskById,
  getTasksByProjectId,
  getProjectsAndTasksIBelongTo,
  appendUploadedFileId,
} from "../../services/taskServices";
import { getClients } from "../../services/clientServices";
import {
  getTeamsICreated,
  getTeamsIBelongTo,
} from "../../services/teamServices";
import { getCompanyUsers } from "../../services/userServices";
import { populateUser } from "../../store/actions/userActions";
import { saveSettings } from "../../store/actions/boardSettingsActions";
import { getColorCodes } from "../../services/boardSettingsService";

import { URLS } from "../urls";

import { socket } from "../../sockets";
import uploadClient from "../../helper/uploadClient";
import { updateFileName } from "../../services/userServices";
import { removeModalElements } from "../../utils";

import "../../../src/assets/css/bootstrap-datetimepicker.min.css";
import { microsoftOfficeFileExtensions } from "../task-update/utils";

const allMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const SortableItem = ({
  project,
  projectIndex,
  projectTask,
  getProjectTaskClassname,
  setEmployeeName,
  setEmployeeInitials,
  hasProjectRights,
  handleChangeProject,
  isAssignedTo,
  handleModal,
  handleUpdateTaskStatus,
  isCreatedBy,
  changeSelectedTask,
  handleEditTask,
  handleSaveEditedTask,
  showColorPicker,
  isBlack,
  hasOpacity,
}) => {
  projectTask.color = projectTask.color ? projectTask.color : "#ffffff";
  const isGrey = ["#ffffff", "beige", "#fff"];
  const iconColor = isGrey.includes(projectTask.color)
    ? "#a0a0a0"
    : isBlack.includes(projectTask.color)
    ? "#000000"
    : "#ffffff";

  return (
    <>
      <div
        className={`task-2-card ${hasOpacity ? "cloned-task-2-card" : ""}`}
        data-id={project._id}
        style={
          isBlack.includes(projectTask.color)
            ? {
                backgroundColor: projectTask.color,
                color: "#000000",
              }
            : isGrey.includes(projectTask.color)
            ? {
                backgroundColor: projectTask.color,
              }
            : {
                backgroundColor: projectTask.color,
                color: iconColor,
              }
        }
      >
        <div className="task-2-progress-bars">
          {projectTask.status === "AWAITING" ? (
            <span className="task-2-awaiting sm-width" title="Created"></span>
          ) : projectTask.status === "INCOMPLETE" ? (
            <>
              <span className="task-2-awaiting sm-width" title="Created"></span>
              <span
                className="task-2-incomplete sm-width"
                title="Marked as complete"
              ></span>
            </>
          ) : projectTask.status === "UNDER_REVIEW" ? (
            <>
              <span className="task-2-awaiting sm-width" title="Created"></span>
              <span
                className="task-2-incomplete sm-width"
                title="Marked as complete"
              ></span>
              <span
                className="task-2-under-review sm-width"
                title="Under Review"
              ></span>
            </>
          ) : projectTask.status === "COMPLETE" ? (
            <>
              <span className="task-2-awaiting sm-width" title="Created"></span>
              <span
                className="task-2-incomplete sm-width"
                title="Marked as complete"
              ></span>
              <span
                className="task-2-under-review sm-width"
                title="Under Review"
              ></span>
              <span
                className="task-2-complete sm-width"
                title="Completed"
              ></span>
            </>
          ) : null}
        </div>
        <div className="task-2-card-name">
          {projectTask.isTaskNameEditable ? (
            <span
              class="input"
              role="textbox"
              contentEditable="true"
              suppressContentEditableWarning={true}
              className="task-2-card-name-textarea"
              onKeyDown={(e) =>
                handleSaveEditedTask(e, "taskName", projectTask._id)
              }
            >
              {projectTask.taskName}
            </span>
          ) : (
            <span
              onClick={(e) => {
                changeSelectedTask(e, projectTask, true);
              }}
              className="task-2-card-name-text"
            >
              {/* Used new String() to prevent taskName from updating when user didn't hit Enter key in contentEditable span*/}
              {projectTask.taskName ? new String(projectTask.taskName) : ""}
            </span>
          )}
          {projectTask.taskName &&
          !projectTask.isTaskNameEditable &&
          hasProjectRights(project) ? (
            <EditIcon
              className="task-2-card-name-icon"
              onClick={() => handleEditTask(projectTask, "taskName")}
            />
          ) : null}
          {projectTask.taskName &&
          projectTask.isTaskNameEditable &&
          hasProjectRights(project) ? (
            <CloseIcon
              className="task-2-card-name-icon"
              onClick={() => handleEditTask(projectTask, "taskName", "close")}
            />
          ) : null}
        </div>

        <div className="task-2-card-detail">
          {projectTask.isTaskDetailEditable ? (
            <span
              class="input"
              role="textbox"
              contentEditable="true"
              suppressContentEditableWarning={true}
              className="task-2-card-detail-textarea"
              onKeyDown={(e) =>
                handleSaveEditedTask(e, "taskDetail", projectTask._id)
              }
            >
              {projectTask.taskDetail}
            </span>
          ) : (
            <span
              onClick={(e) => {
                changeSelectedTask(e, projectTask, true);
              }}
              className="task-2-card-detail-text"
            >
              {/* Used new String() to prevent taskDetail from updating when user didn't hit Enter key in contentEditable span*/}
              {projectTask.taskDetail ? new String(projectTask.taskDetail) : ""}
            </span>
          )}
          {!projectTask.isTaskDetailEditable && hasProjectRights(project) ? (
            <EditIcon
              className="task-2-card-detail-icon"
              onClick={() => handleEditTask(projectTask, "taskDetail")}
            />
          ) : null}
          {projectTask.isTaskDetailEditable && hasProjectRights(project) ? (
            <CloseIcon
              className="task-2-card-detail-icon"
              onClick={() => handleEditTask(projectTask, "taskDetail", "close")}
            />
          ) : null}
        </div>
        <div className="task-2-bottom">
          <div>
            {projectTask.assignedTo.map((employee, index) => {
              if (index < 3) {
                return (
                  <div className="avatars" key={index}>
                    <span className="avatar" title={setEmployeeName(employee)}>
                      {employee.photo ? (
                        <img src={URLS.backendStatic + "/" + employee.photo} />
                      ) : (
                        <span className="av-alternative">
                          {setEmployeeInitials(employee)}
                        </span>
                      )}
                    </span>
                  </div>
                );
              }
            })}
            {hasProjectRights(project) ? (
              <div
                className="avatars"
                // data-toggle="modal"
                // data-target="#assignee"
                onClick={async () => {
                  await handleChangeProject(project, projectTask);
                  handleModal("assignTask");
                }}
              >
                <span className="avatar">
                  <span className="add-button-wrapper">
                    <PlusIcon className="add-button p-cursor" />
                  </span>
                </span>
              </div>
            ) : null}
          </div>
          <div>
            <span
              className="task-2-icon"
              style={{ marginRight: "5px" }}
              // data-toggle="modal"
              // data-target="#edit_task_modal"
              onClick={async () => {
                await handleChangeProject(project, projectTask);
                handleModal("editTask");
              }}
            >
              <EditIcon
                style={{ color: iconColor, height: "17px", width: "17px" }}
              />
            </span>
            <span
              className="task-2-icon"
              style={{ marginRight: "5px" }}
              data-toggle="modal"
              data-target="#delete_task"
              onClick={async () => {
                await handleChangeProject(project, projectTask);
                handleModal("deleteTask");
              }}
            >
              <DeleteFullIcon
                style={{ color: iconColor, height: "17px", width: "17px" }}
              />
            </span>
            {isCreatedBy(projectTask) && (
              <span title="Pick a color" style={{ marginRight: "5px" }}>
                <PaletteIcon
                  className="circle-icon"
                  style={{ color: iconColor }}
                  onClick={(e) =>
                    showColorPicker(
                      e,
                      projectTask._id,
                      project._id,
                      projectTask.color
                    )
                  }
                />
              </span>
            )}
            {projectTask.status === "AWAITING"
              ? isAssignedTo(projectTask) && (
                  <span title="Accept">
                    <AdjustIcon
                      className="circle-icon ch-awaiting-btn"
                      onClick={() =>
                        handleUpdateTaskStatus(
                          projectTask,
                          "task-board-view",
                          projectIndex,
                          "Task accepted"
                        )
                      }
                      style={{
                        cursor: "pointer",
                        color: iconColor,
                      }}
                    />
                  </span>
                )
              : null}
            {projectTask.status === "INCOMPLETE"
              ? isAssignedTo(projectTask) && (
                  <span title="Mark completed">
                    <CheckCircleIcon
                      className="circle-icon ch-mark-completed-btn"
                      onClick={() =>
                        handleUpdateTaskStatus(
                          projectTask,
                          "task-board-view",
                          projectIndex,
                          "Task completed"
                        )
                      }
                    />
                  </span>
                )
              : null}
            {projectTask.status === "UNDER_REVIEW" ? (
              isCreatedBy(projectTask) ? (
                <span title="Approve as Completed">
                  <CheckCircleIcon
                    className="circle-icon ch-approve-as-complete-btn"
                    data-toggle="modal"
                    data-target="#handle_approve_task"
                    onClick={(e) => changeSelectedTask(e, projectTask)}
                  />
                </span>
              ) : isAssignedTo(projectTask) ? (
                <span title="Unmark">
                  <CheckCircleIcon
                    className="circle-icon ch-mark-completed-btn"
                    onClick={() =>
                      handleUpdateTaskStatus(
                        null,
                        "task-board-view",
                        projectIndex,
                        "Task unmarked"
                      )
                    }
                  />
                </span>
              ) : null
            ) : null}
            {projectTask.status === "COMPLETE" ? (
              <span title="Completed">
                <CheckCircleIcon className="circle-icon ch-complete-btn" />
              </span>
            ) : null}
            <div
              onClick={(e) => changeSelectedTask(e, projectTask, true)}
              title="Thread"
              style={{ cursor: "pointer", color: iconColor }}
            >
              <span className="ml-1" style={{ marginTop: "2px" }}>
                {projectTask.comments && projectTask.comments.length}
              </span>
              <MessageIcon
                className="task-2-icon"
                style={{ color: iconColor }}
              />
            </div>

            {/* <div>
				<span>
					{this.getNoOfFiles(projectTask)}
				</span>
				<AttachmentIcon className="task-2-icon" />
				</div> */}
          </div>
        </div>
      </div>
      {<div style={{ height: "20px" }}></div>}
    </>
  );
};

/*const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});
*/

class Tasks extends Component {
  constructor(props) {
    super(props);

    this.messagesEndRef = createRef();
    this.inputEndRef = createRef();
    this.taskTypeSelectRef = createRef();
    this.taskBySelectRef = createRef();
    this.selectAttachmentRef = createRef();
    this.searchFieldRef = createRef();
    this.wrapperRef = createRef();

    const watcherServerUrl = process.env.REACT_APP_FILE_WATCHER_SERVER;
    const cloudDriveFrontEnd = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;
    const collaborationFrontEndUrl = process.env.REACT_APP_FRONTEND_URL;

    this.state = {
      employee: null,
      newTask: {
        taskName: "",
        taskDetail: "",
        startDate: moment(new Date()).format("DD/MM/YYYY"),
        dueDate: "",
      },
      newComment: {
        employeeId: null,
        employeeImage: "",
        employeeName: "",
        date: "",
        time: "",
        comment: "",
        files: [],
      },
      taskFilesDataList: [],
      fileObject: null,
      projects: [],
      projectsClone: [],
      activeProjectId: "",
      tasks: {},
      userWiseTasks: {},
      selectedTask: null,
      teams: {},
      clientList: [],
      employeesList: [],
      leadersForNotification: {},
      isAddProjectModalOpen: false,
      isEditProjectModalOpen: false,
      isDeleteProjectModalOpen: false,
      isAddTaskModalOpen: false,
      isEditTaskModalOpen: false,
      isAssignTaskModalOpen: false,
      typeOfView: 0,
      isLoading: true,
      showTaskBy: "tasksByProject",
      isTaskChat2: false,
      watcherServerUrl,
      cloudDriveFrontEnd,
      collaborationFrontEndUrl,
      isFileGalleryOpen: false,
      canDeleteProjectFolder: true,
      taskType: "All Tasks",
      fileToOpen: "",
      isFileViewerOpen: false,
      tasksData: [],
      teamsICreated: [],
      teamsIBelongTo: [],
      isToastShown: true,
      toastMessage: "Toast Message",
      boardSettingsClose: true,
      photos: [
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
        image7,
        image8,
        image9,
        image10,
        image11,
      ],
      task2ChatStyle: { top: 0, left: 0 },
      isSearchFieldVisible: false,
      searchTag: "Tasks",
      showColorPicker: false,
      colorPickerStyle: { top: 0, left: 0 },
      currentTaskToChangeColor: null,
      isBlack: ["#ffc107"],
      wrapperScrollPosition: 0,
      colorCodes: [],
      showTaskMoveConfirm: false,
      taskConfirmStyle: { top: 0, left: 0 },
    };
  }

  async componentDidMount() {
    try {
      const user = this.props.user;
      const token = this.props.token;
      const { tasks, userWiseTasks } = this.state;
      let taskViewSelectedTask, autoCreateTaskProjectId;

      const fullUrl = window.location.pathname.split("/");

      if (fullUrl[1] === "task-view" || fullUrl[1] === "create-task") {
        if (fullUrl[2]) {
          if (fullUrl[1] === "create-task") {
            autoCreateTaskProjectId = fullUrl[2];
          } else {
            const taskId = fullUrl[2];
            const taskResponse = await getTaskById(token, taskId);

            //Redirect if project doesn't exist
            if (!taskResponse || taskResponse.error) {
              window.location.assign("/error");
            } else {
              taskViewSelectedTask = taskResponse.task;
            }
          }
        } else {
          window.location.assign("/error");
        }
      }

      let data = getProjectsAndTasksIBelongTo(token);
      let settings = getBoardSettings(token);

      // let projectsResponse = getProjectsIBelongTo(token);
      let clientsResponse = getClients(token);
      let teamsICreatedResponse = getTeamsICreated(token);
      let teamsIBelongToResponse = getTeamsIBelongTo(token);
      let usersResponse = getCompanyUsers(token);
      let colorCodes = getColorCodes(token);

      await Promise.all([
        data,
        settings,
        clientsResponse,
        teamsICreatedResponse,
        teamsIBelongToResponse,
        usersResponse,
        colorCodes,
      ])
        .then(([res1, res2, res3, res4, res5, res6, res7]) => {
          data = res1;
          settings = res2;
          clientsResponse = res3;
          teamsICreatedResponse = res4;
          teamsIBelongToResponse = res5;
          usersResponse = res6;
          colorCodes = res7;
        })
        .catch((err) => console.error(err));

      if (colorCodes && colorCodes.color_code_desc) {
        this.setState({
          ...this.state,
          colorCodes,
        });
      }

      if (settings && settings.boardSettings) {
        this.props.saveSettings(settings.boardSettings);
      }

      if (data && data.projects) {
        let projects = data.projects;
        const tasksData = data.tasks;
        let project = data.projects[0] || [];
        let activeProjectId = project ? project._id : null;

        projects = projects.map((projectData) => {
          tasks[projectData._id] = tasksData.filter(
            (task) => task.project === projectData._id
          );
          projectData.isToastVisible = false;
          return projectData;
        });

        let selectedTask =
          tasks && activeProjectId ? tasks[activeProjectId][0] : null;

        // if (tasksData.length) {
        //   userWiseTasks[activeProjectId] = this.formatUserTasks(data.tasks);
        // }

        if (fullUrl[1] === "task-view" && taskViewSelectedTask) {
          selectedTask = taskViewSelectedTask;
          activeProjectId = taskViewSelectedTask.project;
          project = projects.find(
            (project) => project._id === taskViewSelectedTask.project
          );
        } else if (fullUrl[1] === "create-task") {
          activeProjectId = autoCreateTaskProjectId;
          project = projects.find(
            (project) => project._id === autoCreateTaskProjectId
          );
          this.autoAddTaskToProject(project);
        }

        // Open BoardView or ListView
        const typeOfView = ["project-board", "create-task"].find(
          (val) => val === fullUrl[1]
        )
          ? 1
          : 0;

        this.setState({
          projects,
          projectsClone: projects,
          tasksData,
          tasks,
          tasksClone: tasks,
          selectedTask,
          clientList: clientsResponse.clients || [],
          teamsICreated: teamsICreatedResponse.teams || [],
          employeesList: usersResponse.users || [],
          isLoading: false,
          activeProjectId,
          selectedProject: project,
          typeOfView,
          teamsIBelongTo: teamsIBelongToResponse.teams || [],
        });
      }

      this.handleSocketEvents();
    } catch (err) {}
  }

  autoAddTaskToProject = async (project, projectTask) => {
    this.handleChangeProject(project, projectTask);
    this.setState({ isAddTaskModalOpen: true });
    window.$("#add_task_modal").modal("toggle");
  };

  toggleBoardSettings = () => {
    this.setState({
      ...this.state,
      boardSettingsClose: !this.state.boardSettingsClose,
    });
  };

  filterTaskByProjectId = async (activeProjectId) => {
    const { tasks, tasksData, showTaskBy, taskType } = this.state;
    let status;

    if (showTaskBy === "tasksByUser") {
      await this.handleTasksBy("tasksByUser");
    }

    if (taskType !== "All Tasks") {
      if (taskType === "Pending") {
        status = "AWAITING";
      } else if (taskType === "Ongoing") {
        status = "INCOMPLETE";
      } else if (taskType === "Under Review") {
        status = "UNDER_REVIEW";
      } else if (taskType === "Completed") {
        status = "COMPLETE";
      }
      tasks[activeProjectId] = tasksData.filter(
        (task) => task.project === activeProjectId && task.status === status
      );
    } else {
      tasks[activeProjectId] = tasksData.filter(
        (task) => task.project == activeProjectId
      );
    }

    this.setState({
      activeProjectId,
      tasks,
      selectedTask: tasks[activeProjectId][0] || null,
    });
  };

  handleTaskCreated = ({ task, updatedProject }) => {
    const updatedTask = task;
    let { tasks, tasksData, selectedTask, selectedProject } = this.state;

    if (updatedTask) {
      tasks[updatedTask.project] = [...tasks[updatedTask.project], updatedTask];
      tasksData.push(updatedTask);
      selectedTask = updatedTask;
    }

    if (updatedProject && updatedProject._id) {
      this.updateProjectsList(updatedProject);
    }

    this.setState({ tasks, tasksData, selectedTask });

    this.handleModal("addTask");
  };

  updateNewTaskName = (e) => {
    const { newTask } = this.state;
    newTask.taskName = e.target.value;
    this.setState({
      newTask,
    });
  };
  updateNewTaskDetail = (e) => {
    const { newTask } = this.state;
    newTask.taskDetail = e.target.value;
    this.setState({
      newTask,
    });
  };

  updateNewTaskStartDate = (event) => {
    const { newTask } = this.state;
    newTask.startDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      newTask,
    });
  };
  updateNewTaskDueDate = (event) => {
    const { newTask } = this.state;
    newTask.dueDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      newTask,
    });
  };
  updateNewTaskAssign = (event) => {
    const { newTask } = this.state;
    newTask.assignedTo = event.target.value;
    this.setState({
      newTask,
    });
  };

  deleteTask = () => {
    const token = this.props.token;
    const { activeProjectId, tasks, selectedTask } = this.state;
    const { _id } = selectedTask;

    fetch(`${URLS.backendTasks}/delete/${_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let pTasks = tasks[activeProjectId];
        let newSelectedTask = null;
        for (let index = 0; index < pTasks.length; index++) {
          if (pTasks[index]._id === _id) {
            if (index < pTasks.length - 1) {
              newSelectedTask = pTasks[index + 1];
            } else if (index === pTasks.length - 1 && pTasks.length === 1) {
              newSelectedTask = null;
            } else {
              newSelectedTask = pTasks[index - 1];
            }
            pTasks.splice(index, 1);
            break;
          }
        }
        tasks[activeProjectId] = pTasks;
        this.setState({
          tasks: tasks,
          selectedTask: newSelectedTask,
        });
        // if (deletedTask.assignedTo && deletedTask.assignedTo !== "")
        //   this.generateTaskNotification("DELETE", null, deletedTask);
      });
  };

  toggleCanDeleteProjectFolder = () => {
    const { canDeleteProjectFolder } = this.state;
    this.setState({
      canDeleteProjectFolder: !canDeleteProjectFolder,
    });
  };

  handleDeleteProject = () => {
    let { selectedProject, canDeleteProjectFolder, projects, projectsClone } =
      this.state;
    const selectedProjectId = selectedProject._id;
    const token = this.props.token;
    const project_folder_id = selectedProject.project_folder_id;

    deleteProject(
      selectedProjectId,
      project_folder_id,
      token,
      canDeleteProjectFolder
    ).then((response) => {
      if (response.success) {
        projects = projectsClone.filter(
          (project) => project._id !== selectedProjectId
        );
        this.setState({
          projects,
          projectsClone: projects,
          //canDeleteProjectFolder: true,
        });
        this.handleModal("deleteProject");
      }
    });
  };

  changeSelectedTask = async (e, task, canSelectTaskByProject) => {
    let { activeProjectId, isTaskChat2, task2ChatStyle } = this.state;
    if (canSelectTaskByProject) {
      activeProjectId = task.project;
      isTaskChat2 = true;
      let x = e.clientX,
        y = e.clientY,
        sx = e.screenX,
        sy = e.screenY,
        w = window.innerWidth;
      // task2ChatStyle.bottom = sy - y + 45;
      task2ChatStyle.top = 60;
      // If screen left is < 300 Else if screen left
      task2ChatStyle.left = x < 300 ? 300 : w - x < 600 ? x - 600 : x;
    }
    await this.setState({
      selectedTask: task,
      activeProjectId,
      isTaskChat2,
      task2ChatStyle,
    });
    this.scrollToBottom();
  };

  changeActiveProject = async (project) => {
    const id = project._id;
    await this.setState({
      activeProjectId: id,
      selectedProject: project,
    });
    this.filterTaskByProjectId(id);
  };

  handleChangeProject = (project, projectTask) => {
    const { tasks, selectedProject } = this.state;
    const activeProjectId = project._id;
    let selectedTask = [];
    if (projectTask) {
      selectedTask = projectTask;
    } else {
      selectedTask = tasks && activeProjectId ? tasks[activeProjectId][0] : [];
    }
    this.setState({
      activeProjectId,
      selectedProject: project,
      selectedTask,
    });
  };

  updateProjectsList = (project) => {
    let {
      projects,
      projectsClone,
      tasks,
      isAddProjectModalOpen,
      isEditProjectModalOpen,
    } = this.state;
    if (project && project._id) {
      const id = project._id;
      tasks[project._id] = tasks[project._id] ? tasks[project._id] : [];
      this.setState({
        tasks,
      });
      let isExists = false;
      for (let index = projectsClone.length - 1; index >= 0; --index) {
        if (projectsClone[index]._id === id) {
          projectsClone.splice(index, 1);
          projectsClone.splice(index, 0, project);
          isExists = true;
          break;
        }
      }
      if (isExists) {
        this.setState({ projectsClone, projects: projectsClone });
      } else {
        projectsClone = projectsClone.concat(project);
        this.setState({
          projectsClone,
          projects: projectsClone,
        });
      }

      if (isAddProjectModalOpen) {
        this.handleModal("addProject");
      } else if (isEditProjectModalOpen) {
        this.handleModal("editProject");
      }
    }
  };

  // removeProject = (projectId) => {
  //   let {projects, projectsClone, tasks} = this.state;
  //   if(projectId){

  //   }
  // }

  showDateInFormat = (date) => {
    date = date.split("/");
    return allMonths[date[1] - 1] + " " + date[0] + ", " + date[2];
  };

  handleSendMessage = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      this.submitComment(e);
    }
  };

  submitComment = async (event) => {
    try {
      event.preventDefault();
      let { newComment } = this.state;
      const { hasAccountExpired } = this.props;
      if (!hasAccountExpired) {
        if (newComment && (newComment.comment || newComment.files.length)) {
          const { token, user } = this.props;
          let {
            selectedTask,
            tasks,
            tasksData,
            selectedProject,
            taskFilesDataList,
          } = this.state;
          const taskId = selectedTask._id;

          newComment.employeeId = user._id;
          newComment.employeeName = user.name.first + " " + user.name.last;
          newComment.employeeImage = user.photo;
          const date = new Date();
          newComment.date =
            date.getDate() +
            "/" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "/" +
            date.getFullYear();
          newComment.time =
            date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2);

          socket.emit(
            "fx:task-add-comment",
            { token, newComment, taskId },
            async (data) => {
              if (data && data.success) {
                const updatedTask = data.task;
                const filesToUpload = newComment.files;

                if (updatedTask) {
                  const updatedTaskId = updatedTask._id;
                  const updatedTaskProjectId = updatedTask.project;

                  tasksData = tasksData.map((task) => {
                    if (task._id === updatedTaskId) {
                      task = updatedTask;
                    }
                    return task;
                  });

                  tasks[updatedTaskProjectId] = tasksData.filter(
                    (taskData) => taskData.project === updatedTaskProjectId
                  );

                  this.setState({
                    tasksData,
                    tasks,
                    selectedTask: updatedTask,
                    newComment: {
                      employeeId: null,
                      employeeImage: "",
                      employeeName: "",
                      date: "",
                      time: "",
                      comment: "",
                      files: [],
                    },
                  });

                  //If user adds comment with files
                  if (filesToUpload.length && taskFilesDataList.length) {
                    const folder_id =
                      selectedProject && selectedProject.task_folder_id
                        ? selectedProject.task_folder_id
                        : "";

                    const commentId = updatedTask.comments
                      ? updatedTask.comments[updatedTask.comments.length - 1][
                          "_id"
                        ]
                      : "";

                    let id;

                    uploadClient.addEventListener("progress", (pro) => {
                      let percentage = (pro.current / pro.size) * 100;
                      console.log(pro);
                      // prop.innerText = percentage + "%";
                      // progress.value = percentage;
                    });
                    try {
                      for (let [index, file] of taskFilesDataList.entries()) {
                        id = Date.now();

                        const res = await uploadClient.upload(file, {
                          folder_id,
                          id,
                        });

                        if (res) {
                          const { cloud_id } = res;
                          const body = {
                            name: file.name,
                            size: file.size,
                            current_directory_id: folder_id,
                            id: cloud_id,
                            type: file.type,
                            token,
                          };
                          //Rename file using it's id
                          updateFileName(body).then((response) => {
                            console.log("File renamed:", response);
                          });

                          const uniqueName =
                            index != null &&
                            newComment &&
                            filesToUpload &&
                            filesToUpload[index]
                              ? filesToUpload[index].uniqueName
                              : "";

                          const data = {
                            cloud_id,
                            uniqueName,
                            taskId,
                            commentId,
                          };

                          if (cloud_id && uniqueName && taskId && commentId) {
                            appendUploadedFileId(token, data).then(
                              (response) => {
                                if (response && response.task) {
                                  const updatedTask = response.task;
                                  this.handleRearrangeTasks(updatedTask);
                                }
                              }
                            );
                          }
                        }
                      }
                    } catch (err) {
                      const uploadError = uploadClient.getError();
                      if (uploadError && uploadError.limited_space) {
                        //Make Toast to alert user that space is limited
                      }
                      console.log(uploadError);
                      console.log("Upload client error:", err);
                    }
                  }
                }
              }
              this.inputEndRef.current.value = "";

              this.scrollToBottom();
            }
          );
        }
      }
    } catch (err) {
      console.log("Task comment error:", err);
    }
  };

  handleSelectFiles = () => {
    this.selectAttachmentRef.current.click();
  };

  removeProjectFile = (idx) => {
    const { newComment, taskFilesDataList } = this.state;
    newComment.files.splice(idx, 1);
    taskFilesDataList.splice(idx, 1);
    this.setState({
      newComment,
      taskFilesDataList,
    });
  };

  calculateFileSize = (size) => {
    let newSize;
    if (size < 1024) {
      newSize = size + " B";
    } else if (size >= 1024 && size < 1024 * 1024) {
      newSize = (size / 1024).toFixed(2) + " KB";
    } else {
      newSize = (size / 1024 / 1024).toFixed(2) + " MB";
    }
    return newSize;
  };

  handleDragOver(ev) {
    ev.preventDefault();
  }

  handleDropFile = (ev) => {
    ev.preventDefault();
    let files = ev.dataTransfer.files;
    this.pickFiles(files);
  };

  updateCommentFile = (event) => {
    let fileInput = event.target;
    let files = fileInput.files;

    this.pickFiles(files);

    this.inputEndRef.current.focus();

    // let uploadedFile = event.target.files[0];
    // const uploadedFile = this.selectAttachmentRef.current.files[0];

    // const name = uploadedFile.name;
    // const lastDot = name.lastIndexOf(".");
    // const fileName = name.substring(0, lastDot);
    // const ext = name.substring(lastDot + 1);
    // const size = uploadedFile.size;
    // let currentDate = new Date();
    // currentDate = currentDate.getTime();
    // newFile.file.original = fileName;
    // newFile.file.unique = currentDate;
    // newFile.file.ext = ext;
    // if (size < 1024) {
    //   newFile.file.size = size + " B";
    // } else if (size >= 1024 && size < 1024 * 1024) {
    //   newFile.file.size = (size / 1024).toFixed(2) + " KB";
    // } else {
    //   newFile.file.size = (size / 1024 / 1024).toFixed(2) + " MB";
    // }
    // this.setState({
    //   fileObject: uploadedFile,
    //   newFile,
    // });
    // window.$("#add_file").modal("toggle");
  };

  pickFiles = (files) => {
    let { newComment, taskFilesDataList } = this.state;
    let fl = files.length;
    let i = 0;
    while (i < fl) {
      const taskFile = {
        originalName: "",
        uniqueName: "",
        extension: "",
        size: "",
      };
      const file = files[i];
      const name = file.name;
      const lastDot = name.lastIndexOf(".");
      const fileName = name.substring(0, lastDot);
      const ext = name.substring(lastDot + 1);
      const size = file.size;
      let currentDate = new Date();
      currentDate = currentDate.getTime();
      taskFile.originalName = fileName;
      taskFile.uniqueName = currentDate;
      taskFile.extension = ext;
      taskFile.size = size;
      taskFile.file = file;
      newComment.files = newComment.files.concat(taskFile);
      taskFilesDataList = taskFilesDataList.concat(file);
      this.setState({
        newComment,
        taskFilesDataList,
      });
      i++;
    }
  };

  validationStartDate = (currentDate) => {
    return currentDate.isAfter(moment(new Date()).add(-1, "days"));
  };

  validationDueDate = (currentDate) => {
    const { newTask } = this.state;
    return currentDate.isAfter(
      moment(newTask.startDate, "DD/MM/YYYY").add(-1, "days")
    );
  };

  handleModal = (modal) => {
    removeModalElements();
    let { employeesList } = this.state;
    if (modal === "addProject") {
      const isAddProjectModalOpen = !this.state.isAddProjectModalOpen;
      if (!isAddProjectModalOpen) {
        window.$("#create_project").modal("toggle");
      }
      this.setState({ isAddProjectModalOpen, employeesList });
    } else if (modal === "editProject") {
      const isEditProjectModalOpen = !this.state.isEditProjectModalOpen;
      if (!isEditProjectModalOpen) {
        window.$("#edit_project").modal("toggle");
      }
      this.setState({ isEditProjectModalOpen, employeesList });
    } else if (modal === "deleteProject") {
      const isDeleteProjectModalOpen = !this.state.isDeleteProjectModalOpen;
      if (!isDeleteProjectModalOpen) {
        window.$("#delete_project").modal("toggle");
      }
      this.setState({ isDeleteProjectModalOpen });
    } else if (modal === "addTask") {
      const isAddTaskModalOpen = !this.state.isAddTaskModalOpen;
      if (!isAddTaskModalOpen) {
        window.$("#add_task_modal").modal("toggle");
      }
      this.setState({ isAddTaskModalOpen });
    } else if (modal === "editTask") {
      const isEditTaskModalOpen = !this.state.isEditTaskModalOpen;
      this.setState({ isEditTaskModalOpen });
      window.$("#edit_task_modal").modal("toggle");
    } else if (modal === "assignTask") {
      console.log("here", this.state.isAssignTaskModalOpen);
      const isAssignTaskModalOpen = !this.state.isAssignTaskModalOpen;
      this.setState({ isAssignTaskModalOpen });
      window.$("#assignee").modal("toggle");
    } else if (modal === "fileGallery") {
      const isFileGalleryOpen = !this.state.isFileGalleryOpen;
      if (!isFileGalleryOpen) {
        window.$("#file_gallery").modal("toggle");
      }
      this.setState({ isFileGalleryOpen });
    }
  };

  goBack = () => {
    this.props.history.push("/");
  };

  handleChangeView = (typeOfView) => {
    const { collaborationFrontEndUrl } = this.state;
    let url;
    if (typeOfView === 0) {
      // url = collaborationFrontEndUrl + "/tasks-board";
      url = collaborationFrontEndUrl + "/tasks-board";
      window.location.replace(url);
      // this.props.history.push('/tasks-board');
    } else if (typeOfView === 1) {
      url = collaborationFrontEndUrl + "/tasks";
      window.location.replace(url);
      // this.props.history.push('/tasks');
    }
  };

  setEmployeeName = (employee) => {
    let name;
    if (employee.name && employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else if (employee.name) {
      name = employee.name;
    }
    return name;
  };

  setEmployeeInitials = (employee) => {
    let name;
    if (employee.name && employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else if (employee.name) {
      name = employee.name;
    } else {
      name = employee;
    }
    return name.charAt(0);
  };

  getProjectTaskClassname = (status, num) => {
    let className;
    if (status === "COMPLETE") {
      className = `task-${num}-complete`;
    } else if (status === "UNDER_REVIEW") {
      className = `task-${num}-under-review`;
    } else if (status === "INCOMPLETE") {
      className = `task-${num}-incomplete`;
    } else if (status === "AWAITING") {
      className = `task-${num}-awaiting`;
    }
    return className;
  };

  getSelectedTaskClassname = (_id) => {
    const { selectedTask } = this.state;
    return selectedTask && selectedTask._id == _id ? "task-1-selected" : "";
  };

  handleChangeComment = (e) => {
    e.preventDefault();
    const { newComment } = this.state;
    newComment.comment = e.target.value;
    this.setState({ newComment });
  };

  scrollToBottom = () => {
    const messagesEndRef = this.messagesEndRef;
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        block: "end",
      });
    }
  };

  handleRearrangeTasks = (updatedTask, type) => {
    let { selectedTask, tasks, tasksClone, tasksData } = this.state;

    if (updatedTask) {
      const updatedTaskId = updatedTask._id;
      const updatedTaskProjectId = updatedTask.project;

      if (type === "new") {
        tasksData.push(updatedTask);
      } else {
        tasksData = tasksData.map((task) => {
          if (task._id == updatedTaskId) {
            task = updatedTask;
          }
          return task;
        });
      }

      tasks[updatedTaskProjectId] = tasksData.filter(
        (taskData) => taskData.project === updatedTaskProjectId
      );
      tasksClone[updatedTaskProjectId] = tasksData.filter(
        (taskData) => taskData.project === updatedTaskProjectId
      );

      if (selectedTask) {
        selectedTask =
          selectedTask._id === updatedTaskId ? updatedTask : selectedTask;
      }

      this.setState({ selectedTask, tasks, tasksClone, tasksData });
    }
  };

  handleSocketEvents = () => {
    socket.on("fx:task-comment-added", (updatedTask) => {
      if (updatedTask) {
        this.handleRearrangeTasks(updatedTask);
        this.scrollToBottom();
      }
    });

    socket.on("fx:task-updated", ({ data, type }) => {
      const updatedTask = data;
      if (updatedTask) {
        this.handleRearrangeTasks(updatedTask, type);
      }
    });

    socket.on("fx-added-to-project", ({ project }) => {
      if (project) {
        this.updateProjectsList(project);
      }
    });

    socket.on("fx-project-updated", ({ project }) => {
      if (project) {
        this.updateProjectsList(project);
      }
    });

    socket.on("fx-removed-from-project", ({ projectId }) => {
      if (projectId) {
        let projectsClone = this.state.projectsClone;
        projectsClone = projectsClone.filter(
          (project) => project._id !== projectId
        );
        this.setState({ projectsClone, projects: projectsClone });
      }
    });

    socket.on("fx-project-deleted", ({ projectId }) => {
      if (projectId) {
        let {
          projectsClone,
          tasks,
          tasksClone,
          tasksData,
          selectedTask,
          isTaskChat2,
          activeProjectId,
        } = this.state;
        projectsClone = projectsClone.filter(
          (project) => project._id !== projectId
        );
        activeProjectId = activeProjectId === projectId ? "" : activeProjectId;
        delete tasks[projectId];
        delete tasksClone[projectId];
        tasksData = tasksData.filter((task) => task.project !== projectId);
        // Set selectedTask to empty object if the selectedTask belongs to the deleted project
        if (selectedTask && selectedTask.project === projectId) {
          isTaskChat2 = false;
          selectedTask = {};
        }
        this.setState({
          projectsClone,
          projects: projectsClone,
          tasks,
          tasksClone,
          tasksData,
          selectedTask,
          isTaskChat2,
          activeProjectId,
        });
      }
    });
  };

  handleApproveTask = () => {
    this.handleUpdateTaskStatus();
    window.$("#handle_approve_task").modal("toggle");
  };

  handleSaveEditedTask = (e, type, taskId) => {
    const savedText = e.target.innerText;
    if (e.key === "Enter" && !e.shiftKey) {
      const token = this.props.token;
      const payload = {
        value: savedText,
        taskId,
        type,
        token,
      };
      socket.emit("fx:task-update-task-info", payload, (data) => {
        if (data && data.success) {
          const updatedTask = data.task;

          if (updatedTask) {
            this.handleRearrangeTasks(updatedTask);
          }
        }
      });
    }
  };

  handleEditTask = (projectTask, field, type) => {
    let { tasks, tasksClone, tasksData } = this.state;
    const editableTaskId = projectTask._id;
    const editableTaskProjectId = projectTask.project;
    const { hasAccountExpired } = this.props;

    if (!hasAccountExpired) {
      tasksData = tasksData.map((taskData) => {
        if (
          taskData._id === editableTaskId &&
          taskData.project === editableTaskProjectId
        ) {
          if (type === "close") {
            taskData.isTaskNameEditable = false;
            taskData.isTaskDetailEditable = false;
          } else {
            if (field === "taskName") {
              taskData.isTaskNameEditable = true;
              taskData.isTaskDetailEditable = false;
            } else if (field === "taskDetail") {
              taskData.isTaskDetailEditable = true;
              taskData.isTaskNameEditable = false;
            }
          }
        } else {
          taskData.isTaskNameEditable = false;
          taskData.isTaskDetailEditable = false;
        }
        return taskData;
      });

      tasks[editableTaskProjectId] = tasksData.filter(
        (task) => task.project === editableTaskProjectId
      );
      tasksClone[editableTaskProjectId] = tasksData.filter(
        (task) => task.project === editableTaskProjectId
      );

      this.setState({ tasks, tasksClone, tasksData });
    }
  };

  handleUpdateTaskStatus = (projectTask, type, projectIndex, message) => {
    const token = this.props.token;
    let selectedTask = projectTask ? projectTask : this.state.selectedTask;
    let { projects, projectsClone } = this.state;
    const { hasAccountExpired } = this.props;

    if (!hasAccountExpired) {
      socket.emit("fx:task-update-status", { token, selectedTask }, (data) => {
        if (data && data.success) {
          const updatedTask = data.task;
          if (updatedTask) {
            this.handleRearrangeTasks(updatedTask);

            if (type === "task-board-view") {
              if (projectsClone && projectsClone[projectIndex]) {
                projectsClone[projectIndex].isToastVisible = true;
                projectsClone[projectIndex].toastMessage = message;
                this.setState({ projectsClone });
                setTimeout(() => {
                  projectsClone[projectIndex].isToastVisible = false;
                  projectsClone[projectIndex].toastMessage = "";
                  this.setState({ projectsClone, projects: projectsClone });
                }, 2500);
              }
            }
          }
        }
      });
    }
  };

  handleTasksType = (taskType) => {
    let { activeProjectId, tasks, tasksData } = this.state;
    let status;

    if (taskType !== "All Tasks") {
      if (taskType === "Pending") {
        status = "AWAITING";
      } else if (taskType === "Ongoing") {
        status = "INCOMPLETE";
      } else if (taskType === "Under Review") {
        status = "UNDER_REVIEW";
      } else if (taskType === "Completed") {
        status = "COMPLETE";
      }
      tasks = Object.entries(tasks).reduce((obj, [key, value]) => {
        obj[key] = tasksData.filter(
          (task) => task.project === key && task.status === status
        );
        return obj;
      }, {});
    } else {
      tasks = this.resetTaskBoard(tasks, tasksData);
    }

    const selectedTask =
      tasks && activeProjectId && tasks[activeProjectId]
        ? tasks[activeProjectId][0]
        : [];

    this.setState({ tasks, selectedTask, taskType });
  };

  handleTasksBy = (showTaskBy) => {
    let { tasks, activeProjectId, selectedProject, userWiseTasks, tasksData } =
      this.state;
    const team = selectedProject
      ? [...selectedProject.team, ...selectedProject.projectLeader]
      : [];

    if (Array.isArray(team) && tasks[activeProjectId]) {
      for (let member of team) {
        const userTasks = tasks[activeProjectId].filter((task) =>
          task.assignedTo.find((element) => element._id === member._id)
        );
        userWiseTasks[member._id] = userTasks;
      }
      this.setState({
        showTaskBy,
        userWiseTasks,
      });
    }
  };

  handleBoardTasksBy = (showTaskBy) => {
    let { tasks, tasksData, projects } = this.state;
    const { user } = this.props;
    const userId = user._id;

    if (showTaskBy === "myTasks") {
      tasks = Object.entries(tasks).reduce((obj, [key, value]) => {
        const tasksFound = tasksData.filter(
          (task) =>
            task.project === key &&
            task.assignedTo.find((element) => element._id === userId)
        );
        if (tasksFound) {
          obj[key] = tasksFound;
        }
        return obj;
      }, {});
    } else if (showTaskBy === "tasksByProject") {
      tasks = this.resetTaskBoard(tasks, tasksData);
    }
    this.setState({ tasks });
  };

  resetTaskBoard = (tasks, tasksData) => {
    return Object.entries(tasks).reduce((obj, [key, value]) => {
      obj[key] = tasksData.filter((task) => task.project === key);
      return obj;
    }, {});
  };

  toggleSearchField = async () => {
    if (this.searchFieldRef.current) {
      this.searchFieldRef.current.value = "";
    }
    const isSearchFieldVisible = !this.state.isSearchFieldVisible;
    await this.setState({ isSearchFieldVisible });
    this.handleSearchField();
  };

  handleSearchTag = async (searchTag) => {
    await this.setState({ searchTag });
    this.handleSearchField();
  };

  handleSearchField = () => {
    let searchValue;
    if (this.searchFieldRef.current) {
      searchValue = this.searchFieldRef.current.value.trim();
    }
    let { tasks, tasksData, searchTag, projectsClone, projects } = this.state;

    if (searchValue) {
      if (searchTag === "Tasks") {
        tasks = Object.entries(tasks).reduce((obj, [key, value]) => {
          const tasksFound = tasksData.filter(
            (task) =>
              task.project === key &&
              task.taskName.toLowerCase().includes(searchValue)
          );
          if (tasksFound) {
            obj[key] = tasksFound;
          }
          return obj;
        }, {});
      } else if (searchTag === "Projects") {
        projects = projectsClone.filter((project) => {
          if (project.name.toLowerCase().includes(searchValue)) {
            return project;
          }
        });
      }
    } else {
      tasks = Object.entries(tasks).reduce((obj, [key, value]) => {
        obj[key] = tasksData.filter((task) => task.project === key);
        return obj;
      }, {});
      projects = projectsClone;
    }
    this.setState({ tasks, projects });
  };

  handleChat2 = () => {
    const isTaskChat2 = !this.state.isTaskChat2;
    this.setState({ isTaskChat2 });
  };

  handleTaskUpdated = async ({ task, updatedProject }) => {
    const updatedTask = task;
    let { tasks, tasksData, selectedTask, showTaskBy } = this.state;

    if (updatedTask) {
      let activeTasks = tasks[updatedTask.project];
      activeTasks = activeTasks.map((activeTask) => {
        if (activeTask._id === updatedTask._id) {
          activeTask = updatedTask;
        }
        return activeTask;
      });
      tasks[updatedTask.project] = activeTasks;
      tasksData = tasksData.map((activeTask) => {
        if (activeTask._id === updatedTask._id) {
          activeTask = updatedTask;
        }
        return activeTask;
      });
      selectedTask = updatedTask;

      await this.setState({ tasks, tasksData, selectedTask });

      if (showTaskBy === "tasksByUser") {
        this.handleTasksBy("tasksByUser");
      }
    }

    if (updatedProject && updatedProject._id) {
      this.updateProjectsList(updatedProject);
    }

    this.handleModal("assignTask");
  };

  getNoOfFiles = (projectTask) => {
    let noOfFiles = 0;
    if (projectTask.comments && Array.isArray(projectTask.comments)) {
      projectTask.comments.forEach((task) => {
        if (task && task.file) {
          noOfFiles++;
        }
      });
    }
    return noOfFiles;
  };

  isCreatedBy = (param) => {
    const userId = this.props.user._id;
    let { createdBy } = param;
    let isTrue;
    if (createdBy === userId) {
      isTrue = true;
    }
    return isTrue;
  };

  isObject = (data) => {
    let value;
    if (data.constructor.name === "Object") {
      value = true;
    }
    return value;
  };

  isProjectCreator = (createdBy) => {
    const userId = this.props.user._id;
    let value, isTrue;
    if (createdBy && this.isObject(createdBy)) {
      value = createdBy._id;
    } else {
      value = createdBy;
    }
    isTrue = value == userId ? true : false;
    return isTrue;
  };

  isAssignedTo = (selectedTask) => {
    const userId = this.props.user._id;
    let { assignedTo } = selectedTask;
    const isFound = assignedTo.find((assigned) => assigned._id === userId);
    return isFound;
  };

  hasProjectRights = (selectedProject) => {
    let isLeader;
    const userId = this.props.user._id;
    let createdBy;
    if (selectedProject.createdBy && this.isObject(selectedProject.createdBy)) {
      createdBy = selectedProject.createdBy._id;
    } else {
      createdBy = selectedProject.createdBy;
    }
    const leaders = selectedProject ? selectedProject.projectLeader : [];

    const isFound =
      leaders && leaders.find((leader) => leader._id == userId) ? true : false;

    if (createdBy == userId || isFound) {
      isLeader = true;
    }

    return isLeader;
  };

  canSeeChat = (selectedTask) => {
    const { selectedProject, teamsIBelongTo } = this.state;
    let isVisible;
    const { _id } = this.props.user;
    let { createdBy, assignedTo } = selectedTask;
    const projectLeader =
      selectedProject && selectedProject.projectLeader
        ? selectedProject.projectLeader
        : [];

    const isAssignedTo = assignedTo.find((assigned) => assigned._id === _id);
    const isProjectLeader = projectLeader.find((leader) => leader._id === _id);
    const teamForSelectedProject = teamsIBelongTo.find(
      (team) => team._id === selectedProject.teamId
    );
    let isTeamLeader;
    if (teamForSelectedProject) {
      const teamLeaders = teamForSelectedProject.leaders
        ? teamForSelectedProject.leaders
        : [];
      isTeamLeader = teamLeaders.find((leader) => leader._id === _id);
    }

    if (createdBy === _id || isAssignedTo || isProjectLeader || isTeamLeader) {
      isVisible = true;
    }

    return isVisible;
  };

  handleOpenFileViewer = (file) => {
    const { watcherServerUrl, cloudDriveFrontEnd } = this.state;
    const cloud_id = file.cloud_id;
    if (cloud_id) {
      if (microsoftOfficeFileExtensions.includes(file.extension)) {
        const fileUrl = cloudDriveFrontEnd + "/group-edit-doc/" + file.cloud_id;
        window.open(fileUrl, "_blank");
      } else {
        const fileToOpen = `${watcherServerUrl}/files/${cloud_id}/${file.originalName}.${file.extension}?view`;
        this.setState({ fileToOpen, isFileViewerOpen: true });
      }
    }
  };

  handleCloseFileModal = () => {
    this.setState({
      fileToOpen: "",
      isFileViewerOpen: false,
    });
  };

  handleOpenProject = (projectId) => {
    let { projects, projectsClone, tasks, tasksData } = this.state;
    // const projectUrl = `${URLS.projectView}/${projectId}`;
    const projectUrl = `${URLS.singleProjectTasks}/${projectId}`;
    window.location.replace(projectUrl);
    // projects = projectsClone.filter((project) => project._id === projectId);
    // projects = [
    //   { _id: `${projectId}-Created`, name: "Created" },
    //   { _id: `${projectId}-Incomplete`, name: "Inprogress" },
    //   { _id: `${projectId}-Under Review`, name: "Under Review" },
    //   { _id: `${projectId}-Completed`, name: "Completed" },
    // ];
    // tasks[activeProjectId] = tasksData.filter(
    //   (task) => task.project === activeProjectId && task.status === status
    // );
    // projectsClone.map((projectData) => {
    //   projects.map((project) => {
    //     tasks[project._id] = tasksData.filter((task) => {
    //       if (task.project === projectData._id) {
    //       }
    //     });
    //   });
    // });
    // tasks = tasksData.filter((task) => {
    //   if (task.project === projectId) {
    //     if (task.status === "COMPLETE") {
    //       task.project = `${projectId}-Completed`;
    //     } else if (task.status === "UNDER_REVIEW") {
    //       task.project = `${projectId}-Under Review`;
    //     } else if (task.status === "INCOMPLETE") {
    //       task.project = `${projectId}-Incomplete`;
    //     } else if (task.status === "AWAITING") {
    //       task.project = `${projectId}-Created`;
    //     }
    //     return task;
    //   }
    // });
    // console.log(tasks, tasksData);
    // this.setState({ projects, tasks });
  };

  handleSectionClick = (e) => {
    const colorBox = e.target.closest(".color-box") ? true : false;
    if (!this.state.showColorPicker) {
    } else {
      if (!colorBox) {
        this.setState({
          ...this.state,
          showColorPicker: false,
        });
      }
    }

    let { tasks, tasksData } = this.state;
    const canCloseSearchField = e.target.closest(
      ".task-2-board-search-container"
    )
      ? true
      : false;
    if (!canCloseSearchField) {
      const isSearchFieldVisible = this.state.isSearchFieldVisible;
      if (isSearchFieldVisible) {
        this.setState({ isSearchFieldVisible: !isSearchFieldVisible });
        tasks = this.resetTaskBoard(tasks, tasksData);
        this.setState({ tasks });
      }
    }
  };

  showProjectToast = (projectsClone, project_id_source) => {
    const projectIndex = projectsClone.findIndex(
      (project) => project._id === project_id_source
    );

    projectsClone[projectIndex].isToastVisible = true;
    projectsClone[projectIndex].toastMessage = "Unauthorized Move";
    this.setState({ projectsClone });
    setTimeout(() => {
      projectsClone[projectIndex].isToastVisible = false;
      projectsClone[projectIndex].toastMessage = "";
      this.setState({ projectsClone });
    }, 2500);
  };

  onSortEnd = async ({ oldIndex, newIndex, project_id }) => {
    const tasks = this.state.tasksClone;
    const currentTask = project_id;
    const taskToMutate = tasks[currentTask];
    const mutated = arrayMove(taskToMutate, oldIndex, newIndex);

    await this.setState({
      ...this.state,
      tasks: {
        ...this.state.tasks,
        [currentTask]: mutated,
      },
      tasksClone: {
        ...this.state.tasksClone,
        [currentTask]: mutated,
      },
    });

    await this.setBackToTaskData();

    const arrangement = this.state.tasksClone[currentTask].map((task, i) => {
      return {
        project_id: currentTask,
        task_id: task._id,
        task_position: i,
      };
    });
    socket.emit("fx:save-arrangement", {
      arrangement,
      user_id: this.props.user._id,
      project_id: currentTask,
    });
  };

  onDragEnd = async (e) => {
    // console.log(e);
    if (!e.destination) {
      return;
    }
    this.setState({
      originalBoard: cloneDeep(this.state.tasksClone),
    });

    const projects = this.state.projectsClone;
    const project_id_source = e.source.droppableId;
    const project_id_destination = e.destination.droppableId;
    const oldIndex = e.source.index;
    const newIndex = e.destination.index;
    const currentDraggedTaskId = e.draggableId.split("_")[0];

    if (e.type.split("_")[0] === "task") {
      if (e.source.droppableId === e.destination.droppableId) {
        this.onSortEnd({
          oldIndex,
          newIndex,
          project_id: project_id_source,
        });
      } else {
        // check if the user can move project
        // console.log(e.draggableId.split('_')[2], this.props.user._id);
        if (e.draggableId.split("_")[2] !== this.props.user._id) {
          this.showProjectToast(projects, project_id_destination);
          return;
        }

        // remove from source
        const tasks = this.state.tasksClone;
        const taskToMutate = tasks[project_id_source];

        // add to new parent
        const taskToMove = cloneDeep(taskToMutate[oldIndex]);
        const newParentTaskToMutate = cloneDeep(tasks[project_id_destination]);
        newParentTaskToMutate.splice(newIndex, 0, taskToMove);

        await this.setState({
          ...this.state,
          tasks: {
            ...this.state.tasks,
            [project_id_destination]: newParentTaskToMutate,
          },
          tasksClone: {
            ...this.state.tasksClone,
            [project_id_destination]: newParentTaskToMutate,
          },
        });

        const tasksData = [];
        for (const [key, value] of Object.entries(this.state.tasksClone)) {
          tasksData.push(...value);
        }

        await this.setState({
          ...this.state,
          tasksData,
          showTaskMoveConfirm: true,
          project_id_source,
          project_id_destination,
          oldIndex,
          newIndex,
          currentDraggedTaskId,
        });
      }
      return;
    }
    const mutated = arrayMove(projects, e.source.index, e.destination.index);

    this.setState({
      ...this.state,
      projects: [...mutated],
      projectsClone: [...mutated],
    });

    const arrangement = [...mutated].map((project, i) => {
      return {
        project_id: project._id,
        project_position: i,
      };
    });

    socket.emit("fx:save-project-arrangement", {
      arrangement,
      user_id: this.props.user._id,
    });
  };

  cancelTaskMove = async () => {
    this.revertToOriginalBoard();
  };

  setBackToTaskData = async () => {
    const tasksData = [];
    for (const [key, value] of Object.entries(this.state.tasksClone)) {
      tasksData.push(...value);
    }
    await this.setState({
      ...this.state,
      tasksData,
    });
  };

  saveTaskMoveArrangement = (project_id_source, project_id_destination) => {
    let arrangement = {};
    arrangement.source = this.state.tasksClone[project_id_source].map(
      (task, i) => {
        return {
          project_id: project_id_source,
          task_id: task._id,
          task_position: i,
        };
      }
    );
    arrangement.destination = this.state.tasksClone[project_id_destination].map(
      (task, i) => {
        return {
          project_id: project_id_destination,
          task_id: task._id,
          task_position: i,
        };
      }
    );
    socket.emit("fx:save-arrangement", {
      arrangement: [...arrangement.destination, ...arrangement.source],
      user_id: this.props.user._id,
      project_id: project_id_destination,
    });
  };

  duplicateTask = async () => {
    const {
      project_id_source,
      newIndex,
      project_id_destination,
      currentDraggedTaskId,
    } = this.state;
    const tasks = this.state.tasksClone;

    const taskToMutate = cloneDeep(tasks[project_id_destination]);
    taskToMutate[newIndex].status = "INCOMPLETE";
    taskToMutate[newIndex].comments = [];

    await this.setState({
      ...this.state,
      showTaskMoveConfirm: false,
      tasks: {
        ...this.state.tasks,
        [project_id_destination]: taskToMutate,
      },
      tasksClone: {
        ...this.state.tasksClone,
        [project_id_destination]: taskToMutate,
      },
    });

    socket.emit(
      "fx:task-copy",
      {
        newProjectId: project_id_destination,
        token: this.props.token,
        taskId: currentDraggedTaskId,
      },
      async (data) => {
        // update task with server details
        if (data.success) {
          const taskToMutate = cloneDeep(tasks[project_id_destination]);
          taskToMutate[newIndex] = {
            ...taskToMutate[newIndex],
            ...data.newTask,
          };

          await this.setState({
            ...this.state,
            tasks: {
              ...this.state.tasks,
              [project_id_destination]: taskToMutate,
            },
            tasksClone: {
              ...this.state.tasksClone,
              [project_id_destination]: taskToMutate,
            },
          });
          await this.setBackToTaskData();
          this.saveTaskMoveArrangement(
            project_id_source,
            project_id_destination
          );
        } else {
          //revert to original board
          this.revertToOriginalBoard();
        }
      }
    );
  };

  moveTask = async () => {
    const {
      project_id_destination,
      project_id_source,
      oldIndex,
      currentDraggedTaskId,
    } = this.state;

    // remove from source
    const tasks = this.state.tasksClone;
    const taskToMutate = cloneDeep(tasks[project_id_source]);
    const mutatedTask = taskToMutate.filter((task, index) => {
      return oldIndex !== index;
    });

    await this.setState({
      ...this.state,
      showTaskMoveConfirm: false,
      tasks: {
        ...this.state.tasks,
        [project_id_source]: mutatedTask,
      },
      tasksClone: {
        ...this.state.tasksClone,
        [project_id_source]: mutatedTask,
      },
    });

    socket.emit(
      "fx:task-move",
      {
        newProjectId: project_id_destination,
        token: this.props.token,
        taskId: currentDraggedTaskId,
      },
      async (data) => {
        if (data.success) {
          this.saveTaskMoveArrangement(
            project_id_source,
            project_id_destination
          );
        } else {
          this.revertToOriginalBoard();
        }
      }
    );
  };

  revertToOriginalBoard = async () => {
    //revert to original board
    await this.setState({
      ...this.state,
      showTaskMoveConfirm: false,
      tasks: {
        ...this.state.originalBoard,
      },
      tasksClone: {
        ...this.state.originalBoard,
      },
    });
    await this.setBackToTaskData();
  };

  showOrHideRef = (el) => {
    el.style.display = "block";
  };

  taskMoveConfirmStyle = (e) => {
    if (this.state.showTaskMoveConfirm) {
      return;
    }
    let x = e.clientX,
      y = e.clientY,
      sx = e.screenX,
      sy = e.screenY,
      w = window.innerWidth,
      h = window.innerHeight,
      top = y,
      left = x;

    if (h - y < 100) {
      top = y - 100;
    }
    if (w - x < 200) {
      left = x - 200;
    }

    this.setState({
      ...this.state,
      showColorPicker: false,
      taskConfirmStyle: {
        top,
        left,
      },
    });
  };

  showColorPicker = (e, task_id, project_id, checkedColor) => {
    let x = e.clientX,
      y = e.clientY,
      sx = e.screenX,
      sy = e.screenY,
      w = window.innerWidth,
      h = window.innerHeight,
      // task2ChatStyle.bottom = sy - y + 45;
      top = y,
      // If screen left is < 300 Else if screen left
      // left = x < 150 ? 150 : w - x < 150 ? x - 175 : x;
      left = x;

    if (h - y < 180) {
      top = y - 180;
    }
    if (w - x < 250) {
      left = x - 250;
    }

    this.setState({
      ...this.state,
      showColorPicker: true,
      colorPickerStyle: {
        top,
        left,
      },
      checkedColor,
      currentTaskToChangeColor: task_id,
      currentProjectToChangeColor: project_id,
    });
  };

  determineTextColour = (color) => {
    const isGrey = ["#ffffff", "beige", "#fff"];
    const isBlack = ["#ffc107"];
    return isGrey.includes(color)
      ? "#a0a0a0"
      : isBlack.includes(color)
      ? "#000000"
      : "#ffffff";
  };

  changeColor = (color) => {
    const tasks = this.state.tasksClone;
    const currentProject = this.state.currentProjectToChangeColor;
    const currentTask = this.state.currentTaskToChangeColor;
    let mutatedTask = tasks[currentProject];

    const index = mutatedTask.findIndex((task) => task._id === currentTask);
    mutatedTask[index].color = color;

    this.setState({
      ...this.state,
      checkedColor: color,
      tasks: {
        ...this.state.tasks,
        [currentTask]: [...mutatedTask],
      },
      tasksClone: {
        ...this.state.tasks,
        [currentTask]: [...mutatedTask],
      },
    });

    const textColor = this.determineTextColour(color);
    socket.emit("fx:save-color-settings", {
      task_id: currentTask,
      color,
      textColor,
    });
  };

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#bcdfeb" : "",
    marginTop: "10px",
    borderRadius: "5px",
  });

  render() {
    const {
      projects,
      selectedProject,
      activeProjectId,
      newTask,
      tasks,
      selectedTask,
      teamsICreated,
      clientList,
      employeesList,
      newComment,
      userWiseTasks,
      isAddProjectModalOpen,
      isEditProjectModalOpen,
      isDeleteProjectModalOpen,
      isAddTaskModalOpen,
      isEditTaskModalOpen,
      isAssignTaskModalOpen,
      typeOfView,
      isLoading,
      showTaskBy,
      isTaskChat2,
      watcherServerUrl,
      isFileGalleryOpen,
      canDeleteProjectFolder,
      isFileViewerOpen,
      fileToOpen,
      taskType,
      isToastShown,
      toastMessage,
      task2ChatStyle,
      isSearchFieldVisible,
      cloudDriveFrontEnd,
    } = this.state;
    const { user, hasAccountExpired } = this.props;
    const boardSettings = this.props.boardSettings;
    const projectTasks = tasks[activeProjectId] ? tasks[activeProjectId] : null;
    // const projectTasksUserWise = userWiseTasks[activeProjectId] ? userWiseTasks[activeProjectId] : null;
    // const projectTasksUserWiseList = projectTasksUserWise
    //   ? Object.keys(projectTasksUserWise)
    //   : null;
    const allProjectMembers =
      selectedProject && selectedProject.team && selectedProject.projectLeader
        ? [...selectedProject.team, ...selectedProject.projectLeader]
        : [];

    return (
      <section onClick={(e) => this.handleSectionClick(e)}>
        <div className="main-wrapper">
          <Topbar />
          {isLoading && (
            <div
              className="d-flex flex-row justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <Spinner />
            </div>
          )}

          {!isLoading && typeOfView === 0 && (
            <div>
              <div className="sd-pr">
                <div onClick={() => this.goBack()}>
                  <BackIcon />
                  <span>Main Menu</span>
                </div>
                <div>
                  <Link style={{ color: "#fff" }} to={URLS.projects}>
                    Projects
                  </Link>
                  {!hasAccountExpired ? (
                    <a
                      data-toggle="modal"
                      data-target="#create_project"
                      onClick={() => this.handleModal("addProject")}
                    >
                      <i className="fa fa-plus"></i>
                    </a>
                  ) : null}
                </div>
                <div className="sleek-scrollbar">
                  <div>
                    {projects && projects.length
                      ? projects.map((project, index) => {
                          return (
                            <div
                              key={shortid.generate()}
                              className={
                                activeProjectId === project._id
                                  ? "pr-active"
                                  : null
                              }
                              onClick={() => this.changeActiveProject(project)}
                              title={project.name}
                            >
                              <a className="pr-single">{project.name}</a>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>

              <div className="task-1-layout">
                <div className="task-1-header">
                  {activeProjectId ? (
                    <span
                      className="task-name"
                      title={`${
                        projects.find(
                          (project) => project._id === activeProjectId
                        ).name
                      }`}
                    >
                      {
                        projects.find(
                          (project) => project._id === activeProjectId
                        ).name
                      }
                    </span>
                  ) : null}
                  <div className="task-header-controls">
                    <div className="custom-dropdown">
                      {/* <span>Displaying</span> */}
                      <select
                        className="custom-dropdown-tag"
                        ref={this.taskBySelectRef}
                        onChange={(e) => this.handleTasksBy(e.target.value)}
                      >
                        <option value="tasksByProject">Tasks By Project</option>
                        <option value="tasksByUser">Tasks By User</option>
                        <option value="myTasks">My Tasks</option>
                      </select>
                    </div>

                    <div className="custom-dropdown ct-container">
                      {/* <span>Task Type</span> */}
                      <select
                        className="custom-dropdown-tag"
                        ref={this.taskTypeSelectRef}
                        onChange={(e) => this.handleTasksType(e.target.value)}
                        value={taskType}
                      >
                        <option value="All Tasks">All Tasks</option>
                        <option value="Pending">Pending</option>
                        <option value="Ongoing">Ongoing</option>
                        <option value="Under Review">Under Review</option>
                        <option value="Completed">Completed</option>
                      </select>
                    </div>

                    <button
                      className="button switch-view-btn"
                      onClick={() => this.handleChangeView(0)}
                    >
                      Switch to Project Board
                    </button>
                    {this.hasProjectRights(selectedProject) &&
                    !hasAccountExpired ? (
                      <button
                        className="button new-task-btn"
                        data-toggle="modal"
                        data-target="#add_task_modal"
                        onClick={() => this.handleModal("addTask")}
                      >
                        New Task
                      </button>
                    ) : null}
                  </div>
                </div>

                <div className="task-1-body">
                  <div className="task-1-list-of-tasks">
                    {projectTasks && projectTasks.length > 0 ? (
                      <div className="task-1-show-tasks">
                        {showTaskBy === "tasksByProject" ? (
                          projectTasks.map((projectTask, index) => (
                            <div
                              className={`task-1-by-project ${this.getSelectedTaskClassname(
                                projectTask._id
                              )}`}
                              onClick={(e) =>
                                this.changeSelectedTask(e, projectTask)
                              }
                              key={index}
                            >
                              <span
                                className={this.getProjectTaskClassname(
                                  projectTask.status,
                                  1
                                )}
                              >
                                <CheckIcon />
                              </span>
                              <span>{projectTask.taskName}</span>
                              {this.hasProjectRights(selectedProject) &&
                              !hasAccountExpired ? (
                                <span
                                  className={`${
                                    projectTask.status !== "COMPLETE"
                                      ? "add-person-icon"
                                      : "empty-person-icon"
                                  } task-ctrl-icon`}
                                  // data-toggle="modal"
                                  // data-target="#assignee"
                                  onClick={() => this.handleModal("assignTask")}
                                >
                                  <AddPersonIcon />
                                </span>
                              ) : null}
                              {this.hasProjectRights(selectedProject) &&
                              !hasAccountExpired ? (
                                <span
                                  className="task-ctrl-icon"
                                  data-toggle="modal"
                                  data-target="#delete_task"
                                  onClick={() =>
                                    this.handleModal("deleteTask", projectTask)
                                  }
                                >
                                  <DeleteIcon />
                                </span>
                              ) : null}
                            </div>
                          ))
                        ) : showTaskBy === "tasksByUser" ? (
                          <div className="task-1-by-users">
                            {selectedProject &&
                              allProjectMembers &&
                              allProjectMembers.map((member, index) => {
                                // const rate =
                                //   projectTasksUserWise[member._id]["rate"];
                                return (
                                  <div key={index}>
                                    <div className="task-1-user-details">
                                      {member.photo ? (
                                        <img
                                          src={
                                            URLS.backendStatic +
                                            "/" +
                                            member.photo
                                          }
                                        />
                                      ) : (
                                        <span className="sp-initials">
                                          {this.setEmployeeInitials(member)}
                                        </span>
                                      )}
                                      <div>{this.setEmployeeName(member)}</div>
                                      {/* <Progress percent={0} />
																			<div className="task-1-rate-info">
																				<span>Rate:</span>
																				<span>{parseFloat(rate)}</span>
																			</div> */}
                                    </div>
                                    {userWiseTasks[member._id] &&
                                      userWiseTasks[member._id].map(
                                        (projectTask) => (
                                          <div
                                            className={`task-1-by-project ${this.getSelectedTaskClassname(
                                              projectTask._id
                                            )}`}
                                            onClick={(e) =>
                                              this.changeSelectedTask(
                                                e,
                                                projectTask
                                              )
                                            }
                                          >
                                            <span
                                              className={this.getProjectTaskClassname(
                                                projectTask.status,
                                                1
                                              )}
                                            >
                                              <CheckIcon />
                                            </span>
                                            <span>{projectTask.taskName}</span>
                                            <span
                                              className={`${
                                                projectTask.status !==
                                                "COMPLETE"
                                                  ? "add-person-icon"
                                                  : "empty-person-icon"
                                              } task-ctrl-icon`}
                                              // data-toggle="modal"
                                              // data-target="#assignee"
                                              onClick={() =>
                                                this.handleModal("assignTask")
                                              }
                                            >
                                              {projectTask.status !==
                                                "COMPLETE" && <AddPersonIcon />}
                                            </span>
                                            <span
                                              className="task-ctrl-icon"
                                              data-toggle="modal"
                                              data-target="#delete_task"
                                              onClick={() =>
                                                this.handleModal(
                                                  "deleteTask",
                                                  projectTask
                                                )
                                              }
                                            >
                                              <DeleteIcon />
                                            </span>
                                          </div>
                                        )
                                      )}
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          <div className="task-1-by-users">
                            <div>
                              <div className="task-1-user-details">
                                {user.photo ? (
                                  <img
                                    src={URLS.backendStatic + "/" + user.photo}
                                  />
                                ) : (
                                  <span className="sp-initials">
                                    {this.setEmployeeInitials(user)}
                                  </span>
                                )}
                                <div>{this.setEmployeeName(user)}</div>
                                {/* <Progress percent={0} />
									<div className="task-1-rate-info">
										<span>Rate:</span>
										<span>{parseFloat(rate)}</span>
									</div> */}
                              </div>
                              {userWiseTasks[user._id] &&
                                userWiseTasks[user._id].map((projectTask) => (
                                  <div
                                    className={`task-1-by-project ${this.getSelectedTaskClassname(
                                      projectTask._id
                                    )}`}
                                    onClick={(e) =>
                                      this.changeSelectedTask(e, projectTask)
                                    }
                                  >
                                    <span
                                      className={this.getProjectTaskClassname(
                                        projectTask.status,
                                        1
                                      )}
                                    >
                                      <CheckIcon />
                                    </span>
                                    <span>{projectTask.taskName}</span>
                                    <span
                                      className={`${
                                        projectTask.status !== "COMPLETE"
                                          ? "add-person-icon"
                                          : "empty-person-icon"
                                      } task-ctrl-icon`}
                                      // data-toggle="modal"
                                      // data-target="#assignee"
                                      onClick={() =>
                                        this.handleModal("assignTask")
                                      }
                                    >
                                      {projectTask.status !== "COMPLETE" && (
                                        <AddPersonIcon />
                                      )}
                                    </span>
                                    <span
                                      className="task-ctrl-icon"
                                      data-toggle="modal"
                                      data-target="#delete_task"
                                      onClick={() =>
                                        this.handleModal(
                                          "deleteTask",
                                          projectTask
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </span>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="task-empty-container">
                        <img src="../../assets/svg/undraw_Working_oh83.svg" />
                        <span>No Task found</span>
                      </div>
                    )}
                  </div>
                  <div className="task-1-chat">
                    {selectedTask && selectedTask.taskName ? (
                      <>
                        <div className="task-1-chat-header">
                          <span>{selectedTask.taskName}</span>

                          {selectedTask.status === "AWAITING" ? (
                            this.isAssignedTo(selectedTask) ? (
                              <button
                                className="button ts-awaiting-btn"
                                onClick={() => this.handleUpdateTaskStatus()}
                              >
                                <AdjustIcon /> Accept
                              </button>
                            ) : !this.isAssignedTo(selectedTask) ? (
                              <span className="ts-pending-btn">Pending</span>
                            ) : null
                          ) : null}

                          {selectedTask.status === "INCOMPLETE" ? (
                            this.isAssignedTo(selectedTask) ? (
                              <button
                                className="button ts-mark-completed-btn"
                                onClick={() => this.handleUpdateTaskStatus()}
                              >
                                <CheckIcon /> Mark completed
                              </button>
                            ) : !this.isAssignedTo(selectedTask) ? (
                              <span className="ts-accepted-btn">Accepted</span>
                            ) : null
                          ) : null}

                          {selectedTask.status === "UNDER_REVIEW" ? (
                            this.isCreatedBy(selectedTask) ? (
                              <button
                                className="button ts-approve-as-complete-btn"
                                data-toggle="modal"
                                data-target="#handle_approve_task"
                              >
                                <CheckIcon /> Approve as Completed
                              </button>
                            ) : this.isAssignedTo(selectedTask) ? (
                              <>
                                <button
                                  className="button ts-mark-completed-btn"
                                  onClick={() => this.handleUpdateTaskStatus()}
                                >
                                  <CheckIcon /> Unmark
                                </button>
                                <span className="ts-under-review-btn">
                                  Under Review
                                </span>
                              </>
                            ) : null
                          ) : null}
                          {selectedTask.status === "COMPLETE" ? (
                            <span className="ts-complete-btn">Completed</span>
                          ) : null}
                        </div>

                        <div className="task-1-chat-body">
                          <div>
                            <span>Assigned To</span>
                            {selectedTask.assignedTo && (
                              <div>
                                <div className="task-img-collection">
                                  {selectedTask.assignedTo.map(
                                    (employee, index) => {
                                      if (index < 3) {
                                        return (
                                          <div className="avatars" key={index}>
                                            <span
                                              className="avatar"
                                              title={this.setEmployeeName(
                                                employee
                                              )}
                                            >
                                              {employee.photo ? (
                                                <img
                                                  src={
                                                    URLS.backendStatic +
                                                    "/" +
                                                    employee.photo
                                                  }
                                                />
                                              ) : (
                                                <span className="av-alternative">
                                                  {this.setEmployeeInitials(
                                                    employee
                                                  )}
                                                </span>
                                              )}
                                            </span>
                                          </div>
                                        );
                                      }
                                    }
                                  )}
                                </div>
                                <div>
                                  {selectedTask.assignedTo.length > 0 && (
                                    <span>
                                      {this.setEmployeeName(
                                        selectedTask.assignedTo[0]
                                      )}
                                    </span>
                                  )}
                                  {selectedTask.assignedTo.length > 1 && (
                                    <div className="assigned-to-details">
                                      <span className="assigned-to-others-text">
                                        &amp;{" "}
                                        {selectedTask.assignedTo.length - 1}{" "}
                                        other
                                        {selectedTask.assignedTo.length > 2
                                          ? "s"
                                          : null}
                                      </span>

                                      <div className="assigned-to-others-layout">
                                        {selectedTask.assignedTo.map(
                                          (employee, index) => {
                                            if (index !== 0) {
                                              return (
                                                <div key={index}>
                                                  {employee.photo ? (
                                                    <img
                                                      src={
                                                        URLS.backendStatic +
                                                        "/" +
                                                        employee.photo
                                                      }
                                                    />
                                                  ) : (
                                                    <span className="sp-initials">
                                                      {this.setEmployeeInitials(
                                                        employee
                                                      )}
                                                    </span>
                                                  )}
                                                  <span>
                                                    {this.setEmployeeName(
                                                      employee
                                                    )}
                                                  </span>
                                                </div>
                                              );
                                            }
                                          }
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                          <div>
                            {selectedTask.startDate && (
                              <div>
                                <span>Start Date</span>
                                <span>
                                  {this.showDateInFormat(
                                    selectedTask.startDate
                                  )}
                                </span>
                              </div>
                            )}
                            {selectedTask.dueDate && (
                              <div>
                                <span>End Date</span>
                                <span>
                                  {selectedTask.status === "COMPLETE"
                                    ? this.showDateInFormat(
                                        selectedTask.completeDate
                                      )
                                    : this.showDateInFormat(
                                        selectedTask.dueDate
                                      )}
                                </span>
                              </div>
                            )}
                            {selectedTask.completeDate &&
                              selectedTask.dueDate && (
                                <div>
                                  <span>Completed Date</span>
                                  <span>
                                    {selectedTask.status === "COMPLETE"
                                      ? this.showDateInFormat(
                                          selectedTask.completeDate
                                        )
                                      : this.showDateInFormat(
                                          selectedTask.dueDate
                                        )}
                                  </span>
                                </div>
                              )}
                          </div>
                          <div>
                            <span>Description</span>
                            <span>
                              {selectedTask.taskDetail
                                ? selectedTask.taskDetail
                                : ""}
                            </span>
                          </div>
                          <div>
                            <span>Conversation</span>
                            <span title="View All Files">
                              <FolderIcon
                                className="gallery-icon"
                                data-toggle="modal"
                                data-target="#file_gallery"
                                onClick={() => this.handleModal("fileGallery")}
                              />
                            </span>
                          </div>
                          <div ref={this.messagesEndRef}>
                            {selectedTask.comments &&
                              selectedTask.comments.map((comment, index) => (
                                <div className="single-comment" key={index}>
                                  <div>
                                    {comment.employeeId &&
                                    comment.employeeId.photo ? (
                                      <img
                                        src={
                                          URLS.backendStatic +
                                          "/" +
                                          comment.employeeId.photo
                                        }
                                      />
                                    ) : (
                                      <span className="sp-initials">
                                        {this.setEmployeeInitials(
                                          comment.employeeName
                                        )}
                                      </span>
                                    )}

                                    <div>
                                      <span>{comment.employeeName}</span>
                                      <div>
                                        <span>{comment.date}</span>
                                        <span>{comment.time}</span>
                                      </div>
                                    </div>
                                  </div>
                                  {comment.files && comment.files.length
                                    ? comment.files.map((file) =>
                                        file.extension === "link" ? (
                                          <div style={{ cursor: "pointer" }}>
                                            <a
                                              href={file.uniqueName}
                                              target="blank"
                                              className="file-info-text"
                                            >
                                              {file.uniqueName}
                                            </a>
                                          </div>
                                        ) : (
                                          <div
                                            data-toggle={
                                              file.cloud_id ? "modal" : null
                                            }
                                            data-target={
                                              file.cloud_id
                                                ? "#view_file"
                                                : null
                                            }
                                            onClick={() =>
                                              this.handleOpenFileViewer(file)
                                            }
                                            style={{
                                              cursor: file.cloud_id
                                                ? "pointer"
                                                : "default",
                                            }}
                                            className="file-detail-flex"
                                          >
                                            {file.extension.includes("pdf") ? (
                                              <FilePdfIcon className="file-pdf-icon" />
                                            ) : file.extension.includes(
                                                "doc"
                                              ) ? (
                                              <FileWordIcon className="file-word-icon" />
                                            ) : file.extension.includes(
                                                "xls"
                                              ) ? (
                                              <FileExcelIcon className="file-excel-icon" />
                                            ) : (
                                              <FileIcon className="file-other-icon" />
                                            )}
                                            <span
                                              className="file-info-text"
                                              style={{
                                                fontSize: "12px",
                                                marginLeft: "7px",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                width: "70%",
                                              }}
                                            >
                                              {file.originalName +
                                                "." +
                                                file.extension}
                                            </span>
                                            {!file.cloud_id ? (
                                              <LoadIcon className="load-file-icon" />
                                            ) : null}
                                          </div>
                                        )
                                      )
                                    : null}

                                  {comment.comment ? (
                                    <div className="only-comment">
                                      {comment.comment}
                                    </div>
                                  ) : null}
                                </div>
                              ))}
                          </div>
                        </div>

                        {this.canSeeChat(selectedTask) ? (
                          <div className="task-controls-layout">
                            {newComment &&
                            newComment.files &&
                            newComment.files.length ? (
                              <div className="task-controls-attachments-layout sleek-scrollbar">
                                <div className="attachments-row">
                                  {newComment.files.map((file, index) => (
                                    <div
                                      className="single-attachment"
                                      key={index}
                                    >
                                      <div className="attach-file-close">
                                        <CloseIcon
                                          className="attach-file-close-icon"
                                          onClick={() =>
                                            this.removeProjectFile(index)
                                          }
                                        />
                                      </div>
                                      <div className="attach-file-col">
                                        <FileIcon className="attach-file-icon" />
                                        <div className="attach-file-info">
                                          <span>
                                            {file.originalName +
                                              "." +
                                              file.extension}
                                          </span>
                                          <span>
                                            {this.calculateFileSize(file.size)}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : null}
                            <div className="task-controls-message-layout sleek-scrollbar">
                              <div>
                                <textarea
                                  placeholder="Send a message"
                                  onChange={(e) => this.handleChangeComment(e)}
                                  onKeyDown={(e) => this.handleSendMessage(e)}
                                  ref={this.inputEndRef}
                                  onDrop={(e) => this.handleDropFile(e)}
                                  onDragOver={(e) => this.handleDragOver(e)}
                                />
                                <span>
                                  <AttachmentIcon
                                    onClick={() => this.handleSelectFiles()}
                                  />
                                </span>
                              </div>
                              <span onClick={(e) => this.submitComment(e)}>
                                <SendIcon />
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <div className="task-1-illustration">
                        <img src="../../assets/svg/undraw_chatting_2yvo.svg" />
                        <span>No Selected Task</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {!isLoading && typeOfView === 1 && (
            <div
              className={`task-2-layout ${
                boardSettings.mode === "colors" && boardSettings.color
              }`}
              style={
                boardSettings.mode === "photos"
                  ? {
                      backgroundImage: `url(${
                        this.state.photos[boardSettings.currentPhoto]
                      })`,
                      backgroundSize: "cover",
                    }
                  : boardSettings.mode === "custom-photo"
                  ? {
                      backgroundImage: `url(${URLS.backendBoardSettings}/custom-photo/${user._id}?i=${boardSettings.unique})`,
                      backgroundSize: "cover",
                    }
                  : {}
              }
            >
              <div
                className={`color-box position-absolute bg-white ${
                  !this.state.showColorPicker ? "d-none" : ""
                }`}
                style={{
                  top: this.state.colorPickerStyle.top,
                  left: this.state.colorPickerStyle.left,
                  width: "250px",
                  zIndex: "4",
                }}
              >
                <div className="d-flex justify-content-between m-1">
                  <span>Pick a color</span>
                  <CloseIcon
                    className="p-1 p-cursor"
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        showColorPicker: false,
                      });
                    }}
                  />
                </div>
                <ColorPicker
                  checkedColor={this.state.checkedColor}
                  changeColor={(color) => this.changeColor(color)}
                />
              </div>
              <div
                className={`task-move-box  position-absolute bg-white ${
                  !this.state.showTaskMoveConfirm ? "d-none" : ""
                }`}
                style={{
                  top: this.state.taskConfirmStyle.top,
                  left: this.state.taskConfirmStyle.left,
                  width: "200px",
                }}
              >
                <div className="d-flex justify-content-between m-1 border-bottom border-gray">
                  <span>Complete Action</span>
                  <CloseIcon
                    className="p-cursor close"
                    onClick={() => this.cancelTaskMove()}
                  />
                </div>
                <div className="p-2">
                  <p onClick={() => this.duplicateTask()}>
                    <ClipboardTextOutline /> Copy
                  </p>
                  <p onClick={() => this.moveTask()}>
                    <SwapHorizontalBold /> Move
                  </p>
                </div>
              </div>
              <div className="task-2-header">
                <span className="task-name task-2-board-title">Projects</span>
                <div className="task-img-collection">
                  <div
                    className="avatars"
                    data-toggle="modal"
                    data-target="#create_project"
                    onClick={() => this.handleModal("addProject")}
                  >
                    <span className="avatar">
                      <span className="add-button-wrapper white-border">
                        <PlusIcon className="add-button p-cursor" />
                      </span>
                    </span>
                  </div>
                </div>
                {!isSearchFieldVisible ? (
                  <MagnifyIcon
                    className="task-2-board-search-icon p-cursor"
                    onClick={() => this.toggleSearchField()}
                  />
                ) : null}

                {isSearchFieldVisible ? (
                  <div className="task-2-board-search-container">
                    <input
                      type="text"
                      placeholder="Search"
                      className="task-2-board-search-field"
                      ref={this.searchFieldRef}
                      onChange={() => this.handleSearchField()}
                    />
                    <span className="task-2-board-floating-icon">
                      <MagnifyIcon />
                    </span>
                    <span>
                      <span className="search-dropdown">
                        <select
                          className="search-dropdown-tag"
                          onChange={(e) => this.handleSearchTag(e.target.value)}
                        >
                          <option value="Tasks">Tasks</option>
                          <option value="Projects">Projects</option>
                        </select>
                      </span>
                    </span>
                  </div>
                ) : null}

                <div className="task-header-controls">
                  <div className="custom-dropdown ct-container btn-opacity">
                    <select
                      className="custom-dropdown-tag btn-opacity"
                      ref={this.taskBySelectRef}
                      onChange={(e) => this.handleBoardTasksBy(e.target.value)}
                    >
                      <option value="tasksByProject">Tasks By Project</option>
                      <option value="myTasks">My Tasks</option>
                    </select>
                  </div>
                  <div className="custom-dropdown ct-container btn-opacity">
                    <select
                      className="custom-dropdown-tag btn-opacity"
                      ref={this.taskTypeSelectRef}
                      onChange={(e) => this.handleTasksType(e.target.value)}
                      value={taskType}
                    >
                      <option value="All Tasks">All Tasks</option>
                      <option value="Pending">Pending</option>
                      <option value="Ongoing">Ongoing</option>
                      <option value="Under Review">Under Review</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                  <button
                    className="button switch-view-btn btn-opacity"
                    onClick={() => this.handleChangeView(1)}
                  >
                    Switch to List View
                  </button>
                  {/* <button
                    className="button switch-view-btn btn-opacity"
                    onClick={() => this.toggleBoardSettings()}
                  >
                    Settings
                  </button> */}
                  <CogIcon
                    className="gear-icon"
                    onClick={() => this.toggleBoardSettings()}
                  />
                  <BoardSettingsContainer
                    colorCodes={this.state.colorCodes}
                    user={this.props.user}
                    isClosed={this.state.boardSettingsClose}
                    onClose={() => this.toggleBoardSettings()}
                  ></BoardSettingsContainer>
                </div>
              </div>
              <div className="task-2-body">
                <div
                  onScroll={() => {
                    this.setState({
                      ...this.state,
                      showColorPicker: false,
                      showTitle: true,
                      wrapperScrollPosition: this.wrapperRef.current.scrollTop,
                    });
                  }}
                  onMouseUp={(e) => this.taskMoveConfirmStyle(e)}
                  className="task-2-container"
                  ref={this.wrapperRef}
                >
                  <div className="task-2-content">
                    {projects && projects.length ? (
                      <DragDropContext
                        onDragEnd={(e) => {
                          this.onDragEnd(e);
                        }}
                        onBeforeCapture={(e) => {
                          this.setState({
                            showTaskMoveConfirm: false,
                            currentDraggedTaskCreatedBy:
                              e.draggableId.split("_")[2],
                          });
                        }}
                      >
                        <Droppable
                          droppableId="all-columns"
                          direction="horizontal"
                          type="project"
                        >
                          {(provided) => (
                            <div
                              className="task-2-wrapper"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {projects.map((project, projectIndex) => {
                                this[project._id] = createRef();
                                const hasRights =
                                  this.hasProjectRights(project);
                                return (
                                  <Draggable
                                    key={project._id}
                                    draggableId={project._id + "project"}
                                    index={projectIndex}
                                    isDragDisabled={
                                      this.state.isSearchFieldVisible ||
                                      this.state.showTaskMoveConfirm ||
                                      this.state.wrapperScrollPosition > 15 ||
                                      this.state.taskType !== "All Tasks" ||
                                      this.state.showTaskBy !== "tasksByProject"
                                    }
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        className="single-project relative-parent"
                                      >
                                        <div
                                          {...provided.dragHandleProps}
                                          className={`task-2-sticky d-flex ${
                                            this.state.wrapperScrollPosition >
                                            15
                                              ? "shadow-one"
                                              : ""
                                          }`}
                                          ref={this[project._id]}
                                          data-id={projectIndex}
                                        >
                                          <div className="position-absolute w-100">
                                            {project.isToastVisible ? (
                                              <Toast
                                                message={project.toastMessage}
                                              />
                                            ) : null}
                                          </div>
                                          <div className="project-2-name">
                                            <span
                                              onClick={() =>
                                                this.handleOpenProject(
                                                  project._id
                                                )
                                              }
                                            >
                                              {project?.name}
                                            </span>
                                            <span
                                              className="project-tooltiptext text-wrap mt-2 p-2"
                                              style={
                                                !(project?.name?.length > 30)
                                                  ? {
                                                      display: "none",
                                                    }
                                                  : {}
                                              }
                                            >
                                              {project?.name}
                                            </span>
                                          </div>

                                          <MoreIcon className="task-2-more-icon" />

                                          <div className="task-2-more-layout">
                                            {this.isCreatedBy(project) ? (
                                              <span
                                                data-toggle="modal"
                                                data-target="#edit_project"
                                                onClick={async () => {
                                                  await this.changeActiveProject(
                                                    project
                                                  );
                                                  this.handleModal(
                                                    "editProject"
                                                  );
                                                }}
                                              >
                                                Edit
                                              </span>
                                            ) : null}
                                            {this.isCreatedBy(project) ? (
                                              <span
                                                data-toggle="modal"
                                                data-target="#delete_project"
                                                onClick={async () => {
                                                  await this.changeActiveProject(
                                                    project
                                                  );
                                                  this.handleModal(
                                                    "deleteProject"
                                                  );
                                                }}
                                              >
                                                Delete
                                              </span>
                                            ) : null}
                                            {project.project_folder_id ? (
                                              <span>
                                                <a
                                                  href={`${cloudDriveFrontEnd}/${project.project_folder_id}`}
                                                >
                                                  Project Drive
                                                </a>
                                              </span>
                                            ) : null}
                                          </div>
                                        </div>

                                        <Droppable
                                          type={"task_" + hasRights}
                                          droppableId={project._id}
                                          index={projectIndex}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.droppableProps}
                                              style={{
                                                ...this.getListStyle(
                                                  snapshot.isDraggingOver
                                                ),
                                              }}
                                            >
                                              {tasks &&
                                                tasks[project._id] &&
                                                tasks[project._id].map(
                                                  (projectTask, index) => (
                                                    <Draggable
                                                      draggableId={`${projectTask._id}_ ${project._id}_${projectTask.createdBy}_${projectIndex}`}
                                                      index={index}
                                                      key={
                                                        projectTask._id + index
                                                      }
                                                      isDragDisabled={
                                                        this.state
                                                          .isSearchFieldVisible ||
                                                        this.state
                                                          .showTaskMoveConfirm ||
                                                        this.state.taskType !==
                                                          "All Tasks" ||
                                                        this.state
                                                          .showTaskBy !==
                                                          "tasksByProject"
                                                      }
                                                    >
                                                      {(provided, snapshot) => {
                                                        // console.log(snapshot);
                                                        return (
                                                          <>
                                                            <div
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                            >
                                                              <SortableItem
                                                                key={index}
                                                                index={index}
                                                                project={
                                                                  project
                                                                }
                                                                projectIndex={
                                                                  projectIndex
                                                                }
                                                                projectTask={
                                                                  projectTask
                                                                }
                                                                hasOpacity={
                                                                  /*
                                                              snapshot.isDragging === false && 
                                                              this.state.currentDraggedTaskId === projectTask._id && 
                                                              this.state.showTaskMoveConfirm && 
                                                              projectTask.project === project._id 
                                                              */
                                                                  this.state
                                                                    .showTaskMoveConfirm &&
                                                                  this.state
                                                                    .currentDraggedTaskId ===
                                                                    projectTask._id
                                                                }
                                                                showColorPicker={(
                                                                  e,
                                                                  task_id,
                                                                  project_id,
                                                                  checkedColor
                                                                ) =>
                                                                  this.showColorPicker(
                                                                    e,
                                                                    task_id,
                                                                    project_id,
                                                                    checkedColor
                                                                  )
                                                                }
                                                                isBlack={
                                                                  this.state
                                                                    .isBlack
                                                                }
                                                                getProjectTaskClassname={(
                                                                  x,
                                                                  y
                                                                ) =>
                                                                  this.getProjectTaskClassname(
                                                                    x,
                                                                    y
                                                                  )
                                                                }
                                                                setEmployeeName={(
                                                                  x
                                                                ) =>
                                                                  this.setEmployeeName(
                                                                    x
                                                                  )
                                                                }
                                                                setEmployeeInitials={(
                                                                  x
                                                                ) =>
                                                                  this.setEmployeeInitials(
                                                                    x
                                                                  )
                                                                }
                                                                hasProjectRights={(
                                                                  x
                                                                ) =>
                                                                  this.hasProjectRights(
                                                                    x
                                                                  )
                                                                }
                                                                handleChangeProject={(
                                                                  x,
                                                                  y
                                                                ) =>
                                                                  this.handleChangeProject(
                                                                    x,
                                                                    y
                                                                  )
                                                                }
                                                                isAssignedTo={(
                                                                  x
                                                                ) =>
                                                                  this.isAssignedTo(
                                                                    x
                                                                  )
                                                                }
                                                                handleModal={(
                                                                  x
                                                                ) =>
                                                                  this.handleModal(
                                                                    x
                                                                  )
                                                                }
                                                                handleUpdateTaskStatus={(
                                                                  x,
                                                                  y,
                                                                  z,
                                                                  m
                                                                ) =>
                                                                  this.handleUpdateTaskStatus(
                                                                    x,
                                                                    y,
                                                                    z,
                                                                    m
                                                                  )
                                                                }
                                                                isCreatedBy={(
                                                                  x
                                                                ) =>
                                                                  this.isCreatedBy(
                                                                    x
                                                                  )
                                                                }
                                                                changeSelectedTask={(
                                                                  x,
                                                                  y,
                                                                  z
                                                                ) =>
                                                                  this.changeSelectedTask(
                                                                    x,
                                                                    y,
                                                                    z
                                                                  )
                                                                }
                                                                handleEditTask={(
                                                                  x,
                                                                  y,
                                                                  z
                                                                ) =>
                                                                  this.handleEditTask(
                                                                    x,
                                                                    y,
                                                                    z
                                                                  )
                                                                }
                                                                handleSaveEditedTask={(
                                                                  x,
                                                                  y,
                                                                  z
                                                                ) =>
                                                                  this.handleSaveEditedTask(
                                                                    x,
                                                                    y,
                                                                    z
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          </>
                                                        );
                                                      }}
                                                    </Draggable>
                                                  )
                                                )}
                                              {provided.placeholder}
                                            </div>
                                          )}
                                        </Droppable>
                                        <>
                                          {this.hasProjectRights(project) ? (
                                            <div
                                              className="task-2-add-new"
                                              data-toggle="modal"
                                              data-target="#add_task_modal"
                                              onClick={async () => {
                                                await this.handleChangeProject(
                                                  project
                                                );
                                                this.handleModal("addTask");
                                              }}
                                            >
                                              <PlusIcon className="p-cursor" />
                                              <span>Add new task</span>
                                            </div>
                                          ) : //If currentUser doesn't have project rights & there is no task in this project
                                          !this.hasProjectRights(project) &&
                                            tasks[project._id] &&
                                            !tasks[project._id].length ? (
                                            <div className="task-2-add-new">
                                              <EmptyIcon className="empty-icon" />
                                              <span>No task</span>
                                            </div>
                                          ) : null}
                                        </>
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    ) : (
                      <div className="full-centre">
                        <div className="task-empty-container">
                          <img src="../../assets/svg/undraw_Working_oh83.svg" />
                          <span>None found</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {isTaskChat2 && (
                  <FreeDrag>
                    <div
                      className="task-2-chat"
                      style={{
                        top: task2ChatStyle.top,
                        left: task2ChatStyle.left,
                      }}
                    >
                      <div className="task-2-chat-header">
                        <span className="task-2-title">
                          {selectedTask.taskName}
                        </span>
                        <CloseIcon
                          onClick={() => this.handleChat2()}
                          className="task-2-close-btn"
                        />
                      </div>
                      {selectedTask && selectedTask.taskName && (
                        <div className="task-2-chat-body">
                          <div>
                            <div className="task-2-chat-assigned-to">
                              <span>Assigned To</span>
                              {selectedTask.status === "AWAITING" ? (
                                this.isAssignedTo(selectedTask) ? (
                                  <button
                                    className="button ts-awaiting-btn"
                                    onClick={() =>
                                      this.handleUpdateTaskStatus()
                                    }
                                  >
                                    <AdjustIcon /> Accept
                                  </button>
                                ) : !this.isAssignedTo(selectedTask) ? (
                                  <span className="ts-pending-btn">
                                    Pending
                                  </span>
                                ) : null
                              ) : null}

                              {selectedTask.status === "INCOMPLETE" ? (
                                this.isAssignedTo(selectedTask) ? (
                                  <button
                                    className="button ts-mark-completed-btn"
                                    onClick={() =>
                                      this.handleUpdateTaskStatus()
                                    }
                                  >
                                    <CheckIcon /> Mark completed
                                  </button>
                                ) : !this.isAssignedTo(selectedTask) ? (
                                  <span className="ts-accepted-btn">
                                    Accepted
                                  </span>
                                ) : null
                              ) : null}

                              {selectedTask.status === "UNDER_REVIEW" ? (
                                this.isCreatedBy(selectedTask) ? (
                                  <button
                                    className="button ts-approve-as-complete-btn"
                                    data-toggle="modal"
                                    data-target="#handle_approve_task"
                                  >
                                    <CheckIcon /> Approve as Completed
                                  </button>
                                ) : this.isAssignedTo(selectedTask) ? (
                                  <>
                                    <button
                                      className="button ts-mark-completed-btn"
                                      onClick={() =>
                                        this.handleUpdateTaskStatus()
                                      }
                                    >
                                      <CheckIcon /> Unmark
                                    </button>
                                    <span className="ts-under-review-btn">
                                      Under Review
                                    </span>
                                  </>
                                ) : null
                              ) : null}
                              {selectedTask.status === "COMPLETE" ? (
                                <span className="ts-complete-btn">
                                  Completed
                                </span>
                              ) : null}
                            </div>
                            {selectedTask.assignedTo && (
                              <div>
                                <div className="task-img-collection">
                                  {selectedTask.assignedTo.map(
                                    (employee, index) => {
                                      if (index < 3) {
                                        return (
                                          <div className="avatars" key={index}>
                                            <span
                                              className="avatar"
                                              title={this.setEmployeeName(
                                                employee
                                              )}
                                            >
                                              {employee.photo ? (
                                                <img
                                                  src={
                                                    URLS.backendStatic +
                                                    "/" +
                                                    employee.photo
                                                  }
                                                />
                                              ) : (
                                                <span className="av-alternative">
                                                  {this.setEmployeeInitials(
                                                    employee
                                                  )}
                                                </span>
                                              )}
                                            </span>
                                          </div>
                                        );
                                      }
                                    }
                                  )}
                                </div>
                                <div>
                                  {selectedTask.assignedTo.length > 0 && (
                                    <span>
                                      {this.setEmployeeName(
                                        selectedTask.assignedTo[0]
                                      )}
                                    </span>
                                  )}
                                  {selectedTask.assignedTo.length > 1 && (
                                    <div className="assigned-to-details">
                                      <span className="assigned-to-others-text">
                                        &amp;{" "}
                                        {selectedTask.assignedTo.length - 1}{" "}
                                        other
                                        {selectedTask.assignedTo.length > 2
                                          ? "s"
                                          : null}
                                      </span>

                                      <div className="assigned-to-others-layout">
                                        {selectedTask.assignedTo.map(
                                          (employee, index) => {
                                            if (index !== 0) {
                                              return (
                                                <div>
                                                  {employee.photo ? (
                                                    <img
                                                      src={
                                                        URLS.backendStatic +
                                                        "/" +
                                                        employee.photo
                                                      }
                                                    />
                                                  ) : (
                                                    <span className="sp-initials">
                                                      {this.setEmployeeInitials(
                                                        employee
                                                      )}
                                                    </span>
                                                  )}
                                                  <span>
                                                    {this.setEmployeeName(
                                                      employee
                                                    )}
                                                  </span>
                                                </div>
                                              );
                                            }
                                          }
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                          <div>
                            {selectedTask.startDate && (
                              <div>
                                <span>Start Date</span>
                                <span>
                                  {this.showDateInFormat(
                                    selectedTask.startDate
                                  )}
                                </span>
                              </div>
                            )}
                            {selectedTask.dueDate && (
                              <div>
                                <span>End Date</span>
                                <span>
                                  {selectedTask.status === "COMPLETE"
                                    ? this.showDateInFormat(
                                        selectedTask.completeDate
                                      )
                                    : this.showDateInFormat(
                                        selectedTask.dueDate
                                      )}
                                </span>
                              </div>
                            )}
                            {selectedTask.completeDate &&
                              selectedTask.dueDate && (
                                <div>
                                  <span>Completed Date</span>
                                  <span>
                                    {selectedTask.status === "COMPLETE"
                                      ? this.showDateInFormat(
                                          selectedTask.completeDate
                                        )
                                      : this.showDateInFormat(
                                          selectedTask.dueDate
                                        )}
                                  </span>
                                </div>
                              )}
                          </div>
                          <div>
                            <span>Description</span>
                            <span>
                              {selectedTask.taskDetail
                                ? selectedTask.taskDetail
                                : ""}
                            </span>
                          </div>
                          <div>
                            <span>Conversation</span>
                            <span title="View All Files">
                              <FolderIcon
                                className="gallery-icon"
                                data-toggle="modal"
                                data-target="#file_gallery"
                                onClick={() => this.handleModal("fileGallery")}
                              />
                            </span>
                          </div>
                          <div ref={this.messagesEndRef}>
                            {selectedTask.comments &&
                              selectedTask.comments.map((comment, index) => (
                                <div className="single-comment" key={index}>
                                  <div>
                                    {comment.employeeId &&
                                    comment.employeeId.photo ? (
                                      <img
                                        src={
                                          URLS.backendStatic +
                                          "/" +
                                          comment.employeeId.photo
                                        }
                                      />
                                    ) : (
                                      <span className="sp-initials">
                                        {this.setEmployeeInitials(
                                          comment.employeeName
                                        )}
                                      </span>
                                    )}

                                    <div>
                                      <span>{comment.employeeName}</span>
                                      <div>
                                        <span>{comment.date}</span>
                                        <span>{comment.time}</span>
                                      </div>
                                    </div>
                                  </div>
                                  {comment.files && comment.files.length
                                    ? comment.files.map((file) =>
                                        file.extension === "link" ? (
                                          <div
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            <a
                                              href={file.uniqueName}
                                              target="blank"
                                              className="file-info-text"
                                            >
                                              {file.uniqueName}
                                            </a>
                                          </div>
                                        ) : (
                                          <div
                                            data-toggle={
                                              file.cloud_id ? "modal" : null
                                            }
                                            data-target={
                                              file.cloud_id
                                                ? "#view_file"
                                                : null
                                            }
                                            onClick={() =>
                                              this.handleOpenFileViewer(file)
                                            }
                                            style={{
                                              cursor: file.cloud_id
                                                ? "pointer"
                                                : "default",
                                            }}
                                            className="file-detail-flex"
                                          >
                                            {file.extension.includes("pdf") ? (
                                              <FilePdfIcon className="file-pdf-icon" />
                                            ) : file.extension.includes(
                                                "doc"
                                              ) ? (
                                              <FileWordIcon className="file-word-icon" />
                                            ) : file.extension.includes(
                                                "xls"
                                              ) ? (
                                              <FileExcelIcon className="file-excel-icon" />
                                            ) : (
                                              <FileIcon className="file-other-icon" />
                                            )}
                                            <span
                                              // href={`${cloudDriveUrl}/${file.cloud_id}`}
                                              className="file-info-text"
                                              style={{
                                                fontSize: "12px",
                                                marginLeft: "7px",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                width: "70%",
                                              }}
                                            >
                                              {file.originalName +
                                                "." +
                                                file.extension}
                                            </span>
                                            {!file.cloud_id ? (
                                              <LoadIcon className="load-file-icon" />
                                            ) : null}
                                          </div>
                                        )
                                      )
                                    : null}

                                  {comment.comment ? (
                                    <div className="only-comment">
                                      {comment.comment}
                                    </div>
                                  ) : null}
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                      {selectedTask &&
                        selectedTask.taskName &&
                        this.canSeeChat(selectedTask) && (
                          <div className="task-controls-layout">
                            {newComment &&
                            newComment.files &&
                            newComment.files.length ? (
                              <div className="task-controls-attachments-layout sleek-scrollbar">
                                <div className="attachments-row">
                                  {newComment.files.map((file, index) => (
                                    <div
                                      className="single-attachment"
                                      key={index}
                                    >
                                      <div className="attach-file-close">
                                        <CloseIcon
                                          className="attach-file-close-icon"
                                          onClick={() =>
                                            this.removeProjectFile(index)
                                          }
                                        />
                                      </div>
                                      <div className="attach-file-col">
                                        <FileIcon className="attach-file-icon" />
                                        <div className="attach-file-info">
                                          <span>
                                            {file.originalName +
                                              "." +
                                              file.extension}
                                          </span>
                                          <span>
                                            {this.calculateFileSize(file.size)}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : null}
                            <div className="task-controls-message-layout">
                              <div>
                                <textarea
                                  placeholder="Send a message"
                                  onChange={(e) => this.handleChangeComment(e)}
                                  onKeyDown={(e) => this.handleSendMessage(e)}
                                  ref={this.inputEndRef}
                                  onDrop={(e) => this.handleDropFile(e)}
                                  onDragOver={(e) => this.handleDragOver(e)}
                                />
                                <span>
                                  <AttachmentIcon
                                    onClick={() => this.handleSelectFiles()}
                                  />
                                </span>
                              </div>
                              <span onClick={(e) => this.submitComment(e)}>
                                <SendIcon />
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                  </FreeDrag>
                )}
              </div>
            </div>
          )}

          {isAssignTaskModalOpen ? (
            <AssignTaskModal
              activeProjectId={activeProjectId}
              team={allProjectMembers}
              selectedTask={selectedTask}
              closeModal={() => this.handleModal("assignTask")}
              taskUpdated={(response) => this.handleTaskUpdated(response)}
            />
          ) : null}

          {isAddTaskModalOpen ? (
            <AddTaskModal
              team={allProjectMembers}
              projectTasks={tasks[activeProjectId]}
              projectId={activeProjectId}
              closeModal={() => this.handleModal("addTask")}
              taskUpdated={(response) => this.handleTaskUpdated(response)}
              taskCreated={this.handleTaskCreated}
            />
          ) : null}

          {isEditTaskModalOpen ? (
            <EditTaskModal
              projectId={activeProjectId}
              team={allProjectMembers}
              selectedTask={selectedTask}
              closeModal={() => this.handleModal("editTask")}
              taskUpdated={(response) => this.handleTaskUpdated(response)}
            />
          ) : null}

          {isAddProjectModalOpen ? (
            <AddProjectModal
              updateProjectsList={(project) => this.updateProjectsList(project)}
              clientList={clientList}
              teams={teamsICreated}
              employeesList={employeesList}
              closeModal={() => this.handleModal("addProject")}
            />
          ) : null}

          {isEditProjectModalOpen ? (
            <EditProjectModal
              project={selectedProject}
              updateProjectsList={(project) => this.updateProjectsList(project)}
              clientList={clientList}
              teams={teamsICreated}
              employeesList={employeesList}
              closeModal={() => this.handleModal("editProject")}
            />
          ) : null}

          {isFileGalleryOpen ? (
            <FileGallery
              task={selectedTask}
              closeModal={() => this.handleModal("fileGallery")}
            />
          ) : null}

          <input
            ref={this.selectAttachmentRef}
            type="file"
            style={{ display: "none" }}
            onChange={this.updateCommentFile}
            multiple
          />

          {isFileViewerOpen ? (
            <div
              id="view_file"
              className="modal custom-modal fade"
              role="dialog"
              onClick={() => this.handleCloseFileModal()}
            >
              <div className="modal-dialog modal-dialog-centered gallery-preview-modal">
                <div className="modal-content gallery-preview-content">
                  <iframe
                    src={fileToOpen}
                    description=""
                    style={{ height: "100%", width: "100%", border: "none" }}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {isDeleteProjectModalOpen ? (
            <DeleteProjectModal
              selectedProject={selectedProject}
              canDeleteProjectFolder={canDeleteProjectFolder}
              toggleCanDeleteProjectFolder={() =>
                this.toggleCanDeleteProjectFolder()
              }
              handleDeleteProject={() => this.handleDeleteProject()}
              closeModal={() => this.handleModal("deleteProject")}
            />
          ) : null}

          <div
            className="modal custom-modal fade"
            id="delete_task"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered modal-sm">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="form-header">
                    <h3>Delete Task</h3>
                    <p>Are you sure you want to delete?</p>
                  </div>
                  <div className="modal-btn delete-action">
                    <div className="row">
                      <div className="col-6">
                        <span
                          className="btn delete-btn"
                          data-dismiss="modal"
                          onClick={() => this.deleteTask()}
                        >
                          Delete
                        </span>
                      </div>
                      <div className="col-6">
                        <span data-dismiss="modal" className="btn cancel-btn">
                          Cancel
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal custom-modal fade"
            id="handle_approve_task"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered modal-sm">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="form-header">
                    <h3>Approve Task</h3>
                    <p>Are you sure want to approve this task?</p>
                  </div>
                  <div className="modal-btn delete-action">
                    <div className="row">
                      <div className="col-6">
                        <span
                          className="btn btn-primary continue-btn"
                          onClick={this.handleApproveTask}
                        >
                          Approve
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          data-dismiss="modal"
                          className="btn btn-primary cancel-btn"
                        >
                          Cancel
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    user: state.userReducer.user,
    boardSettings: state.boardSettingsReducer,
    hasAccountExpired: state.userReducer.hasAccountExpired,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    populateUser: (user) => {
      dispatch(populateUser(user));
    },
    saveSettings: (x) => dispatch(saveSettings(x)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
