import { TodoList } from "./TodoList";

import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import DialogModal from "../modals/DialogModal";
import { socket } from "../../../sockets";
import { allTaskMembers } from "../utils";
import { formatSlashedDate, showToast, styleHelper } from "../utils";
import { fetchQuotes, deleteQuote } from '../../../services/customerServices'
import QuoteCard from './QuoteCard'
import "../../../assets/scss/schedule.scss";
import { UploadBtnIcon } from './../../task-update/components/Icons';
import eventBus from "../../../helper/EventBus";
import Cookies from "universal-cookie";

function QuotationList({ token, invex_domain, invex_api_url, selectedTask, usersList, setShowUploadQuotation, Quotes }) {

    const cookies = new Cookies()
    const [quotes, setQuotes] = useState([])

    useEffect(() => {
        fetchData();
    }, [])

    const invextoken = cookies.get('invextoken')

    async function fetchData() {
        let data = await fetchQuotes(token, { taskId: selectedTask._id })
        console.log(data)
        setQuotes([...data.data])
    }

    const updateQuotes = (data) => {
        setQuotes((oldValue) => {
            return [...oldValue, data]
        });
    }

    eventBus.useCustomEventListener("updateQuotes", updateQuotes);

    const handleDeleteQuote = async (quote, index) => {
        console.log(quote)
        console.log(index)
        // let res = await deleteSchedule(token, schedule._id)
        if (
            await DialogModal({
                title: "Delete",
                description: `Are you sure you want to delete?`,
                type: "warning",
            })
        ) {
            setQuotes((oldValue) => {
                oldValue = oldValue.filter((el, i) => i !== index);
                return [...oldValue];
            });

            const res = await deleteQuote(token, { id: quote._id })
            if (res.success) {
                showToast({
                    message: {
                        title: "Quote deleted successfully...",
                    },
                    type: "success",
                });
            }
        }
    }

    return (
        <>
            <div className="todo">
                <div className="mt-2 add-todo">
                    <button
                        onClick={() => {
                            window.open(invex_domain + '/auth?invextoken=' + invextoken + '&redirect=' + invex_domain + process.env.REACT_APP_QUOTATION_LINK + '&route=' + invex_api_url, '_blank')
                        }}
                        className="btn btn-outline-primary border-0"
                    >
                        + Create Quotation
                    </button>&nbsp;&nbsp;<button
                        onClick={() => {
                            setShowUploadQuotation(true)
                        }}
                        className="btn btn-outline-primary border-0"
                    >
                        <UploadBtnIcon /> Upload Quotation
                    </button>
                </div>
                {!quotes.length ?
                    (<p className="title mb-2">Send quote to customer</p>) : (
                        <p className="title mb-2">Quotation Sent</p>)}
                {quotes.map((el, index) => (
                    <div key={index}>
                        <QuoteCard
                            index={index}
                            quote={el}
                            handleDeleteQuote={handleDeleteQuote}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.userReducer.token,
        user: state.userReducer.user,
        invex_domain: state.userReducer.user.invex_domain,
        invex_api_url: state.userReducer.user.invex_api_url
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationList);
