import { verify } from 'jsonwebtoken';

export const verifyToken = (token) => {
	let isValidToken;

	try {
		const REACT_APP_JWT_SECRET = process.env.REACT_APP_JWT_SECRET;

		if (token) {
			let decoded = verify(token, REACT_APP_JWT_SECRET, {
				issuer: 'com.excellentbridge.cloud',
			});

			if (decoded && Object.entries(decoded).length) {
				isValidToken = true;
			}
		}
	} catch (e) {
		isValidToken = false;
	}
	return isValidToken;
};
