import { URLS } from "../components/urls";

export function deleteProject(
  projectId,
  project_folder_id,
  token,
  canDeleteProjectFolder
) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendProjects}/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        projectId,
        project_folder_id,
        canDeleteProjectFolder,
        token,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProjectsIBelongTo(token) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendProjects}/getProjectsIBelongTo`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addProject(token, project, canCreateProjectFolder) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendProjects}/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ project, canCreateProjectFolder }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function isUserInProjectApi({ userId, projectId }) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendProjects}/isUserInProject`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ userId, projectId }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateProject(token, project) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendProjects}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ project }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProjectById(token, id, sharableId = "") {
  return new Promise((resolve, reject) => {
    fetch(
      sharableId
        ? `${URLS.backendProjects}/${id}?sharableId=${sharableId}`
        : `${URLS.backendProjects}/${id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProjectsByEmployeeId(token, employeeId) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendProjects}/getProjectsByEmployeeId/${employeeId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProjectsByClientId(token, id) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendProjects}/getProjectsByClientId/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function appendUploadedFileId(token, data) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendProjects}/appendUploadedFileId`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ data }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function removeProjectUser(
  token,
  projectId,
  selectedUser,
  typeOfUserToRemove
) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendProjects}/removeProjectUser`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ projectId, selectedUser, typeOfUserToRemove }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTasksInProject({ token, projectId }) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendProjects}/getTasksInProject/${projectId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addList({ token, list }) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendProjects}/createList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        list,
        projectId: list.projectId,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteList({ token, list, projectId }) {
  // console.log(list);
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendProjects}/deleteList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        list,
        projectId,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateList({ token, list, projectId, oldList }) {
  // console.log(list);
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendProjects}/updateList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        list,
        projectId,
        oldList,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProjectAndTask({ token, sharableId, projectId, taskId }) {
  return new Promise((resolve, reject) => {
    fetch(
      `${URLS.backendProjects}/getProjectAndTask/${sharableId}/${projectId}/${taskId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
