/* eslint-disable react/jsx-no-undef */
import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Col, Row, Image } from "react-bootstrap";
import { pdfjs, Document, Page } from "react-pdf";
import Datepicker from "react-datetime";
import moment from "moment";

import CloseIcon from "mdi-react/CloseIcon";
import AttachFileIcon from "mdi-react/AttachFileIcon";
import CheckIcon from "mdi-react/CheckIcon";
import FileIcon from "mdi-react/FileIcon";

import Spinner from "../global/Spinner";

import "../../assets/css/project-files.css";
import "../../../src/assets/css/date-picker.css";
import "../../../src/assets/css/image-list.css";

import "../../assets/scss/global.scss";
import "../../assets/scss/projects.scss";

import { URLS } from "../urls";
import Multiselect from "./Multiselect";
import ImageNameList from "./ImageNameList";
import { updateFileName, searchUserByEmail } from "../../services/userServices";
import { updateProject } from "../../services/projectServices";
import uploadClient from "../../helper/uploadClient";
import { debounce } from "../../utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class ProjectModal extends React.Component {
  project = {
    name: "",
    client: null,
    startDate: "",
    endDate: "",
    cost: 0,
    duration: 0,
    durationType: "",
    priority: "High",
    projectLeader: [],
    team: [],
    description: "",
    files: [],
    teamId: null,
    status: "Created",
    createdBy: "",
    isPublic: false,
  };

  constructor(props) {
    super(props);

    this.selectTeamRef = createRef();
    this.projectFiles = createRef();

    const { account_type } = props.user;

    const teamTemplates = props.teams || [];

    const clientList = props.clientList || [];
    const projectLeaderList = props.project.projectLeader || [];
    const projectTeamList = props.project.team || [];

    const project = props.project || this.project;

    const employeesList = props.employeesList || [];

    const allMembers = this.populateParticipants(
      project,
      teamTemplates,
      employeesList
    );

    const selectedTeam =
      props.project && props.project.teamId
        ? props.project.teamId
        : "Select Team";

    const formerProjectFiles = props.project ? props.project.files : [];

    this.state = {
      buttonText: "Edit Project",
      isWaiting: false,
      oldProject: null,
      employee: null,
      project: project,
      projectTeamList: projectTeamList,
      projectLeaderList: projectLeaderList,
      clientList: clientList,
      employeesList,
      teamTemplates: teamTemplates,
      nameFieldError: false,
      hasFilledDetails: false,
      hasAddedParticipants: false,
      activeParticipants: "leaders",
      selectedTeam: selectedTeam,
      newTeam: [],
      newLeaders: [],
      isAdding: false,
      searchParams: "",
      allMembers: allMembers || [],
      allMembersClone: allMembers || [],
      formerProjectFiles: formerProjectFiles,
      account_type,
      canCreateProjectFolder: true,
      ongoingUploads: [],
      allLeadersChecked: false,
      allMembersChecked: false,
      membersToJoin: project.membersToJoin ? project.membersToJoin : [],
    };
  }

  populateParticipants = (project, teamTemplates, employeesList) => {
    const tM = project && project.team ? project.team : [];
    const tL = project && project.projectLeader ? project.projectLeader : [];
    const membersToJoin = project.membersToJoin;
    let activeTeamTemplate = {};
    let allMembers = [];

    if (teamTemplates && project) {
      activeTeamTemplate =
        project && project.teamId
          ? teamTemplates.find(
              (teamTemplate) => teamTemplate._id === project.teamId
            )
          : {};

      allMembers =
        activeTeamTemplate && Object.entries(activeTeamTemplate).length
          ? [...activeTeamTemplate.leaders, ...activeTeamTemplate.members]
          : [];

      allMembers = [...allMembers, ...employeesList];

      const teamAsMembersToJoin = tM.filter((t) => {
        const isMemberToJoin = membersToJoin.find(
          (m) => m.userId === t._id && !m.hasAccepted
        );
        if (isMemberToJoin) {
          return t;
        }
      });

      const leadersAsMembersToJoin = tL.filter((t) => {
        const isMemberToJoin = membersToJoin.find(
          (m) => m.userId === t._id && !m.hasAccepted
        );
        if (isMemberToJoin) {
          return t;
        }
      });

      allMembers = [
        ...allMembers,
        ...teamAsMembersToJoin,
        ...leadersAsMembersToJoin,
      ];

      let unique = {};
      allMembers = allMembers.filter((member) => {
        if (!unique[member._id]) {
          unique[member._id] = true;

          if (tM.find((t) => t._id === member._id)) {
            member.isTeam = true;
          } else if (tL.find((t) => t._id === member._id)) {
            member.isLeader = true;
          }
          return true;
        } else {
          return false;
        }
      });
    }

    return allMembers;
  };

  updateProjectName = (event) => {
    const { project } = this.state;
    project.name = event.target.value;
    this.setState({
      project,
    });
  };

  updateClient = (event) => {
    const { project } = this.state;
    project.client = event.target.value;
    this.setState({
      project,
    });
  };

  updateStartDate = (event) => {
    const { project } = this.state;
    project.startDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      project,
    });
  };

  updateEndDate = (event) => {
    const { project } = this.state;
    project.endDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      project,
    });
  };

  updateCost = (event) => {
    const { project } = this.state;
    project.cost = event.target.value;
    this.setState({
      project,
    });
  };
  updateDuration = (event) => {
    const { project } = this.state;
    project.duration = event.target.value;
    this.setState({
      project,
    });
  };

  updateDurationType = (event) => {
    const { project } = this.state;
    project.durationType = event.target.value;
    this.setState({
      project,
    });
  };
  updateStatus = (event) => {
    const { project } = this.state;
    project.status = event.target.value;
    this.setState({
      project,
    });
  };

  updatePriority = (event) => {
    const { project } = this.state;
    project.priority = event.target.value;
    this.setState({
      project,
    });
  };

  updateIsPublic = () => {
    const { project } = this.state;
    let isPublic = project.isPublic;
    if (isPublic !== true) {
      isPublic = true;
    } else {
      isPublic = false;
    }
    project.isPublic = isPublic;
    this.setState({
      project,
    });
  };

  updateTeamTemplateId = (event) => {
    const value = event.target.value;
    let { project, teamTemplates, employeesList } = this.state;

    if (value !== "Select Team") {
      project.teamId = value;
    } else {
      project.teamId = "";
      project.projectLeader = [];
      project.projectLeader = [];
    }

    const allMembers = this.populateParticipants(
      project,
      teamTemplates,
      employeesList
    );

    this.setState({
      selectedTeam: value,
      project,
      allMembers,
      allMembersClone: allMembers,
    });
  };

  updateDescription = (event) => {
    const { project } = this.state;
    project.description = event.target.value;
    this.setState({
      project,
    });
  };

  updateProjectFile = (event) => {
    const { project } = this.state;
    let fileInput = event.target;
    let files = fileInput.files;
    let fl = files.length;
    let i = 0;
    while (i < fl) {
      const projectFile = {
        originalName: "",
        uniqueName: "",
        extension: "",
        size: "",
      };
      const file = files[i];
      const name = file.name;
      const lastDot = name.lastIndexOf(".");
      const fileName = name.substring(0, lastDot);
      const ext = name.substring(lastDot + 1);
      const size = file.size;
      let currentDate = new Date();
      currentDate = currentDate.getTime();
      projectFile.originalName = fileName;
      projectFile.uniqueName = currentDate;
      projectFile.extension = ext;
      if (size < 1024) {
        projectFile.size = size + " B";
      } else if (size >= 1024 && size < 1024 * 1024) {
        projectFile.size = (size / 1024).toFixed(2) + " KB";
      } else {
        projectFile.size = (size / 1024 / 1024).toFixed(2) + " MB";
      }

      projectFile.file = file;
      project.files = project.files.concat(projectFile);
      this.setState({
        project,
      });
      i++;
    }
  };
  removeProjectFile = (idx) => {
    const { project } = this.state;
    project.files.splice(idx, 1);
    this.setState({
      project,
    });
  };

  validationStartDate = (currentDate) => {
    return currentDate.isAfter(moment(new Date()).add(-1, "days"));
  };

  validationEndDate = (currentDate) => {
    const { project } = this.state;
    return currentDate.isAfter(
      moment(project.startDate, "DD/MM/YYYY").add(-1, "days")
    );
  };

  handleCloseModal = (e, wrapper) => {
    if (wrapper) {
      if (e.target !== e.currentTarget) return;
    }
    let { allMembers, allMembersClone } = this.state;

    if (allMembersClone && allMembersClone.length) {
      allMembersClone.map((member) => {
        if (member.isChecked) {
          member.isChecked = false;
        }
        return member;
      });
      allMembers = allMembersClone;
    }

    this.setState({
      allMembers,
      allMembersClone,
    });

    this.props.closeModal();
  };

  setClassNameStyle = (name) => {
    let value;
    if (!name || !name.trim()) {
      value = "in-active-project";
    }
    return value;
  };

  isAvailableStyle = () => {
    const { allMembersClone } = this.state;
    let value;

    const totalSelected = allMembersClone.filter(
      (member) => member.isLeader || member.isTeam
    );

    if (totalSelected.length < 2) {
      value = "in-active-project";
    }
    return value;
  };

  handleNextLayout = (value) => {
    const { project } = this.state;

    if (value === 1 && project.name.trim().length) {
      // const selectedValue = this.selectTeamRef.current.options[this.selectTeamRef.current.selectedIndex].value;
      this.setState({
        hasFilledDetails: true,
        hasAddedParticipants: false,
      });
    } else if (value === 0) {
      this.setState({
        hasFilledDetails: false,
        hasAddedParticipants: false,
      });
    }
  };

  handleActiveParticipants = (activeParticipants) => {
    this.setState({ activeParticipants });
  };

  setEmployeeName = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name;
  };

  setEmployeeInitials = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name.charAt(0);
  };

  handleSelectedMember = (member, type) => {
    let {
      allMembers,
      allMembersClone,
      searchParams,
      allMembersChecked,
      allLeadersChecked,
      account_type,
      membersToJoin,
    } = this.state;

    const memberId = member._id;

    // If personal user hasn't been invited
    if (account_type === 1 && member.showInvitation) {
      if (!(member.isTeam || member.isLeader)) {
        allMembersClone.push(member);
      }

      // Add or Remove user from membersToJoin
      let indexFound = membersToJoin.findIndex(
        (memberToJoin) => memberToJoin.userId === memberId
      );
      if (indexFound === -1) {
        membersToJoin.push({ userId: memberId });
      } else {
        membersToJoin.splice(indexFound, 1);
      }
    }

    allMembersClone = allMembersClone.map((member) => {
      if (member._id === memberId) {
        if (type === "team") {
          if (member.isTeam) {
            member.isTeam = false;
            allMembersChecked = false;
          } else {
            member.isTeam = true;
            member.isLeader = false;
          }
        } else if (type === "leader") {
          if (member.isLeader) {
            member.isLeader = false;
            allLeadersChecked = false;
          } else {
            member.isLeader = true;
            member.isTeam = false;
          }
        }
      }
      return member;
    });

    // For company users
    if (account_type === 2) {
      if (searchParams) {
        allMembers = this.handleFilterByName(allMembersClone, searchParams);
      } else {
        allMembers = allMembersClone;
      }
    }

    this.setState({
      allMembers,
      allMembersClone,
      allMembersChecked,
      allLeadersChecked,
      membersToJoin,
    });
  };

  searchPersonalAccount = (searchParams) => {
    const token = this.props.token;
    let { allMembersClone } = this.state;

    if (searchParams) {
      searchUserByEmail(searchParams, token).then((response) => {
        if (response.users) {
          let usersToInvite = response.users;

          usersToInvite = usersToInvite.filter(
            (userToInvite) =>
              allMembersClone.findIndex(
                (currentMember) => currentMember.email === userToInvite.email
              ) < 0
          );

          this.setState({ allMembers: usersToInvite });
        }
      });
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    const searchParams = e.target.value.trim();
    this.setState({ searchParams });
    let { allMembers, allMembersClone, account_type } = this.state;

    if (account_type === 1) {
      if (searchParams) {
        debounce(this.searchPersonalAccount(searchParams), 500);
      } else {
        allMembers = allMembersClone;
        this.setState({ allMembers, allMembersClone });
      }
      this.setState({ allMembers, allMembersClone });
    } else if (account_type === 2) {
      if (searchParams) {
        allMembers = this.handleFilterByName(allMembersClone, searchParams);
      } else {
        allMembers = allMembersClone;
      }
      this.setState({ allMembers, allMembersClone });
    }
  };

  handleFilterByName = (arr, value) => {
    let result = [];
    result = arr.filter((member) => {
      let name;
      if (member.name.first) {
        name = member.name.first + " " + member.name.last;
      } else {
        name = member.name;
      }

      if (name.toLowerCase().includes(value)) {
        return member;
      }
    });
    return result;
  };

  handleEditProject = () => {
    this.setState({ hasAddedParticipants: true, isAdding: true });
    const { project, allMembersClone, formerProjectFiles, membersToJoin } =
      this.state;
    const { hasAccountExpired } = this.props;
    const leaders = [];
    const team = [];

    if (!hasAccountExpired) {
      if (allMembersClone && allMembersClone.length) {
        for (let member of allMembersClone) {
          if (member.isTeam) {
            team.push(member);
          } else if (member.isLeader) {
            leaders.push(member);
          }
        }
      }
      project.projectLeader = leaders;
      project.team = team;
      project.membersToJoin = membersToJoin;

      const token = this.props.token;

      let formData = new FormData();
      formData.append("project", JSON.stringify({ ...project }));
      for (let fileIdx in project.files) {
        const fileInfo = project.files[fileIdx];
        const isOldFile = formerProjectFiles.findIndex(
          (formerProjectFile) =>
            formerProjectFile.uniqueName === fileInfo.uniqueName
        );

        if (isOldFile === -1) {
          const uniqueName = fileInfo.uniqueName + fileIdx;
          formData.append(
            "file",
            fileInfo.file,
            uniqueName + "." + fileInfo.extension
          );
        }
      }

      updateProject(token, project).then((response) => {
        window.$("#edit_project").modal("toggle");
        this.setState({
          hasFilledDetails: false,
          hasAddedParticipants: false,
          isAdding: false,
        });
        if (response.project) {
          this.props.updateProjectsList(response.project);
        }
      });
    }
  };

  handleSelectFiles = () => {
    this.projectFiles.current.click();
  };

  handleSwitch = (e) => {
    const showOnlySelected = e.target.checked;
    let { allMembers, allMembersClone } = this.state;

    if (showOnlySelected) {
      allMembers = allMembersClone.filter(
        (member) => member.isTeam || member.isLeader
      );
    } else {
      allMembers = allMembersClone;
    }
    this.setState({ allMembers });
  };

  handleCheckAll = (e) => {
    const showChecked = e.target.checked;
    let {
      allMembers,
      allMembersClone,
      activeParticipants,
      allLeadersChecked,
      allMembersChecked,
    } = this.state;

    if (activeParticipants === "leaders") {
      if (showChecked) {
        allMembers = allMembersClone.map((member) => {
          if (!member.isLeader && !member.isTeam) {
            member.isLeader = true;
          }
          return member;
        });
        allLeadersChecked = true;
      } else {
        allMembers = allMembersClone.map((member) => {
          if (member.isLeader) {
            member.isLeader = false;
          }
          return member;
        });
        allLeadersChecked = false;
      }
    } else if (activeParticipants === "members") {
      if (showChecked) {
        allMembers = allMembersClone.map((member) => {
          if (!member.isLeader && !member.isTeam) {
            member.isTeam = true;
          }
          return member;
        });
        allMembersChecked = true;
      } else {
        allMembers = allMembersClone.map((member) => {
          if (member.isTeam) {
            member.isTeam = false;
          }
          return member;
        });
        allMembersChecked = false;
      }
    }

    this.setState({
      allMembers,
      allMembersClone: allMembers,
      allLeadersChecked,
      allMembersChecked,
    });
  };

  render() {
    const {
      project,
      clientList,
      employeeProjectTeam,
      employeeProjectLeader,
      projectTeamList,
      projectLeaderList,
      teamTemplates,
      nameFieldError,
      hasFilledDetails,
      hasAddedParticipants,
      activeParticipants,
      selectedTeam,
      isAdding,
      allMembers,
      account_type,
      ongoingUploads,
      allLeadersChecked,
      allMembersChecked,
    } = this.state;

    return (
      <div
        id="edit_project"
        className="modal custom-modal fade"
        role="dialog"
        onClick={(e) => this.handleCloseModal(e, "wrapper")}
      >
        <div className="modal-dialog modal-dialog-centered project-modal">
          <div className="modal-content">
            <div className="m-h-between">
              <h5 className="modal-title">Edit Project</h5>
              <CloseIcon
                onClick={(e) => this.handleCloseModal(e)}
                className="p-cursor"
              />
            </div>
            <div className="modal-body" style={{ paddingTop: "10px" }}>
              {!hasFilledDetails && (
                <Form className="s-height">
                  <Form.Group as={Row}>
                    <Col sm={12}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Project Name
                      </Form.Label>
                      <Form.Label
                        className="text-danger"
                        style={{ fontSize: "14px" }}
                      ></Form.Label>
                      <Form.Control
                        placeholder="Project name"
                        value={project.name}
                        onChange={this.updateProjectName}
                        required
                        className="w-p-100"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Client
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={project.client}
                        onChange={this.updateClient}
                        className="w-p-100"
                      >
                        <option>Select</option>
                        <option disabled>__________________</option>
                        {clientList.map((client, index) => {
                          return (
                            <option key={index} value={client._id}>
                              {client.companyName}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Start Date
                      </Form.Label>
                      <div className="datePicker">
                        <i
                          className="fa fa-calendar fa-xs calendar-icon-3"
                          aria-hidden="true"
                        ></i>
                        <Datepicker
                          value={project.startDate}
                          closeOnSelect={true}
                          onChange={this.updateStartDate}
                          isValidDate={this.validationStartDate}
                          className="w-dt-100"
                        />
                      </div>
                    </Col>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        End Date
                      </Form.Label>
                      <div className="datePicker">
                        <i
                          className="fa fa-calendar fa-xs calendar-icon-3"
                          aria-hidden="true"
                        ></i>
                        <Datepicker
                          value={project.endDate}
                          closeOnSelect={true}
                          onChange={this.updateEndDate}
                          isValidDate={this.validationEndDate}
                          className="w-dt-100"
                        />
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Cost (in USD)
                      </Form.Label>
                      <Form.Control
                        value={project.cost}
                        placeholder="$"
                        onChange={this.updateCost}
                        className="w-p-100"
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Duration
                      </Form.Label>
                      <Form.Control
                        value={project.duration}
                        onChange={this.updateDuration}
                        className="w-p-100"
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Duration Unit
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={project.durationType}
                        onChange={this.updateDurationType}
                        className="w-p-100"
                      >
                        <option value="">Select Unit</option>
                        <option value="Hours">Hours</option>
                        <option value="Days">Days</option>
                        <option value="Months">Months</option>
                        <option value="Years">Years</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Assign a Team to Project
                      </Form.Label>
                      <Form.Control
                        as="select"
                        onChange={this.updateTeamTemplateId}
                        className="w-p-100"
                        ref={this.selectTeamRef}
                        value={selectedTeam}
                      >
                        <option value="Select Team"> Select Team </option>
                        {teamTemplates.length > 0 &&
                          teamTemplates.map((teamTemplate, index) => {
                            return (
                              <option value={teamTemplate._id}>
                                {teamTemplate.name}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Col>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Project Status
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={project.status}
                        onChange={this.updateStatus}
                        className="w-p-100"
                      >
                        <option value="Created">Created</option>
                        <option value="Working">Working</option>
                        <option value="Completed">Completed</option>
                      </Form.Control>
                    </Col>
                    <Col sm={4}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Priority
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={project.priority}
                        onChange={this.updatePriority}
                        className="w-p-100"
                      >
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={12}>
                      <Form.Label style={{ fontSize: "14px" }}>
                        Description
                      </Form.Label>
                      <textarea
                        rows="10"
                        className="form-control fm-area sleek-scrollbar"
                        onChange={this.updateDescription}
                        value={project.description}
                        style={{ height: "50px" }}
                      ></textarea>
                    </Col>
                  </Form.Group>

                  <Col sm={8}>
                    <div className="d-flex align-items-center">
                      <div
                        className="ml-4 dv-up-cn"
                        style={{ fontSize: "13px", color: "#808080" }}
                      >
                        <input
                          type="checkbox"
                          className="mr-2"
                          checked={project.isPublic}
                          onChange={() => this.updateIsPublic()}
                        />
                        <span
                          onClick={() => this.updateIsPublic()}
                          style={{ cursor: "default" }}
                        >
                          Make project public, project will posted on
                          {account_type === 1 ? " Feed" : " Community"}
                        </span>
                      </div>
                    </div>
                  </Col>

                  <div className="d-flex">
                    <Col sm={8}>
                      <div className="upload-project-layout">
                        <div className="dv-up-lt">
                          <span onClick={() => this.handleSelectFiles()}>
                            <AttachFileIcon />
                          </span>
                          <div className="sleek-scrollbar">
                            <div className="list-of-files">
                              {project &&
                                project.files &&
                                project.files.map((file, index) => (
                                  <div key={index}>
                                    <CloseIcon
                                      onClick={() =>
                                        this.removeProjectFile(index)
                                      }
                                      className="p-cursor fl-close"
                                    />
                                    <FileIcon className="fl-file-icon" />
                                    <span
                                      className="fl-info"
                                      title={
                                        file.originalName + "." + file.extension
                                      }
                                    >
                                      {file.originalName}
                                    </span>
                                    <span className="fl-file-size">
                                      {file.size} {"|"} {file.extension}
                                    </span>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>

                        <input
                          ref={this.projectFiles}
                          id="projectfiles"
                          type="file"
                          multiple
                          label="Upload File"
                          onChange={this.updateProjectFile}
                          style={{ display: "none" }}
                        />
                      </div>
                    </Col>
                    <Col sm={4}>
                      <span
                        className={`next-add-project p-cursor ${this.setClassNameStyle(
                          project.name
                        )}`}
                        onClick={() => this.handleNextLayout(1)}
                      >
                        Next
                      </span>
                    </Col>
                  </div>
                </Form>
              )}
              {hasFilledDetails && !hasAddedParticipants && (
                <div className="dv-participant">
                  <div className="participants-tabs">
                    <div
                      className={`${
                        activeParticipants === "leaders"
                          ? "active-participants"
                          : "inactive-participants"
                      }`}
                      onClick={() => this.handleActiveParticipants("leaders")}
                    >
                      Add Project Leaders
                    </div>
                    <div
                      className={`${
                        activeParticipants === "members"
                          ? "active-participants"
                          : "inactive-participants"
                      }`}
                      onClick={() => this.handleActiveParticipants("members")}
                    >
                      Add Project Members
                    </div>
                  </div>
                  <input
                    placeholder="Search to add"
                    className="form-control "
                    type="text"
                    onChange={(e) => this.handleSearch(e)}
                  />
                  {activeParticipants === "leaders" && (
                    <div className="participants-list sleek-scrollbar">
                      {allMembers && allMembers.length ? (
                        <div>
                          {allMembers.map((member, index) => (
                            <div
                              className="user-info"
                              key={index}
                              onClick={() =>
                                this.handleSelectedMember(member, "leader")
                              }
                            >
                              {member.photo ? (
                                <img
                                  className="user-desc"
                                  src={URLS.backendStatic + "/" + member.photo}
                                />
                              ) : (
                                <div className="user-desc user-init">
                                  {this.setEmployeeInitials(member)}
                                </div>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: "5px",
                                }}
                              >
                                <span>{this.setEmployeeName(member)}</span>
                                <span
                                  style={{ fontSize: "13px", color: "#888" }}
                                >
                                  {member.email}
                                </span>
                              </div>
                              {member.isTeam ? (
                                <span className="unchecked-box">
                                  <CheckIcon className="check-mark-icon" />
                                </span>
                              ) : (
                                <input
                                  type="checkbox"
                                  checked={
                                    member.isLeader ? member.isLeader : false
                                  }
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="none-found">None found</div>
                      )}
                    </div>
                  )}
                  {activeParticipants === "members" && (
                    <div className="participants-list sleek-scrollbar">
                      {allMembers && allMembers.length ? (
                        <div>
                          {allMembers.map((member, index) => (
                            <div
                              className="user-info"
                              key={index}
                              onClick={() =>
                                this.handleSelectedMember(member, "team")
                              }
                            >
                              {member.photo ? (
                                <img
                                  className="user-desc"
                                  src={URLS.backendStatic + "/" + member.photo}
                                />
                              ) : (
                                <div className="user-desc user-init">
                                  {this.setEmployeeInitials(member)}
                                </div>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: "5px",
                                }}
                              >
                                <span>{this.setEmployeeName(member)}</span>
                                <span
                                  style={{ fontSize: "13px", color: "#888" }}
                                >
                                  {member.email}
                                </span>
                              </div>
                              {member.isLeader ? (
                                <span className="unchecked-box">
                                  <CheckIcon className="check-mark-icon" />
                                </span>
                              ) : (
                                <input
                                  type="checkbox"
                                  checked={
                                    member.isTeam ? member.isTeam : false
                                  }
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="none-found">None found</div>
                      )}
                    </div>
                  )}
                  <div className="project-controls participants-project-controls">
                    <div>
                      <div className="d-flex">
                        <div className="check-all" style={{ height: "50px" }}>
                          {activeParticipants === "members" ? (
                            <>
                              <input
                                checked={allMembersChecked}
                                type="checkbox"
                                style={{ marginTop: "auto" }}
                                onChange={(e) => this.handleCheckAll(e)}
                              />
                              <span>Select All</span>
                            </>
                          ) : (
                            <>
                              <input
                                checked={allLeadersChecked}
                                type="checkbox"
                                style={{ marginTop: "auto" }}
                                onChange={(e) => this.handleCheckAll(e)}
                              />
                              <span>Select All</span>
                            </>
                          )}
                        </div>

                        <div className="project-dv-switch">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={(e) => this.handleSwitch(e)}
                            />
                            <span className="slider round"></span>
                          </label>
                          <span className="sh-only">Show only selected</span>
                        </div>
                      </div>
                      {activeParticipants === "members" ? (
                        <span
                          className="back"
                          onClick={() =>
                            this.handleActiveParticipants("leaders")
                          }
                        >
                          Back
                        </span>
                      ) : (
                        <span
                          className="back"
                          onClick={() => this.handleNextLayout(0)}
                        >
                          Back
                        </span>
                      )}
                      <span
                        className="cancel"
                        onClick={(e) => this.handleCloseModal(e)}
                      >
                        Cancel
                      </span>
                      {activeParticipants === "leaders" ? (
                        <span
                          className="next"
                          onClick={() =>
                            this.handleActiveParticipants("members")
                          }
                        >
                          Next
                        </span>
                      ) : (
                        <span
                          className={`next ${this.isAvailableStyle()}`}
                          onClick={() => this.handleEditProject()}
                        >
                          Save
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {hasAddedParticipants && isAdding && (
                <div
                  className="d-flex flex-column justify-content-center"
                  style={{ height: "400px", paddingTop: "30px" }}
                >
                  <Spinner spinnerHeight="50px" />
                  <span
                    style={{
                      display: "block",
                      textAlign: "center",
                      marginTop: "40px",
                    }}
                  >
                    Updating Project....
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    user: state.userReducer.user,
    hasAccountExpired: state.userReducer.hasAccountExpired,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectModal));
