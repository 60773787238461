import * as Actions from "../actions";

const initialState = {
  isRightSidebarVisible: false,
  type: "",
  activeSideBar: "reports",
  displayProfileInfo: false,
  displayErp: false,
  bottomMessage: "",
  isInviteModalVisible: false,
  toastMessage: "",
  pages: [],
};

const topBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.TOOGLE_RIGHT_SIDEBAR: {
      const type = action.payload;
      const isRightSidebarVisible = !type ? false : true;

      return {
        ...state,
        type,
        isRightSidebarVisible,
      };
    }
    case Actions.TOOGLE_LEFT_SIDE_BAR: {
      return {
        ...state,
        activeSideBar: action.payload,
      };
    }
    case Actions.TOGGLE_PROFILE_INFO: {
      const displayProfileInfo = action.payload;
      return {
        ...state,
        displayProfileInfo,
      };
    }
    case Actions.TOGGLE_ERP: {
      const displayErp = action.payload;
      return {
        ...state,
        displayErp,
      };
    }
    case Actions.TOGGLE_INVITE_MODAL: {
      const isInviteModalVisible = action.payload;
      return {
        ...state,
        isInviteModalVisible,
      };
    }
    case Actions.TOGGLE_TOAST_MESSAGE: {
      const toastMessage = action.payload;
      return {
        ...state,
        // toastMessage,
      };
    }
    default: {
      return state;
    }
  }
};

export default topBarReducer;
