import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import BellOutlineIcon from "mdi-react/BellOutlineIcon";
import BellOffOutlineIcon from "mdi-react/BellOffOutlineIcon";
import CheckCircleOutline from "mdi-react/CheckCircleOutlineIcon";
import CommentTextMultipleIcon from "mdi-react/CommentTextMultipleIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PaletteIcon from "mdi-react/PaletteIcon";
import TrashCanIcon from "mdi-react/TrashCanOutlineIcon";
import { Dropdown } from "react-bootstrap";
import { editSchedule } from '../../../services/crmServices'
import { AddUserIcon, CompleteIcon, ImageRemoveOutlineIcon, CalendarClock } from "./Icons";
import {
    allTaskMembers,
    cloudDriveBackend,
    CopyToClipboard,
    formatSlashedDate,
    dateFormat,
    dateTimeFormat,
    checkExpiration,
    showToast,
    isBaseGrey,
    setFileUrl,
} from "../utils";
import Avatar from "./Avatar";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import { socket } from "../../../sockets";
import {
    compareAsc,
    differenceInDays,
    differenceInHours,
    subDays,
} from "date-fns";
import PageLayoutHeaderIcon from "mdi-react/PageLayoutHeaderIcon";
import { truncate } from "lodash";

function ScheduleCard({
    index,
    schedule,
    reloadSchedule,
    handleDeleteSchedule,
    token
}) {

    const [showAddForm, setShowAddForm] = useState(false)
    const [scheduleText, setScheduleText] = useState('')
    const [scheduleType, setScheduleType] = useState('')
    const [scheduleAddress, setScheduleAddress] = useState('')
    const [scheduleDate, setScheduleDate] = useState('')

    // const showTaskDetails = () => {
    //     setSelectedTask();
    //     setSelectedList();
    //     setShowTaskDetailsModal();
    // };

    const editTask = (schedule) => {
        setShowAddForm(true)
        setScheduleDate(schedule.dueDate)
        setScheduleAddress(schedule.meetingAddress)
        setScheduleType(schedule.scheduleType)
        setScheduleText(schedule.scheduleName)
        console.log(schedule)
    }

    const onSubmit = async (e) => {
        const payload = {
            id: schedule._id,
            scheduleName: scheduleText,
            scheduleType: scheduleType,
            dueDate: scheduleDate,
            meetingAddress: scheduleAddress,
            taskId: schedule.taskId,
            isDue: false
        };

        setShowAddForm(false)
        setScheduleText('')
        setScheduleType('')
        setScheduleDate('')
        setScheduleAddress('')
        showToast({
            message: {
                title: "Schedule updated successfully...",
            },
            type: "success",
        });

        let res = await editSchedule(token, payload)
        console.log(res)
        reloadSchedule()
        e.preventDefault()
    }

    const resolveDueColor = (schedule) => {
        if (checkExpiration(schedule.dueDate)) {
            return {
                display: 'inline',
                background: "#FC6D6C",
                color: "#fff",
                border: "none",
                "padding-left": "10px",
                "padding-right": "10px",
                "text-align": "center",
                width: 'auto'
            };
        } else {
            return {
                display: 'inline',
                background: "#40D886",
                color: "#fff",
                border: "none",
                "padding-left": "10px",
                "padding-right": "10px",
                "text-align": "center",
                width: 'auto'
            };
        }
    };

    return (
        <>
            <div
                key={schedule._id}
                className="stage-card"
            >

                <div className="stage-content">
                    <div className="d-flex justify-content-between align-items-center">
                        <span
                            className="title p-cursor"
                            title={
                                schedule.scheduleName && schedule.scheduleName.length > 31 ? schedule.scheduleName : ""
                            }
                        >
                            {truncate(schedule.scheduleName, { length: 31 })}
                        </span>

                        <Dropdown
                            alignRight
                        /*style={
                        isProjectLeaderOrCreatedBy() || task.createdBy === user._id
                            ? {}
                            : { visibility: "hidden" }
                        } */
                        >
                            <Dropdown.Toggle bsPrefix={`btn bg-white p-0 toggle-btn`}>
                                <DotsHorizontalIcon
                                    className="text-lighter"
                                />
                            </Dropdown.Toggle>

                            <Dropdown.Menu bsPrefix={`task-menu`} alignRight>
                                <Dropdown.Item
                                    as="button"
                                    onClick={() => editTask(schedule)}
                                >
                                    <PencilIcon /> Edit Schedule
                                </Dropdown.Item>
                                <Dropdown.Item
                                    as="button"
                                    className="text-danger"
                                    onClick={() => handleDeleteSchedule(schedule, index)}
                                >
                                    <TrashCanIcon className="text-danger" /> Delete Schedule
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <p
                        className="m-0 task-detail w-100 p-cursor"
                    >
                        Schedule Type: {schedule.scheduleType}
                    </p>
                    {schedule.meetingAddress && <p
                        className="m-0 task-detail w-100 p-cursor"
                        title="Meeting Location"
                    >
                        <MapMarkerIcon /> {schedule.meetingAddress}
                    </p>}
                </div>

                <div className="px-3 d-flex">
                    {schedule.dueDate && (
                        <>
                            <button
                                className={`btn btn-date btn-outline-secondary`}
                                style={resolveDueColor(schedule)}
                                title={`Due date: ${formatSlashedDate(schedule.dueDate, true)}`}
                            >
                                {checkExpiration(schedule.dueDate) ? (
                                    <CalendarClock />
                                ) : (
                                    <CalendarClock />
                                )}{" "}
                                <span>{dateTimeFormat(schedule.dueDate)}</span>
                            </button>
                        </>
                    )}
                </div>
            </div>
            {showAddForm ? (<form
                className="mt-3 mb-3"
                onSubmit={(e) => onSubmit(e)}
            >
                <input
                    placeholder="Schedule title"
                    value={scheduleText}
                    autoComplete="off"
                    autoFocus
                    required
                    onChange={(e) => { setScheduleText(e.target.value) }}
                    spellCheck={false}
                    className="form-control mb-3"
                />
                <select
                    required
                    onChange={(e) => { setScheduleType(e.target.value) }}
                    className="form-control mb-3"
                    value={scheduleType}
                >
                    <option value={''}>Select Schedule Type</option>
                    <option value={'Call'}>Call</option>
                    <option value={'Physical Meeting'}>Physical Meeting</option>
                    <option value={'Online Meeting'}>Online Meeting</option>
                    <option value={'Email'}>Email</option>
                </select>
                {(scheduleType === 'Physical Meeting' || scheduleType === 'Online Meeting') && <input
                    placeholder="Enter meeting address"
                    value={scheduleAddress}
                    autoComplete="off"
                    autoFocus
                    required
                    onChange={(e) => { setScheduleAddress(e.target.value) }}
                    spellCheck={false}
                    type="=text"
                    className="form-control mb-3"
                />}
                <input
                    placeholder="Select Date"
                    value={scheduleDate}
                    autoComplete="off"
                    autoFocus
                    required
                    onChange={(e) => { setScheduleDate(e.target.value) }}
                    spellCheck={false}
                    type="datetime-local"
                    className="form-control"
                />
                <div className="d-flex justify-content-between mt-3">
                    <div>
                        <button className="btn btn-primary mr-2 py-2">Save</button>
                        <button
                            onClick={() => {
                                setShowAddForm(false)
                                setScheduleAddress('')
                                setScheduleDate('')
                                setScheduleType('')
                                setScheduleText('')
                            }}
                            className="btn btn-light text-dark py-2"
                            type="button"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </form>) : (null)}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        token: state.userReducer.token,
        user: state.userReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCard);
