import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { URLS } from "../urls";
import Spinner from "../global/Spinner";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import Project from "../projects/Project";
import EditProjectModal from "../projects/EditProjectModal";
import AllTypeTasksClient from "../tasks/AllTypeTasksClient";
import {
  deleteProject,
  getProjectsByClientId,
} from "../../services/projectServices";
import { getTeamsICreated } from "../../services/teamServices";
import { getCompanyUsers } from "../../services/userServices";
import { getClientById, getClients } from "../../services/clientServices";
import { getTasksByClientId } from "../../services/taskServices";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import "../../assets/scss/new-ui.scss";
import ProjectModal from "../task-update/modals/ProjectModal";
import { showToast, styleHelper } from "../task-update/utils";
import DialogModal from "../task-update/modals/DialogModal";

class ClientProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: {
        companyName: "",
        companyEmail: "",
        companyPhone: "",
        contactPersonFirstName: "",
        contactPersonLastName: "",
        contactPersonEmail: "",
        contactPersonPhone: "",
      },
      projects: [],
      editProject: "",
      clientList: [],
      employeesList: [],
      projectTasks: [],
      activeView: "projects",
      selectedProjectId: null,
      isEditModalOpen: false,
      editProjectData: "",
      isLoading: true,
    };
  }

  selectClient = (_id) => {
    this.setState({ selectedProjectId: _id });
  };

  editProject = async (project) => {
    await this.setState({ editProjectData: project });
    this.handleModal();
  };

  getEditProjectDataFromId = (id) => {
    const { projects } = this.state;
    return projects.find((item) => id === item._id);
  };

  updateProjectsList = (project, isProjectModalOpen = false) => {
    let { projects } = this.state;
    const id = project && project._id;
    if (id) {
      const token = this.props.token;

      fetch(`${URLS.backendProjects}/${id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          let isExists = false;
          for (let index = projects.length - 1; index >= 0; --index) {
            if (projects[index]._id === id) {
              projects.splice(index, 1);
              projects.splice(index, 0, result.project);
              isExists = true;
              break;
            }
          }
          if (isExists) {
            this.setState({ projects: projects });
          } else {
            this.setState({ projects: projects.concat(result.project) });
          }

          if (isProjectModalOpen) return;
          this.handleModal("edit");
        });
    }
  };

  async componentDidMount() {
    const id = window.location.pathname.split("/")[2];
    const token = this.props.token;

    const [
      clientResponse,
      teamsResponse,
      usersResponse,
      projectsResponse,
      clientsResponse,
      tasksResponse,
    ] = await Promise.all([
      getClientById(token, id),
      getTeamsICreated(token),
      getCompanyUsers(token),
      getProjectsByClientId(token, id),
      getClients(token, id),
      getTasksByClientId(token, id),
    ]);

    this.setState({
      client: clientResponse.client || [],
      teams: teamsResponse.teams || [],
      employeesList: usersResponse.users || [],
      projects: projectsResponse.projects || [],
      clientList: clientsResponse.clients || [],
      projectTasks: tasksResponse.tasks || [],
      isLoading: false,
    });
  }

  getClientInitials = (name) => {
    return name.charAt(0);
  };

  handleActiveView = (activeView) => {
    this.setState({ activeView });
  };

  handleDeleteProject = async (projectToDelete) => {
    const res = await DialogModal({
      title: "Delete Project",
      description: `Are you sure, you want to delete?`,
      type: "warning",
      form: [
        {
          label: "Delete Project Folder",
          name: "canDeleteProjectFolder",
          value: false,
          type: "option",
        },
      ],
    });
    if (res) {
      let projects = this.state.projects;
      const token = this.props.token;

      deleteProject(
        projectToDelete._id,
        projectToDelete.project_folder_id,
        token,
        res.canDeleteProjectFolder
      ).then((response) => {
        if (response.success) {
          projects = projects.filter(
            (project) => project._id !== projectToDelete._id
          );
          this.setState({ projects });
          showToast({
            message: {
              title: "Project Deleted",
            },
            type: "success",
          });
        }
      });
    }
  };

  handleModal = () => {
    const isEditModalOpen = !this.state.isEditModalOpen;
    if (!isEditModalOpen) {
      // window.$("#edit_project").modal("toggle");
    }
    this.setState({ isEditModalOpen });
  };

  render() {
    const {
      client,
      projects,
      editProject,
      employeesList,
      clientList,
      projectTasks,
      activeView,
      isEditModalOpen,
      editProjectData,
      teams,
      isLoading,
    } = this.state;

    return (
      <div className="main-wrapper">
        <Topbar />
        <div className="d-flex new-wrapper sleek-scrollbar">
          <Sidebar activeSideBar="clients" />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper p-0">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid  px-0 px-md-4 rounded">
              {/*<!-- Page Title -->*/}
              <div className="g-crumb px-4 px-md-0">
                <a href="/">Home</a> <ChevronRightIcon class="active" />{" "}
                <Link to={`${URLS.clients}`}>Clients</Link>{" "}
                <ChevronRightIcon class="active" />{" "}
                <a href="#" class="active">
                  Clients {client.companyName}
                </a>
              </div>

              {/*<!-- /Page Title -->*/}

              {isLoading ? (
                <Spinner />
              ) : (
                <div>
                  <div className="card-box mb-0">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="profile-view">
                          <div className="profile-img-wrap">
                            <div className="profile-img">
                              {client.image ? (
                                <img
                                  src={
                                    URLS.backendPublic +
                                    "/clients/" +
                                    client.image
                                  }
                                />
                              ) : (
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    height: "120px",
                                    width: "120px",
                                    backgroundColor: "#DCECF7",
                                    color: "#5D696F",
                                    fontSize: "30px",
                                  }}
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  {this.getClientInitials(client.companyName)}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="profile-basic">
                            <div className="row">
                              <div className="col-md-5 d-flex align-items-center">
                                <div>
                                  <h3 className="user-name m-t-0">
                                    {client.companyName}
                                  </h3>
                                  <h5 className="company-role m-t-0 mb-0">
                                    {client.contactPersonFirstName +
                                      " " +
                                      client.contactPersonLastName}
                                  </h5>
                                  {/* <div className="staff-msg">
                              <a href="chat.html" className="btn btn-custom">
                                Send Message
                              </a>
                            </div> */}
                                </div>
                              </div>
                              <div className="col-md-7">
                                <ul className="personal-info">
                                  <div
                                    style={{
                                      display: "flex",
                                      padding: "4px 0px",
                                    }}
                                  >
                                    <div className="title" style={{ flex: 1 }}>
                                      Company Phone:
                                    </div>
                                    <div className="text" style={{ flex: 1 }}>
                                      <span style={{ opacity: 0.6 }}>
                                        {client.companyPhone}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      padding: "4px 0px",
                                    }}
                                  >
                                    <div className="title" style={{ flex: 1 }}>
                                      Company Email:
                                    </div>
                                    <div className="text" style={{ flex: 1 }}>
                                      <span style={{ opacity: 0.6 }}>
                                        {client.companyEmail}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      padding: "4px 0px",
                                    }}
                                  >
                                    <div className="title" style={{ flex: 1 }}>
                                      Contact Person Phone:
                                    </div>
                                    <div className="text" style={{ flex: 1 }}>
                                      <span style={{ opacity: 0.6 }}>
                                        {client.contactPersonPhone}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      padding: "4px 0px",
                                    }}
                                  >
                                    <div className="title" style={{ flex: 1 }}>
                                      Contact Person Email:
                                    </div>
                                    <div className="text" style={{ flex: 1 }}>
                                      <span style={{ opacity: 0.6 }}>
                                        {client.contactPersonEmail}
                                      </span>
                                    </div>
                                  </div>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-box tab-box">
                    <div className="row user-tabs">
                      <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                        <ul className="nav nav-tabs nav-tabs-bottom">
                          <li
                            className={`nav-item col-sm-3 client-normal-tab p-cursor ${
                              activeView === "projects"
                                ? "client-active-tab"
                                : ""
                            }`}
                            data-toggle="tab"
                            data-target="#myprojects"
                            onClick={() => this.handleActiveView("projects")}
                          >
                            <a>Projects</a>
                          </li>
                          <li
                            className={`nav-item col-sm-3 client-normal-tab  p-cursor ${
                              activeView === "tasks" ? "client-active-tab" : ""
                            }`}
                            data-toggle="tab"
                            data-target="#tasks"
                            onClick={() => this.handleActiveView("tasks")}
                          >
                            <a>Tasks</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="tab-content profile-tab-content pt-0 mt-0">
                        {/*<!-- Projects Tab -->*/}
                        <div
                          id="myprojects"
                          className="tab-pane fade show active"
                        >
                          {projects && projects.length ? (
                            <div
                              className="row m-0"
                              style={{
                                gap: styleHelper.isMobile
                                  ? "1.5rem"
                                  : "3.75rem",
                              }}
                            >
                              {projects.map((project) => {
                                let projectTeam = [];
                                let projectLeader = [];
                                if (project.team && project.team.length) {
                                  projectTeam = project.team.map((employee) => {
                                    let name = employee.name.first;
                                    name += employee.name.middle
                                      ? " " + employee.name.middle
                                      : "";
                                    name += employee.name.last
                                      ? " " + employee.name.last
                                      : "";
                                    const id = employee._id;
                                    const image = employee.photo;
                                    return { name: name, id: id, image: image };
                                  });
                                }
                                if (
                                  project.projectLeader &&
                                  project.projectLeader.length
                                ) {
                                  projectLeader = project.projectLeader.map(
                                    (employee) => {
                                      let name = employee.name.first;
                                      name += employee.name.middle
                                        ? " " + employee.name.middle
                                        : "";
                                      name += employee.name.last
                                        ? " " + employee.name.last
                                        : "";
                                      const id = employee._id;
                                      const image = employee.photo;
                                      return {
                                        name: name,
                                        id: id,
                                        image: image,
                                      };
                                    }
                                  );
                                }
                                return (
                                  <Project
                                    key={project._id}
                                    project={project}
                                    projectTeamList={projectTeam}
                                    projectLeaderList={projectLeader}
                                    onEdit={this.editProject}
                                    onDelete={() =>
                                      this.handleDeleteProject(project)
                                    }
                                  />
                                );
                              })}
                            </div>
                          ) : (
                            <div className="d-flex flex-column align-items-center justify-content-center m-t-20 card">
                              <img
                                src="../../assets/svg/project.svg"
                                className="empty-svg m-t-20"
                              />
                              <h4
                                className="mt-5 m-b-20"
                                style={{ fontSize: "15px" }}
                              >
                                No Project found
                              </h4>
                            </div>
                          )}
                        </div>
                        {/*
                        <EditProjectModal
                            mode="edit"
                            project={editProjectData}
                            updateProjectsList={this.updateProjectsList}
                            clientList={clientList}
                            employeesList={employeesList}
                            teams={teams}
                            closeModal={() => this.handleModal()}
                          />

                        */}

                        {isEditModalOpen && (
                          <ProjectModal
                            showProjectModal={isEditModalOpen}
                            setShowProjectModal={(isOpen) => {
                              this.setState({ isEditModalOpen: isOpen });
                            }}
                            clientList={clientList}
                            teamsICreated={teams}
                            employeesList={employeesList}
                            mode={"edit"}
                            selectedProject={editProjectData}
                            projectEditStage={1}
                            projectUpdated={({ project, isProjectModalOpen }) =>
                              this.updateProjectsList(
                                project,
                                isProjectModalOpen
                              )
                            }
                          />
                        )}
                        {/*<!-- /Projects Tab -->*/}

                        {/*<!-- Task Tab -->*/}
                        <div id="tasks" className="tab-pane fade">
                          {projectTasks && projectTasks.length ? (
                            <div className="project-task bg-white">
                              <ul className="nav nav-tabs nav-tabs-top nav-justified mb-0">
                                <li className="nav-item">
                                  <a
                                    className="nav-link active"
                                    href="#all_tasks"
                                    data-toggle="tab"
                                    aria-expanded="true"
                                  >
                                    All Tasks
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link"
                                    href="#pending_tasks"
                                    data-toggle="tab"
                                    aria-expanded="false"
                                  >
                                    Pending Tasks
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link"
                                    href="#completed_tasks"
                                    data-toggle="tab"
                                    aria-expanded="false"
                                  >
                                    Completed Tasks
                                  </a>
                                </li>
                              </ul>
                              <div className="px-3">
                                <AllTypeTasksClient
                                  projectTasks={projectTasks}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex flex-column align-items-center justify-content-center m-t-20 card">
                              <img
                                src="../../assets/svg/undraw_Organizing_projects_0p9a.svg"
                                className="empty-svg m-t-20"
                              />
                              <h4
                                className="mt-5 m-b-20"
                                style={{ fontSize: "15px" }}
                              >
                                No Task found
                              </h4>
                            </div>
                          )}
                        </div>
                        {/*<!-- /Task Tab -->*/}

                        {/*
                        <div
                          className="modal custom-modal fade"
                          id="delete_project"
                          role="dialog"
                        >
                          <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                              <div className="modal-body">
                                <div className="form-header">
                                  <h3>Delete Project</h3>
                                  <p>Are you sure want to delete?</p>
                                </div>
                                <div className="modal-btn delete-action">
                                  <div className="row">
                                    <div className="col-6">
                                      <span
                                        className="btn btn-primary continue-btn"
                                        onClick={this.handleDeleteProject}
                                      >
                                        Delete
                                      </span>
                                    </div>
                                    <div className="col-6">
                                      <span
                                        data-dismiss="modal"
                                        className="btn btn-primary cancel-btn"
                                      >
                                        Cancel
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                       */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/*<!-- /Page Content -->*/}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClientProfile));
