import { TodoList } from "./TodoList";

import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import DialogModal from "../modals/DialogModal";
import { socket } from "../../../sockets";
import { allTaskMembers } from "../utils";
import { formatSlashedDate, showToast, styleHelper } from "../utils";
import { fetchContactPerson, deleteContactPerson, createContactPerson } from '../../../services/customerServices'
import ContactPersonCard from './ContactPersonCard'
import EditContactPerson from "./EditContactPerson";

function ContactPersonList({ token, companyName, selectedTask }) {
    const [showAddForm, setShowAddForm] = useState(false)
    const [showEditForm, setShowEditForm] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [position, setPosition] = useState('')
    const [contactPersons, setContactPersons] = useState([])
    const [index, setIndex] = useState('');
    const [editPerson, setEditPerson] = useState('')

    useEffect(() => {
        fetchData();
    }, [])

    async function fetchData() {
        let data = await fetchContactPerson(token, { taskId: selectedTask._id })
        setContactPersons([...data.data])
    }

    async function handleDeleteContact(data, index) {
        if (
            await DialogModal({
                title: "Delete",
                description: `Are you sure you want to delete?`,
                type: "warning",
            })
        ) {
            setContactPersons((oldValue) => {
                oldValue = oldValue.filter((el, i) => i !== index);
                return [...oldValue]
            })

            const res = await deleteContactPerson(token, { id: data._id });
            if (res.success) {
                showToast({
                    message: {
                        title: "Contact person deleted successfully...",
                    },
                    type: "success",
                });
            }
            console.log(res)
        }
    }

    const onSubmit = async (e) => {
        const payload = {
            name: { first: firstName, last: lastName },
            phone: phone,
            email: email,
            position: position,
            taskId: selectedTask._id,
            projectId: selectedTask.project
        };

        setShowAddForm(false)
        setContactPersons((oldValue) => {
            setFirstName('')
            setLastName('')
            setPhone('')
            setEmail('')
            setPosition('')
            showToast({
                message: {
                    title: "Contact person created successfully...",
                },
                type: "success",
            });
            return [...oldValue, payload]
        });

        await createContactPerson(token, payload)
        e.preventDefault()
    }

    return (
        <>
            <div className="todo">
                {!contactPersons.length ?
                    (<p className="title mb-2">Create contact persons in <strong>{companyName}</strong></p>) : (
                        <p className="title mb-2">Contact persons in <strong>{companyName}</strong></p>
                    )}
                {contactPersons.map((el, index) => (
                    <div key={index}>
                        <ContactPersonCard
                            index={index}
                            contactPerson={el}
                            reloadPerson={fetchData}
                            handleDeleteContact={handleDeleteContact}
                        />
                    </div>
                ))}
                {!showAddForm ? (<div className="mt-2 add-todo">
                    <button
                        onClick={() => {
                            setShowAddForm(true)
                        }}
                        className="btn btn-outline-primary border-0"
                    >
                        + Create contact person
                    </button>
                </div>) : (<form
                    key={contactPersons?.length}
                    className="mt-3"
                    onSubmit={(e) => onSubmit(e)}
                >
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <input
                                placeholder="First Name"
                                value={firstName}
                                autoComplete="off"
                                required
                                onChange={(e) => setFirstName(e.target.value)}
                                spellCheck={false}
                                className="form-control mb-3"
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <input
                                placeholder="Last Name"
                                value={lastName}
                                autoComplete="off"
                                required
                                onChange={(e) => setLastName(e.target.value)}
                                spellCheck={false}
                                className="form-control mb-3"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <input
                                placeholder="Phone"
                                value={phone}
                                autoComplete="off"
                                required
                                onChange={(e) => setPhone(e.target.value)}
                                spellCheck={false}
                                className="form-control mb-3"
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <input
                                placeholder="Email"
                                value={email}
                                type="email"
                                autoComplete="off"
                                required
                                onChange={(e) => setEmail(e.target.value)}
                                spellCheck={false}
                                className="form-control mb-3"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <input
                                placeholder="Position"
                                value={position}
                                autoComplete="off"
                                required
                                onChange={(e) => setPosition(e.target.value)}
                                spellCheck={false}
                                className="form-control mb-3"
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                        <div>
                            <button className="btn btn-primary mr-2 py-2">Save</button>
                            <button
                                onClick={() => setShowAddForm(false)}
                                className="btn btn-light text-dark py-2"
                                type="button"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>)}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.userReducer.token,
        user: state.userReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactPersonList);
