import React, { useEffect, useState, useRef } from "react";
import PencilIcon from "mdi-react/PencilIcon";
import PaletteIcon from "mdi-react/PaletteIcon";
import TrashCanIcon from "mdi-react/TrashCanOutlineIcon";
import PlusIcon from "mdi-react/PlusIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { Dropdown, Form } from "react-bootstrap";
import { showToast } from "../utils";
import { updateList } from "../../../services/projectServices";
import ColorPicker from "./ColorPicker";
import { socket } from "../../../sockets";

export default function StageHeader({
  listItem,
  projectId,
  setSelectedList,
  setShowTaskModal,
  // seteditableList,
  handleDeleteList,
  selectedProject,
  token,
  handleUpdatedList,
  showColorPicker,
  allList,
  user,
  isProjectLeaderOrCreatedBy,
}) {
  const [editableList, setEditableList] = useState({});
  const [colorPickertoggleRef, setColorPickertoggleRef] = useState("");
  const [list, setList] = useState({});
  const inputRef = useRef("");
  const toggleRef = useRef("");
  useEffect(() => {
    setList(listItem);
    return () => {};
  }, [listItem]);

  const handleSubmit = async () => {
    // e.preventDefault();
    // console.log(e);
    // if (e.keyCode === 13) {
    try {
      const listWithRemovedCurrent = allList.filter(
        (listEl) => listEl.status !== list.status
      );
      if (
        listWithRemovedCurrent.find(
          (list) => list.status === editableList.status
        )
      ) {
        showToast({
          message: {
            title: "There's already a list with that name",
          },
          type: "error",
        });
        return;
      }
      editableList.tasks = [];
      socket.emit(
        "fx:update-list",
        {
          projectId: selectedProject._id,
          list: editableList,
        },
        (response) => {
          if (response.success) {
            handleUpdatedList({
              listToUpdate: editableList,
              project: response.project,
            });
            setEditableList({});
          } else {
            throw new Error();
          }
        }
      );
    } catch (err) {
      console.log(err);
      showToast({
        message: {
          title: "Unable to edit List",
        },
        type: "error",
      });
    }
    // }
  };

  return (
    <div className="stage-header d-flex justify-content-between">
      {/*<span class="noti-dot position-absolute"></span>*/}
      <span
        className={`stage-title  ${
          editableList?._id !== list._id ? "d-block" : "d-none"
        }`}
        title={list.title}
      >
        {list.title}
      </span>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <input
          className={`stage-title bg-white w-100 ${
            editableList?._id !== list._id ? "d-none" : "d-block"
          }`}
          value={editableList.title || ""}
          onChange={(e) =>
            setEditableList({
              ...editableList,
              title: e.target.value,
              // status: e.target.value,
            })
          }
          autoFocus
          onBlur={() => setEditableList({})}
          ref={inputRef}
          name="listName"
          autoComplete="off"
          required
        />
      </Form>
      {isProjectLeaderOrCreatedBy() || list.createdBy === user._id ? (
        <Dropdown alignRight>
          <Dropdown.Toggle bsPrefix={`btn p-0 toggle-btn`}>
            <DotsHorizontalIcon />
          </Dropdown.Toggle>

          <Dropdown.Menu bsPrefix={`list-menu`} alignRight>
            <Dropdown.Item
              as="button"
              onClick={() => {
                setSelectedList(list);
                setShowTaskModal(true);
              }}
            >
              <PlusIcon /> Add Task
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={() => {
                setEditableList(list);
                setTimeout(() => {
                  inputRef.current.focus();
                  inputRef.current.select();
                }, 500);
              }}
            >
              <PencilIcon /> Edit List
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={(e) => {
                setSelectedList({ ...list });
                showColorPicker({
                  e,
                  checkedColor: list.color,
                  type: "list",
                });
              }}
            >
              <PaletteIcon /> List Colour
            </Dropdown.Item>
            <Dropdown.Item as="button" onClick={() => handleDeleteList(list)}>
              <TrashCanIcon /> Delete List
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : null}
    </div>
  );
}
